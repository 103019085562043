import { CSSTransition, TransitionGroup } from 'react-transition-group'
import React, { ReactNode } from 'react'
import { Close } from '../icons'
import {
  PopoverBackdrop,
  PopoverContainer,
  PopoverWindow,
  StyledCloseContainer,
  StyledContentContainer,
} from './styles'

interface Props {
  visible?: boolean
  onDismiss: () => void
  children?: ReactNode
}

export const Popover = ({ visible = true, onDismiss, children }: Props) => {
  return <TransitionGroup>
    {visible && (
      <CSSTransition
        timeout={300}
        in={visible}
        unmountOnExit
        mountOnEnter>
        <PopoverContainer onClick={onDismiss}>
          <PopoverBackdrop onClick={onDismiss}>
            <PopoverWindow onClick={(e) => e.stopPropagation()}>
              <StyledCloseContainer>
                <button
                  onClick={(event: any) => {
                    event.stopPropagation()
                    onDismiss()
                  }}
                  aria-label="close"
                >
                  <Close />
                </button>
              </StyledCloseContainer>
              <StyledContentContainer>
                {children}
              </StyledContentContainer>
            </PopoverWindow>
          </PopoverBackdrop>
        </PopoverContainer>
      </CSSTransition>
    )}
  </TransitionGroup>
}
