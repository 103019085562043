import { ModalProps } from './models'

export enum ModalActions {
  ShowModal = 'modal/SHOW_MODAL',
  HideModal = 'modal/HIDE_MODAL'
}

export interface ShowModal {
  type: typeof ModalActions.ShowModal
  modal: ModalProps
}

export const showModal = (modal: Omit<ModalProps, 'display'>): ModalActionTypes => ({
  type: ModalActions.ShowModal,
  modal: {
    ...modal,
    display: true,
  },
})

export const showStartOverModal = (startOverFunction: () => Promise<void> ): ModalActionTypes =>
  showModal({
    title: 'Start Over',
    body: 'All the progress and selections will be lost. Are you sure you want to start over?',
    secondaryButtonText: 'Cancel',
    actionButtonText: 'Yes',
    action: () => startOverFunction(),
    name: 'startOver',
  })

export interface HideModal {
  type: typeof ModalActions.HideModal
}

export const hideModal = (): ModalActionTypes => ({
  type: ModalActions.HideModal,
})

export type ModalActionTypes =
  | ShowModal
  | HideModal
