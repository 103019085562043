import styled, { css } from 'styled-components'
import { FONTS, LAYOUT } from '../../../constants/styles'

import breakpoint from '../../../utils/breakpoints'
// Image
import background from '../../../static/images/background.svg'
import backgroundLarge from '../../../static/images/background-large.svg'

import { BREAKPOINTS } from '../../../constants/breakpoints'
import { HeadlineH3Dark } from '../../../styles/text.styles'
import { LandingContent } from '../LandingContent'

export const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
`

export const StyledLogo = styled.h1`
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  ${HeadlineH3Dark}
  color: #fff;
  display: inline-block;
  padding-top: 32px;
`

export const StyledNav = styled.div`
  width: 100%;
  background: transparent;
  margin: 0 auto;

  ${breakpoint(BREAKPOINTS.desktopMin)`
    padding: 0 24px;
    max-width: ${LAYOUT.LANDING_CONTAINER}px;
    margin: 0 auto;
  `}
   ${breakpoint(BREAKPOINTS.ultraWideMin)`
    max-width: ${LAYOUT.LANDING_CONTAINER_LARGE}px;
  `}
`

export const StyledLandingContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`

const ContentPadding = css`
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  ${breakpoint(BREAKPOINTS.desktopMin)`
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 8%;
  `}
  ${breakpoint(BREAKPOINTS.ultraWideMin)`
    padding-bottom: 48px;
  `}
`

export const StyledLandingContent = styled(LandingContent)`
  ${ContentPadding}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    max-width:  ${LAYOUT.LANDING_CONTAINER}px;
    margin: 0 auto;
  `}
   ${breakpoint(BREAKPOINTS.ultraWideMin)`
    max-width: ${LAYOUT.LANDING_CONTAINER_LARGE}px;
    margin-top: 145px;
  `}
  &:last-child{
    margin-bottom: 0;
  }
`

export const StyledLandingContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${ContentPadding}

  ${breakpoint(BREAKPOINTS.desktopMax)`
    margin-bottom: 40px;
    padding-bottom: 11%;
  `}

  ${breakpoint(BREAKPOINTS.tabletMax)`
    padding-bottom: 50px;
    margin-bottom: 55px;
    background-position: unset;
  `}

  ${breakpoint(BREAKPOINTS.desktopMin)`
    max-height: 635px;
  `}
  ${breakpoint(BREAKPOINTS.ultraWideMin)`
    background-image: url(${backgroundLarge});
  `}
`
