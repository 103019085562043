import React, { useEffect, useState } from 'react'
import { LeaseCalculationModel } from '../models'
import {
  MobileResultsContainer,
  ResultsContainer,
  ResultsTitle,
  StyledAddButton,
  StyledButtonContainer,
  StyledButtonWrapperWithMargin,
  StyledLeaseResultsContainer,
  StyledResultsSummaryCard,
  StyledMobileButtonWrapper
} from './styles'
import ButtonWrapper, { ButtonType } from '../../common/button'
import MediaQuery from 'react-responsive'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { Tooltip } from '../../common/tooltip'
import { useBreakpoint, usePrevious } from '../../../utils/hooks/hooks'


interface Props {
  results: readonly LeaseCalculationModel[]
  addLease: () => void
  removeLease: (model: LeaseCalculationModel) => void
  onTapLease: (model: LeaseCalculationModel) => void
  selectedLease: string
  viewDetailedResults: () => void
  currencySymbol: string
  canAddLease: boolean
  className?: string
}

type ResultsProps ={
  results: readonly LeaseCalculationModel[]
  removeLease: (model: LeaseCalculationModel) => void
  onTapLease: (model: LeaseCalculationModel) => void
  selectedLease: string
  currencySymbol: string
  scrollContainer: any
}

const renderLeaseResults = (
  {
    results,
    onTapLease,
    selectedLease,
    currencySymbol,
    removeLease,
    scrollContainer
  }
    : ResultsProps) =>
  <StyledLeaseResultsContainer ref={scrollContainer}>
    {results.map(r => <StyledResultsSummaryCard
      className="lease-result-card"
      key={r.lease.id}
      onClick={() => onTapLease(r)}
      leaseName={r.name}
      npv={r.totalNPV}
      leaseType={r.type}
      isSelected={selectedLease === r.lease.id}
      currencySymbol={currencySymbol}
      onClose={() => removeLease(r)}
    />)}
  </StyledLeaseResultsContainer>

const ResultSummaryCardContainer = (
  {
    results, removeLease, addLease, viewDetailedResults,
    selectedLease,
    currencySymbol, onTapLease,
    canAddLease,
    className,
  }: Props) => {
    const isMobile = useBreakpoint(BREAKPOINTS.desktopMax)
    const prevResults = usePrevious<number | undefined>(results.length);
    const useHookWithRefCallback = () => {
      const [node, setRef] = useState<any | null>(null)
      useEffect(
        () => {
          if (isMobile && prevResults && node && node !== null) {
            if(results.length > prevResults){
              // mobile should scroll left on new item
              node.scrollLeft = node.offsetWidth
            }
          }
        },
        [node],
      )

      return [setRef]
    }
    const [scrollContainer] = useHookWithRefCallback()

  return <ResultsContainer className={className}>
    <MediaQuery minWidth={BREAKPOINTS.desktopMin.value}>
      <ResultsTitle><span>Results</span> <Tooltip
        id="resultSummaryTooltip"
        title="The average cost is calculated from running five thousand simulations.
        Results may vary slightly with each calculation." />
      </ResultsTitle>
      {renderLeaseResults({
        results,
        onTapLease,
        selectedLease,
        currencySymbol,
        removeLease,
        scrollContainer
      })}
    </MediaQuery>
    <MediaQuery maxWidth={BREAKPOINTS.desktopMax.value}>
      <MobileResultsContainer>
        {renderLeaseResults({
          results,
          onTapLease,
          selectedLease,
          currencySymbol,
          removeLease,
          scrollContainer
        })}
        <StyledAddButton
          id='add-another-lease'
          text={''}
          type={ButtonType.Add}
          onClick={addLease}
          disabled={!canAddLease}
        />
      </MobileResultsContainer>
    </MediaQuery>
    {/* Desktop */}
    <MediaQuery minWidth={BREAKPOINTS.desktopMin.value}>
      <StyledButtonContainer>
        <ButtonWrapper
          // disable if we have 10 leases
          disabled={!canAddLease}
          onClick={addLease}
          text={'Add Another Option'}
          id={'add-another-lease'}
        />
        <StyledButtonWrapperWithMargin
          onClick={viewDetailedResults}
          type={ButtonType.Secondary}
          text={'View Detailed Results'}
          id={'view-detailed-results'}
        />
      </StyledButtonContainer>
    </MediaQuery>
    {/* Mobile */}
    <MediaQuery maxWidth={BREAKPOINTS.desktopMax.value}>
      <StyledMobileButtonWrapper
        text={'View Detailed Results'}
        onClick={viewDetailedResults}
        type={ButtonType.Secondary}
        id={'view-detailed-results'}
      />
    </MediaQuery>
  </ResultsContainer>
}

export default ResultSummaryCardContainer
