import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ href, label, icon, className, id }) => {
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        id={id}
      >
        <img src={icon} alt={label} className={className} />
      </a>
    )
  }

  return (
    <img src={icon} alt={label} className={className} />
  )
}

Icon.defaultProps = {
  href: '',
  className: '',
}

Icon.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default Icon
