import { MultiLeaseScenario, ScenarioLeases, ScenarioLeaseType } from '../../data/models'

export enum ScenarioActions {
  GetScenarioById = 'scenario/GET_SCENARIO_BY_ID',
  GetScenarioByIdSucceeded = 'scenario/GET_SCENARIO_BY_ID_SUCCEEDED',
  GetScenarioByIdFailed = 'scenario/GET_SCENARIO_BY_ID_FAILED',
  SaveScenario = 'scenario/SAVE_SCENARIO',
  SaveScenarioSucceeded = 'scenario/SAVE_SCENARIO_SUCCEEDED',
  SaveScenarioFailed = 'scenario/SAVE_SCENARIO_FAILED',
  StoreSavedScenario = 'scenario/STORE_SAVED_SCENARIO',
  AddNewLease = 'scenario/ADD_NEW_LEASE',
  AddLeaseLocally = 'scenario/ADD_LEASE_LOCALLY',
  RemoveLeaseLocally = 'scenario/REMOVE_LEASE_LOCALLY',
  DuplicateScenario = 'scenario/DUPLICATE_SCENARIO',
  DuplicateScenarioSucceeded = 'scenario/DUPLICATE_SCENARIO_SUCCEEDED',
  DuplicateScenarioFailed = 'scenario/DUPLICATE_SCENARIO_FAILED',
  EmailScenario = 'scenario/EMAIL_SCENARIO',
  EmailScenarioSucceeded = 'scenario/EMAIL_SCENARIO_SUCCEEDED',
  EmailScenarioFailed = 'scenario/EMAIL_SCENARIO_FAILED',
  PutNewScenario = 'scenario/PUT_NEW_SCENARIO',
  UpdateNPVDiscountRate = 'calculate/UPDATE_NPV_DISCOUNT_RATE',
  UpdateLocalScenario = 'scenario/UPDATE_LOCAL_SCENARIO',
  UpdateLocalLease = 'scenario/UPDATE_LOCAL_LEASE',
  SelectLease = 'scenario/SELECT_LEASE',
  SelectLeaseLocally = 'scenario/SELECT_LEASE_LOCALLY',
  SaveLeaseChanges = 'scenario/SAVE_LEASE_CHANGES',
  ClearSelectedLease = 'scenario/CLEAR_SELECTED_LEASE',
  SelectLeaseType = 'scenario/SELECT_LEASE_TYPE',
  NoCalculation = 'scenario/NO_CALCULATION',
  StartOverPreserveInputs = 'scenario/START_OVER_PRESERVE_INPUTS',
  StartOver = 'scenario/START_OVER',
  RestoreLeaseDefaults = 'scenario/RESTORE_LEASE_DEFAULTS',
  RecalculateLease = 'scenario/RECALCULATE_LEASE',
  ClearCurrentScenario = 'scenario/CLEAR_CURRENT_SCENARIO'
}

export interface GetScenarioById {
  type: typeof ScenarioActions.GetScenarioById
  scenarioId: string
}

export const getScenarioById = (scenarioId: string): ScenarioActionTypes => ({
  type: ScenarioActions.GetScenarioById,
  scenarioId,
})

export interface GetScenarioByIdSucceeded {
  type: typeof ScenarioActions.GetScenarioByIdSucceeded
  scenario: MultiLeaseScenario
}

export const getScenarioByIdSucceeded = (scenario: MultiLeaseScenario): ScenarioActionTypes => ({
  type: ScenarioActions.GetScenarioByIdSucceeded,
  scenario,
})

export interface GetScenarioByIdFailed {
  type: typeof ScenarioActions.GetScenarioByIdFailed
  error: Error
}

export const getScenarioByIdFailed = (error: Error): ScenarioActionTypes => ({
  type: ScenarioActions.GetScenarioByIdFailed,
  error,
})

export interface SaveScenario {
  type: typeof ScenarioActions.SaveScenario
  scenario: Partial<MultiLeaseScenario>
  isPrint?: boolean | undefined
}

export const saveScenario = (scenario: Partial<MultiLeaseScenario>, isPrint?: boolean | undefined): ScenarioActionTypes => ({
  type: ScenarioActions.SaveScenario,
  scenario,
  isPrint,
})

export interface SaveScenarioSucceeded {
  type: typeof ScenarioActions.SaveScenarioSucceeded
  scenario: MultiLeaseScenario
}

export const saveScenarioSucceeded = (scenario: MultiLeaseScenario): ScenarioActionTypes => ({
  type: ScenarioActions.SaveScenarioSucceeded,
  scenario,
})

export interface SaveScenarioFailed {
  type: typeof ScenarioActions.SaveScenarioFailed
  error: Error
}

export const saveScenarioFailed = (error: Error): ScenarioActionTypes => ({
  type: ScenarioActions.SaveScenarioFailed,
  error,
})

export interface StoreSavedScenario {
  type: typeof ScenarioActions.StoreSavedScenario
  scenario: MultiLeaseScenario
}

export const storeSavedScenario = (scenario: MultiLeaseScenario): ScenarioActionTypes => ({
  type: ScenarioActions.StoreSavedScenario,
  scenario,
})

export interface AddNewLease {
  type: typeof ScenarioActions.AddNewLease
  lease: ScenarioLeases
}

/**
 * Places a new lease in store to the current scenario and runs a set of calculations on it.
 * It should be a copy of the last lease based on requirements.
 */
export const addNewLease = (lease: ScenarioLeases): ScenarioActionTypes => ({
  type: ScenarioActions.AddNewLease,
  lease,
})

export interface AddLeaseLocally {
  type: typeof ScenarioActions.AddLeaseLocally
  lease: ScenarioLeases
}

export const addLeaseLocally = (lease: ScenarioLeases): ScenarioActionTypes => ({
  type: ScenarioActions.AddLeaseLocally,
  lease,
})

export interface RemoveLeaseLocally {
  type: typeof ScenarioActions.RemoveLeaseLocally
  leaseId: string
}

export const removeLeaseLocally = (leaseId: string): ScenarioActionTypes => ({
  type: ScenarioActions.RemoveLeaseLocally,
  leaseId,
})

export interface DuplicateScenario {
  type: typeof ScenarioActions.DuplicateScenario
}

export const duplicateScenario = (): ScenarioActionTypes => ({
  type: ScenarioActions.DuplicateScenario,
})

export interface DuplicateScenarioSucceeded {
  type: typeof ScenarioActions.DuplicateScenarioSucceeded
  scenario: MultiLeaseScenario
}

export const duplicateScenarioSucceeded = (scenario: MultiLeaseScenario): ScenarioActionTypes => ({
  type: ScenarioActions.DuplicateScenarioSucceeded,
  scenario,
})

export interface DuplicateScenarioFailed {
  type: typeof ScenarioActions.DuplicateScenarioFailed
  error: Error
}

export const duplicateScenarioFailed = (error: Error): ScenarioActionTypes => ({
  type: ScenarioActions.DuplicateScenarioFailed,
  error,
})

export interface EmailScenario {
  type: typeof ScenarioActions.EmailScenario
  email: string
}

export const emailScenario = (email: string): ScenarioActionTypes => ({
  type: ScenarioActions.EmailScenario,
  email,
})

export interface EmailScenarioSucceeded {
  type: typeof ScenarioActions.EmailScenarioSucceeded
  scenario: MultiLeaseScenario
}

export const emailScenarioSucceeded = (scenario: MultiLeaseScenario): ScenarioActionTypes => ({
  type: ScenarioActions.EmailScenarioSucceeded,
  scenario,
})

export interface EmailScenarioFailed {
  type: typeof ScenarioActions.EmailScenarioFailed
  error: Error
}

export const emailScenarioFailed = (error: Error): ScenarioActionTypes => ({
  type: ScenarioActions.EmailScenarioFailed,
  error,
})

export interface PutNewScenario {
  type: typeof ScenarioActions.PutNewScenario
  scenario: MultiLeaseScenario
}

/**
 * Places a new scenario in state. This only stores it and does not save it.
 */
export const putNewScenario = (scenario: MultiLeaseScenario): ScenarioActionTypes => ({
  type: ScenarioActions.PutNewScenario,
  scenario,
})

export interface UpdateNPVDiscountRate {
  type: typeof ScenarioActions.UpdateNPVDiscountRate
  discountRate: number
}

export const updateNPVDiscountRate = (discountRate: number): ScenarioActionTypes => ({
  type: ScenarioActions.UpdateNPVDiscountRate,
  discountRate,
})

export interface UpdateLocalScenario {
  type: typeof ScenarioActions.UpdateLocalScenario
  scenario: Partial<MultiLeaseScenario>
}

/**
 * Updates the local scenario with whatever values you pass in here. It does not save
 * the scenario and will mark the current scenario as "dirty"
 */
export const updateLocalScenario = (scenario: Partial<MultiLeaseScenario>): ScenarioActionTypes => ({
  type: ScenarioActions.UpdateLocalScenario,
  scenario,
})

export interface UpdateLocalLease {
  type: typeof ScenarioActions.UpdateLocalLease
  updateValues: Partial<ScenarioLeases>
  existing: ScenarioLeases
  shouldRecalculate: boolean
}

/**
 * Updates the local lease with whatever values you pass in here. It does not save
 * the lease and will mark the current scenario as "dirty"
 */
export const updateLocalLease = (updateValues: Partial<ScenarioLeases>,
                                 existing: ScenarioLeases,
                                 /**
                                  * If false, this does not trigger a recalculation.
                                  */
                                 shouldRecalculate: boolean = true): ScenarioActionTypes => ({
  type: ScenarioActions.UpdateLocalLease,
  updateValues,
  existing,
  shouldRecalculate,
})

export interface RecalculateLease {
  type: ScenarioActions.RecalculateLease
  leaseToUpdate: ScenarioLeases
  existingLease: ScenarioLeases
}

export const recalculateLease = (leaseToUpdate: ScenarioLeases, existingLease: ScenarioLeases): ScenarioActionTypes => ({
  type: ScenarioActions.RecalculateLease,
  leaseToUpdate,
  existingLease,
})

export interface SelectLease {
  type: typeof ScenarioActions.SelectLease
  lease: ScenarioLeases
}

/**
 * Called when lease is selecteed. This action with run in a saga that selects the new lease, but
 * also updates global scenario state with the change to the lease, to ensure its saved.
 */
export const selectLease = (lease: ScenarioLeases): ScenarioActionTypes => ({
  type: ScenarioActions.SelectLease,
  lease,
})

export interface SelectLeaseLocally {
  type: typeof ScenarioActions.SelectLeaseLocally
  lease: ScenarioLeases
}

/**
 * Locally places the new selected lease in state.
 */
export const selectLeaseLocally = (lease: ScenarioLeases): ScenarioActionTypes => ({
  type: ScenarioActions.SelectLeaseLocally,
  lease,
})

export interface ClearSelectedLease {
  type: typeof ScenarioActions.ClearSelectedLease
}

export const clearSelectedLease = (): ScenarioActionTypes => ({
  type: ScenarioActions.ClearSelectedLease,
})

export interface SelectLeaseType {
  type: typeof ScenarioActions.SelectLeaseType
  leaseType: ScenarioLeaseType
}

export const selectLeaseType = (leaseType: ScenarioLeaseType): ScenarioActionTypes => ({
  type: ScenarioActions.SelectLeaseType,
  leaseType,
})

export interface NoCalculation {
  type: typeof ScenarioActions.NoCalculation
  reason: string
}

/**
 * Dispatches a logging action that no calculation was made for this scenario.
 */
export const noCalculation = (reason: string): ScenarioActionTypes => ({
  type: ScenarioActions.NoCalculation,
  reason,
})

export interface StartOverPreserveInputs {
  type: typeof ScenarioActions.StartOverPreserveInputs
}

/**
 * Starts over, preserves company inputs.
 */
export const startOverPreserveInputs = (): ScenarioActionTypes => ({
  type: ScenarioActions.StartOverPreserveInputs,
})

export interface StartOver {
  type: typeof ScenarioActions.StartOver
}

/**
 * Starts over app
 */
export const startOver = (): ScenarioActionTypes => ({
  type: ScenarioActions.StartOver,
})

export interface RestoreLeaseDefaults {
  type: typeof ScenarioActions.RestoreLeaseDefaults
}

export const restoreLeaseDefaults = (): ScenarioActionTypes => ({
  type: ScenarioActions.RestoreLeaseDefaults,
})

export interface SaveLeaseChanges {
  type: typeof ScenarioActions.SaveLeaseChanges
  lease: ScenarioLeases
}

export const saveLeaseChanges = (lease: ScenarioLeases): ScenarioActionTypes => ({
  type: ScenarioActions.SaveLeaseChanges,
  lease,
})

export interface ClearCurrentScenario {
  type: typeof ScenarioActions.ClearCurrentScenario
}

export const clearCurrentScenario = (): ScenarioActionTypes => ({
  type: ScenarioActions.ClearCurrentScenario,
})

export type ScenarioActionTypes =
  | SaveScenario
  | SaveScenarioSucceeded
  | SaveScenarioFailed
  | AddNewLease
  | AddLeaseLocally
  | DuplicateScenario
  | DuplicateScenarioSucceeded
  | DuplicateScenarioFailed
  | GetScenarioById
  | GetScenarioByIdSucceeded
  | GetScenarioByIdFailed
  | EmailScenario
  | EmailScenarioSucceeded
  | EmailScenarioFailed
  | PutNewScenario
  | RemoveLeaseLocally
  | UpdateNPVDiscountRate
  | UpdateLocalScenario
  | UpdateLocalLease
  | SelectLease
  | SelectLeaseLocally
  | ClearSelectedLease
  | SelectLeaseType
  | NoCalculation
  | StartOverPreserveInputs
  | RestoreLeaseDefaults
  | SaveLeaseChanges
  | RecalculateLease
  | StartOver
  | ClearCurrentScenario
  | StoreSavedScenario
