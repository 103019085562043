import { OptionValue } from './optionValues'

export interface UnitOfTime extends OptionValue {
  readonly optionName: string
  readonly convert: number
}

export const unitOfTime: readonly UnitOfTime[] = [
  {
    value: 1,
    optionName: 'Year',
    convert: 1,
  },
  {
    value: 2,
    optionName: 'Month',
    convert: 1 / 12,
  },
]
