import { createSelector } from 'reselect'
import { LeaseCalculationModel } from './models'
import {
  selectActiveLeaseTab,
  selectFlexOverrideLease,
  selectScenarioLeases,
  selectSelectedOriginalLease,
  selectTradOverrideLease,
} from '../../redux/scenario/scenario.selectors'
import { selectCalculationById, selectCompanyInputs } from '../../redux/calculations/calculation.selectors'
import { roundedPcCalcResultsToAverage } from '../../data/conversions'
import { ScenarioLeases, ScenarioLeaseType } from '../../data/models'

export const selectLeaseResults = createSelector((state: any) => state,
  selectScenarioLeases,
  selectCompanyInputs,
  selectTradOverrideLease,
  selectFlexOverrideLease,
  selectActiveLeaseTab,
  selectSelectedOriginalLease,
  (state, leases, inputs, tradOverride, flexOverride, activeTab, original) => {
    const leaseResults: LeaseCalculationModel[] = leases.map((l, index) => {
      const calculation = selectCalculationById(state)(l.id)
      let existing: ScenarioLeases = l as ScenarioLeases
      if ((original && original.id) === l.id) {
        switch (activeTab) {
          case ScenarioLeaseType.FlexCoworking:
            existing = flexOverride
            break
          case ScenarioLeaseType.Traditional:
            existing = tradOverride
            break
        }
      }
      return ({
        lease: l as ScenarioLeases,
        totalNPV: (calculation && roundedPcCalcResultsToAverage(calculation.totalNPV, inputs!.initialDesiredLeaseTerm)) || undefined,
        name: existing.name,
        type: existing.type,
      })
    })
    return leaseResults
  })

export const selectLeaseNames = createSelector(selectLeaseResults, (results) => results.map(r => r.name))
