import {
  CalculateIterationsInput,
  CostComparisonLease,
  INITIAL_NPV_DISCOUNT_RATE,
  LeaseWithCalculations,
  MultiLeaseScenario,
  PCCalcResults,
  ScenarioData,
  ScenarioLease,
  ScenarioLeaseFlexCoworking,
  ScenarioLeases,
  ScenarioLeaseTraditional,
  ScenarioLeaseType,
  TermOptionsLease,
  TraditionalLeaseCalculation,
} from './models'
import round from 'lodash/round'
import { CompanyLocation, CurrencyOption } from '../optionsConfig/models'
import { UnitOfMeasure } from '../utils/selectOptions/unitOfMeasure'
import { UnitOfTime } from '../utils/selectOptions/unitOfTime'
import { CompanyInputsShape } from './form_models'
import { annualSeatChange } from '../utils/selectOptions'
import { foundValue } from '../utils'
import { max, min } from 'lodash'
import { roundLeaseVariableData } from './lease_utils'

export const toMinMaxValues = (key: PCCalcResults, year: number, isMax: boolean) => {
  if (isMax) {
    return max([key.low[year],
      key.avg[year],
      key.high[year]])
  } else {
    return min([key.low[year],
      key.avg[year],
      key.high[year]])
  }
}

export const defaultCostComparisonRounding = -3

export const scenarioToScenarioData = (scenario: MultiLeaseScenario): ScenarioData => ({
  _id: scenario._id!, // enforce id exists.
  discountRate: scenario.discountRate,
  initialDesiredLeaseTerm: scenario.initialDesiredLeaseTerm,
  seatVolatility: scenario.seatVolatility,
  initialSeats: scenario.initialSeats,
  annualSeatChange: scenario.annualSeatChange,
})

export const scenarioLeaseTypeToUIName = (leaseType: ScenarioLeaseType, isMobile?: boolean): string => {
  switch (leaseType) {
    case ScenarioLeaseType.Traditional:
      return isMobile ? 'Standard' : 'Standard Lease'
    case ScenarioLeaseType.FlexCoworking:
      return isMobile ? 'Coworking' : 'Coworking Space'
  }
}

export const leaseTypeToAnnualReOccurringCostsHeader = (leaseType: ScenarioLeaseType): string => {
  switch (leaseType) {
    case ScenarioLeaseType.FlexCoworking:
      return 'Meeting Rooms & Rent'
    case ScenarioLeaseType.Traditional:
      return 'Operating Expenses & Rent'
  }
}

export const leaseTypeToOneTimeCostsHeader = (leaseType: ScenarioLeaseType): string => {
  switch (leaseType) {
    case ScenarioLeaseType.FlexCoworking:
      return 'Set-Up Costs'
    case ScenarioLeaseType.Traditional:
      return 'Fit-Out Costs'
  }
}

export const leaseTypeToShortName = ({ type, name }: { type: ScenarioLeaseType, name: string }): string => {
  switch (type) {
    case ScenarioLeaseType.FlexCoworking:
      return `${name} (C)`
    case ScenarioLeaseType.Traditional:
      return `${name} (S)`
  }
}

export const leaseTypeToLeaseDescription = (
  leaseType: ScenarioLeaseType,
  netArea: number | undefined,
  initialSeats: number,
  measureName: string,
  isMobile: boolean): string => {
  switch (leaseType) {
    case ScenarioLeaseType.Traditional:
      return `${!isMobile ? 'Starting at ' : ''}${netArea!.toLocaleString()} ${measureName}`
    case ScenarioLeaseType.FlexCoworking:
      return `${initialSeats} Seat${initialSeats !== 1 ? ('s' || '') : ''}`
  }
}


export const pcCalcResultsToAverage = (results: PCCalcResults, year: number) => {
  // results arrays are 0 indexed, but year is input-parsed and not 0-based
  const average = results.avg.length > 1 ? results.avg[year - 1] : results.avg[0]
  return round(average)
}

export const roundedPcCalcResultsToAverage = (results: PCCalcResults, year: number) =>
  round(pcCalcResultsToAverage(results, year), defaultCostComparisonRounding)

export const mergeScenario = (existingScenario: MultiLeaseScenario | undefined,
                              inputs: CompanyInputsShape,
                              companyLocation: CompanyLocation,
                              leases: readonly ScenarioLease[],
                              currency: CurrencyOption,
                              unitOfMeasure: UnitOfMeasure,
                              unitOfTime: UnitOfTime): MultiLeaseScenario => {
  const updateValues = {
    ...inputs,
    leases,
    unitCurrency: {
      currency: currency.value,
      unitOfMeasure: unitOfMeasure.value,
      unitOfTime: unitOfTime.value,
    },
    annualSeatChangeVariance: foundValue(annualSeatChange, inputs.annualSeatChange).value,
    market: companyLocation.optionName,
    measureName: unitOfMeasure.uom,
    periodName: unitOfTime.optionName,
    currencyName: currency.iso,
  }
  return (
    existingScenario && {
      _id: '',
      ...existingScenario,
      ...updateValues,
    }
  ) || {
    _id: '',
    ...updateValues,
    email: [],
    discountRate: INITIAL_NPV_DISCOUNT_RATE,
    save: 0, // true / false
    print: 0,
    scenarioName: 'Untitled Scenario', // default name. find a better place for this.
    timestamp: '', // blank, it'll get updated on the server.
  }
}

/**
 * Merges an existing scenario (if specified) into the necessary data structure to submit for iteration calculation.
 * @param existingScenario - existing
 * @param unitOfTime - {@link UnitOfTime}
 * @param inputs - {@link CompanyInputsShape}
 * @param discountRate
 * @param leases - set of leases you wish to calculate. could be any number 1 to N.
 */
export const iterationsInputForCompanyInputs = (existingScenario: MultiLeaseScenario | undefined,
                                                unitOfTime: UnitOfTime,
                                                unitOfMeasure: UnitOfMeasure,
                                                currency: CurrencyOption,
                                                inputs: CompanyInputsShape,
                                                discountRate: number,
                                                leases: readonly ScenarioLease[]): CalculateIterationsInput => {
  const { annualSeatChange, initialSeats, initialDesiredLeaseTerm, seatVolatility } = inputs
  return {
    timeValue: unitOfTime.convert,
    scenario: {
      _id: '',
      // use existing data first in scenario, then use action inputs overriding it.
      ...(existingScenario && scenarioToScenarioData(existingScenario)),
      annualSeatChange,
      initialSeats,
      initialDesiredLeaseTerm,
      seatVolatility,
      discountRate: INITIAL_NPV_DISCOUNT_RATE,
    },
    /*eslint-disable-next-line array-callback-return */
    leases: leases.map(l => {
      switch (l.type) {
        case ScenarioLeaseType.Traditional:
          return adjustTradLeaseForInputs(l as ScenarioLeaseTraditional, unitOfTime, unitOfMeasure, currency)
        case ScenarioLeaseType.FlexCoworking:
          return adjustFlexLeaseForInputs(l as ScenarioLeaseFlexCoworking, currency)
      }
    }),
  }
}

export const adjustTradLeaseForInputs = (lease: ScenarioLeaseTraditional,
                                         unitOfTime: UnitOfTime,
                                         unitOfMeasure: UnitOfMeasure,
                                         currency: CurrencyOption): ScenarioLeaseTraditional => {
  const currencyMeasureMultiplier = currency.usd * unitOfMeasure.convert
  const currencyMeasureTimeMultiplier = currencyMeasureMultiplier * unitOfTime.convert
  return {
    ...lease,
    rentRate: roundLeaseVariableData(lease.rentRate * currencyMeasureTimeMultiplier),
    operatingCosts: roundLeaseVariableData(lease.operatingCosts * currencyMeasureTimeMultiplier),
    facilityManagement: roundLeaseVariableData(lease.facilityManagement * currencyMeasureTimeMultiplier),
    otherMiscOpex: roundLeaseVariableData(lease.otherMiscOpex * currencyMeasureTimeMultiplier),
    capexHardCost: roundLeaseVariableData(lease.capexHardCost * currencyMeasureMultiplier),
    capexFFE: roundLeaseVariableData(lease.capexFFE * currencyMeasureMultiplier),
    capexSoftCost: roundLeaseVariableData(lease.capexSoftCost * currencyMeasureMultiplier),
    capexITData: roundLeaseVariableData(lease.capexITData * currencyMeasureMultiplier),
    tiAllowanceValue: roundLeaseVariableData(lease.tiAllowanceValue * currencyMeasureMultiplier),
    reinstatement: roundLeaseVariableData(lease.reinstatement * currencyMeasureMultiplier),
    usableSeatArea: roundLeaseVariableData(lease.usableSeatArea * (1 / unitOfMeasure.convert)),
    rentableSeatArea: roundLeaseVariableData(lease.rentableSeatArea / unitOfMeasure.convert),
    totalGrossRentableAreaYear2: roundLeaseVariableData(lease.totalGrossRentableAreaYear2 / unitOfMeasure.convert),
  }
}

export const adjustFlexLeaseForInputs = (lease: ScenarioLeaseFlexCoworking,
                                         currency: CurrencyOption): ScenarioLeaseFlexCoworking => {
  const currencyValue = currency.usd
  return {
    ...lease,
    otherOpexCharges: roundLeaseVariableData(lease.otherOpexCharges * currencyValue),
    quotedSeatCharges: roundLeaseVariableData(lease.quotedSeatCharges * currencyValue),
    oneTimeSetupCost: roundLeaseVariableData(lease.oneTimeSetupCost * currencyValue),
    meetingRoomChargesHourly: roundLeaseVariableData(lease.meetingRoomChargesHourly * currencyValue),
    monthlyMeetingRoomCost: roundLeaseVariableData(lease.monthlyMeetingRoomCost * currencyValue),
  }
}

export const minNetArea = (lease: TraditionalLeaseCalculation, year: number) =>
  round(toMinMaxValues(lease.netArea, year, false) || 0)

export const leaseToCostComparisonLease = (inputs: CompanyInputsShape,
                                           lease: ScenarioLeases,
                                           leaseCalc: LeaseWithCalculations): CostComparisonLease => {
  const year = inputs.initialDesiredLeaseTerm - 1
  switch (leaseCalc.type) {
    case ScenarioLeaseType.Traditional:
      return {
        id: leaseCalc.id,
        type: leaseCalc.type,
        name: lease.name,
        npv: roundedPcCalcResultsToAverage(leaseCalc.totalNPV, year),
        costSeatYear: roundedPcCalcResultsToAverage(leaseCalc.costSeatYear, year),
        oneTimeCosts: round(leaseCalc.breakdown.oneTimeTotalCost, defaultCostComparisonRounding),
        annualReOccurringCosts: round(leaseCalc.breakdown.facilMgmtCost, defaultCostComparisonRounding),
        netArea: minNetArea(leaseCalc, year),
        totalCosts: roundedPcCalcResultsToAverage(leaseCalc.totalCost, year),
        annualCost: roundedPcCalcResultsToAverage(leaseCalc.totalCost, year) / inputs.initialDesiredLeaseTerm,
      }
    case ScenarioLeaseType.FlexCoworking:
      return {
        id: leaseCalc.id,
        type: leaseCalc.type,
        name: lease.name,
        npv: roundedPcCalcResultsToAverage(leaseCalc.totalNPV, year),
        costSeatYear: roundedPcCalcResultsToAverage(leaseCalc.costSeatYear, year),
        oneTimeCosts: round(leaseCalc.breakdown.oneTimeCosts, defaultCostComparisonRounding),
        annualReOccurringCosts: round(leaseCalc.breakdown.meetAncCost, defaultCostComparisonRounding),
        totalCosts: roundedPcCalcResultsToAverage(leaseCalc.totalCost, year),
        annualCost: roundedPcCalcResultsToAverage(leaseCalc.totalCost, year) / inputs.initialDesiredLeaseTerm,
      }
  }
}

export const leaseToTermOptionsLease = (lease: ScenarioLeases,
                                        leaseCalc: LeaseWithCalculations): TermOptionsLease => {
  return {
    id: lease.id,
    name: lease.name,
    type: lease.type,
    npv: leaseCalc.npvSeatYear,
    totalCosts: leaseCalc.totalCost,
    costSeatYear: leaseCalc.costSeatYear,
  }
}

export const scenarioToCompanyInputs = (scenario: MultiLeaseScenario): CompanyInputsShape => {
  return {
    initialDesiredLeaseTerm: scenario.initialDesiredLeaseTerm,
    seatVolatility: scenario.seatVolatility,
    annualSeatChange: scenario.annualSeatChange,
    initialSeats: scenario.initialSeats,
    location: scenario.location,
  }
}
