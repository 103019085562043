import React from 'react'
import logo from '../../static/images/logo.svg'
import { CONFIG } from './landing.config'
import {
  StyledFooterContact,
  StyledFooterContactContainer,
  StyledFooterContainer,
  StyledFooterIconContainer,
  StyledFooterLink,
  StyledFooterLinkColumn,
  StyledFooterLogoIconContainer,
  StyledFooterRouterLink,
  StyledQuestions,
  StyledSocialIcon,
  StyledFooterContainerInner
} from './styles'

export const LandingFooter = () => {
  return (
    <StyledFooterContainer>
      <StyledFooterContainerInner>
        <StyledFooterLogoIconContainer>
          <div>
            <img src={logo}
                alt="Footer Logo" />
          </div>
          <StyledFooterIconContainer>
            {CONFIG.SOCIAL.map(({ url, iconName, label }) => {
              return (<StyledSocialIcon
                href={url}
                icon={iconName}
                label={label}
                key={label}
                className="social--icon"
                id={label.toLowerCase()}
              />)
            })}
          </StyledFooterIconContainer>
        </StyledFooterLogoIconContainer>
        <StyledFooterContactContainer>
          <StyledFooterContact>
            <StyledFooterLinkColumn>
              <StyledQuestions>Questions?</StyledQuestions>
              <StyledFooterLink
                href="mailto:calcsupport@cbre.com"
                id="questions-email-link">calcsupport@cbre.com</StyledFooterLink>
              <StyledFooterRouterLink
                to="/termsofservice"
                href="/termsofservice"
                id="terms-of-service">Terms of Service</StyledFooterRouterLink>
              <StyledFooterLink
                href="https://www.cbre.us/about/privacy-policy"
                id="privacy-policy">Privacy Policy
              </StyledFooterLink>
            </StyledFooterLinkColumn>
          </StyledFooterContact>
        </StyledFooterContactContainer>
      </StyledFooterContainerInner>
    </StyledFooterContainer>
  )
}
