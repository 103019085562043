export const defaultMeetingRoomRequirement = {
  value: 12,
  name: 'Average *',
  optionName: 'Average',
}

export const meetingRoomRequirements = [
  {
    value: 8,
    name: 'Low *',
    optionName: 'Low',
  },
  defaultMeetingRoomRequirement,
  {
    value: 16,
    name: 'High *',
    optionName: 'High',
  },
]
