import styled from 'styled-components'

import { COLORS, LAYOUT } from '../../../../constants/styles'
// Utils
import breakpoint from '../../../../utils/breakpoints'
import LeaseResultsSummary from '../../leaseResultsSummary'
import { HeadlineH3Dark } from '../../../../styles/text.styles'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  ${breakpoint(BREAKPOINTS.desktopMax)`
    flex-direction: column;
  `}
`

export const StyledToggleWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`

export const StyledHeader = styled.div`
  padding-top: 3px;
  padding-bottom: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 18px;
    font-weight: 500;
    color: ${COLORS.DARKGREYTEXT};

    ${breakpoint(BREAKPOINTS.cbreMin)`
      font-size: 24px;
    `}
  }
`

export const StyledInputContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  height: 100%;
  position: relative;
  ${breakpoint(BREAKPOINTS.desktopMin)`
    border-right: solid 1px ${COLORS.LIGHT_01};
    padding-right: ${LAYOUT.GUTTER}px;
  `}
  h2 {
    font-weight: 500;
  }
`

export const ScrollableContainer = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  ${breakpoint(BREAKPOINTS.desktopMin)`
    overflow-y: auto;
    overflow-scrolling: touch;
    height: calc(100vh - ${LAYOUT.TABSHEIGHT + LAYOUT.HEADERHEIGHT + LAYOUT.TAB_CONTENT_TOP_PADDING + 1}px);
  `}
`

export const LeftContainer = styled.div`
  width: 100%;
  max-width: ${LAYOUT.LEASE_VARIABLES_LEFT_MAX_WIDTH}px;
  flex: 0;
`

export const RightContainer = styled.div`
  width: 100%;
  min-width: ${LAYOUT.LEASE_VARIABLES_RIGHT_MAX_WIDTH}px;
`

export const LeftWidth = styled.div`
  width: 100%;
  height: 100%;
  ${breakpoint(BREAKPOINTS.desktopMax)`
    width: 100%
    height: auto;
    transition: padding 0.2s ease-out;
    padding-bottom: ${LAYOUT.LEASE_RESULT_SUMMARY_HEIGHT}px;
    padding-bottom: calc(${LAYOUT.LEASE_RESULT_SUMMARY_HEIGHT}px + env(safe-area-inset-bottom));
  `}
`

export const AnimationContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    display: block;
    width: 100px;
  }
`

export const StyledLeaseResultsSummary = styled(LeaseResultsSummary)`
  padding-left: ${LAYOUT.GUTTER}px;
  padding-top: 2px;

  ${breakpoint(BREAKPOINTS.desktopMax)`
    padding: 0;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
    /* this is for iphones/ ipads */
    transition: padding 0.2s ease-out;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: ${COLORS.HIGHLIGHT};
  `}
`

export const StyledLeaseVariablesHeader = styled.h4`
  ${HeadlineH3Dark}
`

export const StyledRestoreDefaultInputsContainer = styled.div`
  ${breakpoint(BREAKPOINTS.desktopMax)`
    margin-top: 15px;
  `}
`
