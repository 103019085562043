import React from 'react'
import { bindActionCreators } from 'redux'
import { connect, Dispatch } from 'react-redux'
import Layout from '../../components/layout'
import { BREAKPOINTS } from '../../constants/breakpoints'
import Calculator from '../../components/calculator'
import { AppContainer } from './styles'
import PolicyConsentModal from '../../components/common/modal/policyModal'

import ModalWrapper, { ModalAction } from '../../components/common/modal'
import { selectModal } from '../../redux/modal/modal.selectors'
import { hideModal } from '../../redux/modal/modal.actions'
import { push } from 'react-router-redux'
import { ModalProps } from '../../redux/modal/models'
import { useWindowResize } from '../../utils/hooks/hooks'

export interface OwnProps {

}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = OwnProps & StateProps & DispatchProps

const handleModalCallback = (type: ModalAction,
                             modal: ModalProps,
                             hideModalFn: typeof hideModal) => {
  switch (type) {
    case ModalAction.Primary:
      if (modal.action) {
        modal.action()
      }
      break
    case ModalAction.Secondary:
      if (modal.secondaryAction) {
        modal.secondaryAction()
      }
      break
    case ModalAction.Close:
      break
  }
  hideModalFn()
}

export const Home = (
  {
    push,
    modal,
    hideModal,
  }: Props) => {
  useWindowResize((newWidth) => {
    // intercom messsenger plugin. used to boot up the messenger
    (window as any).Intercom('update', {
      hide_default_launcher: newWidth < BREAKPOINTS.desktopMin.value,
    })
  })
  return (
    <Layout>
      <AppContainer>
        <div>
          <Calculator />
        </div>
      </AppContainer>
      {/* Modal - currently used to confirm overriding form submissions */}
      <ModalWrapper
        primaryText={modal.actionButtonText}
        secondaryText={modal.secondaryButtonText}
        title={modal.title}
        callback={(type) => handleModalCallback(type, modal, hideModal)}
        visible={modal.display}
        name={modal.name}
      >
        <p>{modal.body}</p>
      </ModalWrapper>
      <PolicyConsentModal push={push} />
    </Layout>
  )
}

const mapStateToProps = (state: any) => ({ modal: selectModal(state) })

const mapDispatchToProps = (dispatch: Dispatch<any>) => (bindActionCreators({
  hideModal,
  push,
}, dispatch))

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Home)
