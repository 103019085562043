import React from 'react'
import { StyledTermOptionsGraphContainer } from './styles'
import { StyledLegend } from '../../headcountProjection/styles'
import { LegendLabel } from '../../legendLabel'
import { COLORS } from '../../../../constants/styles'
import { leaseTypeToShortName } from '../../../../data/conversions'
import { PCCalcResults, TermOptionsLease } from '../../../../data/models'
import { termOptionsGraphOptions } from './termOptionsGraphOptions'
import ReactHighcharts from 'react-highcharts'
import { breakEvenYear } from '../../../../utils/breakEvenYear'
import { ChartOption } from '../../../../utils/selectOptions/chartOptions'
import { valueForCurrentOption } from '../conversions'

import { useChartHeight } from '../../../../utils/hooks/hooks'

interface Props {
  firstLease: TermOptionsLease,
  secondLease: TermOptionsLease | undefined,
  initialDesiredLeaseTerm: number,
  chartOption: ChartOption,
  chartHeight?: number,
  chartWidth?: number | undefined,
  isPrint?: boolean,
  id?: string,
  loading?: boolean,
}

const leaseLabelsForResults = (firstValue: PCCalcResults,
                               initialDesiredLeaseTerm: number,
                               breakEvenYear: number | undefined): string[] => firstValue.avg.map((x, index) => {
  const year = index + 1
  const yearLease = `${year} Yr Lease`
  const isYearDesiredLeaseYear = initialDesiredLeaseTerm === year
  const isBreakEvenYear = breakEvenYear === year
  if (isYearDesiredLeaseYear && isBreakEvenYear) {
    return `<b>${yearLease} Anticipated</b><br /><b>Term & Breakeven</b>`
  } else if (isYearDesiredLeaseYear) {
    return `<b>${yearLease}</b><br /><b>Anticipated Term</b>`
  } else if (isBreakEvenYear) {
    return `<b>${yearLease}</b><br /><b>Breakeven</b>`
  }
  return `${year} Yr Lease`
})

const TermOptionsGraph = ({ firstLease, secondLease, initialDesiredLeaseTerm, chartOption, chartHeight = undefined, chartWidth = undefined, isPrint, id, loading = undefined}: Props) => {
  const firstValue = valueForCurrentOption(chartOption.value, firstLease)
  const secondValue = secondLease && valueForCurrentOption(chartOption.value, secondLease)
  const yearBreakEven = secondValue && breakEvenYear(firstValue, secondValue)

  const options = termOptionsGraphOptions(
    leaseLabelsForResults(firstValue, initialDesiredLeaseTerm, yearBreakEven),
    initialDesiredLeaseTerm,
    firstLease,
    firstValue,
    useChartHeight(chartHeight),
    chartWidth,
    secondLease,
    secondValue,
    isPrint)

  return <StyledTermOptionsGraphContainer isPrint={isPrint} id={id} loading={loading}>
    <StyledLegend isPrint={isPrint}>
      <LegendLabel
        color={COLORS.DARK_00}
        label={leaseTypeToShortName(firstLease)}
        isPrint={isPrint}
      />
      {secondLease && <LegendLabel color={COLORS.DARK_02}
                                   label={leaseTypeToShortName(secondLease)}
                                   isPrint={isPrint} />}
      <LegendLabel
        color={COLORS.LIGHT_01}
        label="Anticipated Lease Term"
        isPrint={isPrint}/>
    </StyledLegend>
    <ReactHighcharts config={options} />
  </StyledTermOptionsGraphContainer>
}

export default TermOptionsGraph
