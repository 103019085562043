export const rentFreePeriod = [
  { id: 0, optionName: '0 Months', value: 0 },
  { id: 1, optionName: '1 Month', value: 1 },
  { id: 2, optionName: '2 Months', value: 2 },
  { id: 3, optionName: '3 Months', value: 3 },
  { id: 4, optionName: '4 Months', value: 4 },
  { id: 5, optionName: '5 Months', value: 5 },
  { id: 6, optionName: '6 Months', value: 6 },
  { id: 7, optionName: '7 Months', value: 7 },
  { id: 8, optionName: '8 Months', value: 8 },
  { id: 9, optionName: '9 Months', value: 9 },
  { id: 10, optionName: '10 Months', value: 10 },
  { id: 11, optionName: '11 Months', value: 11 },
  { id: 12, optionName: '12 Months', value: 12 },
  { id: 13, optionName: '13 Months', value: 13 },
  { id: 14, optionName: '14 Months', value: 14 },
  { id: 15, optionName: '15 Months', value: 15 },
  { id: 16, optionName: '16 Months', value: 16 },
  { id: 17, optionName: '17 Months', value: 17 },
  { id: 18, optionName: '18 Months', value: 18 },
  { id: 19, optionName: '19 Months', value: 19 },
  { id: 20, optionName: '20 Months', value: 20 },
  { id: 21, optionName: '21 Months', value: 21 },
  { id: 22, optionName: '22 Months', value: 22 },
  { id: 23, optionName: '23 Months', value: 23 },
  { id: 24, optionName: '24 Months', value: 24 },
]
