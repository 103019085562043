import React from 'react'
import { PopoverContainer, StyledSaveButton } from './styles'
import { ButtonType } from '../../common/button'
import { selectPopoverScreen, selectPopoverVisible } from '../../../redux/popover/popover.selectors'
import { connect, Dispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closePopover, showPopover } from '../../../redux/popover/popover.actions'
import NameScenario from '../nameScenario'
import {
  selectActiveLeaseHasChanged,
  selectHasSavedScenario,
  selectHasScenarioChanged,
  selectScenarioLoading,
} from '../../../redux/scenario/scenario.selectors'
import AccessScenario from '../accessScenario'
import { saveScenario } from '../../../redux/scenario/scenario.actions'
import { Popover } from '../../common/popover'
import EmailScenario from '../emailScenario'
import { PopoverScreen } from '../../../redux/popover/popover.reducer'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

const saveButtonNameForState = (
  hasScenarioChanged: boolean,
  hasSavedScenario: boolean,
  hasActiveLeaseChanged: boolean) => {
  if (hasSavedScenario) {
    if (hasScenarioChanged || hasActiveLeaseChanged) {
      return 'Save Changes'
    } else {
      return 'View Links'
    }
  }
  return 'Save Scenario'
}

const idValueForState = (
  changed: boolean,
  saved: boolean,
  active: boolean) => {
    return `${saveButtonNameForState(changed, saved, active)
    .toLowerCase().split(' ').join('-')}--button`
}

const ContentComponent = ({ popoverScreen }: { popoverScreen: PopoverScreen }) => {
  switch (popoverScreen) {
    case PopoverScreen.Edit:
    case PopoverScreen.Naming:
      return <NameScenario />
    case PopoverScreen.Email:
      return <EmailScenario />
    case PopoverScreen.AccessLinks:
      return <AccessScenario />
  }
}

const SaveScenario = (
  {
    visible,
    closePopover,
    showPopover,
    hasScenarioChanged,
    hasSavedScenario,
    scenarioLoading,
    saveScenario,
    hasActiveLeaseChanged,
    popoverScreen,
  }: Props) => {

  return <PopoverContainer
    className="popover__container"
    id="popover">
    <StyledSaveButton
      text={saveButtonNameForState(hasScenarioChanged, hasSavedScenario, hasActiveLeaseChanged)}
      type={ButtonType.Secondary}
      disabled={(!hasScenarioChanged && !hasSavedScenario) || (hasScenarioChanged && scenarioLoading)}
      onClick={() => {
        if (hasSavedScenario && (hasScenarioChanged || hasActiveLeaseChanged)) {
          saveScenario({})
        } else {
          showPopover()
        }
      }}
      id={idValueForState(hasScenarioChanged, hasSavedScenario, hasActiveLeaseChanged)}
    />
    <Popover
      visible={visible}
      onDismiss={closePopover}>
      <ContentComponent popoverScreen={popoverScreen} />
    </Popover>
  </PopoverContainer>
}

const mapStateToProps = (state: any) => ({
  visible: selectPopoverVisible(state),
  popoverScreen: selectPopoverScreen(state),
  hasScenarioChanged: selectHasScenarioChanged(state),
  hasSavedScenario: selectHasSavedScenario(state),
  scenarioLoading: selectScenarioLoading(state),
  hasActiveLeaseChanged: selectActiveLeaseHasChanged(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => (bindActionCreators({
  closePopover,
  showPopover,
  saveScenario,
}, dispatch))

export default connect(mapStateToProps, mapDispatchToProps)(SaveScenario)
