import styled, { createGlobalStyle } from 'styled-components'
import Select from 'antd/lib/select'

import { COLORS, FONTS } from '../../../../constants/styles'
import breakpoint from '../../../../utils/breakpoints'

import { BREAKPOINTS } from '../../../../constants/breakpoints'


const delay = '0.6s'

export const DropdownContainer = styled.div`
  position: relative;
`
export const DropdownStyles = createGlobalStyle`
  .ant-select-intro{
    border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
`

export const StyledArrow = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  right:0;
  width: 25px;
  height: 25px;
  pointer-events: none;
  ${breakpoint(BREAKPOINTS.tabletMin)`
    right:4px;
  `}
  path{
    fill: ${COLORS.PRIMARY_00}
  }
  transition: transform 0.2s ease-out;
  transform: ${(props) => (props.isOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%) rotate(0deg)')};
  transform-origin: 50% 50%;
`

interface MenuContainerProps {
  large: boolean
}

export const MenuContainer = styled.div<MenuContainerProps>`
  transition: min-width 0.2s ease-out 2s;

  ${breakpoint(BREAKPOINTS.desktopMin)`
    min-width: ${({ large }: MenuContainerProps) => (large ? '873px' : '332px')} !important;
  `}
  .ant-select-dropdown{
    border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;

  }
  .ant-select-dropdown-menu{

  }
  .ant-select-dropdown-menu-item {
    padding: 22px 18px;
    font-family: ${FONTS.PRIMARY};
    font-weight: bold !important;
    font-size: 34px !important;
    line-height:1.19;
    color: ${COLORS.PRIMARY_00};
    background-color: ${COLORS.SECONDARY_00};
    ${({ large }) => large && `
       padding: 22px 18px;
       font-size: 39px !important;
    `}
    &.ant-select-dropdown-menu-item-active, &.ant-select-dropdown-menu-item-selected {
      background-color: ${COLORS.PRIMARY_00} !important;
      color: ${COLORS.SECONDARY_00};
    }
    &:hover {
      background-color: ${COLORS.SECONDARY_01} !important;
      color: ${COLORS.PRIMARY_00} !important;
    }

  }
`

interface StyledDropdownProps {
  setWidth?: string
}

const pickWidth = ({ setWidth }: StyledDropdownProps) => (!!setWidth && setWidth) || '514px'

export const StyledDropdown = styled(Select)<StyledDropdownProps>`
  padding-bottom: 8px;
  transition: width 0.4s ease-in-out;
  width: ${(props) => pickWidth(props)} !important;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    border-bottom: solid ${COLORS.DARK_01} 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &:focus-within {
    &:after{
      border-color: ${COLORS.PRIMARY_00} !important;
    }
  }
  .ant-select-selection {
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    height: 28px;
    ${breakpoint(BREAKPOINTS.ultraWideMin)`
       height: 46px;
    `}

    &:focus {
      box-shadow: none;
    }

    &__rendered {
      margin-left: 0;
      margin-right: 0;
    }

    &__placeholder {
      height: auto;
    }
  }

  .ant-select-search{
    height: 46px;
  }
  .ant-select-search__field{
    transition: font-size 0.4s ease-in-out ${delay};
    font-size: 16px;
    ${breakpoint(BREAKPOINTS.tabletMin)`
      font-size:28px;
    `}
    ${breakpoint(BREAKPOINTS.ultraWideMin)`
      font-size: 39px;
    `}
  }
  .ant-select-selection__placeholder{
    margin-top: -18px;
  }
  .ant-select-selection__placeholder, .ant-select-selection-selected-value{
    color: ${COLORS.PRIMARY_00} !important;
    font-family: ${FONTS.PRIMARY} !important;
    font-weight: bold;

    line-height: 1.19;
    letter-spacing: -0.81px;
    font-size: 16px;
    ${breakpoint(BREAKPOINTS.tabletMin)`
      font-size:28px;
    `}
    ${breakpoint(BREAKPOINTS.ultraWideMin)`
      font-size: 39px;
    `}
  }

  .ant-select {
    outline: none;
  }

  &.ant-select-open{
    .ant-select-selection__placeholder{
      color: ${COLORS.SHADOW_01} !important;
    }
  }

`

//ts doesn't let you pass custom props to dom elements so we had to use data-*
interface SelectProps {
  "data-large": boolean
}

export const StyledSelect = styled.select<SelectProps>`
  display: block;
  width: 100%;
  display: block;
  position: relative;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: ${COLORS.PRIMARY_00} !important;
  font-family: ${FONTS.PRIMARY} !important;
  background: #fff;
  border: none;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -2px;
  transition: font-size 0.4s ease-in-out ${delay}, line-height 0.4s ease-in-out ${delay}, letter-spacing 0.4s ease-in-out ${delay};
  padding-top: 12px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  font-size: 23px;
  font-size: ${(props) => (props['data-large'] ? '23px' : '16px')};

  line-height: 1.13;
  letter-spacing: 0.17px;
  border-bottom: solid ${COLORS.DARK_01} 1px;
  border-radius: 0;
  &:focus {
    outline:none;
  }
  ::-ms-expand {
    display: none;
  }
  option {
    font-weight:normal;
  }

`
export const StyledStory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: max-content;
`
