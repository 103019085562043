import styled, { createGlobalStyle } from 'styled-components'
import Select from 'antd/lib/select'

import { COLORS, FONTS } from '../../../../constants/styles'
import { UIDefault, UIDefaultImportant } from '../../../../styles/text.styles'

export const DropdownStyles= createGlobalStyle`
  .ant-message-notice-content {
    background: ${COLORS.PRIMARY_00};
    color: white;
    font-family: ${FONTS.PRIMARY_SEMIBOLD};
    padding: 10px 32px;
    font-size: 14px;

    .anticon {
      display: none;
    }
  }
`
export const DropdownContainer = styled.div`
`

export const MenuContainer = styled.div`
  .ant-select-dropdown-menu-item {
    padding: 10px 12px;
    font-family: ${FONTS.PRIMARY};
    font-weight: normal !important;
    font-size: 14px !important;
    color: ${COLORS.DARK_00};
    background-color: ${COLORS.SECONDARY_00};
    &.ant-select-dropdown-menu-item-active, &.ant-select-dropdown-menu-item-selected {
      color: ${COLORS.SECONDARY_00}!important;
      background-color: ${COLORS.PRIMARY_00}!important;
      font-weight: 300;
    }
    &:hover {
      background-color: ${COLORS.SECONDARY_01} !important;
      color: ${COLORS.DARK_00} !important;
    }
  }
`

export const StyledDropdown = styled(Select)`
  width: 100%;
  .ant-select-selection {
    border-color: ${COLORS.LIGHT_01};
    box-shadow: none;
    border-radius: 7px;
    &:hover, &:focus {
      border-color: ${COLORS.PRIMARY_00} !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-select-selection__rendered{
    margin-left: 16px;
    margin-right: 36px;
  }

  .ant-select-selection__placeholder{
    color: ${COLORS.GRAYTEXT} !important;
    ${UIDefaultImportant}
  }

  .ant-select-selection-selected-value {
    color: ${COLORS.DARK_00} !important;
    ${UIDefaultImportant}
    margin-top: 10px;
  }

  .ant-select {
    outline: none;
    width: auto;
  }

  .ant-select-dropdown-menu-item{
    &.ant-select-dropdown-menu-item-selected{
      color: ${COLORS.SECONDARY_00};
      background-color: ${COLORS.PRIMARY_00}
    }
  }
`
export const StyledSelect = styled.select`
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 7px;
  background-color: ${COLORS.SECONDARY_00};
  border: 1px solid ${COLORS.LIGHT_01};
  line-height: 1.13;
  letter-spacing: 0.17px;
  margin: 0;
  margin-right: 24px;
  padding: 10px 14px;
  ::-ms-expand {
    display: none;
  }
  option {
    font-weight:normal;
  }
  &:focus {
    outline: none;
  }
  ${UIDefault}
`
export const StyledArrow = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  right:12px;
  width: 22px;
  height: 22px;
  pointer-events: none;
  path{
    fill: ${COLORS.PRIMARY_00};
  }
  transition: transform 0.2s ease-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%) rotate(0deg)')};
  transform-origin: 50% 50%;
`

export const ArrowContainer = styled.div`
  position: relative;
`

export const StyledStory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: max-content;
`
