import React from 'react'

interface Props {
  width?: number
  height?: number
}

export const FlexibleLease1 = ({ width = 93, height = 84 }: Props) => (
      <svg xmlns="http://www.w3.org/2000/svg" width="95" height="82" viewBox="0 0 95 82">
      <g fill="none" fillRule="evenodd" transform="translate(1)">
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M93.421 81.5V55h-93v26.5"/>
          <circle cx="46.921" cy="10.5" r="10.5" fill="#009F6F" fillRule="nonzero"/>
          <path stroke="#ECF5F1" strokeLinecap="round" strokeLinejoin="round" d="M46.421 6v5h5"/>
          <path fill="#505958" fillRule="nonzero" d="M49.421 28h38v20h-38zM5.421 28h37v20h-37z"/>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M24.421 68v13.5M15 81.5l9.288-8.5L34 81.5M68.421 68v13.5M59 81.5l9.299-8.5L78 81.5"/>
          <rect width="24" height="7" x="12.421" y="61" fill="#AAB7B2" fillRule="nonzero" rx="1.49"/>
          <path fill="#AAB7B2" fillRule="nonzero" d="M24.421 38c5.523 0 10 4.396 10 9.818V62h-20V47.818c0-5.422 4.477-9.818 10-9.818z"/>
          <rect width="24" height="7" x="56.421" y="61" fill="#D5F0E5" fillRule="nonzero" rx="1.49"/>
          <path fill="#D5F0E5" fillRule="nonzero" d="M68.421 38c5.523 0 10 4.396 10 9.818V62h-20V47.818c0-5.422 4.477-9.818 10-9.818z"/>
      </g>
  </svg>

)

export const FlexibleLease2 = ({ width = 92, height = 84 }: Props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="94" height="82" viewBox="0 0 94 82">
      <g fill="none" fillRule="evenodd">
          <g strokeLinecap="round" strokeLinejoin="round" transform="translate(26 1)">
              <path stroke="#505958" d="M31 13h7v3.5c0 1.933-1.547 3.5-3.456 3.5s-3.456-1.567-3.456-3.5V13H31zM34 8v5"/>
              <circle cx="34" cy="5" r="3" fill="#009F6F" fillRule="nonzero" stroke="#009F6F" strokeWidth=".75"/>
              <path fill="#AAB7B2" fillRule="nonzero" stroke="#AAB7B2" strokeWidth=".75" d="M4 5h4v16H4z"/>
              <path fill="#7C8C88" fillRule="nonzero" stroke="#7C8C88" strokeWidth=".75" d="M17.092 7.863l3.514-.5 1.91 13.57-3.513.499z"/>
              <path fill="#009F6F" fillRule="nonzero" stroke="#009F6F" strokeWidth=".75" d="M12 2h5v19h-5z"/>
              <path fill="#008B61" fillRule="nonzero" stroke="#008B61" strokeWidth=".75" d="M8 0h4v21H8z"/>
              <path fill="#505958" fillRule="nonzero" stroke="#505958" strokeWidth=".75" d="M0 20h44v3H0z"/>
          </g>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M93.421 81.5V55h-93v26.5M68.421 68v13.5"/>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M59 81.5l9.299-8.5L78 81.5"/>
          <rect width="24" height="7" x="56" y="61" fill="#009F6F" fillRule="nonzero" rx="1.49"/>
          <path fill="#009F6F" fillRule="nonzero" d="M68 39c5.523 0 10 4.396 10 9.818V63H58V48.818C58 43.396 62.477 39 68 39z"/>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M24.421 68v13.5M15 81.5l9.299-8.5L34 81.5"/>
          <rect width="24" height="7" x="13" y="61" fill="#505958" fillRule="nonzero" rx="1.49"/>
          <path fill="#505958" fillRule="nonzero" d="M35 48.818V63H15V48.818C15 43.396 19.477 39 25 39s10 4.396 10 9.818z"/>
      </g>
</svg>
)

export const FlexibleLease3 = ({ width = 93, height = 84 }: Props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="95" height="82" viewBox="0 0 95 82">
    <g fill="none" fillRule="evenodd" transform="translate(1)">
        <g transform="translate(9)">
            <circle cx="25" cy="10" r="10" fill="#D5F0E5" fillRule="nonzero"/>
            <path stroke="#505958" strokeLinecap="round" strokeLinejoin="round" d="M24 6v5h5"/>
            <path fill="#505958" fillRule="nonzero" d="M43 35h31v20H43zM0 35h31v20H0z"/>
            <path stroke="#505958" strokeLinecap="round" strokeLinejoin="round" d="M41 6h15v19H41z"/>
            <path fill="#009F6F" fillRule="nonzero" d="M44 9h9v13h-9z"/>
        </g>
        <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M93.421 81.5V55h-93v26.5M24.421 68v13.5"/>
        <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M15 81.5l9.299-8.5L34 81.5M69.421 68v13.5M60 81.5l9.299-8.5L79 81.5"/>
        <rect width="24" height="7" x="13" y="61" fill="#009F6F" fillRule="nonzero" rx="1.49"/>
        <path fill="#009F6F" fillRule="nonzero" d="M25 39c5.523 0 10 4.396 10 9.818V63H15V48.818C15 43.396 19.477 39 25 39z"/>
        <rect width="24" height="7" x="57" y="61" fill="#D5F0E5" fillRule="nonzero" rx="1.49"/>
        <path fill="#D5F0E5" fillRule="nonzero" d="M69 39c5.523 0 10 4.396 10 9.818V63H59V48.818C59 43.396 63.477 39 69 39z"/>
    </g>
</svg>
)

export const FlexibleLease4 = ({ width = 93, height = 84 }: Props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="93" height="84" viewBox="0 0 93 84">
      <g fill="none" fillRule="evenodd" transform="translate(1)">
          <g transform="translate(5.872)">
              <ellipse cx="17.617" cy="10.244" fill="#009F6F" fillRule="nonzero" rx="9.787" ry="10.244"/>
              <path stroke="#ECF5F1" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75" d="M16.638 6.146v5.122h4.894"/>
              <path fill="#505958" fillRule="nonzero" d="M0 30.732h36.213V51.22H0z"/>
              <rect width="23.489" height="7.171" x="5.872" y="62.488" fill="#AAB7B2" fillRule="nonzero" rx="1.49"/>
              <path fill="#AAB7B2" fillRule="nonzero" d="M17.617 39.951c5.405 0 9.787 4.503 9.787 10.058v14.528H7.83V50.009c0-5.555 4.382-10.058 9.787-10.058z"/>
          </g>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M91.433 83.488V56.34H.413v27.147"/>
          <path fill="#505958" fillRule="nonzero" d="M72.426 45.073h14.68v9.879c0 .767-.624 1.39-1.394 1.39H73.82c-.77 0-1.394-.623-1.394-1.39v-9.879z"/>
          <ellipse cx="83.204" cy="22.307" fill="#009F6F" fillRule="nonzero" rx="4.963" ry="2.813" transform="rotate(-30 83.204 22.307)"/>
          <ellipse cx="83.221" cy="31.536" fill="#009F6F" fillRule="nonzero" rx="4.98" ry="2.823" transform="rotate(-29.887 83.22 31.536)"/>
          <ellipse cx="75.143" cy="26.646" fill="#009F6F" fillRule="nonzero" rx="2.802" ry="4.985" transform="rotate(-53.78 75.143 26.646)"/>
          <ellipse cx="75.144" cy="35.863" fill="#009F6F" fillRule="nonzero" rx="2.784" ry="5.017" transform="rotate(-45 75.144 35.863)"/>
          <ellipse cx="75.143" cy="17.426" fill="#009F6F" fillRule="nonzero" rx="2.802" ry="4.985" transform="rotate(-53.78 75.143 17.426)"/>
          <ellipse cx="84.183" cy="39.722" fill="#009F6F" fillRule="nonzero" rx="4.963" ry="2.813" transform="rotate(-30 84.183 39.722)"/>
          <path stroke="#505958" strokeLinecap="round" strokeLinejoin="round" d="M79.277 17.415V46.61"/>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M23.901 69.659v13.829M14.68 83.488l9.102-8.708 9.495 8.708M58.157 69.659v13.829M48.936 83.488l9.101-8.708 9.495 8.708"/>
          <rect width="23.489" height="7.171" x="46" y="62.488" fill="#D5F0E5" fillRule="nonzero" rx="1.49"/>
          <path fill="#D5F0E5" fillRule="nonzero" d="M57.745 39.951c5.405 0 9.787 4.503 9.787 10.058v14.528H47.957V50.009c0-5.555 4.382-10.058 9.788-10.058z"/>
      </g>
  </svg>
)

export const FlexibleLease5 = ({ width = 92, height = 84 }: Props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="94" height="82" viewBox="0 0 94 82">
      <g fill="none" fillRule="evenodd">
          <g transform="translate(12 1)">
              <path stroke="#505958" strokeLinecap="round" strokeLinejoin="round" d="M6 10h7v3.477c0 .934-.364 1.83-1.012 2.49A3.424 3.424 0 0 1 9.544 17a3.421 3.421 0 0 1-2.5-1A3.555 3.555 0 0 1 6 13.478V10zM10 5v5"/>
              <circle cx="10" cy="3" r="3" fill="#009F6F" fillRule="nonzero" stroke="#009F6F" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75"/>
              <path fill="#505958" fillRule="nonzero" stroke="#505958" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75" d="M0 17h20v3H0z"/>
              <path fill="#505958" fillRule="nonzero" d="M34 34h38v20H34z"/>
          </g>
          <path stroke="#505958" strokeLinecap="round" strokeLinejoin="round" d="M58 8h15v19H58z"/>
          <path fill="#009F6F" fillRule="nonzero" d="M61 11h9v13h-9z"/>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M93.421 81.5V55h-93v26.5M24.421 68v13.5"/>
          <path stroke="#AAB7B2" strokeLinecap="round" strokeLinejoin="round" d="M15 81.5l9.299-8.5L34 81.5M65.421 68v13.5M56 81.5l9.299-8.5L75 81.5"/>
          <rect width="25" height="7" x="12" y="62" fill="#505958" fillRule="nonzero" rx="1.49"/>
          <path fill="#505958" fillRule="nonzero" d="M25 38c5.523 0 10 4.579 10 10.227V63H15V48.227C15 42.58 19.477 38 25 38z"/>
          <rect width="25" height="7" x="52" y="62" fill="#009F6F" fillRule="nonzero" rx="1.49"/>
          <path fill="#009F6F" fillRule="nonzero" d="M65 38c5.523 0 10 4.579 10 10.227V63H55V48.227C55 42.58 59.477 38 65 38z"/>
      </g>
</svg>
)
