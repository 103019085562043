import { css } from 'styled-components'

// Constants
import { BREAKPOINTS } from '../constants/breakpoints'

/**
 * @param   {string|object} arg
 * @param   {object}        [breakpoints]
 * @returns {*}
 */
const breakpoint = (arg) => {
  const breakpointObj = BREAKPOINTS[arg] || arg
  // tslint:disable-next-line
  return (...args) => css`@media (${breakpointObj.type}-${breakpointObj.direction}: ${breakpointObj.value}px) {
    ${css.call(this, ...args)}
  }`
}

export default breakpoint
