import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'

import CompanyProfile from '../companyProfile/companyProfilePage'
import LeaseVariables from '../lease/leaseVariables/leaseVariables'

import {
  StyledContainer,
  StyledTab,
  StyledTabButton,
  StyledTabNav,
  StyledResultsTabPane,
  StyledTabs,
  StyledTabNavTabs,
  StyledTabNavSpacer,
  StyledIntroTabPane,
  StyledLeaseVariablesTabPane
} from './styles'
import DetailedResultsSummary from '../detailedresults/detailedResultsSummary'
import { selectHasScenario } from '../../redux/scenario/scenario.selectors'
import { bindActionCreators } from 'redux'
import { changeHomeTab } from '../../redux/home/home.actions'
import { selectHomeCurrentTab } from '../../redux/home/home.selectors'

const TABDATA = [
  {
    tab: '1',
    title: 'Company Profile',
    id: 'company-profile--tab',
  },
  {
    tab: '2',
    title: 'Lease Variables',
    id: 'lease-variables--tab',
  },
  {
    tab: '3',
    title: 'Detailed Results',
    id: 'detailed-results--tab',
  },
]

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & DispatchProps


class Calculator extends Component<Props> {

  tabClick = (tab: string) => !this.props.hasScenario ? null : this.props.changeHomeTab(tab)

  render() {
    const { hasScenario, currentTab, changeHomeTab } = this.props
    return (
      <StyledContainer>
        <StyledTabNav>
          <StyledTabNavTabs>
            {TABDATA.map((data) => {
              const { tab: localTab, title, id } = data
              return (
                <StyledTab id={id} key={id}>
                  <StyledTabButton
                    disabled={localTab !== TABDATA[0].tab && !hasScenario}
                    isActive={currentTab === localTab}
                    onClick={() => this.tabClick(localTab)}>
                    <span>{localTab}</span>
                    <h3>{title}</h3>
                  </StyledTabButton>
                </StyledTab>
              )
            })}
          </StyledTabNavTabs>
          <StyledTabNavSpacer/>
        </StyledTabNav>
        <StyledTabs
          activeKey={currentTab}
          onChange={changeHomeTab}>
          <StyledIntroTabPane
            tab=""
            key={TABDATA[0].tab}>
            <CompanyProfile/>
          </StyledIntroTabPane>
          <StyledLeaseVariablesTabPane
            tab=""
            key={TABDATA[1].tab}>
            <LeaseVariables/>
          </StyledLeaseVariablesTabPane>
          <StyledResultsTabPane
            tab=""
            key={TABDATA[2].tab}>
            <DetailedResultsSummary/>
          </StyledResultsTabPane>
        </StyledTabs>
      </StyledContainer>
    )
  }
}

const mapStateToProps = (state: any) => ({
  hasScenario: selectHasScenario(state),
  currentTab: selectHomeCurrentTab(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({ changeHomeTab }, dispatch)

// @ts-ignore
export default connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Calculator)
