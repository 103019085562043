import styled from 'styled-components'
import { COLORS, FONTS } from '../../../constants/styles'

export const popoverWindow = 'popover-window'

export const PopoverContainer = styled.div`
  position: absolute;
  z-index: 6;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 61px;
`

export const PopoverBackdrop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${COLORS.DARK_02 + '73'};
  padding-right: 32px;
`

export const PopoverWindow = styled.div`
  width: 402px;
  box-shadow: 0 2px 30px 0 rgba(39, 43, 47, 0.25);
  background-color: ${COLORS.WHITE};
  text-align: left;
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 16px;
  transition: all 300ms ease-in-out;

  &.${popoverWindow}-enter {
    opacity: 0.01;
    transform: scale(0.9) translateY(50%);
  }

  &.${popoverWindow}-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0%);
    transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  }

  &.${popoverWindow}-exit {
    opacity: 1;
    transform: scale(1) translateY(0%);
  }

  &.${popoverWindow}-exit-active {
    opacity: 0.01;
    transform: scale(0.9) translateY(50%);
    transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  }

  &:before {
    content: '';
    position: absolute;
    top: 61px;
    right: 5%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: ${COLORS.WHITE};
    border-top: 0;
  }
`

export const StyledCloseContainer = styled.div`
  position: relative;
  right: 0;
  top: 0;
  button {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }

  img{
    width: 20px;
    height: 20px;
  }
`

export const StyledContentContainer = styled.div`
  padding: 30px 25px;
`
