/*
 * Colors
 */
export const COLORS = {
  PRIMARY: '#0c7f70',
  SECONDARY: '#d4e7e0',
  LIGHTGREEN: '#00d2a0',
  AQUAMARINE: '#02d2a0',
  WHITE: 'white',
  GRAY: '#f3f3f3',
  GRAYTEXT: '#666666',
  PURPLE: '#7d7ccf',
  BRIGHT_PURPLE: '#bd10e0',
  GRAYLINES: '#dfdfdf',
  DARKGREYTEXT: '#272b2f',
  ERROR: '#f1001e',
  BORDER_GRAY: '#e6e6e6',
  WARM_GREY: '#797979',
  WARMER_GREY: '#747474',
  PRIMARY_00: '#00704f',
  PRIMARY_01: '#008b61',
  SECONDARY_00: '#ffffff',
  SECONDARY_01: '#ecf5f1',
  DARK_00: '#2b3836',
  DARK_01: '#505958',
  DARK_02: '#7c8c88',
  SHADOW_00: 'rgba(0, 72, 51, 0.25)',
  SHADOW_01: '#aab7b2',
  SHADOW_02: '#ebf5f1',
  INFORMATION_00: '#6f6bf8',
  DANGER_00: '#eb5534',
  LIGHT_01: '#d9e1e0',
  HIGHLIGHT: '#f6f8f6',
}

export const LAYOUT = {
  HEADERHEIGHT: 65,
  MOBILE_HEADERHEIGHT: 48,
  TABSHEIGHT: 66,
  TAB_CONTENT_TOP_PADDING: 23,
  MOBILE_TABSHEIGHT: 59,
  MAXWIDTH: 1440,
  GUTTER: 32,
  MOBILE_GUTTER: 14,
  H_SPACING: 50,
  SCROLLBAR_WIDTH: 16,
  CUSTOMIZE_PRODUCT_DETAIL_WIDTH: 350,
  LEASE_RESULT_SUMMARY_HEIGHT: 122,
  LEASE_VARIABLES_LEFT_MAX_WIDTH: 1000,
  LEASE_VARIABLES_RIGHT_MAX_WIDTH: 508,
  LANDING_CONTAINER: 1070,
  LANDING_CONTAINER_LARGE: 1210,

}

/*
 * Fonts
 */
export const FONTS = {
  PRIMARY: '"Graphik", "Arial", "Helvetica"',
  MEDIUM: '"Graphik-Medium", "Arial", "Helvetica"',
  PRIMARY_SEMIBOLD: '"Graphik-Bold", "Arial", "Helvetica"',
}


export const ORDER = {
  HEADER: 5,
  TABS: 4,
  MOBILE_RESULTS_NAV: 6,
}
