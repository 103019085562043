/* eslint-disable consistent-return */
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import MediaQuery from 'react-responsive'
import { BREAKPOINTS } from '../../../constants/breakpoints'

import { ScenarioName } from '../scenarioName'
// Styles
import {
  CreatePDFButton,
  StyledHeader,
  StyledLogoContainer,
  StyledNavContainer,
  StyledNavItem,
  StyledRightSide,
  UnsavedText,
} from './styles'
// Utilities
import { isReadOnly } from '../../../utils/index'
// image
import logo from '../../../static/images/cbreLogo.png'
import { showStartOverModal } from '../../../redux/modal/modal.actions'
import { startOver } from '../../../redux/scenario/scenario.actions'
import {
  selectHasSavedScenario,
  selectHasScenario,
  selectScenarioLoading,
  selectScenarioName,
  selectScenarioPrintValue,
} from '../../../redux/scenario/scenario.selectors'
import { closePopover, showPopoverEdit } from '../../../redux/popover/popover.actions'
import { selectPopoverScreen, selectPopoverVisible } from '../../../redux/popover/popover.selectors'
import SaveScenario from '../saveScenario'
import { Logo } from './components/Logo'
import DuplicateScenario from '../duplicateScenario'
import { PopoverScreen } from '../../../redux/popover/popover.reducer'


type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps<any>

interface State {
  popoverScenarioName: string | null
  scenarioName: string | null
}

export class Nav extends Component<Props, State> {
  state = {
    popoverScenarioName: null,
    scenarioName: null,
  }

  popover: any | undefined = undefined

  componentDidMount() {
    // intercom messsenger plugin. used to boot up the messenger
    const intercom = (window as any).Intercom
    intercom('boot', { app_id: 'lvi89aek' })
  }

  handlePopoverScenarioChange = (value: string) => (
    this.setState({ popoverScenarioName: value })
  )

  resetCalcClick = (e: any) => {
    const { hasScenario, showStartOverModal } = this.props
    hasScenario ? showStartOverModal(this.resetCalc) : e.preventDefault()
  }

  resetCalc = async () =>{
    const {
      history: { push }, startOver } = this.props
    startOver()
    push('/calc')
  }

  printCapture = async () => {
    const {
      hasScenario,
      history: { goBack, push },
      location: { pathname },
    } = this.props

    if (!hasScenario) return
    if (pathname.indexOf('/calc') >= 0) {
      return push('/print')
    }

    goBack()
  }

  render() {
    const {
      hasScenario,
      popoverVisible,
      popoverScreen,
      hasSavedScenario,
      scenarioName,
      location: { pathname },
      showPopoverEdit,
    } = this.props
    return (
      <>
        <StyledHeader>
          <StyledNavContainer>
            <StyledLogoContainer>
              <Logo logoImage={logo}
                    id={'logo'} />
              {popoverVisible && !hasSavedScenario && (
                <UnsavedText>Untitled Scenario</UnsavedText>
              )}
              {hasSavedScenario && (
                <ScenarioName
                  onEditScenario={showPopoverEdit}
                  isReadOnly={isReadOnly(pathname)}
                  isEditing={popoverScreen === PopoverScreen.Edit}
                  isPrintPage={pathname === '/print'}
                  name={scenarioName}
                />
              )}
            </StyledLogoContainer>
            <StyledRightSide hasScenario={hasScenario}>
              <MediaQuery minWidth={BREAKPOINTS.tabletMin.value}>
                {hasSavedScenario && pathname !== '/print' && <DuplicateScenario />}
              </MediaQuery>
              {pathname !== '/print' && (
                <StyledNavItem
                  onClick={(e) => this.resetCalcClick(e)}
                  id="start-over--button"
                  disabled={!hasScenario}
                >
                  Start Over
                </StyledNavItem>
              )}
              <MediaQuery minWidth={BREAKPOINTS.tabletMin.value}>
                <CreatePDFButton
                  onClick={(e) => (popoverVisible ? e.preventDefault() : this.printCapture())}
                  disabled={!hasScenario}
                  id={pathname.indexOf('/calc') >= 0 ? 'create-pdf--button' : 'exit-pdf--button'}
                >
                  {pathname.indexOf('/calc') >= 0 ? 'Create PDF' : 'Exit'}
                </CreatePDFButton>
                {!isReadOnly(pathname) && pathname !== '/print' && (
                  <SaveScenario />
                )}
              </MediaQuery>
            </StyledRightSide>
          </StyledNavContainer>
        </StyledHeader>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  scenarioName: selectScenarioName(state),
  popoverVisible: selectPopoverVisible(state),
  popoverScreen: selectPopoverScreen(state),
  isLoading: selectScenarioLoading(state),
  print: selectScenarioPrintValue(state),
  hasScenario: selectHasScenario(state),
  hasSavedScenario: selectHasSavedScenario(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => (bindActionCreators({
  showStartOverModal,
  startOver,
  showPopoverEdit,
  closePopover,
}, dispatch))

export default withRouter(connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(Nav))
