import React, { useState } from 'react'
import { StyledTabHeader, TabHeader } from '../../detailedResultsSummary/styles'
import { ResultDisplayMode } from '../../resultsOptions'
import TermOptionsGraph from '../termOptionsGraph'
import { TermOptionsLease } from '../../../../data/models'
import { connect, Dispatch } from 'react-redux'
import {
  selectInitialDesiredLeaseTerm,
  selectSelectedChartOption,
  selectCalculationResultsLoading
} from '../../../../redux/calculations/calculation.selectors'

import ButtonWrapper, { ButtonType } from '../../../common/button'
import { changeHomeTab } from '../../../../redux/home/home.actions'
import { bindActionCreators } from 'redux'

import { selectTermOptionsLeases } from '../../../../redux/scenario/scenario.selectors'

import {
  StyledDataPanel,
  StyledLeaseComparisonDropdown,
  StyledResultsOptions,
  StyledSelectViewLabel,
  StyledTermOptionsContainer,
} from './styles'
import { SetState } from '../../../../utils/state_types'
import MediaQuery from 'react-responsive'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

interface OwnProps {
  id?: string,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & OwnProps & DispatchProps

const ViewSelector = () => {
  return <>
    <StyledSelectViewLabel>Select your view:</StyledSelectViewLabel>
    <StyledResultsOptions
      displayMode={ResultDisplayMode.Condensed}
    />
  </>
}


const TermOptions = (
  {
    leases,
    initialDesiredLeaseTerm,
    chartOption,
    id,
    loading,
    changeHomeTab,
  }: Props) => {
  const first: SetState<TermOptionsLease> = useState(() => leases[0])
  const [firstLease] = first
  const second: SetState<TermOptionsLease> = useState(() => leases[1])
  const [secondLease] = second
  return <>
    <StyledTabHeader margin>
      <TabHeader>Analyze lease terms options ranging from one to ten years.</TabHeader>
    </StyledTabHeader>
    <StyledTermOptionsContainer>
      <MediaQuery maxWidth={BREAKPOINTS.desktopMax.value}>
        <ViewSelector />
      </MediaQuery>
      {firstLease && <TermOptionsGraph
        id={id}
        loading={loading}
        chartOption={chartOption}
        firstLease={firstLease}
        secondLease={secondLease}
        initialDesiredLeaseTerm={initialDesiredLeaseTerm}
      />}
      <StyledDataPanel>
        <MediaQuery minWidth={BREAKPOINTS.desktopMin.value}>
          <ViewSelector />
        </MediaQuery>
        {/* Only show lease selector when we have more than 1 lease */}
        {secondLease && <>
          <StyledSelectViewLabel>Select which leases you'd like to compare:</StyledSelectViewLabel>
          <StyledLeaseComparisonDropdown
            leases={leases}
            selectedLease1={first}
            selectedLease2={second} />
        </>}
        <ButtonWrapper
          id="return-lease-variables"
          text="Adjust Leases"
          type={ButtonType.Secondary}
          onClick={(e: any) => {
            e.preventDefault()
            return changeHomeTab('2')
          }}
        />
      </StyledDataPanel>
    </StyledTermOptionsContainer>
  </>
}

const mapStateToProps = (state: any) => ({
  leases: selectTermOptionsLeases(state),
  initialDesiredLeaseTerm: selectInitialDesiredLeaseTerm(state),
  chartOption: selectSelectedChartOption(state),
  loading: selectCalculationResultsLoading(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators({ changeHomeTab }, dispatch)

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(TermOptions)
