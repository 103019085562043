import styled from 'styled-components'
import { HeadlineH5Default } from '../../../../styles/text.styles'
import { LeaseComparisonDropdown } from '../leaseComparisonDropdown'

import ResultsOptions from '../../resultsOptions'
import breakpoint from '../../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../../constants/breakpoints'
import { LAYOUT } from '../../../../constants/styles'


export const StyledTermOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${breakpoint(BREAKPOINTS.desktopMax)`
    flex-direction: column;
    margin-right: 2px;
  `}
`

export const StyledSelectViewLabel = styled.span`
  width: 100%;
  ${HeadlineH5Default}
  text-align: start;
`

export const StyledResultsOptions = styled(ResultsOptions)`
  margin-bottom: 35px;
`

export const StyledDataPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${LAYOUT.GUTTER}px;
  /* firefox offset for border */
  padding-right: 1px;
  min-width: 240px;
  ${breakpoint(BREAKPOINTS.desktopMax)`
    margin-top: 41px;
    padding-left: 0;
    padding-right: 0;
  `}
`

export const StyledLeaseComparisonDropdown = styled(LeaseComparisonDropdown)`
  margin-top: 12px;
`
