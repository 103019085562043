import styled from 'styled-components'
import { Collapse } from 'antd'

import { COLORS } from '../../../constants/styles'
import breakpoint from '../../../utils/breakpoints'
import { HeadlineH3Dark, SubCopy } from '../../../styles/text.styles'
import { BREAKPOINTS } from '../../../constants/breakpoints'

// @ts-ignore
export const StyledCollapse = styled(Collapse)`
  margin-top: 32px;
  border: 0px solid transparent !important;

  .ant-collapse-content {
    padding: 1px 1px !important;
    border-top: 0px solid transparent !important;
    position: relative;
    & > .ant-collapse-content-box{
      padding: 0px 16px 16px 16px;
    }
  }

  .ant-collapse-header {
    padding-left: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-header {
    padding: 12px 16px 0px 16px;
    background-color: white !important;
    & > svg{
      width: 22px;
      height: 22px;
      path{
        fill: ${COLORS.PRIMARY_01}
      }
      right: 12px;
      position: absolute;
      transform-origin: 50% 50%;
      transition: transform 0.2s ease-out;
      transform: rotate(0deg);
    }
  }

  .ant-collapse-item:nth-of-type(1) {
    border-top: 1px solid ${COLORS.LIGHT_01} !important;
  }

  .ant-collapse-item {
    background-color: white !important;
  }

  .ant-collapse-item-active{
    .subtitle{
      opacity: 0;
    }
    .ant-collapse-header > svg{
      transform: rotate(180deg);
    }
  }
`

export const StyledCollapseTitle = styled.h4`
  ${HeadlineH3Dark}
`

export const StyledCollapseSubtitle = styled.h4`
  padding-top: 14px;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  ${SubCopy}
  ${breakpoint(BREAKPOINTS.mobileMax)`
    font-size: 12px;
  `}
`

export const StyledCollapseHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    user-select: none;

`

export const StyledPanelBody = styled.div`
  >div {
    padding: 0;
  }
`
