import message from 'antd/lib/message'
import delay from 'lodash/delay'

export const successToast = (messageBody: string) => {
  const toast = () => message.success(messageBody, 1.5)
  return delay(toast, 1000)
}

export const errorToast = (messageBody: string) => {
  const toast = () => message.error(messageBody, 1.5)
  return delay(toast, 1000)
}
