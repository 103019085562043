import React from 'react'
import { connect, Dispatch } from 'react-redux'

import { AccordionPanel, AccordionWrapper } from '../../../common/accordion'

import {
  StyledContainer,
  StyledDropdownWrapper,
  StyledField,
  StyledFieldExtended,
  StyledFieldSpacer,
  StyledForm,
  StyledLeaseInputName,
} from '../styles'
import { foundValue } from '../../../../utils'
import * as selectOpt from '../../../../utils/selectOptions/index'
import { tooltipText } from '../../../../utils/selectOptions/tooltipText'
import {
  selectCurrencyName,
  selectCurrencyValue,
  selectMeasureName,
  selectMeasureValue,
  selectPeriodName,
  selectTimeValue,
} from '../../../../redux/unitCurrency/unitCurrency.selectors'
import { selectCompanyInputs } from '../../../../redux/calculations/calculation.selectors'
import { selectFlexOverrideLease, selectTradOverrideLease } from '../../../../redux/scenario/scenario.selectors'
import { bindActionCreators } from 'redux'
import { updateLocalLease } from '../../../../redux/scenario/scenario.actions'
import {
  rentableSeatAreaCalc,
  totalGrossRentableAreaYear2Calc,
  usableSeatAreaReqYear2Calc,
} from '../../../../services/tradLeaseCalc'
import { NumberInput } from '../../../common/input/NumberInput'
import { TextInput } from '../../../common/input/TextInput'
import { MAX_LEASE_NAME_LENGTH } from '../../../../data/models'
import { roundLeaseVariableData, roundToDisplay } from '../../../../data/lease_utils'
import round from 'lodash/round'

const PANELS = [
  'Rent Expenses',
  'Operating Expenses',
  'Capital Expenses',
  'Space Planning',
]

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & DispatchProps
export const InternalCustomizeTrad = (
  {
    currencyName,
    measureName,
    periodName,
    currencyValue,
    inputs,
    selectedLease,
    updateLocalLease,
    otherLease,
    timeValue,
    measureValue,
  }: Props) => {
  const { value: annualSeatChangeSelection } = foundValue(
    selectOpt.annualSeatChange,
    inputs.annualSeatChange,
  )
  const { value: seatVolatility } = foundValue(
    selectOpt.seatVolatility,
    inputs.seatVolatility,
  )

  const currencyMeasure = `${currencyName} / ${measureName}`
  const measureDetails = `${currencyMeasure} / ${periodName}`
  const currencyMeasureMultiplier = currencyValue * measureValue
  const currencyMeasureTimeMultiplier = currencyMeasureMultiplier * timeValue
  const rentValue = roundLeaseVariableData(selectedLease.rentRate * currencyMeasureTimeMultiplier)
  const opExpValue = roundLeaseVariableData(
    (selectedLease.operatingCosts +
      selectedLease.facilityManagement +
      selectedLease.otherMiscOpex) * currencyMeasureTimeMultiplier,
  )
  const capExpValue = roundLeaseVariableData(
    ((selectedLease.capexHardCost +
      selectedLease.capexFFE +
      selectedLease.capexSoftCost +
      selectedLease.capexITData) -
      (selectedLease.tiAllowanceValue / currencyMeasureMultiplier)) * currencyMeasureMultiplier,
  )

  // rounded and currency adjusted maxes
  const rentRateMaxDisplay = roundToDisplay(1200 * currencyValue)
  const operatingCostsMaxDisplay = roundToDisplay(100 * currencyValue)
  const facilityManagementMaxDisplay = roundToDisplay(100 * currencyValue)
  const otherMiscOpexMaxDisplay = roundToDisplay(100 * currencyValue)
  const capexHardCostMaxDisplay = roundToDisplay(1500 * currencyValue)
  const capexFFEMaxDisplay = roundToDisplay(1500 * currencyValue)
  const capexSoftCostMaxDisplay = roundToDisplay(300 * currencyValue)
  const capexITDataMaxDisplay = roundToDisplay(300 * currencyValue)
  const tiAllowanceValueMaxDisplay = roundToDisplay(1000 * currencyValue)
  const reinstatementMaxDisplay = roundToDisplay(200 * currencyValue)
  const usableSeatAreaMaxDisplay = roundToDisplay(300 * currencyValue)
  const rentableSeatAreaMaxDisplay = roundToDisplay(10000 * currencyValue)
  const usableSeatAreaReqYear2MaxDisplay = roundToDisplay(30000 * currencyValue)
  const totalGrossRentableAreaYear2MaxDisplay = roundToDisplay(60000 * currencyValue)

  return (
    <div>
      <StyledForm
        id="customize-standard--form"
        onSubmit={() => updateLocalLease({}, selectedLease)}
        noValidate>
        <StyledLeaseInputName id="lease-name">
          <TextInput
            id="name"
            expandable
            maxLength={MAX_LEASE_NAME_LENGTH}
            initialValue={selectedLease.name}
            submitForm={(value) => {
              updateLocalLease({ name: value }, otherLease)
              updateLocalLease({ name: value }, selectedLease)
            }}
            label="Name (Optional)"
            onChange={(value: any) => {
              // synchronize changes between both set of inputs.
              updateLocalLease({ name: value }, otherLease)
              updateLocalLease({ name: value }, selectedLease)
            }}
          />
        </StyledLeaseInputName>
        <AccordionWrapper>
          <AccordionPanel
            title={PANELS[0]}
            subtitle={`${roundToDisplay(rentValue)} ${measureDetails}`}
            id="0"
          >
            {/*  Rent Rate */}
            <StyledContainer>
              <StyledField id="rent-rate--slider">
                <NumberInput
                  id="rentRate"
                  tooltipText={tooltipText.rentRate}
                  valueIfEmpty={0}
                  min={0}
                  initialValue={selectedLease.rentRate}
                  max={rentRateMaxDisplay}
                  bottomText={`${measureDetails} ${rentRateMaxDisplay} Max`}
                  label="Rent"
                  valueMultiplier={currencyMeasureTimeMultiplier}
                  submitForm={(value) => updateLocalLease({ rentRate: value }, selectedLease)}
                />
              </StyledField>
              {/*  Rent Free Period / rentFreePeriod */}
              <StyledField id="rent-free--slider">
                <StyledDropdownWrapper
                  id="rentFreePeriodValue"
                  value={selectedLease.rentFreePeriodValue}
                  tooltipText={tooltipText.rentFreePeriodValue}
                  label="Rent-free Period"
                  optionsArray={selectOpt.rentFreePeriod}
                  onChange={(value: number) => updateLocalLease({ rentFreePeriodValue: value }, selectedLease)}
                />
              </StyledField>
              {/*  rent increases / rent escalation rate  */}
              <StyledField id="rent-increases--slider">
                <StyledDropdownWrapper
                  id="rentEscalationRate"
                  tooltipText={tooltipText.rentEscalationRate}
                  label="Rent Increases"
                  optionsArray={selectOpt.rentIncreases}
                  value={selectedLease.rentEscalationRate}
                  onChange={(value: number) => updateLocalLease({ rentEscalationRate: value }, selectedLease)}
                />
              </StyledField>
              {/* Trad Cost Escalation */}
              <StyledField id="cost-escalation--slider">
                <StyledDropdownWrapper
                  id="costEscalationTrad"
                  tooltipText={tooltipText.tradCostEscalation}
                  label="Cost Escalation"
                  optionsArray={selectOpt.costEscalation}
                  value={selectedLease.costEscalation}
                  onChange={(value: number) => updateLocalLease({ costEscalation: value }, selectedLease)}
                />
              </StyledField>
            </StyledContainer>
          </AccordionPanel>
          <AccordionPanel
            title={PANELS[1]}
            subtitle={`${round(opExpValue)} ${measureDetails}`}
            id="1"
          >
            <StyledContainer>
              {/* non-rent expenses / operatingCosts */}
              <StyledField id="non-rent--slider">
                <NumberInput
                  id="operatingCosts"
                  tooltipText={tooltipText.operatingCosts}
                  valueIfEmpty={0}
                  initialValue={selectedLease.operatingCosts}
                  key="operatingCosts"
                  bottomText={`${measureDetails} ${operatingCostsMaxDisplay} Max`}
                  min={0}
                  valueMultiplier={currencyMeasureTimeMultiplier}
                  max={operatingCostsMaxDisplay}
                  label="Non-rent Expenses"
                  submitForm={(value) => updateLocalLease({ operatingCosts: value }, selectedLease)}
                />
              </StyledField>
              {/* Facility Management / facilityManagement */}
              <StyledField id="facility-management--slider">
                <NumberInput
                  id="facilityManagement"
                  tooltipText={tooltipText.facilityManagement}
                  initialValue={selectedLease.facilityManagement}
                  valueIfEmpty={0}
                  key="facilityManagement"
                  min={0}
                  valueMultiplier={currencyMeasureTimeMultiplier}
                  max={facilityManagementMaxDisplay}
                  bottomText={`${measureDetails} ${facilityManagementMaxDisplay} Max`}
                  label="Facility Management"
                  submitForm={(value) => updateLocalLease({ facilityManagement: value }, selectedLease)}
                />
              </StyledField>
              {/* Other Misc Opex / otherMiscOpex */}
              <StyledField id="misc-expenses--slider">
                <NumberInput
                  id="otherMiscOpex"
                  valueIfEmpty={0}
                  initialValue={selectedLease.otherMiscOpex}
                  tooltipText={tooltipText.otherMiscOpex}
                  key="otherMiscOpex"
                  min={0}
                  valueMultiplier={currencyMeasureTimeMultiplier}
                  max={otherMiscOpexMaxDisplay}
                  bottomText={`${measureDetails} ${otherMiscOpexMaxDisplay} Max`}
                  label="Miscellaneous Expenses"
                  submitForm={(value) => updateLocalLease({ otherMiscOpex: value }, selectedLease)}
                />
              </StyledField>
            </StyledContainer>
          </AccordionPanel>
          <AccordionPanel
            title={PANELS[2]}
            subtitle={`${roundToDisplay(capExpValue)} ${currencyMeasure}`}
            id="2"
          >
            <StyledContainer>
              {/* CapEx Hard Cost / capexHardCost */}
              <StyledField id="construction-costs--slider">
                <NumberInput
                  id="capexHardCost"
                  key="capexHardCost"
                  initialValue={selectedLease.capexHardCost}
                  tooltipText={tooltipText.capexHardCost}
                  valueIfEmpty={0}
                  label="Construction Costs"
                  bottomText={`${currencyMeasure} ${capexHardCostMaxDisplay} Max`}
                  min={0}
                  valueMultiplier={currencyMeasureMultiplier}
                  max={capexHardCostMaxDisplay}
                  submitForm={(value) => updateLocalLease({ capexHardCost: value }, selectedLease)}
                />
              </StyledField>
              {/* CapEx FF&E / capexFFE */}
              <StyledField id="capex-ffe--slider">
                <NumberInput
                  id="capexFFE"
                  key="capexFFE"
                  initialValue={selectedLease.capexFFE}
                  valueIfEmpty={0}
                  label="Furniture, Fixtures & Equipment"
                  bottomText={`${currencyMeasure} ${capexFFEMaxDisplay} Max`}
                  min={0}
                  valueMultiplier={currencyMeasureMultiplier}
                  max={capexFFEMaxDisplay}
                  submitForm={(value) => updateLocalLease({ capexFFE: value }, selectedLease)}
                />
              </StyledField>
              {/* CapEx Soft Cost / capexSoftCost */}
              <StyledField id="capex-soft--slider">
                <NumberInput
                  id="capexSoftCost"
                  key="capexSoftCost"
                  initialValue={selectedLease.capexSoftCost}
                  valueIfEmpty={0}
                  tooltipText={tooltipText.capexSoftCost}
                  label="Soft Costs"
                  bottomText={`${currencyMeasure} ${capexSoftCostMaxDisplay} Max`}
                  min={0}
                  valueMultiplier={currencyMeasureMultiplier}
                  max={capexSoftCostMaxDisplay}
                  submitForm={(value) => updateLocalLease({ capexSoftCost: value }, selectedLease)}
                />
              </StyledField>
              {/* CapEx Data & IT/ capexITData */}
              <StyledField id="data-it--slider">
                <NumberInput
                  id="capexITData"
                  label="Data & IT"
                  initialValue={selectedLease.capexITData}
                  tooltipText={tooltipText.capexITData}
                  key="capexITData"
                  valueIfEmpty={0}
                  bottomText={`${currencyMeasure} ${capexITDataMaxDisplay} Max`}
                  min={0}
                  valueMultiplier={currencyMeasureMultiplier}
                  max={capexITDataMaxDisplay}
                  submitForm={(value) => updateLocalLease({ capexITData: value }, selectedLease)}
                />
              </StyledField>
              {/* TI Allowance / tenantIncentives */}
              <StyledFieldExtended id="ti-allowance--slider">
                <NumberInput
                  id="tiAllowanceValue"
                  initialValue={selectedLease.tiAllowanceValue}
                  valueMultiplier={currencyMeasureMultiplier}
                  tooltipText={tooltipText.tiAllowanceValue}
                  valueIfEmpty={0}
                  label="Tenant Improvement Allowance"
                  key="tiAllowanceValue"
                  bottomText={`${currencyMeasure} ${tiAllowanceValueMaxDisplay} Max`}
                  min={0}
                  max={tiAllowanceValueMaxDisplay}
                  submitForm={(value) => updateLocalLease({ tiAllowanceValue: value }, selectedLease)}
                />
              </StyledFieldExtended>
              {/*  Reinstatement / tenantIncentives */}
              <StyledField id="reinstatement--slider">
                <NumberInput
                  id="reinstatement"
                  label="Restoration"
                  initialValue={selectedLease.reinstatement}
                  tooltipText={tooltipText.reinstatement}
                  key="reinstatement"
                  valueIfEmpty={0}
                  bottomText={`${currencyMeasure} ${reinstatementMaxDisplay} Max`}
                  min={0}
                  valueMultiplier={currencyMeasureMultiplier}
                  max={reinstatementMaxDisplay}
                  submitForm={(value) => updateLocalLease({ reinstatement: value }, selectedLease)}
                />
              </StyledField>
              <StyledField id="exit-early--slider">
                {/*  earlyExitPenalty */}
                <NumberInput
                  id="earlyExitPenalty"
                  label="Early Exit Penalty"
                  initialValue={selectedLease.earlyExitPenalty}
                  tooltipText={tooltipText.earlyExitPenalty}
                  key="earlyExitPenalty"
                  valueIfEmpty={0}
                  min={0}
                  max={100}
                  percentageValue
                  suffix="%"
                  bottomText="% / remaining lease"
                  submitForm={(value) => updateLocalLease({ earlyExitPenalty: value }, selectedLease)}
                />
              </StyledField>
            </StyledContainer>
          </AccordionPanel>
          <AccordionPanel
            title={PANELS[3]}
            subtitle={`${roundToDisplay(selectedLease.totalGrossRentableAreaYear2 / measureValue)} ${measureName} rentable`}
            id="3"
          >
            <StyledContainer>
              {/* usableSeatArea */}
              <StyledField id="usable-seat--slider">
                <NumberInput
                  id="usableSeatArea"
                  key="usableSeatArea"
                  initialValue={selectedLease.usableSeatArea}
                  valueMultiplier={1 / measureValue}
                  valueIfEmpty={0}
                  bottomText={`${currencyMeasure} ${usableSeatAreaMaxDisplay} Max`}
                  min={0}
                  tooltipText={tooltipText.usableSeatArea}
                  max={usableSeatAreaMaxDisplay}
                  label="Usable Area / Seat"
                  type="number"
                  onChange={(value: any) => {
                    // ADDED ABILITY TO OVERRIDE usableSeatAreaReqYear2 within Form
                    const uar2Result = usableSeatAreaReqYear2Calc({
                      usableSeatArea: value,
                      initialSeats: inputs.initialSeats,
                      spacePlanningAssumption: selectedLease.spacePlanningAssumption,
                      annualSeatChange: annualSeatChangeSelection,
                      seatVolatility,
                    })
                    const tgray2Result = totalGrossRentableAreaYear2Calc({
                      usableSeatArea: value,
                      spacePlanningAssumption: selectedLease.spacePlanningAssumption,
                      initialSeats: inputs.initialSeats,
                      annualSeatChange: annualSeatChangeSelection,
                      lossFactor: selectedLease.lossFactor,
                      seatVolatility,
                    })
                    const rsaResult = rentableSeatAreaCalc({
                      totalGrossRentableAreaYear2: tgray2Result,
                      initialSeats: inputs.initialSeats,
                    })
                    updateLocalLease({
                      usableSeatAreaReqYear2: uar2Result,
                      totalGrossRentableAreaYear2: tgray2Result,
                      rentableSeatArea: rsaResult,
                    }, selectedLease, false)
                  }}
                  submitForm={(value) => updateLocalLease({ usableSeatArea: value }, selectedLease)}
                />
              </StyledField>
              {/* spacePlanningAssumption */}
              <StyledField id="space-planning--slider">
                <NumberInput
                  id="spacePlanningAssumption"
                  initialValue={selectedLease.spacePlanningAssumption}
                  tooltipText={tooltipText.spacePlanningAssumption}
                  key="spacePlanningAssumption"
                  valueIfEmpty={0}
                  min={0}
                  max={100}
                  percentageValue
                  suffix="%"
                  label="Structured Vacancy"
                  type="number"
                  onChange={(value: any) => {
                    const uar2Result = usableSeatAreaReqYear2Calc({
                      usableSeatArea: selectedLease.usableSeatArea,
                      initialSeats: inputs.initialSeats,
                      spacePlanningAssumption: value,
                      annualSeatChange: annualSeatChangeSelection,
                      seatVolatility,
                    })
                    const tgray2Result = totalGrossRentableAreaYear2Calc({
                      usableSeatArea: selectedLease.usableSeatArea,
                      spacePlanningAssumption: value,
                      initialSeats: inputs.initialSeats,
                      annualSeatChange: annualSeatChangeSelection,
                      lossFactor: selectedLease.lossFactor,
                      seatVolatility,
                    })

                    const rsaResult = rentableSeatAreaCalc({
                      totalGrossRentableAreaYear2: tgray2Result,
                      initialSeats: inputs.initialSeats,
                    })

                    updateLocalLease({
                      totalGrossRentableAreaYear2: tgray2Result,
                      rentableSeatArea: rsaResult,
                      usableSeatAreaReqYear2: uar2Result,
                    }, selectedLease, false)
                  }}
                  submitForm={(value) => updateLocalLease({ spacePlanningAssumption: value }, selectedLease)}
                />
              </StyledField>
              {/* Loss Factor */}
              <StyledField id="loss-factor--slider">
                <NumberInput
                  id="lossFactor"
                  initialValue={selectedLease.lossFactor}
                  tooltipText={tooltipText.lossFactor}
                  valueIfEmpty={0}
                  key="lossFactor"
                  min={0}
                  max={100}
                  percentageValue
                  suffix="%"
                  label="Loss Factor"
                  type="number"
                  onChange={(value: any) => {
                    const tgray2Result = totalGrossRentableAreaYear2Calc({
                      usableSeatArea: selectedLease.usableSeatArea,
                      spacePlanningAssumption:
                      selectedLease.spacePlanningAssumption,
                      initialSeats: inputs.initialSeats,
                      annualSeatChange: annualSeatChangeSelection,
                      lossFactor: value,
                      seatVolatility,
                    })

                    const rsaResult = rentableSeatAreaCalc({
                      totalGrossRentableAreaYear2: tgray2Result,
                      initialSeats: inputs.initialSeats,
                    })

                    updateLocalLease({
                      totalGrossRentableAreaYear2: tgray2Result,
                      rentableSeatArea: rsaResult,
                    }, selectedLease, false)
                  }}
                  submitForm={(value) => updateLocalLease({ lossFactor: value }, selectedLease)}
                />
              </StyledField>
              {/* Spacer for tablet*/}
              <StyledFieldSpacer />
              {/* Rentable Area */}
              <StyledField id="rentable-seat--slider">
                <NumberInput
                  id="rentableSeatArea"
                  initialValue={roundToDisplay(selectedLease.rentableSeatArea / measureValue)}
                  tooltipText={tooltipText.rentableSeatArea}
                  valueIfEmpty={0}
                  key="rentableSeatArea"
                  bottomText={measureName}
                  min={0}
                  disableEditing
                  max={rentableSeatAreaMaxDisplay}
                  label="Rentable Area / Seat"
                  type="number"
                />
              </StyledField>
              {/* usableSeatAreaReqYear2 */}
              <StyledField id="total-usable-seat--slider">
                <NumberInput
                  id="usableSeatAreaReqYear2"
                  initialValue={selectedLease.usableSeatAreaReqYear2}
                  tooltipText={tooltipText.usableSeatAreaReqYear2}
                  key="usableSeatAreaReqYear2"
                  valueIfEmpty={0}
                  bottomText={measureName}
                  min={0}
                  disableEditing
                  max={usableSeatAreaReqYear2MaxDisplay}
                  label="Total Usable Area"
                  type="number"
                />
              </StyledField>
              <StyledField id="total-rentable-area--slider">
                <NumberInput
                  id="totalGrossRentableAreaYear2"
                  key="totalGrossRentableAreaYear2"
                  tooltipText={tooltipText.totalGrossRentableAreaYear2}
                  valueIfEmpty={0}
                  initialValue={roundToDisplay(selectedLease.totalGrossRentableAreaYear2 / measureValue)}
                  bottomText={measureName}
                  min={0}
                  disableEditing
                  max={totalGrossRentableAreaYear2MaxDisplay}
                  label="Total Rentable Area"
                  type="number"
                />
              </StyledField>
            </StyledContainer>
          </AccordionPanel>
        </AccordionWrapper>
      </StyledForm>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  periodName: selectPeriodName(state),
  measureName: selectMeasureName(state),
  currencyValue: selectCurrencyValue(state),
  measureValue: selectMeasureValue(state),
  timeValue: selectTimeValue(state),
  currencyName: selectCurrencyName(state),
  inputs: selectCompanyInputs(state),
  selectedLease: selectTradOverrideLease(state),
  otherLease: selectFlexOverrideLease(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
  updateLocalLease,
}, dispatch)

export const CustomizeTrad = connect(mapStateToProps, mapDispatchToProps)(InternalCustomizeTrad)
