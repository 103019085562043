import styled from 'styled-components'

import {
  COLORS,
  LAYOUT
} from '../../constants/styles'
import breakpoint from '../../utils/breakpoints'
import { BREAKPOINTS } from '../../constants/breakpoints'

export const StyledContainer = styled.div`
  max-width: 1500px;
  margin: auto;
  font-size: 18px;
  padding: 14px;
  ${breakpoint(BREAKPOINTS.mobileMin)`
    margin-top: ${LAYOUT.MOBILE_HEADERHEIGHT}px;
  `}
  ${breakpoint(BREAKPOINTS.tabletMin)`
    padding: 30px;
    margin-top: ${LAYOUT.HEADERHEIGHT}px;
  `}
  p{
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 1em;
    color: ${COLORS.DARK_00};
    ${breakpoint(BREAKPOINTS.tabletMin)`
      font-size: 23px;
      line-height: 36px;
    `}
  }
  ol, span{
    color: ${COLORS.DARK_00};
    font-size: 16px;
    line-height: 32px;
    list-style-type: none;
    list-style-position: inside;
    & > li {
      counter-increment: list-counter;
      margin-bottom: 1em;
      &:before{
        content: counter(list-counter) ". ";
        font-weight: bold;
      }
    }
   & > ol{
      list-style-type: normal;
      ${breakpoint(BREAKPOINTS.tabletMin)`
        padding-left: 2em;
      `}
      & > li{
        counter-increment: inner-list-counter;
        &:before{
          content: counter(inner-list-counter) ". ";
          font-weight: normal;
          ${breakpoint(BREAKPOINTS.tabletMax)`
            margin-left: 2em;
          `}
        }
      }
    }
  }
`
