import React from 'react'
import { connect, Dispatch } from 'react-redux'
import ToggleWrapper from '../../../common/toggle'

import {
  LeftContainer,
  LeftWidth,
  RightContainer,
  ScrollableContainer,
  StyledContainer,
  StyledHeader,
  StyledInputContainer,
  StyledLeaseResultsSummary,
  StyledLeaseVariablesHeader,
  StyledRestoreDefaultInputsContainer,
  StyledToggleWrapper
} from './styles'
import { selectCurrencySymbol } from '../../../../redux/unitCurrency/unitCurrency.selectors'
import { selectActiveLeaseHasChanged, selectActiveLeaseTab } from '../../../../redux/scenario/scenario.selectors'
import { bindActionCreators } from 'redux'
import { restoreLeaseDefaults, selectLeaseType } from '../../../../redux/scenario/scenario.actions'
import { ScenarioLeaseType } from '../../../../data/models'
import { leaseToggleOptions } from '../tab.options'
import MediaQuery from 'react-responsive'
import { BREAKPOINTS } from '../../../../constants/breakpoints'
import { showModal } from '../../../../redux/modal/modal.actions'
import { CustomizeTrad } from '../customizeTrad'
import { CustomizeFlex } from '../customizeFlex'
import { StyledLink } from '../../../common/text/styles'


type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const renderDefaultInputsButton = ({ hasLeaseChanged, showModal, restoreLeaseDefaults }
                                     : Pick<Props, 'hasLeaseChanged' | 'showModal' | 'restoreLeaseDefaults'>) =>
  hasLeaseChanged && <StyledRestoreDefaultInputsContainer>
    <StyledLink
      onClick={() => {
        showModal({
          title: 'Restore Default Inputs',
          body: 'Do you want to restore all inputs for this lease back to their market defaults?',
          actionButtonText: 'Cancel',
          secondaryButtonText: 'Yes',
          secondaryAction: restoreLeaseDefaults,
          name: 'restoreDefaultInputs',
        })
      }}
      id="override-done--button"
    >
      Restore Default Inputs
    </StyledLink>
  </StyledRestoreDefaultInputsContainer>

const LeaseVariables = (
  {
    activeLeaseTab,
    selectLeaseType,
    hasLeaseChanged,
    showModal,
    restoreLeaseDefaults,
  }: Props) =>
  <StyledContainer id="leaseVariables">
    <LeftWidth>
      <LeftContainer>
        <StyledInputContainer id="inputContainer">
          <ScrollableContainer>
            <MediaQuery minWidth={BREAKPOINTS.tabletMin.value}>
              <StyledHeader>
                <div>
                  <StyledLeaseVariablesHeader>Lease Variables</StyledLeaseVariablesHeader>
                </div>
                {renderDefaultInputsButton({
                  hasLeaseChanged,
                  showModal,
                  restoreLeaseDefaults,
                })}
              </StyledHeader>
            </MediaQuery>
            <StyledToggleWrapper>
              <ToggleWrapper
                options={leaseToggleOptions}
                callback={(value: string) => selectLeaseType(value as ScenarioLeaseType)}
                value={activeLeaseTab}
                name="lease-variables-toggle"
              />
            </StyledToggleWrapper>
            <MediaQuery maxWidth={BREAKPOINTS.tabletMax.value}>
              {renderDefaultInputsButton({
                hasLeaseChanged,
                showModal,
                restoreLeaseDefaults,
              })}
            </MediaQuery>
            {activeLeaseTab === ScenarioLeaseType.FlexCoworking && <CustomizeFlex />}
            {activeLeaseTab === ScenarioLeaseType.Traditional && <CustomizeTrad />}
            <MediaQuery maxWidth={BREAKPOINTS.desktopMax.value}>
              <StyledLeaseResultsSummary />
            </MediaQuery>
          </ScrollableContainer>
        </StyledInputContainer>
      </LeftContainer>
    </LeftWidth>
    <MediaQuery minWidth={BREAKPOINTS.desktopMin.value}>
      <RightContainer>
        <ScrollableContainer>
          {/* Lease selection component here */}
          <StyledLeaseResultsSummary />
        </ScrollableContainer>
      </RightContainer>
    </MediaQuery>
  </StyledContainer>

const mapStateToProps = (state: any) => ({
  currencySymbol: selectCurrencySymbol(state),
  activeLeaseTab: selectActiveLeaseTab(state),
  hasLeaseChanged: selectActiveLeaseHasChanged(state),
})

const mapDispatchToProps = (dipatch: Dispatch<any>) => (bindActionCreators({
  selectLeaseType,
  showModal,
  restoreLeaseDefaults,
}, dipatch))

// @ts-ignore
export default connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LeaseVariables)
