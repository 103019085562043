import { CostComparisonLease, LeaseWithCalculations, ScenarioLeases } from '../../data/models'
import { leaseToCostComparisonLease } from '../../data/conversions'
import { selectScenarioLeases } from '../../redux/scenario/scenario.selectors'
import { createSelector } from 'reselect'
import { selectCalculationById, selectCompanyInputs } from '../../redux/calculations/calculation.selectors'

export const selectCostComparisonLeases = createSelector((state: any) => state, selectScenarioLeases,
  selectCompanyInputs, (state, leases, inputs) => {
    if (!inputs) {
      return []
    }
    return leases.map<CostComparisonLease | undefined>(l => {
      const calculation = selectCalculationById(state)(l.id)
      return calculation && leaseToCostComparisonLease(inputs, l as ScenarioLeases, calculation as LeaseWithCalculations)
    }).filter(l => !!l) as CostComparisonLease[]
  })
