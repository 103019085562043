import React, { Component } from 'react'
import Lottie from 'lottie-react-web'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'

// Images
import animationData from './assets/data.json'

// Config
import { CONFIG } from './config'

const defaultOptions = {
  loop: true,
  autoplay: false,
  animationData
}
const finalSegment = CONFIG.length - 1

export default class LottieAnimation extends Component {
  constructor() {
    super()
    this.state = {
      currentFrames: CONFIG[0].frames,
      currentSegment: 0,
      prevSegment: 0,
      isPaused: true,
      isHidden: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { segment } = this.props
    const { currentSegment } = this.state

    if (nextProps.segment !== segment) {
      this.setState((prevState) => ({
        ...prevState,
        currentSegment: nextProps.segment,
        isPaused: false
      }))
      if (nextProps.segment === finalSegment) {
        const { frames } = CONFIG[finalSegment]

        this.setFrames(frames)
      }
      return false
    } else if (nextState.currentSegment !== currentSegment) {
      return false
    }
    return true
  }

  setFrames = (frames) => {
    this.setState((prevState) => ({
      ...prevState,
      currentFrames: frames
    }))
  };

  startAnimation = () => setTimeout(() => this.setState({ isPaused: false }), 600)

  transition = () => {
    const { currentFrames, currentSegment, prevSegment } = this.state
    const { frames, loopFrames } = CONFIG[currentSegment]
    const { callback } = this.props

    if (currentSegment !== prevSegment && currentSegment !== finalSegment) {
      this.setState((prevState) => ({
        ...prevState,
        prevSegment: prevSegment + 1
      }))
      return this.setFrames(frames)
    }
    // stop final loop
    if (currentSegment === (finalSegment - 1) && currentFrames === loopFrames) {
      this.setState((prevState) => ({
        ...prevState,
        isPaused: true
      }))
      return null
    }
    // if loopFrames is a thing, swap over to those frames
    if (loopFrames !== undefined) {
      return currentFrames !== loopFrames ? this.setFrames(loopFrames) : null
    }
    this.setState((prevState) => ({
      ...prevState,
      isPaused: true,
      isHidden: true
    }))
    return callback()
  }

  eventListeners = [
    {
      eventName: 'loopComplete',
      callback: this.transition
    },
    {
      eventName: 'DOMLoaded',
      callback: this.startAnimation
    }
  ]

  render() {
    const { currentSegment, currentFrames, isPaused, isHidden } = this.state

    return (
      <StyledContainer
        isHidden={isHidden}
        currentSegment={currentSegment}
        isPaused={isPaused}
      >
        <Lottie
          options={defaultOptions}
          segments={currentFrames}
          isPaused={isPaused}
          forceSegments={true}
          width="100%"
          ariaRole="presentation"
          tabIndex="-1"
          eventListeners={this.eventListeners}
        />
      </StyledContainer>
    )
  }
}

LottieAnimation.propTypes = {
  segment: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired
}
