import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect, Dispatch } from 'react-redux'
import { StyledDropdownWrapper, StyledForm, StyledUnitsCurrencyWarning } from './styles'
import { generateDropdownLabel, UnitCurrencySelectTypes } from './constants'
import { updateUnitCurrencySelection } from '../../redux/unitCurrency/unitCurrency.actions'
import { showModal } from '../../redux/modal/modal.actions'
import { currencies } from '../../optionsConfig/models'
import { selectIsInitial, selectUnitCurrencyForForm } from '../../redux/unitCurrency/unitCurrency.selectors'
import { unitOfMeasure } from '../../utils/selectOptions/unitOfMeasure'
import { unitOfTime } from '../../utils/selectOptions/unitOfTime'
import ModalWrapper, { ModalAction } from '../common/modal'
import isEqual from 'lodash/isEqual'
import { FormUnitCurrency } from '../../redux/unitCurrency/unitCurrency.utils'
import { toNumber } from '../../utils/toNumber'

interface OwnProps {
  display: string
  setView: (value: string) => void
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = DispatchProps & StateProps & OwnProps

const InternalUnitsCurrencySelect = (
  {
    updateUnitCurrencySelection,
    unitCurrency,
    display,
    setView,
    isInitial,
  }: Props) => {
  const [modifiedCurrency, updateCurrencyState] = useState(unitCurrency)
  const [modifiedInputs, setModifiedInputs] = useState(false)
  const updateCurrency = (updates: Partial<FormUnitCurrency>) => {
    setModifiedInputs(true)
    updateCurrencyState({
      ...modifiedCurrency,
      ...updates,
    })
  }
  const haveInputsChanged = !isEqual(modifiedCurrency, unitCurrency)
  // set if past initial load.
  if (haveInputsChanged && !isInitial && !modifiedInputs) {
    updateCurrencyState(unitCurrency)
  }
  return (
    <ModalWrapper
      name='unitsCurrency'
      title="Units and Currency"
      visible={display === 'unitsCurrency'}
      primaryText={haveInputsChanged ? 'Confirm Changes' : 'Done'}
      secondaryText="Cancel"
      callback={(action: ModalAction) => {
        if (action === ModalAction.Primary && haveInputsChanged) {
          updateUnitCurrencySelection(modifiedCurrency)
        }
        return setView('basicInputs')
      }}>
      <StyledForm>
        <StyledDropdownWrapper
          id={UnitCurrencySelectTypes.Currency}
          key={UnitCurrencySelectTypes.Currency}
          optionsArray={currencies}
          value={modifiedCurrency.currency}
          label={generateDropdownLabel(UnitCurrencySelectTypes.Currency)}
          onChange={value => updateCurrency({ currency: toNumber(value) })}
        />
        <StyledDropdownWrapper
          id={UnitCurrencySelectTypes.UnitOfMeasure}
          key={UnitCurrencySelectTypes.UnitOfMeasure}
          optionsArray={unitOfMeasure}
          value={modifiedCurrency.unitOfMeasure}
          label={generateDropdownLabel(UnitCurrencySelectTypes.UnitOfMeasure)}
          onChange={value => updateCurrency({ unitOfMeasure: toNumber(value) })}
        />
        <StyledDropdownWrapper
          id={UnitCurrencySelectTypes.UnitOfTime}
          key={UnitCurrencySelectTypes.UnitOfTime}
          optionsArray={unitOfTime}
          value={modifiedCurrency.unitOfTime}
          label={generateDropdownLabel(UnitCurrencySelectTypes.UnitOfTime)}
          onChange={value => updateCurrency({ unitOfTime: toNumber(value) })}
          isLast
        />
      </StyledForm>
      <StyledUnitsCurrencyWarning>Changing currency, unit of measure, or unit of time will
        reset market costs to their default values.</StyledUnitsCurrencyWarning>
    </ModalWrapper>
  )
}

const mapStateToProps = (state: any) => ({
  unitCurrency: selectUnitCurrencyForForm(state),
  isInitial: selectIsInitial(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
  updateUnitCurrencySelection,
  showModal,
}, dispatch)

export const UnitsCurrencySelect = connect(mapStateToProps, mapDispatchToProps)(InternalUnitsCurrencySelect)
