import styled from 'styled-components'
import { COLORS } from '../../../constants/styles'
import { UISmallDefault, HeadlineH4Default, GreenLabel } from '../../../styles/text.styles'

export const StyledTermCardContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;

`

export const StyledTermCard = styled.div`
  width: 194px;
  border: solid 1px #e9e9e9;
  background-color: ${COLORS.SECONDARY_00};
  padding: 16px 14px;
  &:last-child {
    margin-left: 40px;
  }
`

export const StyledTermCardTitle = styled.div`
  ${HeadlineH4Default}
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  min-height: 28px;
`

export const StyledTermCardType = styled.div`
  ${UISmallDefault}
  margin-bottom: 4px;
`

export const StyledTermCardDescription = styled.div`
  ${UISmallDefault}
  margin-bottom: 12px;
`

export const StyledTermCardCost = styled.div`
  ${GreenLabel}
`

export const StyledTermCardCostLabel = styled.div`
 ${GreenLabel}
  margin-bottom: 4px;
`
