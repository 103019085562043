import { createGlobalStyle } from 'styled-components'

import graphikLightEOT from '../static/font/Graphik-Light-Web.eot'
import graphikLightWOFF from '../static/font/Graphik-Light-Web.woff'
import graphikLightWOFF2 from '../static/font/Graphik-Light-Web.woff2'
import graphikRegularEOT from '../static/font/Graphik-Regular-Web.eot'
import graphikRegularWOFF from '../static/font/Graphik-Regular-Web.woff'
import graphikRegularWOFF2 from '../static/font/Graphik-Regular-Web.woff2'
import graphikRegularItalicEOT from '../static/font/Graphik-RegularItalic-Web.eot'
import graphikRegularItalicWOFF from '../static/font/Graphik-RegularItalic-Web.woff'
import graphikRegularItalicWOFF2 from '../static/font/Graphik-RegularItalic-Web.woff2'
import graphikMediumEOT from '../static/font/Graphik-Medium-Web.eot'
import graphikMediumWOFF from '../static/font/Graphik-Medium-Web.woff'
import graphikMediumWOFF2 from '../static/font/Graphik-Medium-Web.woff2'
import graphikBoldEOT from '../static/font/Graphik-Bold-Web.eot'
import graphikBoldWOFF from '../static/font/Graphik-Bold-Web.woff'
import graphikBoldWOFF2 from '../static/font/Graphik-Bold-Web.woff2'

export const FontStyles = createGlobalStyle`
  ::selection {
    background: #d6e3df;
    color: #666666;
  }
  @font-face {
    font-family: 'Graphik';
    src: url(${graphikLightEOT}) format('embedded-opentype'),
      url(${graphikLightWOFF}) format('woff'),
      url(${graphikLightWOFF2}) format('woff2');
      font-weight: 100;
      font-display: fallback;
  }
  @font-face {
    font-family: 'Graphik';
    src: url(${graphikRegularEOT}) format('embedded-opentype'),
      url(${graphikRegularWOFF}) format('woff'),
      url(${graphikRegularWOFF2}) format('woff2');
      font-display: fallback;
  }
  @font-face {
    font-family: 'Graphik';
    src: url(${graphikRegularItalicEOT}) format('embedded-opentype'),
      url(${graphikRegularItalicWOFF}) format('woff'),
      url(${graphikRegularItalicWOFF2}) format('woff2');
    font-style:italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Graphik';
    src: url(${graphikMediumEOT}) format('embedded-opentype'),
      url(${graphikMediumWOFF}) format('woff'),
      url(${graphikMediumWOFF2}) format('woff2');
      font-weight: 500;
      font-display: fallback;
  }
  @font-face {
    font-family: 'Graphik';
    src: url(${graphikBoldEOT}) format('embedded-opentype'),
      url(${graphikBoldWOFF}) format('woff'),
      url(${graphikBoldWOFF2}) format('woff2');
      font-weight: 600;
      font-display: fallback;
  }

  @font-face {
    font-family: 'Graphik-Light';
    src: url(${graphikLightEOT}) format('embedded-opentype'),
      url(${graphikLightWOFF}) format('woff'),
      url(${graphikLightWOFF2}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Graphik-Regular';
    src: url(${graphikRegularEOT}) format('embedded-opentype'),
      url(${graphikRegularWOFF}) format('woff'),
      url(${graphikRegularWOFF2}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Graphik-RegularItalic';
    src: url(${graphikRegularItalicEOT}) format('embedded-opentype'),
      url(${graphikRegularItalicWOFF}) format('woff'),
      url(${graphikRegularItalicWOFF2}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }


  @font-face {
    font-family: 'Graphik-Medium';
    src: url(${graphikMediumEOT}) format('embedded-opentype'),
      url(${graphikMediumWOFF}) format('woff'),
      url(${graphikMediumWOFF2}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Graphik-Bold';
      src: url(${graphikBoldEOT}) format('embedded-opentype'),
      url(${graphikBoldWOFF}) format('woff'),
      url(${graphikBoldWOFF2}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`
