import React, { useState } from 'react'

import { mobileValues, optionValues } from '../../../../utils/selectOptions'

import { toNumber } from '../../../../utils/toNumber'


import { DropdownContainer, MenuContainer, StyledArrow, StyledDropdown, StyledSelect, DropdownStyles } from './styles'
import { Down } from '../../icons'
import { OptionValue } from '../../../../utils/selectOptions/optionValues'
import MediaQuery from 'react-responsive'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

export interface IntroDropdownProps {
  id: string
  value?: any
  defaultValue?: any
  placeholder?: string
  label?: string
  optionsArray: readonly OptionValue[]
  showSearch?: boolean
  disableSubmit?: boolean
  formNoSubmit?: (value: any) => void
  large: boolean
  setWidth?: string
  onChange: (value: any) => void
}

export const IntroDropdown = (
  {
    id,
    defaultValue,
    onChange,
    formNoSubmit = () => ({}),
    placeholder,
    optionsArray,
    showSearch = false,
    disableSubmit = false,
    large,
    label,
    setWidth,
    value,
  }: IntroDropdownProps) => {
  const [isOpen, toggleOpen] = useState(false)
  const emptyValue = !value && !!placeholder
  return (
    <DropdownContainer>
      <DropdownStyles />
      <MediaQuery minWidth={BREAKPOINTS.tabletMin.value}>
        <StyledDropdown
          id={id}
          showSearch={showSearch}
          size="large"
          dropdownClassName="ant-select-intro"
          // ant design needs to see undefined to show the placeholder
          value={emptyValue ? undefined : value}
          dropdownMatchSelectWidth={false}
          placeholder={placeholder}
          aria-label={label || placeholder}
          defaultValue={defaultValue}
          optionFilterProp="children"
          setWidth={setWidth}
          open={isOpen}
          onDropdownVisibleChange={toggleOpen}
          onChange={(valueSelected) => disableSubmit ? formNoSubmit(valueSelected) : onChange(valueSelected)}
          dropdownRender={(options) => (
            <MenuContainer large={large}>
              {options}
            </MenuContainer>
          )}
          showArrow={false}
          notFoundContent="Not Found"
        >
          {optionValues(optionsArray)}
        </StyledDropdown>
      </MediaQuery>
      <MediaQuery maxWidth={BREAKPOINTS.tabletMax.value}>
        <StyledSelect
          id={id}
          data-large={large}
          value={emptyValue ? 'default' : value}
          onChange={(event) => {
            const valueNum = toNumber(event.target.value)
            return disableSubmit ? formNoSubmit(valueNum) : onChange(valueNum)
          }}>
          {placeholder &&
          <option value="default"
                  disabled
                  hidden>{placeholder}</option>}
          {mobileValues(optionsArray)}
        </StyledSelect>
      </MediaQuery>
      <StyledArrow isOpen={isOpen}>
        <Down />
      </StyledArrow>
    </DropdownContainer>
  )
}


