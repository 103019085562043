// returns breakeven year if it exists
import { PCCalcResults } from '../data/models'

/**
 * Calculates the year by which we break even on between two kinds of leases.
 */
export const breakEvenYear = (firstLease: PCCalcResults, secondLease: PCCalcResults): number | undefined => {
  const arrayCheck = firstLease.avg.map((firstLeaseAvg, year) => (firstLeaseAvg - secondLease.avg[year] > 0))
  for (let i = 0; i < arrayCheck.length; i += 1) {
    // checks when there is a flip from true to false or vice versa using arrayCheck array
    if (arrayCheck[0] !== arrayCheck[i]) {
      return i + 1
    }
  }
  // if breakeven not found in all 10 years, return undefined
  return undefined
}
