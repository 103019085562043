import React from 'react'
import ReactHighcharts from 'react-highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import { selectCompanyInputs, selectSeatChartResults } from '../../../redux/calculations/calculation.selectors'
import { connect } from 'react-redux'
import { StyledChartContainer, StyledLegend } from './styles'
import Highcharts from 'highcharts'
import { headcountChartOptions } from './headcountChartOptions'
import { LegendLabel } from '../legendLabel'
import { COLORS } from '../../../constants/styles'

import { useChartHeight } from '../../../utils/hooks/hooks'


HighchartsMore(ReactHighcharts.Highcharts)
// making the separator use a comma instead of space
ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
  isPrint?: boolean
  chartHeight?: number
  chartWidth?: number | undefined
  id?: string
}

type Props = OwnProps & StateProps

const HeadcountProjection = ({ seatChartResults, isPrint = false , chartHeight = undefined, chartWidth = undefined, id}: Props) => {
  const leaseLabels = seatChartResults.avg.map((x, index) => (`${index + 1} Yr Lease`))
  const options: Highcharts.Options = headcountChartOptions(leaseLabels, seatChartResults, useChartHeight(chartHeight), chartWidth, isPrint)
  return <StyledChartContainer isPrint={isPrint} id={id}>
    <StyledLegend isPrint={isPrint}>
      <LegendLabel
        color={COLORS.PRIMARY_00}
        label="Average Seats Required"
        isPrint={isPrint}
      />
      <LegendLabel
        color={COLORS.INFORMATION_00}
        label="Lowest - Highest Seats Required"
        isPrint={isPrint}
        />
    </StyledLegend>
    <ReactHighcharts config={options} />
  </StyledChartContainer>
}

const mapStateToProps = (state: any) => ({
  companyInputs: selectCompanyInputs(state),
  seatChartResults: selectSeatChartResults(state),
})

export default connect(mapStateToProps)(HeadcountProjection)
