export const rentIncreases = [
  { id: 0, optionName: '0% / Year', value: 0 },
  { id: 1, optionName: '1% / Year', value: 0.01 },
  { id: 2, optionName: '2% / Year', value: 0.02 },
  { id: 3, optionName: '3% / Year', value: 0.03 },
  { id: 4, optionName: '4% / Year', value: 0.04 },
  { id: 5, optionName: '5% / Year', value: 0.05 },
  { id: 6, optionName: '6% / Year', value: 0.06 },
  { id: 7, optionName: '7% / Year', value: 0.07 },
  { id: 8, optionName: '8% / Year', value: 0.08 },
  { id: 9, optionName: '9% / Year', value: 0.09 },
  { id: 10, optionName: '10% / Year', value: 0.1 },
  { id: 11, optionName: '11% / Year', value: 0.11 },
  { id: 12, optionName: '12% / Year', value: 0.12 },
  { id: 13, optionName: '13% / Year', value: 0.13 },
  { id: 14, optionName: '14% / Year', value: 0.14 },
  { id: 15, optionName: '15% / Year', value: 0.15 },
  { id: 16, optionName: '16% / Year', value: 0.16 },
  { id: 17, optionName: '17% / Year', value: 0.17 },
  { id: 18, optionName: '18% / Year', value: 0.18 },
  { id: 19, optionName: '19% / Year', value: 0.19 },
  { id: 20, optionName: '20% / Year', value: 0.2 },
]
