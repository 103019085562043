import { unitOfMeasure, UnitOfMeasure } from '../../utils/selectOptions/unitOfMeasure'
import { currencies, CurrencyOption } from '../../optionsConfig/models'
import { unitOfTime, UnitOfTime } from '../../utils/selectOptions/unitOfTime'
import { UnitCurrencyActions, UnitCurrencyActionTypes } from './unitCurrency.actions'
import { ScenarioActions, ScenarioActionTypes } from '../scenario/scenario.actions'

export interface UnitCurrencyState {
  readonly currency: CurrencyOption
  readonly unitOfTime: UnitOfTime
  readonly unitOfMeasure: UnitOfMeasure
  readonly isInitial: boolean
}

export const initialUnitCurrencyState: UnitCurrencyState = {
  currency: currencies[0],
  unitOfMeasure: unitOfMeasure[0],
  unitOfTime: unitOfTime[0],
  isInitial: true,
}

export const unitCurrencyReducer = (state: UnitCurrencyState = initialUnitCurrencyState,
                                    action: UnitCurrencyActionTypes | ScenarioActionTypes): UnitCurrencyState => {
  switch (action.type) {
    case UnitCurrencyActions.UpdateUnitCurrencySelection:
      return {
        ...state,
        currency: currencies.find(c => c.value === action.values.currency)!,
        unitOfTime: unitOfTime.find(u => u.value === action.values.unitOfTime)!,
        unitOfMeasure: unitOfMeasure.find(u => u.value === action.values.unitOfMeasure)!,
        isInitial: false,
      }
    case ScenarioActions.StoreSavedScenario:
      const unitCurrency = action.scenario.unitCurrency
      return {
        ...state,
        currency: currencies.find(c => unitCurrency.currency === c.value)!,
        unitOfTime: unitOfTime.find(c => unitCurrency.unitOfTime === c.value)!,
        unitOfMeasure: unitOfMeasure.find(u => unitCurrency.unitOfMeasure === u.value)!,
        isInitial: false,
      }
    default:
      return state
  }
}
