import React from 'react'

export const tooltipText = {
  initialSeats: <span>Select the number of workstations you need to support your current headcount. If greater than 100, please contact the Agile Solutions team for help on your particular needs - <a href="mailto:Derek.Chanler-Berat@cbre.com" target="_blank" rel="noopener noreferrer">Derek.Chanler-Berat@cbre.com.</a></span>,
  initialDesiredLeaseTerm: 'Choose the length of your desired lease (1 to 10 years) and then compare traditional and flexible options.',
  annualSeatChange: <span>Estimate your company&apos;s growth and anticipated future headcount. CALC will use this to determine your space requirements, and add structured vacancy to accommodate anticipated growth based on year 3 projections, following CBRE best practices. If your growth rate is over 25%, please take our <a href="https://www.cbre.com/configuration/global%20shared/content/articles/agile-real-estate/hyperscale-the-rough-guide-to-startup-real-estate" target="_blank" rel="noopener noreferrer">Startup Growth Quiz.</a></span>,
  seatVolatility: <span>What is your confidence level in predicting your future headcount. For more information, see our guidance on <a href="https://www.cbre.com/agile-real-estate/how-i-learned-to-stop-worrying-and-love-uncertainty" target="_blank" rel="noopener noreferrer">planning for change</a>.</span>,
  quotedSeatCharges: 'Flexible options usually quote a simple cost per seat that does not include conference room charges and other ancillary fees. When using one of the preset markets, we created a blended rate of 65% dedicated seats, 35% flex seats and 5% private desks, following our recommended office setup.',
  meetingRoomChargesHourly: 'Meeting rooms are commonly charged at an hourly rate per person. Flexible spaces typically charge for meeting rooms separately.',
  meetingRoomRequirement: 'Meeting rooms are often overlooked when considering the estimated total cost per seat. Here’s a range of typical requirements for meeting room space to apply to your analysis: -Low Usage: 2 hours employee/month -Average Usage: 3 hours employee/month -High Usage: 4 hours employee/month.',
  monthlyMeetingRoomCost: 'Cost per seat for meeting rooms is automatically calculated by multiplying the hourly rate and monthly hours used. You may customize this cost to match your specific scenario.',
  oneTimeSetupCost: 'There is typically no one-time set up cost for flexible spaces. However, certain IT/networking requirements may incur a set-up fee from the service provider.',
  flexCostEscalation: 'Yearly cost escalations in addition to rent that might affect financial analysis, such as inflation, for example.',
  otherOpexFlexCharges: 'Additional costs incurred on a monthly basis may include postage, printing, beverages, etc.',
  capexHardCost: 'Construction costs (or “hard costs”) include Mechanical & Electrical systems, builder works and Cat B costs.',
  capexSoftCost: 'May also be known as fees, professional fees, workplace & design.',
  capexITData: 'May include AV cost, cabling, data storage.',
  tiAllowanceValue: 'Any Tenant Improvement allowance provided by the landlord as contribution to one time costs.',
  reinstatement: 'Any contractually agreed costs paid to the landlord to restore the space to its original condition at the end of the lease. May also be known as dilapidations.',
  earlyExitPenalty: 'A cost/penalty for terminating the lease prematurely, expressed as a percentage of the remaining rent due.  Each year for the traditional lease, the formula assesses 1) what the Area is without structured vacancy, 2) what the ((Area without structured vacancy)/ Head) is based on that year\'s number of Heads, and 3) whether the ((Area without structured vacancy)/ Head) is < the value input for (Usable Area/ Head).  If the former is less than the latter, the model assumes that the tenant needs new space and accounts for costs of moving into a new space that meets the value input for (Usable Area/ Head). The model considers the Area without structured vacancy in the above because tenants typically use structured vacancy and anticipate trying to pack into the vacancy a bit, while being aware that the structured vacancy will not last the tenant much longer than a year.',
  rentFreePeriodValue: 'Enter the number of rent-free months you expect to receive.',
  rentRate: 'This value is the cost to occupy the space, whether net or gross, per square foot, square meter, or tsubo and per year or month based on the units you have selected.',
  rentEscalationRate: 'If your rent has annual increases/indexation, enter that amount here. If rent increases change on a basis other than an annual rate, pro-rate that increase to an annual basis and enter it as a % here.',
  operatingCosts: 'These are expenses that are included in the lease payable to the landlord but listed separate from the rent. Examples include Service Charge and Common Area Maintenance. In markets where rent is paid on a gross/all-inclusive basis, this should be left at “zero.”',
  facilityManagement: 'Enter your Faciliites Management costs paid in addition to Service Charges. These may include R&M, Reception, Cleaning, Waste and Utilities.',
  otherMiscOpex: 'Miscellaneous operational expenses include local taxes and specialist tenant-required expenses that don’t fit into other categories. When adding costs here, consider whether or not these would be included/excluded from a flexible option to ensure an accurate comparison.',
  usableSeatArea: 'This the target metric for space-per-workstation based on benchmarks for this market. This can be overwritten with a client space-per-workstation target which would be applied based on usable area (as opposed to rentable area). The load/loss factor ratio between usable : rentable will then be applied for you to calculate the rentable area required.',
  spacePlanningAssumption: 'Extra space taken to allow for growth.',
  usableSeatAreaReqYear2: 'Total usable area refers to the functional space used by the tenant. This includes internal circulation space and meeting rooms. Target metrics for space-per-workstation commonly refer to the usable area as it excludes any areas where the tenant does not have exclusive access, such as stairwells, elevators, lobbies and external walls.',
  lossFactor: 'The difference between usable area and rentable area is known as “loss factor.”  This refers to areas a tenant pays rent for as part of the lease but isn’t able to use exclusively for workstation settings.  This factor varies depending on local market leasing practices but may commonly include stairwells, elevators, lobbies and external walls.  Loss factor = 1 - ((RSF - USF)/ RSF), expressed as a percentage.',
  rentableSeatArea: 'This is the total area per workstation based on the rentable area included in the lease contract.',
  totalGrossRentableAreaYear2: 'This reflects the total rentable area desired using the seat requirement, workplace target and loss/load factor calculations.',
  tradCostEscalation: 'Yearly cost escalations in addition to rent that might affect financial analysis, such as inflation, for example.',
}
