import { ScenarioLeaseType } from '../../data/models'

export const leaseTypeToLeaseUnit = (
  leaseType: ScenarioLeaseType,
  initialSeats: number,
  measureName: string): string => {
  switch (leaseType) {
    case ScenarioLeaseType.Traditional:
      return `${measureName}`
    case ScenarioLeaseType.FlexCoworking:
      return `Seat${initialSeats !== 1 ? ('s' || ''): ''}`
  }
}

export const leaseTypeToLeaseValue = (
  leaseType: ScenarioLeaseType,
  netArea: number | undefined,
  initialSeats: number): string => {
  switch (leaseType) {
    case ScenarioLeaseType.Traditional:
      return `Starting at ${netArea!.toLocaleString()}`
    case ScenarioLeaseType.FlexCoworking:
      return `${initialSeats}`
  }
}
