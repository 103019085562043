import React from 'react'
import { ScenarioLeaseType } from '../../../../data/models'
import {
  StyledCloseContainer,
  StyledContainer,
  StyledContentContainer,
  StyledIconContainer,
  StyledLeaseDescription,
  StyledLeaseName,
  StyledButton,
} from './styles'
import { scenarioLeaseTypeToUIName } from '../../../../data/conversions'
import { Close } from '../../../common/icons/index'
import {
  FlexibleLease1,
  FlexibleLease2,
  FlexibleLease3,
  FlexibleLease4,
  FlexibleLease5,
} from '../../../common/icons/flexible_lease'
import {
  StandardLease1,
  StandardLease2,
  StandardLease3,
  StandardLease4,
  StandardLease5,
} from '../../../common/icons/standard_lease'
import { useBreakpoint } from '../../../../utils/hooks/hooks'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

interface Props {
  leaseType: ScenarioLeaseType
  leaseName: string

  /**
   * This can be either seat count i.e. "14 seats" or Square feet count "Starting at 5,213 sq ft"
   */
  description: string

  /**
   * a number between 0 and 4, determines the icon to show. If over 5, it'll modulo out
   * the number to return an icon within 0-4.
   */
  index: number

  onClose: () => void
}

const IconForIndex = ({ index, leaseType }: { index: number, leaseType: ScenarioLeaseType }) => {
  const reducedIndex = index % 5 // reduce to within 0 - 4
  switch (leaseType) {
    case ScenarioLeaseType.FlexCoworking:
      switch (reducedIndex) {
        case 0:
          return <FlexibleLease1 />
        case 1:
          return <FlexibleLease2 />
        case 2:
          return <FlexibleLease3 />
        case 3:
          return <FlexibleLease4 />
        case 4:
        default:
          return <FlexibleLease5 />
      }
    case ScenarioLeaseType.Traditional:
      switch (reducedIndex) {
        case 0:
          return <StandardLease1 />
        case 1:
          return <StandardLease2 />
        case 2:
          return <StandardLease3 />
        case 3:
          return <StandardLease4 />
        default:
        case 4:
          return <StandardLease5 />
      }
  }
}

const CostComparisonHeader = ({ leaseName, leaseType, description, index, onClose }: Props) => {
  const isTablet = useBreakpoint(BREAKPOINTS.mobileMin)

  return <StyledContainer>
    <StyledCloseContainer>
      <StyledButton onClick={onClose} aria-label="close">
        <Close />
      </StyledButton>
    </StyledCloseContainer>
    <StyledContentContainer>
      <StyledLeaseDescription isLeaseType>{scenarioLeaseTypeToUIName(leaseType, !isTablet)}</StyledLeaseDescription>
      <StyledLeaseName>{leaseName}</StyledLeaseName>
      <StyledLeaseDescription isLeaseType={false}>{description}</StyledLeaseDescription>
    </StyledContentContainer>
    { isTablet && <StyledIconContainer>
        <IconForIndex
          index={index}
          leaseType={leaseType} />
      </StyledIconContainer>
    }
  </StyledContainer>
}
export default CostComparisonHeader
