import styled from 'styled-components'

import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'

export const StyledTermOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${breakpoint(BREAKPOINTS.mobileMax)`
    flex-direction: column;
  `}
`
