import React, { SetStateAction, useState } from 'react'
import { StyledButton, StyledButtonContainer, StyledContainer, StyledInput, StyledTitle } from '../styles'
import { ButtonType } from '../../common/button'
import { combineValidators, isRequired } from '../../../validation/validation'
import { isValidEmail } from '../../../validation/email_validation'
import { connect, Dispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closePopover } from '../../../redux/popover/popover.actions'
import { emailScenario } from '../../../redux/scenario/scenario.actions'
import { selectEmailScenarioRequestLoading } from '../../../redux/scenario/scenario.selectors'
import { useKeyPress } from '../../../utils/hooks/hooks'
import { KEY_ENTER, KEY_TAB } from '../../../utils/hooks/constants'

const emailValidators = combineValidators(isRequired, isValidEmail)

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = DispatchProps & StateProps

const checkAndSendValidEmail = (email: string,
                                setError: React.Dispatch<SetStateAction<string>>,
                                emailScenarioAction: typeof emailScenario) => {
  const error = emailValidators(email)
  if (error) {
    setError(error)
  } else {
    setError('')
    emailScenarioAction(email)
  }
}

const EmailScenario = ({ closePopover, emailScenario, emailLoading }: Props) => {
  const [email, setEmail] = useState('')
  const [errorText, setError] = useState('')
  useKeyPress([
    KEY_ENTER,
    KEY_TAB,
  ], () => checkAndSendValidEmail(email, setError, emailScenario), [email])
  return <StyledContainer submitting={false}>
    <StyledTitle>Email your Scenario.</StyledTitle>
    <StyledInput
      id="email"
      placeholder="Email"
      callback={setEmail}
      value={email}
      errorMessage={errorText}
      error={!!errorText}
    />
    <StyledButtonContainer>
      <StyledButton
        id="cancel-button"
        text="Cancel"
        type={ButtonType.Secondary}
        wrapContent
        onClick={closePopover}
      />
      <StyledButton
        id="send-button"
        text="Send"
        type={ButtonType.Primary}
        wrapContent
        isSecondary
        disabled={emailLoading}
        onClick={() => checkAndSendValidEmail(email, setError, emailScenario)}
      />
    </StyledButtonContainer>
  </StyledContainer>
}

const mapStateToProps = (state: any) => ({
  emailLoading: selectEmailScenarioRequestLoading(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
  closePopover,
  emailScenario,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmailScenario)
