import React, { ReactNode } from 'react'

import { Label } from '../../text/label'

import {
  InvisibleSpan,
  StyledBottomText,
  StyledContainer,
  StyledErrorText,
  StyledExtras,
  StyledSuffix,
  StyledTextField,
  SuffixExtrasContainer,
} from './styles'

export interface InputProps {
  callback: (value: any) => void
  value?: any
  name?: string
  label?: string | null
  tooltipText?: string
  helperText?: string
  prefix?: ReactNode
  bottomText?: ReactNode
  suffix?: ReactNode
  icon?: ReactNode
  placeholder?: string
  errorMessage?: string
  maxLength?: number | null
  disabled?: boolean
  error?: boolean
  handleInputTouch?: () => void
  handlekeydown?: (e: any) => void
  inputSelectAll?: (e: any) => void
  onBlur?: (e: any) => void
  expandable?: boolean
  className?: string
  id: string
  type?: string
}

/**
 * Appends a postFix to the id to uniquely id elements.
 */
const formatId = (id: string, postFix: string) => `${id}--${postFix}`

export const Input = (
  {
    name = 'text-field',
    disabled = false,
    placeholder = '',
    maxLength = null,
    label = null,
    prefix = null,
    bottomText = null,
    icon = null,
    suffix = null,
    errorMessage = '',
    error = false,
    value = '',
    callback = () => {
    },
    helperText = '',
    tooltipText,
    expandable = false,
    onBlur = () => {
    },
    id,
    handlekeydown,
    type,
    className,
  }: InputProps) => {
  return (
    <StyledContainer className={className}>
      {label && <Label
        id={formatId(id, 'label')}
        text={label}
        tooltipText={tooltipText} />}
      <SuffixExtrasContainer>
        <StyledTextField
          id={formatId(id, 'input')}
          aria-label={label}
          expandable={expandable}
          name={name}
          onChange={(e: any) => callback(e.target.value)}
          prefix={prefix}
          icon={icon}
          disabled={disabled}
          placeholder={placeholder}
          helperText={helperText}
          value={value}
          maxLength={maxLength}
          isValid={!error}
          onBlur={onBlur}
          onKeyDown={handlekeydown}
          type={type}
        />
        {suffix && <StyledSuffix>
          <InvisibleSpan>{value || 0}</InvisibleSpan>{suffix}
        </StyledSuffix>}
        {bottomText && <StyledBottomText id={formatId(id, 'bottom')}>{bottomText}</StyledBottomText>}
        {(helperText || (errorMessage && error)) &&
        <StyledExtras
          disabled={disabled}>
          {error && errorMessage !== '' &&
          <StyledErrorText id={formatId(id, 'errorMessage')}>{errorMessage}</StyledErrorText>}
        </StyledExtras>}
      </SuffixExtrasContainer>
    </StyledContainer>
  )
}
