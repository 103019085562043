import {
  CalculateAllLeases,
  calculateAllLeases,
  calculateAllLeasesFailed,
  calculateAllLeasesSucceeded,
  CalculateLeaseResults,
  calculateLeaseResultsCompanyProfile,
  CalculateLeaseResultsCompanyProfile,
  calculateLeaseResultsFailed,
  calculateLeaseResultsSucceeded,
  CalculationActions,
  storeCompanyInputs,
  UpdateCompanyInputs,
} from './calculation.actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { CalculateIterationsInput, CalculateIterationsResult, MultiLeaseScenario } from '../../data/models'
import { selectNPVDiscountRate, selectScenarioSuccess } from '../scenario/scenario.selectors'
import { iterationsInputForCompanyInputs, mergeScenario } from '../../data/conversions'
import { createDefaultLeases } from '../../data/lease_utils'
import { CompanyLocation } from '../../optionsConfig/models'
import { selectUnitCurrencyState, selectUnitCurrencyForForm } from '../unitCurrency/unitCurrency.selectors'
import { client } from '../../api/http.client'
import { selectCompanyInputs, selectCompanyLocation } from './calculation.selectors'
import isEqual from 'lodash/isEqual'
import { putNewScenario } from '../scenario/scenario.actions'
import { defaultMeetingRoomRequirement } from '../../utils/selectOptions/meetingRoomRequirements'
import { CompanyInputsShape } from '../../data/form_models'
import { UnitCurrencyState } from '../unitCurrency/unitCurrency.reducer'
import { updateUnitCurrencySelection } from '../unitCurrency/unitCurrency.actions'
import locationsConfig from '../../optionsConfig/locations.json'

import { foundValue } from '../../utils/arrayHelpers'
/**
 * Calculates all leases for company profile inputs. It will create new leases (if necessary) to
 * send to calculate iterations.
 */
function* calculateLeaseResultsCompanyProfileSaga(action: CalculateLeaseResultsCompanyProfile) {
  // check previous, if same, don't calculate
  const previousInputs = yield select(selectCompanyInputs)
  if (isEqual(previousInputs, action.body)) {
    return
  }
  yield put(storeCompanyInputs(action.body))

  const existingScenario: MultiLeaseScenario | undefined = yield select(selectScenarioSuccess)
  const { currency, unitOfMeasure, unitOfTime }: UnitCurrencyState = yield select(selectUnitCurrencyState)
  const companyLocation: CompanyLocation | undefined = yield select(selectCompanyLocation)
  const discountRate: number = yield select(selectNPVDiscountRate)
  if (!companyLocation) {
    console.error('Could not find company location in state')
    return
  }

  let leases = (existingScenario && existingScenario.leases) || []
  if (leases.length === 0) {
    // add 2 new leases to calculate from scenario and overwrite in store
    // one of each type on initial creation
    leases = [...createDefaultLeases(
      companyLocation,
      action.body,
      defaultMeetingRoomRequirement.value,
      currency,
      unitOfMeasure,
    )]
  }
  const input: CalculateIterationsInput = iterationsInputForCompanyInputs(existingScenario,
    unitOfTime,
    unitOfMeasure,
    currency,
    action.body,
    discountRate,
    leases)
  yield put(calculateAllLeases(input))

  const newScenario: MultiLeaseScenario = mergeScenario(existingScenario, action.body,
    companyLocation, leases, currency, unitOfMeasure, unitOfTime)
  yield put(putNewScenario(newScenario))
}


function* calculateAllLeaseResults(action: CalculateAllLeases) {
  try {
    const response: CalculateIterationsResult = yield call(() => client.post(`/results/calculateAll`, action.body))
    yield put(calculateAllLeasesSucceeded(response))
  } catch (e) {
    yield put(calculateAllLeasesFailed(e))
  }
}

function* calculateLeaseResults(action: CalculateLeaseResults) {
  try {
    const response: CalculateIterationsResult = yield call(() => client.post(`/results/calculateAll`, action.body))
    yield put(calculateLeaseResultsSucceeded(response))
  } catch (e) {
    yield put(calculateLeaseResultsFailed(e))
  }
}

function* updateCompanyInputsSaga(action: UpdateCompanyInputs) {
  const previousInputs: CompanyInputsShape = yield select(selectCompanyInputs)
  const updatedInputs: CompanyInputsShape = {
    ...previousInputs,
    ...action.body,
  }
  if (!isEqual(previousInputs, updatedInputs)) {
    if(action.body.location) {
      // if location changes, updated to stored UOM and Currency
      const currentUnitCurrency = yield select(selectUnitCurrencyForForm)
      const { unitDefaults: {currency,  unitOfMeasure} } = foundValue(locationsConfig.locations, action.body.location)
      yield put(updateUnitCurrencySelection({
        ...currentUnitCurrency,
        currency,
        unitOfMeasure
      }))
    }
    yield put(calculateLeaseResultsCompanyProfile(updatedInputs))
  }
}

export function* calculationSagas() {
  yield takeEvery(CalculationActions.CalculateLeaseResultsCompanyProfile, calculateLeaseResultsCompanyProfileSaga)
  yield takeEvery(CalculationActions.CalculateAllLeases, calculateAllLeaseResults)
  yield takeEvery(CalculationActions.CalculateLeaseResults, calculateLeaseResults)
  yield takeEvery(CalculationActions.UpdateCompanyInputs, updateCompanyInputsSaga)
}
