import locationsJson from './locations.json'
import currenciesJson from './currencies.json'
import { OptionValue } from '../utils/selectOptions/optionValues'

/**
 * Default set of values for a location.
 */
export interface CompanyLocation {
  readonly value: number
  readonly region: string
  readonly city: string
  readonly optionName: string
  readonly rentRate: number
  readonly rentEscalationRate: number
  readonly operatingCosts: number
  readonly facilityManagement: number
  readonly otherMiscOpex: number
  readonly capexHardCost: number
  readonly capexFFE: number
  readonly capexSoftCost: number
  readonly capexITData: number
  readonly tenantIncentives: number
  readonly reinstatement: number
  readonly rentableArea: number
  readonly netAreaSeatAssumption: number
  readonly earlyExitPenalty: number
  readonly rentFreePeriod: number
  readonly typicalFitoutDuration: string
  readonly flexCostEscalation: number
  readonly tradCostEscalation: number
  readonly quotedSeatCharges: number
  readonly meetingRoomChargesHourly: number
  readonly otherOpexFlexCharges: number
  readonly unitDefaults: UnitDefaults
}

export const defaultLocations = locationsJson.locations as readonly CompanyLocation[]

export interface UnitDefaults {
  readonly currency: number
  readonly unitOfMeasure: number
}

export interface CurrencyOption extends OptionValue {
  readonly iso: string
  readonly currency: string
  readonly usd: number
  readonly convert: number
  readonly symbol?: string
}

// @ts-ignore
export const currencies = currenciesJson as readonly CurrencyOption[]
