import { COLORS, FONTS } from '../../../constants/styles'
import Highcharts, { LineChartSeriesOptions } from 'highcharts'
import { PCCalcResults } from '../../../data/models'


export const headcountChartOptions = (
  leaseLabels: string[],
  seatChartResults: PCCalcResults,
  chartHeight: number,
  chartWidth: number | undefined,
  isPrint?: boolean
  ): Highcharts.Options => {
  return {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    chart: {
      height: chartHeight,
      width: chartWidth,
      pinchType: '',
      zoomType: '',
      style: {
        fontFamily: FONTS.PRIMARY,
        fontSize: '16px',
      },
    },
    plotOptions: {
      series: {
        animation: !isPrint,
      },
    },
    title: {
      text: '',
    },
    xAxis: [{
      categories: leaseLabels,
      crosshair: true,
      labels: {
        style: {
          color: COLORS.DARK_01,
          fontFamily: FONTS.PRIMARY,
          fontSize: isPrint ? '9px' : '12px',
        },
        rotation: -90,
      },
    }],
      yAxis: [{ // Primary yAxis
    // min: 0,
    labels: {
      format: '{value}',
      style: {
        color: COLORS.DARK_01,
        fontFamily: FONTS.PRIMARY,
        fontSize: '14px',
      },
    },
    title: {
      text: null,
    },
  }],
  tooltip: {
    enabled: !isPrint,
    followTouchMove: false,
    shared: true,
    followPointer: true,
  },
    series: [
      {
        name: 'Seat Demand (80th percentile)',
        type: 'spline',
        data: seatChartResults.high,
        color: COLORS.INFORMATION_00,
        dashStyle: 'Dash',
        marker: {
          enabled: false,
        },
      },
      {
        name: 'Seat Demand (Average)',
        type: 'spline',
        data: seatChartResults.avg,
        color: COLORS.PRIMARY,
        marker: {
          symbol: 'circle',
          radius: 5,
        },
      },
      {
        name: 'Seat Demand (20th percentile)',
        type: 'spline',
        data: seatChartResults.low,
        color: COLORS.INFORMATION_00,
        dashStyle: 'Dash',
        marker: {
          enabled: false,
        },
      },
    ] as LineChartSeriesOptions[],
  }
}
