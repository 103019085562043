import React, {useState} from 'react'
import MediaQuery from 'react-responsive'

import { Label } from '../../text/label'
import { Down } from '../../icons'
import { toNumber } from '../../../../utils/toNumber'

import { mobileValues, optionValues } from '../../../../utils/selectOptions/index'

import { DropdownStyles, DropdownContainer, MenuContainer, StyledDropdown, StyledSelect, StyledArrow, ArrowContainer } from './styles'
import { OptionValue } from '../../../../utils/selectOptions/optionValues'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

interface OwnProps {
  id: string
  showSearch?: boolean
  defaultValue?: any
  placeholder?: string
  optionsArray: readonly OptionValue[]
  label?: string | null
  tooltipText?: string
  tooltipTitle?: string | null
  formNoSubmit?: (value: any) => void
  disableSubmit?: boolean
  onChange: (value: any) => void
  value?: any
  className?: string
}

export type DropdownProps = OwnProps

export const Dropdown = (
  {
    defaultValue,
    placeholder,
    optionsArray,
    label = null,
    tooltipText,
    showSearch = false,
    disableSubmit = false,
    formNoSubmit = () => ({}),
    onChange,
    value = undefined,
    className,
    id,
  }: DropdownProps) => {
  const [isOpen, toggleOpen] = useState(false)
  //test if initial value is string
  const isString = !Number.isInteger(value)
  const emptyValue = !value && placeholder
  const labelId = `${id}--label`
  return (
    <DropdownContainer className={className}>
      {label && <Label
        id={labelId}
        text={label}
        tooltipText={tooltipText} />}
      {/* For Desktop */}
      <ArrowContainer>
        <MediaQuery minWidth={BREAKPOINTS.mobileMin.value}>
          <DropdownStyles />
          <StyledDropdown
            id={`${id}--dropdown`}
            showSearch={showSearch}
            size="large"
            value={(emptyValue ? undefined : value)}
            aria-labelledby={labelId}
            dropdownMatchSelectWidth={false}
            placeholder={placeholder}
            open={isOpen}
            defaultValue={defaultValue}
            onDropdownVisibleChange={toggleOpen}
            optionFilterProp="children"
            onChange={(valueSelected) => disableSubmit ? formNoSubmit(valueSelected) : onChange(valueSelected)}
            dropdownRender={(options) => (
              <MenuContainer>
                {options}
              </MenuContainer>
            )}
            showArrow={false}
            notFoundContent="Not Found"
          >
            {optionValues(optionsArray)}
          </StyledDropdown>
        </MediaQuery>
        {/* For Mobile */}
        <MediaQuery maxWidth={BREAKPOINTS.mobileMax.value}>
          <StyledSelect
            id={`${id}--dropdown`}
            value={emptyValue ? undefined : value}
            onChange={(event) => {
              const valueNum = isString ? event.target.value : toNumber(event.target.value)
              disableSubmit ? formNoSubmit(valueNum) : onChange(valueNum)
            }}>
              {placeholder &&
              <option value="default"
                  disabled
                  hidden>
                  {placeholder}
                </option>}
            {mobileValues(optionsArray)}
          </StyledSelect>
        </MediaQuery>
        <StyledArrow isOpen={isOpen}>
          <Down height={22} width={22} />
        </StyledArrow>
      </ArrowContainer>
    </DropdownContainer>
  )
}
