import React from 'react'
import { bindActionCreators } from 'redux'
import { connect, Dispatch } from 'react-redux'
import { chartOptions } from '../../../utils/selectOptions/index'
import { StyledDiscountPlaceholder, StyledResultsContainer, StyledDropdownWrapper, StyledSelectOption, StyledInputContainer } from './styles'
import { ChartTypeId } from '../../../utils/selectOptions/chartOptions'
import { selectSelectedChartOption } from '../../../redux/calculations/calculation.selectors'
import { selectChartOption } from '../../../redux/calculations/calculation.actions'
import { selectNPVDiscountRate } from '../../../redux/scenario/scenario.selectors'
import { NumberInput } from '../../common/input/NumberInput'
import { updateNPVDiscountRate } from '../../../redux/scenario/scenario.actions'

export enum ResultDisplayMode {
  /**
   * When set, it will be row layout on desktop width devices and column on mobile sized devices.
   */
  Full,

  /**
   * When set, always column layout.
   */
  Condensed
}

interface OwnProps {
  displayMode?: ResultDisplayMode
  className?: string
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = OwnProps & DispatchProps & StateProps

const ResultsOptions = (
  {
    selectChartOption,
    chartOption,
    npvDiscountRate,
    displayMode = ResultDisplayMode.Full,
    updateNPVDiscountRate,
    className,
  }: Props) =>
  <StyledResultsContainer
    displayMode={displayMode}
    className={className}>
    <StyledSelectOption displayMode={displayMode}>
      <StyledDropdownWrapper
        id="chart-type"
        placeholder="Select Chart Type"
        value={chartOption.value}
        onChange={(valueSelected: any) =>  selectChartOption(chartOptions.find(c => c.value === valueSelected)!)}
        optionsArray={chartOptions}
        />
    </StyledSelectOption>
    {chartOption.value === ChartTypeId.npv &&
    <StyledInputContainer
      id="npv--input"
      displayMode={displayMode}>
      <NumberInput
        expandable
        submitForm={value => updateNPVDiscountRate(value)}
        valueIfEmpty={0}
        id="discountRate"
        label="NPV Discount Rate"
        initialValue={npvDiscountRate}
        suffix="%"
        min={0}
        max={100}
      />
    </StyledInputContainer>}
    {chartOption.value !== ChartTypeId.npv && <StyledDiscountPlaceholder />}
  </StyledResultsContainer>


const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators({
    selectChartOption,
    updateNPVDiscountRate,
  }, dispatch)

const mapStateToProps = (state: any) => ({
  chartOption: selectSelectedChartOption(state),
  npvDiscountRate: selectNPVDiscountRate(state),
})

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(ResultsOptions)