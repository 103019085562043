import { noCalculation, UnitCurrencyActions } from './unitCurrency.actions'
import { put, select, takeEvery } from 'redux-saga/effects'
import { selectNPVDiscountRate, selectScenarioModel } from '../scenario/scenario.selectors'
import { CompanyLocation } from '../../optionsConfig/models'
import { selectUnitCurrencyState } from './unitCurrency.selectors'
import { LoadingModel } from '../../data/loading_model'
import { CalculateIterationsInput, MultiLeaseScenario } from '../../data/models'
import { selectCompanyInputs, selectCompanyLocation } from '../calculations/calculation.selectors'
import { iterationsInputForCompanyInputs, mergeScenario } from '../../data/conversions'
import { calculateAllLeases } from '../calculations/calculation.actions'
import { putNewScenario, saveScenario } from '../scenario/scenario.actions'
import { CompanyInputsShape } from '../../data/form_models'
import { UnitCurrencyState } from './unitCurrency.reducer'

/**
 * Updates values of iterations only if the scenario has been picked and calculated already. It will
 * only update currency values for the {@link MultiLeaseScenario}.
 */
function* updateUnitCurrencyTriggerIterations() {
  const model: LoadingModel<MultiLeaseScenario> = yield select(selectScenarioModel)
  if (!model.optionalSuccess) {
    // no scenario yet, let's await calculating lease results from company profile
    yield put(noCalculation('noScenario'))
    return
  }

  const { currency, unitOfMeasure, unitOfTime }: UnitCurrencyState = yield select(selectUnitCurrencyState)
  const discountRate: number = yield select(selectNPVDiscountRate)

  const existingScenario = model.optionalSuccess
  const leases = (existingScenario && existingScenario.leases) || []
  // select previous inputs
  const companyInputs: CompanyInputsShape = yield select(selectCompanyInputs)
  const companyLocation: CompanyLocation | undefined = yield select(selectCompanyLocation)
  if (!companyLocation) {
    console.error('Could not find company location in state')
    yield put(noCalculation('noLocation'))
    return
  }
  // map leases with new units
  const input: CalculateIterationsInput = iterationsInputForCompanyInputs(existingScenario,
    unitOfTime,
    unitOfMeasure,
    currency,
    companyInputs,
    discountRate,
    leases)

  yield put(calculateAllLeases(input))

  const newScenario: MultiLeaseScenario = mergeScenario(existingScenario, companyInputs,
    companyLocation, leases, currency, unitOfMeasure, unitOfTime)
  yield put(putNewScenario(newScenario))

  // if saved save scenario
  if (!!newScenario._id) {
    yield put(saveScenario(newScenario))
  }
}


export function* unitCurrencySagas() {
  yield takeEvery(UnitCurrencyActions.UpdateUnitCurrencySelection, updateUnitCurrencyTriggerIterations)
}
