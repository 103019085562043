import React from 'react'
import styled, { css } from 'styled-components'
import { COLORS } from '../../../constants/styles'

import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'

const {
  ButtonPrimary,
  ButtonSecondary,
} = require('@cbrebuild/blocks-react').Buttons

const globalButtonWrap = css`
span {
    pointer-events: none;
  }
`

const globalButton = css`
  min-width: 140px;
  ${breakpoint(BREAKPOINTS.tabletMax)`
    width: 100%;
  `}
  ${globalButtonWrap}
`

interface ButtonProps {
  wrapContent?: boolean
}

export const StyledButtonPrimary = styled(({wrapContent, ...rest}) => <ButtonPrimary {...rest}/>)<ButtonProps>`
  background-color: ${COLORS.PRIMARY_00};
  border: 2px solid ${COLORS.PRIMARY_00};
  margin-bottom: 2px;
  ${({ wrapContent }: ButtonProps)=> wrapContent ? globalButtonWrap : globalButton}
`
export const StyledButtonSecondary = styled(({wrapContent, ...rest}) => <ButtonSecondary {...rest}/>)<ButtonProps>`
  color: ${COLORS.PRIMARY_00};
  margin-bottom: 2px;
  &:hover{
    background-color: ${COLORS.SECONDARY_01};
  }
  ${({ wrapContent }: ButtonProps)=> wrapContent ? globalButtonWrap : globalButton}
`

export const StyledContainer = styled.div`

`
