import React, { SetStateAction, useCallback } from 'react'
import { onBlur, SubmittingInput, SubmittingInputProps, ValueAdjuster, Validator } from './SubmittingInput'
import { toNumber } from '../../../utils/toNumber'
import { roundLeaseVariableData } from '../../../data/lease_utils'

export const numberValue = (e: any) => toNumber(e.target.value)

export const roundIfPercentage = (percentageValue: boolean, value: number) => percentageValue ? roundLeaseVariableData(value / 100.0) : value

export const onBlurNumber = (e: any,
                             valueAdjuster: ValueAdjuster<number>,
                             inputValueChange: React.Dispatch<SetStateAction<number | undefined>>,
                             innerValueChange: React.Dispatch<SetStateAction<number | undefined>>,
                             inputTouchedChange: React.Dispatch<SetStateAction<boolean>>,
                             min: number | undefined,
                             max: number | undefined,
                             valueIfEmpty: number,
                             onChange: (value: any) => void,
                             submitForm: (value: any) => void) => {
  const value = e.target.value
  let adjustedValue: number
  if (value) {
    if (max && value > max) {
      adjustedValue = max
    } else if (min && value < min) {
      adjustedValue = min
    } else {
      adjustedValue = value
    }
  } else {
    adjustedValue = valueIfEmpty
  }
  onBlur(e,
    adjustedValue,
    valueAdjuster(adjustedValue, true, false),
    inputValueChange,
    innerValueChange,
    inputTouchedChange,
    onChange,
    submitForm)
}

interface NumberInputOwnProps {
  min?: number
  max?: number
  valueIfEmpty: number
  percentageValue?: boolean

  /**
   * If specified, initial value is multiplied by it and reduced to normal when specified on change.
   */
  valueMultiplier?: number
}

type NumberInputProps = NumberInputOwnProps & Omit<SubmittingInputProps, 'onBlur' | 'valueAdjuster' | 'defaultOnChange'>

export const NumberInput = (
  {
    min,
    max,
    valueIfEmpty,
    onChange = () => {
    },
    percentageValue = false,
    initialValue,
    valueMultiplier,
    ...props
  }: NumberInputProps) => {
  const valueAdjuster: ValueAdjuster = useCallback((value: number, isSubmitting: boolean, forInput: boolean): number => {
    if (percentageValue && !forInput) {
      return roundIfPercentage(percentageValue, value)
    }
    if (valueMultiplier && !percentageValue) {
      return isSubmitting ? roundLeaseVariableData(value / valueMultiplier)
        : roundLeaseVariableData(value * valueMultiplier)
    }
    return value
  }, [percentageValue, valueMultiplier])

  const validator: Validator = useCallback((value: any): boolean => {
    return value !== undefined && value !== null && value !=='' && (!!max && value <= max)
  }, [max])

  return <SubmittingInput
    {...props}
    valueAdjuster={valueAdjuster}
    onChange={onChange}
    defaultOnChange={0}
    // adjust initial value to go to percent
    initialValue={percentageValue ? initialValue * 100 : initialValue}
    onBlur={(e, inputValueChange, innerValueChange, inputTouchedChange, onSubmit) => onBlurNumber(e, valueAdjuster, inputValueChange, innerValueChange, inputTouchedChange, min, max, valueIfEmpty, onChange, onSubmit)}
    inputFilter={(value) => value >= 0 ? value : undefined}
    validator={validator}
  />
}
