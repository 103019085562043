import React from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'

import { history } from './redux/store'
// Containers
import Home from './containers/Home'
import HomeWithIdWrapper from './containers/HomeWithIdWrapper'
import { PrintableVersion } from './containers/PrintableVersion'
import { LandingPage } from './components/landing/LandingPage'
import TermsOfService from './containers/TermsOfService'
import NotFound from './containers/NotFound'

const routes = (
  <ConnectedRouter history={history}>
    <Switch>
      <Route
        exact
        path="/"
        component={LandingPage}
      />
      <Route
        exact
        path="/calc"
        component={Home}
      />
      <Route
        path="/print"
        component={PrintableVersion}
      />
      <Route
        path="/termsofservice"
        component={TermsOfService}
      />
      <Route
        path="/calc/:id"
        component={HomeWithIdWrapper}
      />
      <Route
        path="*"
        component={NotFound}
      />
    </Switch>
  </ConnectedRouter>
)
export default routes
