/**
 * Represents all company inputs
 */
export interface CompanyInputsShape {
  readonly annualSeatChange: number
  readonly initialDesiredLeaseTerm: number
  readonly initialSeats: number
  readonly location: number
  readonly seatVolatility: number
}

export const defaultCompanyInputs: CompanyInputsShape = {
  location: 0,
  initialSeats: 1,
  annualSeatChange: 0.05,
  seatVolatility: 0.041,
  initialDesiredLeaseTerm: 5,
}
