import React from 'react'
import { CostComparisonLease } from '../../../data/models'
import {
  StyledTermCard,
  StyledTermCardContainer,
  StyledTermCardCost,
  StyledTermCardCostLabel,
  StyledTermCardDescription,
  StyledTermCardTitle,
  StyledTermCardType,
} from './styles'

import { leaseTypeToLeaseDescription, scenarioLeaseTypeToUIName } from '../../../data/conversions'
import { formatPrice } from '../../../utils/price_utils'

interface LeaseProps {
  lease: CostComparisonLease,
  currencySymbol: string,
  initialSeats: number,
  measureName: string
}

const LeaseCard = ({ lease: { name, npv, type, netArea }, currencySymbol, initialSeats, measureName }: LeaseProps) => (
  <StyledTermCard>
    <StyledTermCardType>{scenarioLeaseTypeToUIName(type)}</StyledTermCardType>
    <StyledTermCardTitle>{name}</StyledTermCardTitle>
    <StyledTermCardDescription>
      {leaseTypeToLeaseDescription(type, netArea, initialSeats, measureName, false)}
    </StyledTermCardDescription>
    <StyledTermCardCostLabel>Total Average Cost (NPV)</StyledTermCardCostLabel>
    <StyledTermCardCost>{formatPrice(currencySymbol, npv)}</StyledTermCardCost>
  </StyledTermCard>
)


interface OwnProps {
  firstLease: CostComparisonLease,
  secondLease: CostComparisonLease,
  currencySymbol: string,
  initialSeats: number,
  measureName: string,
}

type Props = OwnProps

const PrintTermCard = (
  {
    firstLease,
    secondLease,
    currencySymbol,
    initialSeats,
    measureName,
  }: Props) => {
  return <>
    <StyledTermCardContainer>
      {firstLease && <LeaseCard
        lease={secondLease}
        currencySymbol={currencySymbol}
        initialSeats={initialSeats}
        measureName={measureName}
      />}
      {secondLease && <LeaseCard
        lease={firstLease}
        currencySymbol={currencySymbol}
        initialSeats={initialSeats}
        measureName={measureName}
      />}
    </StyledTermCardContainer>
  </>
}


export default PrintTermCard
