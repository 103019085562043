import React, { useState } from 'react'
import { StyledButton, StyledButtonContainer, StyledContainer, StyledInput, StyledTitle } from '../styles'
import { ButtonType } from '../../common/button'
import { connect, Dispatch } from 'react-redux'
import {
  selectIsScenarioDuplicate,
  selectSavedScenarioName,
  selectScenarioLoading,
} from '../../../redux/scenario/scenario.selectors'
import { bindActionCreators } from 'redux'
import { saveScenario } from '../../../redux/scenario/scenario.actions'
import { UNTITLED_SCENARIO_NAME } from '../../../data/models'

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & DispatchProps

const NameScenario = ({ savingScenario, saveScenario, scenarioName, scenarioDuplicate }: Props) => {
  const [localValue, setValue] = useState(scenarioDuplicate ? `Copy of ${scenarioName}` : scenarioName)
  const hasName = !!scenarioName;
  const updateName = (value: string) => {
    setValue(value)
  }
  return <StyledContainer submitting={false}>
    <StyledTitle>Name your scenario.</StyledTitle>
    <StyledInput
      id="scenarioName"
      placeholder={UNTITLED_SCENARIO_NAME}
      maxLength={45}
      value={localValue}
      callback={updateName} />
    <StyledButtonContainer>
      {!hasName &&
          <StyledButton
          id="skip-button"
          wrapContent
          text="Skip"
          type={ButtonType.Secondary}
          onClick={() => saveScenario({ scenarioName: UNTITLED_SCENARIO_NAME })}
        />
       }
      <StyledButton
        id="next-button"
        isSecondary={!hasName}
        wrapContent
        text="Next"
        disabled={savingScenario}
        type={ButtonType.Primary}
        onClick={() => saveScenario({ scenarioName: !!localValue ? localValue : UNTITLED_SCENARIO_NAME })}
      />
    </StyledButtonContainer>
  </StyledContainer>
}

const mapStateToProps = (state: any) => ({
  savingScenario: selectScenarioLoading(state),
  scenarioName: selectSavedScenarioName(state),
  scenarioDuplicate: selectIsScenarioDuplicate(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => (bindActionCreators({
  saveScenario,
}, dispatch))

export default connect(mapStateToProps, mapDispatchToProps)(NameScenario)
