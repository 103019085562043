import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { scenarioReducer } from './scenario/scenario.reducer'
import { calculationReducer } from './calculations/calculation.reducer'
import { unitCurrencyReducer } from './unitCurrency/unitCurrency.reducer'
import { homeReducer } from './home/home.reducer'
import { modalReducer } from './modal/modal.reducer'
import { companyProfileReducer } from './companyprofile/companyprofile.reducer'
import { popoverReducer } from './popover/popover.reducer'

const createGlobalReducer = () => (
  combineReducers({
    route: routerReducer,
    companyProfile: companyProfileReducer,
    home: homeReducer,
    modal: modalReducer,
    popover: popoverReducer,
    scenario: scenarioReducer,
    calculations: calculationReducer,
    unitCurrency: unitCurrencyReducer,
  })
)

export default createGlobalReducer
