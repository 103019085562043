import { UnitCurrencyState } from './unitCurrency.reducer'
import { createSelector } from 'reselect'
import { CurrencyOption } from '../../optionsConfig/models'
import { FormUnitCurrency } from './unitCurrency.utils'

export const selectUnitCurrencyState = (state: { unitCurrency: UnitCurrencyState }) => state.unitCurrency

export const selectCurrencyOption = createSelector(selectUnitCurrencyState, (state: UnitCurrencyState) => state.currency)
export const selectCurrencyName = createSelector(selectCurrencyOption, (currency: CurrencyOption) => currency.iso)
export const selectCurrencyValue = createSelector(selectCurrencyOption, (currency) => currency.usd)
export const selectCurrencySymbol = createSelector(selectCurrencyOption, (currency) => currency.symbol || currency.iso)
export const selectUnitOfTime = createSelector(selectUnitCurrencyState, (state: UnitCurrencyState) => state.unitOfTime)
export const selectPeriodName = createSelector(selectUnitOfTime, (time) => time.optionName)
export const selectTimeValue = createSelector(selectUnitOfTime, (time) => time.convert)
export const selectUnitOfMeasure = createSelector(selectUnitCurrencyState, (state: UnitCurrencyState) => state.unitOfMeasure)
export const selectMeasureName = createSelector(selectUnitOfMeasure, (uom) => uom.uom)
export const selectMeasureValue = createSelector(selectUnitOfMeasure, (uom) => uom.convert)
export const selectIsInitial = createSelector(selectUnitCurrencyState, (state) => state.isInitial)

export const selectUnitCurrencyForForm = createSelector(selectUnitOfMeasure, selectUnitOfTime, selectCurrencyOption,
  (uom, time, currency) => {
    return {
      unitOfMeasure: uom.value,
      currency: currency.value,
      unitOfTime: time.value,
    } as FormUnitCurrency
  })
