import { ModalProps } from './models'
import { ModalActions, ModalActionTypes } from './modal.actions'


export interface ModalState {
  modal: ModalProps
}

export const defaultModalState: ModalState = {
  modal: {
    display: false,
    name: '',
  },
}

export const modalReducer = (state: ModalState = defaultModalState, action: ModalActionTypes): ModalState => {
  switch (action.type) {
    case ModalActions.HideModal:
      return {
        ...state,
        ...defaultModalState,
      }
    case ModalActions.ShowModal:
      return {
        ...state,
        modal: action.modal,
      }
    default:
      return state
  }
}
