import styled, { css } from 'styled-components'
import { Tabs } from 'antd'
import { COLORS } from '../../../constants/styles'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'

import { HeadlineH3Dark } from '../../../styles/text.styles'

const { TabPane } = Tabs


export const StyledContainer = styled.div`
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`

export const StyledTabs = styled(Tabs)`
  ${breakpoint(BREAKPOINTS.cbreMax)`
    margin-bottom: 40px;
  `}

  .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
    background-color: transparent !important;
  }

  .ant-tabs-bar {
    display: none !important;
  }
`

export const StyledTabPane = styled(TabPane)`

`

export const StyledTabHeader = styled('div')<{ margin?: boolean }>`
  text-align: left;
  flex: 1;
  ${({ margin }) => margin && css`
    margin-top: 30px;
    margin-bottom: 30px;
`}

  ${breakpoint(BREAKPOINTS.cbreMax)`
      margin-right: 0px;
  `}

  > p {
    font-size: 16px;
    color: ${COLORS.GRAYTEXT};
  }
`

export const CostComparisonHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;

  ${breakpoint(BREAKPOINTS.desktopMin)`
    flex-direction: row;
    margin-bottom: 22px;
    align-items: center;
  `}
`

export const TabHeader = styled.h3`
  ${HeadlineH3Dark}

  ${breakpoint(BREAKPOINTS.desktopMax)`
    margin-bottom: 15px;
  `}
`

export const StyledTabNav = styled.div`
  display: flex;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    padding-top: 13px;
  `}
`
