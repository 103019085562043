import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader'
// polyfill
import 'core-js'

import 'antd/dist/antd.css' // styling for antd / ant design components *** keep here unless you remove antd package ***
import '@cbrebuild/blocks-base/dist/blocks.css' // styling for CBRE Blocks
import store from './redux/store'
import routes from './routes'

// main styles
import { MainStyles } from './styles/main.styles'

// font styles
import { FontStyles } from './styles/fonts.styles'

// Windows fingerprinting
import { isWindows } from './utils/isWindows'

// polyfill
import './polyfills/cra-array-find'

if (module.hot && !window.Cypress) {
  module.hot.accept()
}
ReactDOM.render(
  <>
    <MainStyles isWindows={isWindows} />
    <FontStyles />
    <AppContainer key="app">
      <Provider store={store}>
        <BrowserRouter>
          {routes}
        </BrowserRouter>
      </Provider>
    </AppContainer>
  </>,
  document.getElementById('root'),
)

if (window.Cypress) {
  window.store = store
}
