export const unitDisplayToSystemName = (uom: string) => {
  switch (uom) {
    case 'sq ft':
      return 'US System'
    case 'sq m':
      return 'Metric System'
    case 'tsubo':
      return 'Tsubo'
    default:
      return uom
  }
}

/**
 * Represents the field "value" for each unit.
 */
export interface FormUnitCurrency {
  unitOfMeasure: number
  currency: number
  unitOfTime: number
}
