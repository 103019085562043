import styled from 'styled-components'
import { FONTS, LAYOUT } from '../../constants/styles'
import breakpoint from '../../utils/breakpoints'

import { BREAKPOINTS } from '../../constants/breakpoints'

export const StyledContent = styled.div`
  div, h3, p, span {
    font-family: ${FONTS.PRIMARY};
  }

  h1, h2 {
    font-family: ${FONTS.MEDIUM};
  }
`

export const StyledContainer = styled.div`
    /* margin-top:  ${LAYOUT.HEADERHEIGHT}; */
  ${breakpoint(BREAKPOINTS.cbreMax)`
    margin-top:  ${LAYOUT.MOBILE_HEADERHEIGHT};
  `}
  @media print {
    margin-top: 0;
  }
`
