import axios, { AxiosInstance } from 'axios'

/**
 * This is a logical wrapper around {@link axios} so we can inject for testing. Also provides a level of convenience
 * wrappers for return objects.
 */
export class HttpClient {

  constructor(public http: AxiosInstance) {
  }

  get = <T>(path: string): Promise<T> => this.http.get<T>(
    path,
  ).then(r => r.data)

  put = <T, B = any>(path: string, body: B): Promise<T> => this.http.put<T>(path, body)
    .then(r => r.data)

  post = <T, B = any>(path: string, body?: B): Promise<T> => this.http.post<T>(path, body)
    .then(r => r.data)

  delete = <T, B = any>(path: string, body?: B): Promise<T> => this.http.delete(path, { data: body })
    .then(r => r.data)
}

export const client = new HttpClient(axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
}))
