import React, { ReactNode } from 'react'

import { StyledButtonPrimary, StyledButtonSecondary, StyledContainer } from './styles'

import { CustomButton } from './customButton'

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Add = 'add'
}

interface Props {
  onClick?: (event: any) => void
  text: string
  classname?: string
  id: string
  type?: ButtonType
  disabled?: boolean
  icon?: ReactNode | null
  'data-cy'?: string
  className?: string
  tabIndex?: number

  /**
   * If specified, the button can collapse to content
   */
  wrapContent?: boolean
}

const pickButton = (type: ButtonType) => {
  switch (type) {
    case ButtonType.Primary:
      return StyledButtonPrimary
    case ButtonType.Secondary:
      return StyledButtonSecondary
    case ButtonType.Add:
      return CustomButton
    default:
      return StyledButtonPrimary
  }
}

const ButtonWrapper = (
  {
    onClick = () => {
    },
    text,
    type = ButtonType.Primary,
    icon = null,
    disabled = false,
    className,
    id,
    classname,
    tabIndex,
    'data-cy': cypressName,
    wrapContent,
  }: Props) => {
  const ButtonComponent = pickButton(type)
  return (
    <StyledContainer className={className}>
      <ButtonComponent
        wrapContent={wrapContent}
        name="button-basic"
        text={text}
        tabIndex={tabIndex}
        onClick={(e: any) => onClick(e)}
        disabled={disabled}
        icon={icon}
        id={id}
        className={classname}
        data-cy={cypressName}
      />
    </StyledContainer>
  )
}

export default ButtonWrapper
