import { onBlur, SubmittingInput, SubmittingInputProps } from './SubmittingInput'
import React, { SetStateAction } from 'react'

export const onBlurText = (e: any,
                           inputValueChange: React.Dispatch<SetStateAction<number | undefined>>,
                           innerValueChange: React.Dispatch<SetStateAction<number | undefined>>,
                           inputTouchedChange: React.Dispatch<SetStateAction<boolean>>,
                           onChange: (value: any) => void,
                           submitForm: (value: any) => void) =>
  onBlur(e, e.target.value, e.target.value, inputValueChange, innerValueChange, inputTouchedChange, onChange, submitForm)


interface TextInputOwnProps {
  // TODO: add any custom props here
}

type TextInputProps = TextInputOwnProps & Omit<SubmittingInputProps, 'onBlur' | 'valueAdjuster' | 'defaultOnChange'>

export const TextInput = (
  {
    onChange = () => {
    },
    ...props
  }: TextInputProps) => <SubmittingInput
  {...props}
  onChange={onChange}
  defaultOnChange=""
  onBlur={((e, inputValueChange, innerValueChange, inputTouchedChange, onSubmit) =>
    onBlurText(e, inputValueChange, innerValueChange, inputTouchedChange, onChange, onSubmit))}
/>
