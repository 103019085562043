import React, { ReactNode } from 'react'

import Nav from '../navigation/nav'
// styles
import { StyledContainer, StyledContent } from './styles'

interface Props {
  children: ReactNode
}

/**
 * Main layout used in pages.
 */
const Layout = ({ children }: Props) => (
  <StyledContainer>
    <Nav />
    <StyledContent>
      {children}
    </StyledContent>
  </StyledContainer>
)
export default Layout
