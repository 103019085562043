import React from 'react'
import { StyledScenarioNameContainer } from '../nav/styles'
import { StyledScenarioName } from './styles'

interface Props {
  name: string
  onEditScenario: () => void
  isReadOnly: boolean
  isEditing: boolean
  isPrintPage: boolean
}

export const ScenarioName = (
  {
    name,
    onEditScenario,
    isReadOnly,
    isEditing,
    isPrintPage,
  }: Props) => (
  <>
    <StyledScenarioNameContainer>
      <StyledScenarioName
        onClick={onEditScenario}
        isReadOnly={isReadOnly}
        isEditing={isEditing}
        isPrintPage={isPrintPage}
        id="scenario-edit--name"
      >
        {name}
      </StyledScenarioName>
    </StyledScenarioNameContainer>
  </>
)
