export enum PopoverActions {
  ShowPopover = 'popover/SHOW_POPOVER',
  ShowPopoverEdit = 'popover/SHOW_POPOVER_EDIT',
  ClosePopover = 'popover/CLOSE_POPOVER',
  ShowEmailView = 'popover/SHOW_EMAIL_VIEW',
}

export interface ShowPopover {
  type: typeof PopoverActions.ShowPopover
}

export const showPopover = (): PopoverActionTypes => ({
  type: PopoverActions.ShowPopover,
})

export interface ShowPopoverEdit {
  type: typeof PopoverActions.ShowPopoverEdit
}

export const showPopoverEdit = (): PopoverActionTypes => ({
  type: PopoverActions.ShowPopoverEdit,
})

export interface ShowEmailView {
  type: typeof PopoverActions.ShowEmailView
}

export const showEmailView = (): PopoverActionTypes => ({
  type: PopoverActions.ShowEmailView,
})

export interface ClosePopover {
  type: typeof PopoverActions.ClosePopover
}

export const closePopover = (): PopoverActionTypes => ({
  type: PopoverActions.ClosePopover,
})

export type PopoverActionTypes =
  | ShowPopover
  | ShowEmailView
  | ClosePopover
  | ShowPopoverEdit
