import { CalculateIterationsInput, CalculateIterationsResult } from '../../data/models'
import { CompanyInputsShape } from '../../data/form_models'
import { ChartOption } from '../../utils/selectOptions/chartOptions'

export enum CalculationActions {
  CalculateAllLeases = 'calculate/CALCULATE_ALL_LEASES',
  CalculateAllLeasesSucceeded = 'calculate/CALCULATE_ALL_LEASES_SUCCEEDED',
  CalculateAllLeasesFailed = 'calculate/CALCULATE_ALL_LEASES_FAILED',
  CalculateLeaseResultsCompanyProfile = 'calculate/CALCULATE_LEASE_RESULTS_COMPANY_PROFILE',
  CalculateLeaseResults = 'calculate/CALCULATE_LEASE_RESULTS',
  CalculateLeaseResultsSucceeded = 'calculate/CALCULATE_LEASE_RESULTS_SUCCEEDED',
  CalculateLeaseResultsFailed = 'calculate/CALCULATE_LEASE_RESULTS_FAILED',
  RemoveLeaseFromCalculation = 'calculate/REMOVE_LEASE_FROM_CALCULATION',
  StoreCompanyInputs = 'calculate/STORE_COMPANY_INPUTS',
  UpdateCompanyInputs = 'calculate/UPDATE_COMPANY_INPUTS',
  ClearCompanyInputs = 'calculate/CLEAR_COMPANY_INPUTS',
  SelectChartOption = 'calculate/SELECT_CHART_OPTION',
}

export interface CalculateAllLeases {
  type: typeof CalculationActions.CalculateAllLeases
  body: CalculateIterationsInput
}

export const calculateAllLeases = (body: CalculateIterationsInput): CalculationActionTypes => ({
  type: CalculationActions.CalculateAllLeases,
  body,
})

export interface CalculateAllLeasesSucceeded {
  type: typeof CalculationActions.CalculateAllLeasesSucceeded
  body: CalculateIterationsResult
}

export const calculateAllLeasesSucceeded = (body: CalculateIterationsResult): CalculationActionTypes => ({
  type: CalculationActions.CalculateAllLeasesSucceeded,
  body,
})

export interface CalculateAllLeasesFailed {
  type: typeof CalculationActions.CalculateAllLeasesFailed
  error: Error
}

export const calculateAllLeasesFailed = (error: Error): CalculationActionTypes => ({
  type: CalculationActions.CalculateAllLeasesFailed,
  error,
})


export interface CalculateLeaseResultsCompanyProfile {
  type: typeof CalculationActions.CalculateLeaseResultsCompanyProfile
  body: CompanyInputsShape
}

export const calculateLeaseResultsCompanyProfile = (body: CompanyInputsShape): CalculateLeaseResultsCompanyProfile => ({
  type: CalculationActions.CalculateLeaseResultsCompanyProfile,
  body,
})

export interface CalculateLeaseResults {
  type: typeof CalculationActions.CalculateLeaseResults
  body: CalculateIterationsInput
}

export const calculateLeaseResults = (body: CalculateIterationsInput): CalculationActionTypes => ({
  type: CalculationActions.CalculateLeaseResults,
  body,
})

export interface CalculateLeaseResultsSucceeded {
  type: typeof CalculationActions.CalculateLeaseResultsSucceeded
  body: CalculateIterationsResult
}

export const calculateLeaseResultsSucceeded = (body: CalculateIterationsResult): CalculationActionTypes => ({
  type: CalculationActions.CalculateLeaseResultsSucceeded,
  body,
})

export interface CalculateLeaseResultsFailed {
  type: typeof CalculationActions.CalculateLeaseResultsFailed
  error: Error
}

export const calculateLeaseResultsFailed = (error: Error): CalculationActionTypes => ({
  type: CalculationActions.CalculateLeaseResultsFailed,
  error,
})

export interface RemoveLeaseFromCalculation {
  type: typeof CalculationActions.RemoveLeaseFromCalculation
  leaseIds: readonly string[]
}

export const removeLeaseFromCalculation = (leaseIds: readonly string[]): CalculationActionTypes => ({
  type: CalculationActions.RemoveLeaseFromCalculation,
  leaseIds,
})

export interface StoreCompanyInputs {
  type: typeof CalculationActions.StoreCompanyInputs
  body: CompanyInputsShape
}

/**
 * Store Previous inputs to prevent calculating lease results more than once for same inputs.
 * @param body
 */
export const storeCompanyInputs = (body: CompanyInputsShape): StoreCompanyInputs => ({
  type: CalculationActions.StoreCompanyInputs,
  body,
})

export interface UpdateCompanyInputs {
  type: typeof CalculationActions.UpdateCompanyInputs
  body: Partial<CompanyInputsShape>
}

/**
 * Updates company inputs based on values sent over. It may or may not run a full calculation if the inputs are completed
 * or at default.
 */
export const updateCompanyInputs = (body: Partial<CompanyInputsShape>): CalculationActionTypes => ({
  type: CalculationActions.UpdateCompanyInputs,
  body,
})

export interface ClearCompanyInputs {
  type: typeof CalculationActions.ClearCompanyInputs
}

export const clearCompanyInputs = (): ClearCompanyInputs => ({
  type: CalculationActions.ClearCompanyInputs,
})

export interface SelectChartOption {
  type: typeof CalculationActions.SelectChartOption
  option: ChartOption
}

export const selectChartOption = (option: ChartOption): CalculationActionTypes => ({
  type: CalculationActions.SelectChartOption,
  option,
})

export type CalculationActionTypes =
  | CalculateAllLeases
  | CalculateAllLeasesSucceeded
  | CalculateAllLeasesFailed
  | CalculateLeaseResults
  | CalculateLeaseResultsSucceeded
  | CalculateLeaseResultsFailed
  | RemoveLeaseFromCalculation
  | CalculateLeaseResultsCompanyProfile
  | StoreCompanyInputs
  | ClearCompanyInputs
  | SelectChartOption
  | UpdateCompanyInputs
