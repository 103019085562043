import styled, { createGlobalStyle } from 'styled-components'
import { COLORS, FONTS } from '../../constants/styles'
import breakpoint from '../../utils/breakpoints'
import { BREAKPOINTS } from '../../constants/breakpoints'


export const PrintStyles  = createGlobalStyle`
  @media print {
    html, body {
      width: 100%;
      height: auto;
      min-width:216mm;
    }
    html {
      margin: 0px;
    }
    @page {
      margin: 0mm;
      size: portait;
    }
  }
`

export const Container = styled.div`
  padding: 25px 50px;
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  width: 100%;

  @media print {
    justify-content: space-between;
    padding: 25px 25px;
    page-break-after: always;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`
export const ErrorText = styled.div`
  max-width: 644px;
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  padding: 0 12px;
  ${breakpoint(BREAKPOINTS.tabletMin)`
    font-size: 39px;
    line-height: 1.28;
  `}
`
export const ErrorImage = styled.img`
  position: fixed;
  bottom: 0;
  width: 1080px;
  margin: 0 auto;
  padding: 0 12px;
  ${breakpoint(BREAKPOINTS.tabletMin)`
    padding: 0 24px;
  `}
`

export const Image = styled.img`
  width: 74px;
`

export const Subtext = styled.span`
  font-size: 18px;
  letter-spacing: 0.6px;
  font-family: ${FONTS.PRIMARY};
  font-weight: 400;
  padding-left: 8px;
  padding-left: 13px;
  vertical-align: middle;
  color: ${COLORS.SHADOW_01};
`

export const LogoContainer = styled.div<{ isSafari: boolean }>`
  @media print {
    ${({ isSafari }) => !isSafari && `
      position: fixed;
      top: 18px;
    `}
  }
`

export const TextContainer = styled.div`
  margin-top: 25px;
  h2 {
    font-size: 18px;
    font-weight: 400;
    font-family: ${FONTS.PRIMARY};
    line-height: 1.69;
    color: ${COLORS.DARK_00};
  }
`

export const Line = styled.div`
  width: 36px;
  height: 3px;
  background-color: #21091c;
  margin: 12px 0 16px;
  color-adjust: exact;
  -webkit-print-color-adjust: exact; /* print preview doesn't render background-color properly, so this is needed. */
`

export const FactContainer = styled.div`
  margin-bottom: 36px;
  p {
    font-size: 26px;
    line-height: 1.32;
    color: ${COLORS.DARK_00};
    font-family: ${FONTS.PRIMARY};

    @media print {
      font-size: 18px;
    }
  }
`
export const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #21091c;
  margin-bottom: 8px;

  @media print {
    font-size: 16px;
  }
`

export const SeatContainer = styled.div`
  @media print {
    margin-top: 18px;
  }
`

export const Emphasized = styled.span`
  font-family: ${FONTS.PRIMARY_SEMIBOLD}!important;
`

export const ContentContainer = styled.div`
  display: flex;
`

export const HeadcountContainer = styled.div`

`
export const ChartContainer = styled.div`
  width: fit-content;
`

export const StyledFooterText = styled.p<{ isSafari: boolean }>`
  font-family: ${FONTS.PRIMARY};
  font-size: 7px;
  line-height: 1.1;
  font-weight: 400;
  color: #a7a7a7;
  padding: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  a {
    font-size: 7px;
    line-height: 1.1;
    color: #a7a7a7;
  }
  @media print {
    ${({ isSafari }) => !isSafari && `
      position: fixed;
      bottom: 18px;
      background-color: ${COLORS.SECONDARY_00}
    `}
  }
`
