import { ChartTypeId } from '../../../utils/selectOptions/chartOptions'
import { PCCalcResults, TermOptionsLease } from '../../../data/models'


export const valueForCurrentOption = (option: ChartTypeId,
                                      lease: TermOptionsLease): PCCalcResults => {
  switch (option) {
    case ChartTypeId.costSeatYear:
      return lease.costSeatYear
    case ChartTypeId.cash:
      return lease.totalCosts
    case ChartTypeId.npv:
      return lease.npv
  }
}
