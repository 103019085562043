import { InputContainer, InvisibleSpan, StyledInput, StyledInputContainer, StyledSuffix } from './styles'
import React, { ChangeEvent, useState } from 'react'
import { useTimeout } from '../../../../utils/hooks/hooks'
import { numberValue, onBlurNumber } from '../NumberInput'
import { inputSelectAll } from '../../../../utils/inputSelectAll'
import { FORM_IDLE_TIMEOUT } from '../SubmittingInput'
import { KEY_ENTER, KEY_TAB } from '../../../../utils/hooks/constants'

interface Props {
  setWidth: string
  max?: number
  min?: number
  valueIfEmpty?: any
  suffix?: string
  disableSubmit?: boolean
  id: string
  label: string
  submitForm?: (value: any) => void
  // called when form submitting disabled, but a submit would have been called
  formNoSubmit?: (value: any) => void
  initialValue: any
  onChange?: (value: any) => void
}

export const IntroInput = (
  {
    setWidth,
    submitForm = () => ({}),
    formNoSubmit = () => ({}),
    initialValue,
    min,
    max,
    valueIfEmpty,
    onChange = () => ({}),
    suffix,
    disableSubmit,
    id,
    label,
  }: Props) => {
  const [inputTouched, inputTouchedChanged] = useState(false)
  const [inputValue, inputValueChanged] = useState<any | undefined>(initialValue)
  const [, innerValueChanged] = useState<any | undefined>(initialValue)
  const onSubmit = disableSubmit ? formNoSubmit : submitForm
  // trigger change on
  useTimeout(FORM_IDLE_TIMEOUT, () => {
    if (inputTouched) {
      onSubmit(inputValue)
    }
  }, [inputValue,
    inputTouched])
  const handleInputTouch = () => inputTouchedChanged(true)
  const hasError = (max ? inputValue > max : false) || (min ? inputValue < min : false)
  return <div>
    <InputContainer>
      <StyledInputContainer setWidth={setWidth}>
        <StyledInput
          id={id}
          aria-label={label}
          error={hasError}
          value={inputTouched ? inputValue : initialValue}
          type="tel"
          onMouseDown={() => {
            if (!inputTouched) handleInputTouch()
          }}
          onBlur={(e) =>
            onBlurNumber(e, value => value, inputValueChanged,
              innerValueChanged, inputTouchedChanged,
              min, max, valueIfEmpty, onChange, onSubmit)}
          onChange={(e: ChangeEvent<any>) => {
            const value = numberValue(e)
            innerValueChanged(value)
            inputValueChanged(value)
            inputTouchedChanged(true)
            onChange(value)
          }}
          min={min}
          max={max}
          onFocus={inputSelectAll}
          onKeyDown={(e) => {
            if (e.key === KEY_ENTER || e.key === KEY_TAB) {
              onBlurNumber(e, value => value, inputValueChanged,
                innerValueChanged, inputTouchedChanged,
                min, max, valueIfEmpty, onChange, onSubmit)
            }
          }}
        />
        {suffix && <StyledSuffix>
          <InvisibleSpan>{inputValue || 0}</InvisibleSpan>{suffix}
        </StyledSuffix>}
      </StyledInputContainer>
    </InputContainer>
  </div>
}
