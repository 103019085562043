import { ToggleOption } from '../../common/toggle'
import { leaseTypeToTabName } from '../conversions'
import { ScenarioLeaseType } from '../../../data/models'

export const leaseToggleOptions: ToggleOption[] = [
  {
    text: leaseTypeToTabName(ScenarioLeaseType.FlexCoworking),
    value: ScenarioLeaseType.FlexCoworking,
  },
  {
    text: leaseTypeToTabName(ScenarioLeaseType.Traditional),
    value: ScenarioLeaseType.Traditional,
  },
]
