import React from 'react'
import PropTypes from 'prop-types'

export const Close = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <path
      fill="#00704F"
      fillRule="nonzero"
      d="M11.414 10l4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414L8.586 10l-4.95-4.95L5.05 3.636 10 8.586l4.95-4.95 1.414 1.414z"
    />
  </svg>
)

Close.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
}

Close.defaultProps = {
  height: 20,
  width: 20
}
