import React, { useState } from 'react'
import TermOptionsGraph from '../../detailedresults/termOptions/termOptionsGraph'
import { TermOptionsLease } from '../../../data/models'
import { connect } from 'react-redux'
import {
  selectInitialDesiredLeaseTerm,
  selectSelectedChartOption,
  selectInitialSeats,
} from '../../../redux/calculations/calculation.selectors'
import { selectTermOptionsLeases, selectCostComparisonLeases } from '../../../redux/scenario/scenario.selectors'
import { selectCurrencySymbol, selectMeasureName } from '../../../redux/unitCurrency/unitCurrency.selectors'


import {
  StyledTermOptionsContainer,
} from './styles'
import PrintTermCard from '../printTermCard'
import { SetState } from '../../../utils/state_types'

interface OwnProps {
  chartWidth?: number,
  chartHeight?: number
}

type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & OwnProps

const PrintTermOptions = (
  {
    termLeases,
    comparisonLeases,
    initialDesiredLeaseTerm,
    chartOption,
    chartWidth,
    chartHeight,
    currencySymbol,
    initialSeats,
    measureName
  }: Props) => {
  const first: SetState<TermOptionsLease> = useState(() => termLeases[0])
  const [firstLease] = first
  const second: SetState<TermOptionsLease> = useState(() => termLeases[1])
  const [secondLease] = second
  return <>
    <PrintTermCard
      firstLease={comparisonLeases[0]}
      secondLease={comparisonLeases[1]}
      currencySymbol={currencySymbol}
      initialSeats={initialSeats}
      measureName={measureName}
    />
    <StyledTermOptionsContainer>
      {firstLease && <TermOptionsGraph
        isPrint={true}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        chartOption={chartOption}
        firstLease={firstLease}
        secondLease={secondLease}
        initialDesiredLeaseTerm={initialDesiredLeaseTerm}
      />}
    </StyledTermOptionsContainer>
  </>
}

const mapStateToProps = (state: any) => ({
  initialSeats: selectInitialSeats(state),
  comparisonLeases: selectCostComparisonLeases(state),
  termLeases: selectTermOptionsLeases(state),
  currencySymbol: selectCurrencySymbol(state),
  initialDesiredLeaseTerm: selectInitialDesiredLeaseTerm(state),
  chartOption: selectSelectedChartOption(state),
  measureName: selectMeasureName(state),
})

export default connect<StateProps, OwnProps>(mapStateToProps)(PrintTermOptions)
