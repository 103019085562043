import { HomeActions } from './home.actions'
import { put, select, takeEvery } from 'redux-saga/effects'
import { selectUpdatedOverrideLease } from '../scenario/scenario.selectors'
import { saveLeaseChanges } from '../scenario/scenario.actions'

/**
 * Updates name values of leases on tab change.
 */
function* updateLeaseDataWithTab() {
  const selectedLease = yield select(selectUpdatedOverrideLease)
  yield put(saveLeaseChanges(selectedLease))
  return
}


export function* homeSagas() {
  yield takeEvery(HomeActions.ChangeTab, updateLeaseDataWithTab)
}
