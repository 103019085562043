import React, { useEffect } from 'react'
import companyProfile from '../../../static/images/1-style-1-wizard-one@3x.jpg'
import headcount from '../../../static/images/8-2-term-options-headcount-projection@3x.jpg'
import laptop from '../../../static/images/screenshot@3x.png'
import comparisionChart from '../../../static/images/8-term-options-average-annual-count@3x.jpg'
import {
  StyledLandingContainer,
  StyledLandingContent,
  StyledLandingContentContainer,
  StyledLayout,
  StyledLogo,
  StyledNav,
} from './styles'
import { LandingContent } from '../LandingContent'
import { LandingFooter } from '../LandingFooter'

export const LandingPage = () => {
  useEffect(() => {
    const intercom = (window as any).Intercom
    // intercom messsenger plugin. used to boot up the messenger
    intercom('boot', {
      app_id: 'lvi89aek',
    })

    intercom('update', {
      hide_default_launcher: false,
    })
  }, [])
  return (
    <StyledLayout>
      <StyledLandingContainer>
        <StyledNav>
          <StyledLogo id="logo">CBRE Agile Lease Calculator</StyledLogo>
        </StyledNav>
        <StyledLandingContentContainer>
          <LandingContent
            isPrimary
            showImageInMobile={false}
            imageSrc={laptop}
            imageAlt="Laptop"
            header="Need a new office? Make a calculated decision."
            body={'Quickly and accurately compare the cost of traditional versus flexible office leasing options so you\n' +
            'have the necessary data to make an informed decision.'}
            direction='left'
            positionIndex={0} />
        </StyledLandingContentContainer>
      </StyledLandingContainer>
      <StyledLandingContent
        direction='right'
        className="content-input"
        imageSrc={companyProfile}
        imageAlt="Input"
        header="Get a comprehensive report in minutes."
        body={'Simply enter some basic details about your current business and our calculator quickly produces a ' +
        'robust report to help you choose the right office solution for your current size and expected growth.'}
        positionIndex={1} />
      <StyledLandingContent
        className="content-comparison"
        imageSrc={comparisionChart}
        imageAlt="Comparison Chart"
        header="Trying to balance growth and productivity? Find the sweet spot."
        body={'Evaluate different possible lease terms and discover where your breakeven point would be for each option.' +
        ' The more informed you are about short- and long-term costs, the easier your decision becomes. '}
        direction='left'
        positionIndex={2} />
      <StyledLandingContent
        className="content-line"
        imageSrc={headcount}
        imageAlt="Line Chart"
        header="What kind of office do your clients need?"
        body={'Enter the amount of employees you currently have and how much growth you anticipate in the future. Then\n' +
        'CALC determines how much square footage you’ll need to thrive. The more you know, the more you can\n' +
        'grow. '}
        direction='right'
        positionIndex={3} />
      <LandingFooter />
    </StyledLayout>
  )
}
