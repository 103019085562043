import styled from 'styled-components'

import breakpoint from '../../utils/breakpoints'
import { BREAKPOINTS } from '../../constants/breakpoints'


export const StyledContainer = styled.div`
  width: 105%;
  position: fixed;
  bottom: -6px;
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  z-index:-1;
  max-width: 1200px;
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  opacity: ${(props) => (props.isPaused ? '0' : '1')};

  transition: opacity 0.3s ease-out;
  ${breakpoint(BREAKPOINTS.tabletMin)`
    opacity: 0.4;
    width: 98%;
    display: ${(props) => (props.isHidden ? 'none' : 'block')};
  `}
  ${breakpoint(BREAKPOINTS.desktopMax)`
    ${breakpoint(BREAKPOINTS.introExtraSmallMin)`
      opacity: 1;
    `}
  `}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    max-width: 1400px;
    width: 85%;
    ${breakpoint(BREAKPOINTS.introExtraSmallMin)`
      opacity: 1;
    `}
  `}
   ${breakpoint(BREAKPOINTS.ultraWideMin)`
    opacity: 0.4;
    ${breakpoint(BREAKPOINTS.introLargeMin)`
      width: 105%;
      opacity: 1 !important;
    `}
  `}
`
