import React from 'react'
import { StyledLogo, StyledLogoLink, StyledLogoText } from '../styles'

interface Props {
  logoImage: string
  id: string
}

export const Logo = ({ logoImage, id }: Props) => (
  <StyledLogo>
    <StyledLogoLink
      id={id}
      href="/">
      <img src={logoImage}
           alt="CBRE" />
      <StyledLogoText>
        CALC
      </StyledLogoText>
    </StyledLogoLink>
  </StyledLogo>
)
