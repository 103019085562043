import facebook from '../../static/images/icon/facebook.svg'
import linkedin from '../../static/images/icon/linkedin.svg'
import twitter from '../../static/images/icon/twitter.svg'

export const CONFIG = {
  SOCIAL: [
    {
      url: 'https://www.facebook.com/cbre/',
      label: 'Facebook',
      iconName: facebook
    },
    {
      url: 'https://www.twitter.com/CBRE',
      label: 'Twitter',
      iconName: twitter,
    },
    {
      url: 'https://www.linkedin.com/company/cbre/',
      label: 'LinkedIn',
      iconName: linkedin,
    },
  ]
}
