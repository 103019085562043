import styled from 'styled-components'
import { COLORS, FONTS } from '../../../constants/styles'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'

import { isSafari } from '../../../utils/isSafari'


import { TableAccent } from '../../../styles/text.styles'
import { StyledLinkButton } from '../../common/text/styles'

const delay = '0.8s'

interface SegmentProps {
  readonly segment: number
}

export const StyledUnitButton = styled(StyledLinkButton)`

  ${breakpoint(BREAKPOINTS.tabletMax)`
    font-size: 16px;
  `}
`

export const StyledHeaderContainer = styled.div<SegmentProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 3px;
  height: 40px;

  ${breakpoint(BREAKPOINTS.tabletMin)`
    margin-top: 10px;
    ${breakpoint(BREAKPOINTS.introMediumMin)`
      margin-top: 30px;
    `}
  `}

  h2 {
    font-family: ${FONTS.PRIMARY};
    font-size: 12px;
    letter-spacing: 0.8px;
    color: ${COLORS.DARK_01};
    font-weight: normal;
    text-transform: uppercase;
    ${breakpoint(BREAKPOINTS.tabletMin)`
      line-height: 1.71;
      font-size: 14px;
      transition: ${(props: SegmentProps) => (props.segment === 0  && !isSafari ? 'transform 0.4s ease-in-out' : `transform 0.4s ease-in-out ${delay}`)};
      transform: ${(props: SegmentProps) => (props.segment === 0  && !isSafari ? `scale(${1 / 1.2})` : 'scale(1.0)')} translate3d( 0, 0, 0);
    `}
    ${breakpoint(BREAKPOINTS.desktopMin)`
      transition: ${(props: SegmentProps) => (props.segment === 0  && !isSafari ? 'transform 0.4s ease-in-out' : `transform 0.4s ease-in-out ${delay}`)};
      transform: ${(props: SegmentProps) => (props.segment === 0  && !isSafari ? `scale(${1 / 1.7})` : 'scale(1.0)')} translate3d( 0, 0, 0);
    `}

    transform-origin: 0% 50%;
  }
  button {
    transition: ${(props: SegmentProps) => (props.segment === 0 ? 'opacity 0.4s ease-in-out' : `opacity 0.4s ease-in-out ${delay}`)};
    opacity: ${(props: SegmentProps) => (props.segment === 0 ? '0': '1')};
    visibility: ${(props: SegmentProps) => (props.segment >= 1 ? 'visible' : 'hidden')};
  }
`

export const StyledGrowthRiskSection = styled.div<SegmentProps>`
  transition: opacity 300ms ease-in-out 2s, pointer-events 0ms ease-in-out 2s;
  pointer-events: ${(props: SegmentProps) => (props.segment >= 2 ? 'auto' : 'none')};
  visibility: ${(props: SegmentProps) => (props.segment >= 2 ? 'visible' : 'hidden')};

  user-select: ${(props: SegmentProps) => (props.segment >= 2 ? 'auto' : 'none')};
  opacity: ${(props: SegmentProps) => (props.segment >= 2 ? 1 : 0)};
  margin-bottom: 24px;

  > div {
    text-align: left;
  }

  h2 {
    font-family: ${FONTS.PRIMARY};
    font-size: 24px;
    font-weight: light;
    padding-left: 3px;
  }
  ${breakpoint(BREAKPOINTS.tabletMax)`
    padding-bottom: 34px;
    background:white;
  `}
  ${breakpoint(BREAKPOINTS.tabletMin)`
    margin-top: 30px;
  `}
`

export const StyledMarketSection = styled.div<SegmentProps>`
  margin-top: 16px;
  max-width: 514px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: ${(props) => (props.segment === 0 ? 'transform 0.4s ease-out, left 0.4s ease-out, margin-top 0.4s ease-out' : `transform 0.4s ease-out ${delay}, left 0.4s ease-out ${delay}, margin-top 0.4s ease-out`)};
  transform: perspective(1px) ${(props) => (props.segment === 0 ? (!isSafari ? 'translate(-50%, 20vh)': 'translate(0%, 20vh)') : 'translate(0, 0)')} translate3d(0, 0, 0);
  left: ${(props) => (props.segment === 0 && !isSafari ? '50%' : '0%')};
  ${breakpoint(BREAKPOINTS.tabletMin)`
    transform: perspective(1px) ${(props: SegmentProps) => (props.segment === 0 ? `${!isSafari ? 'scale(1.2) translate(-50%, 10vh)': 'translate(0, 10vh)'}` : 'scale(1.0)')} translate3d(0, 0, 0);
    ${breakpoint(BREAKPOINTS.introMediumMin)`
      margin-top: 30px;
    `}
  `}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    transform: perspective(1px) ${(props: SegmentProps) => (props.segment === 0 ? `${!isSafari && 'scale(1.7)'} translate(-50%, 5.5vh)` : 'scale(1.0)')} translate3d(0, 0, 0);
  `}
  transform-origin: 0% 50%;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  button{
    padding-top: 12px;
    ${breakpoint(BREAKPOINTS.tabletMin)`
      padding-top: 0;
      height: 1.4em;
    `}
  }

`

export const StyledHeadcountSection = styled.div<SegmentProps>`
  margin-top: 36px;
  max-width: 730px;
  position: relative;
  transform: perspective(1px) ${(props) => (props.segment <= 1 ? 'translate(0, 4vh)' : 'translate(0, 0)')} translate3d(0, 0, 0);
  transition: ${(props) => (props.segment <= 1 ? 'opacity 300ms ease-in-out 2s, transform 0.4s ease-out, left 0.4s ease-out, margin-top 0.4s ease-out' : `opacity 300ms ease-in-out 2s, transform 0.4s ease-out ${delay}, left 0.4s ease-out ${delay}, margin-top 0.4s ease-out`)};
  ${breakpoint(BREAKPOINTS.tabletMin)`
    transform: ${(props: SegmentProps) => (props.segment <= 1 ? `${!isSafari ? 'scale(1.1) translate(0, 6vh)': 'translate(0, 6vh)'}` : 'scale(1.0)')};
    margin-top: 10px;
    ${breakpoint(BREAKPOINTS.introMediumMin)`
      margin-top: 24px;
    `}
  `}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    transform: ${(props: SegmentProps) => (props.segment <= 1 ? `${!isSafari ? 'scale(1.4) translate(0, 6vh)': 'translate(0, 6vh)'}` : 'scale(1.0)')};
  `}
  transform-origin: 0% 50%;
  pointer-events: ${(props) => (props.segment >= 1 ? 'auto' : 'none')};
  user-select: ${(props) => (props.segment >= 1 ? 'auto' : 'none')};
  opacity: ${(props) => (props.segment >= 1 ? 1 : 0)};
  visibility: ${(props) => (props.segment >= 1 ? 'visible' : 'hidden')};
  svg{
    transition: ${(props: SegmentProps) => (props.segment === 1 ? 'opacity 0.4s ease-in-out' : `opacity 0.4s ease-in-out ${delay}`)};
    opacity: ${(props: SegmentProps) => (props.segment <= 1 ? '0': '1')};
    visibility: ${(props: SegmentProps) => (props.segment >= 2 ? 'visible' : 'hidden')};
  }
`

export const StyledContainer = styled.div`
  position: relative;
  transition: margin-top 0.4s ease-out;
  margin-top: 36px;
  ${breakpoint(BREAKPOINTS.tabletMin)`
    ${breakpoint(BREAKPOINTS.introMediumMin)`
      margin-top: 45px;
    `}
  `}
`

export const StyledButtonContainer = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  ${breakpoint(BREAKPOINTS.tabletMax)`
    button {
      border-radius: 0;
      max-width: unset;
    }
    padding-bottom: env(safe-area-inset-bottom);
    background-color: ${COLORS.SECONDARY_00};
  `}
  ${breakpoint(BREAKPOINTS.tabletMin)`
    position: relative;
    margin-top: 45px;
    width: unset;
    left: unset;
    bottom: unset;
  `}

`

export const StyledTipCopy = styled.h3`
  ${TableAccent}
  overflow: show;
  ${breakpoint(BREAKPOINTS.tabletMin)`
    height: 0;
  `}
`
