import {
  all,
  fork,
} from 'redux-saga/effects'
import { scenarioSagas } from './scenario/scenario.sagas'
import { calculationSagas } from './calculations/calculation.sagas'
import { unitCurrencySagas } from './unitCurrency/unitCurrency.sagas'
import { homeSagas } from './home/home.sagas'


const sagas = [
  scenarioSagas,
  calculationSagas,
  unitCurrencySagas,
  homeSagas,
]

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga))

  yield all([...globalSagasForks])
}

export default globalSagas
