import { createGlobalStyle, css } from 'styled-components'
import breakpoint from '../utils/breakpoints'
import { BREAKPOINTS } from '../constants/breakpoints'

export const MainStyles = createGlobalStyle<{ isWindows: boolean }>`
  body, html {
    margin: 0;
    padding: 0;
    ${breakpoint(BREAKPOINTS.tabletMin)`
      height: 100vh !important;
    `}
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  ${(props) => props.isWindows && css`
    /* windows only taskbar styling */
    *::-webkit-scrollbar {
      width: 6px;
      height: 6px;
        background: transparent;
    }
    *:hover::-webkit-scrollbar-thumb {
      background: rgba(0, 72, 51, 0.25);
      -webkit-border-radius: 1ex;
    }
    *::-webkit-scrollbar-thumb {
      background: transparent;
      transition: background 0.2s ease-out;
      -webkit-border-radius: 1ex;
    }
    *::-webkit-scrollbar-corner {
      background: transparent;
    }
  `}
`
