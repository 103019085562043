import React from 'react'
import { StyledComparisonContainer, StyledComparisonGrid, StyledComparisonLeftColumn } from './styles'
import CostComparisonHeader from '../costComparisonHeader'
import { valueForCurrentOption } from '../conversions'
import {
  leaseTypeToAnnualReOccurringCostsHeader,
  leaseTypeToLeaseDescription,
  leaseTypeToOneTimeCostsHeader,
} from '../../../../data/conversions'

import MediaQuery from 'react-responsive'
import { BREAKPOINTS } from '../../../../constants/breakpoints'
import { CostComparisonLease } from '../../../../data/models'
import { CostComparisonDataLabel, CostComparisonDataValue } from '../costComparisonRow'
import { chartColumns, ChartOption } from '../../../../utils/selectOptions/chartOptions'
import { formatPrice } from '../../../../utils/price_utils'

interface Props {
  leases: readonly CostComparisonLease[]
  currencySymbol: string
  currentOption: ChartOption
  id: string
  /**
   * This value is picked by user on the Company Profile
   */
  initialSeats: number
  measureName: string
  onClose: (lease: CostComparisonLease) => void
}

const ComparisonGrid = ({
                          leases, initialSeats, measureName,
                          onClose, currencySymbol,
                          isMobile,
                          currentOption,
                          isCurrentOptionReflected,
                          id
                        }: Props & { isMobile: boolean, isCurrentOptionReflected: boolean }) =>
  (<StyledComparisonGrid isCurrentObjectReflected={isCurrentOptionReflected}>
    {leases.map((l, index) => [<CostComparisonHeader
      key={l.id}
      leaseType={l.type}
      leaseName={l.name}
      index={index}
      description={leaseTypeToLeaseDescription(l.type, l.netArea, initialSeats, measureName, isMobile)}
      onClose={() => onClose(l)} />,
      !isCurrentOptionReflected && <CostComparisonDataValue
        isHighlighted
        key={l.id + 1}
        value={formatPrice(currencySymbol, valueForCurrentOption(currentOption.value, l))} />,
      <CostComparisonDataValue
        isHighlighted={isCurrentOptionReflected}
        key={l.id + 2}
        value={formatPrice(currencySymbol, l.costSeatYear)} />,
      <CostComparisonDataValue
        title={leaseTypeToAnnualReOccurringCostsHeader(l.type)}
        key={l.id + 3}
        value={formatPrice(currencySymbol, l.annualReOccurringCosts)} />,
      <CostComparisonDataValue
        title={leaseTypeToOneTimeCostsHeader(l.type)}
        key={l.id + 4}
        value={formatPrice(currencySymbol, l.oneTimeCosts)} />,
    ])}
  </StyledComparisonGrid>)

const CostComparisonTable = ({ leases, onClose, currencySymbol, initialSeats, measureName, currentOption, id }: Props) => {
  const isCurrentOptionReflected = !!chartColumns.find(c => c === currentOption.optionName)
  return (
    <StyledComparisonContainer id={id}>
      <StyledComparisonLeftColumn>
        {(['Lease Name',
          // satisfy restraint that Cost / Seat / Year not double reflected
          (!isCurrentOptionReflected && currentOption.optionName) || undefined,
          ...chartColumns,
        ].filter(f => !!f) as string[]).map((l: string, index: number) => [
          <CostComparisonDataLabel
            key={l}
            label={l}
            isHeader={index === 0}
            isHighlighted={index === 1} />,
        ])}
      </StyledComparisonLeftColumn>
      <MediaQuery minWidth={BREAKPOINTS.mobileMin.value}>
        {ComparisonGrid({
          id,
          leases,
          currencySymbol,
          initialSeats,
          measureName,
          onClose,
          isMobile: false,
          currentOption,
          isCurrentOptionReflected,
        })}
      </MediaQuery>
      <MediaQuery maxWidth={BREAKPOINTS.mobileMax.value}>
        {ComparisonGrid({
          id,
          leases,
          currencySymbol,
          initialSeats,
          measureName,
          onClose,
          isMobile: true,
          currentOption,
          isCurrentOptionReflected,
        })}
      </MediaQuery>
    </StyledComparisonContainer>
  )
}
export default CostComparisonTable
