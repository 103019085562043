import React, { Component } from 'react'

import { AnimationContainer, StyledContainer, StyledInputContainer } from './styles'
import { CompanyInputsShape } from '../../../data/form_models'
import { connect, Dispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { calculateLeaseResultsCompanyProfile } from '../../../redux/calculations/calculation.actions'
import { changeHomeTab } from '../../../redux/home/home.actions'
import { selectCurrentCompanyProfileSegment } from '../../../redux/companyprofile/companyprofile.selectors'
import { changeSegment } from '../../../redux/companyprofile/companyprofile.actions'
import { CompanyInputs } from '../companyInputs'
import { UnitsCurrencySelect } from '../../unitsCurrencySelect'

interface OwnProps {
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = OwnProps & DispatchProps & StateProps

interface State {
  readonly display: string
  readonly version: number
  readonly name: string
}

class CompanyProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      display: 'basicInputs',
      version: Date.now(),
      name: '',
    }
  }

  setView = (view: string) => {
    this.setState((prevState) => ({
      ...prevState,
      display: view,
    }))
  }

  editInput = (type: string, title: string) => {
    this.setState((prevState) => ({
      ...prevState,
      display: type,
      name: title,
    }))
  }

  changeSegment = (nextSegment: number) => {
    const { segment, changeSegment } = this.props
    if (nextSegment > segment) {
      changeSegment(segment + 1)
    }
  }

  submitInputs = (data: CompanyInputsShape) => {
    this.props.calculateLeaseResultsCompanyProfile(data)
  }

  render() {
    const {
      changeHomeTab,
      segment,
    } = this.props
    const { display, version } = this.state
    // if location not chosen, then remove location from object.
    return (
      <StyledContainer key={version}>
        <StyledInputContainer
          id="inputContainer"
          display={display}>
          <CompanyInputs displayUnitCurrency={() => this.setView('unitsCurrency')} />
          <UnitsCurrencySelect
            display={display}
            setView={this.setView} />
        </StyledInputContainer>
        <AnimationContainer
          segment={segment}
          callback={() => changeHomeTab('2')}
        />
      </StyledContainer>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
  calculateLeaseResultsCompanyProfile,
  changeHomeTab,
  changeSegment,
}, dispatch)

const mapStateToProps = (state: any) => ({
  segment: selectCurrentCompanyProfileSegment(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile)
