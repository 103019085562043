import styled, { css } from 'styled-components'
import { GraphBorder } from '../../../../styles/border.styles'
import { StyledShadowBox } from '../../../../styles/shadow.styles'

import breakpoint from '../../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../../constants/breakpoints'
export interface StyledChartContainerProps {
  isPrint?: boolean,
  loading?: boolean,
}
export const StyledTermOptionsGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  transition: opacity 300ms ease-in-out;
  border: ${GraphBorder};
  ${({ isPrint }: StyledChartContainerProps) => css`
    ${!isPrint ? `
      ${StyledShadowBox}
      margin-right: 4px;
      ${breakpoint(BREAKPOINTS.desktopMin)`
        width: 75%;
      `}
    `:`
      padding-bottom: 2px;
    `}
  `}
  ${({ loading }: StyledChartContainerProps) => css`
    opacity: ${loading ? '0.35': '1'}
  `}
`
