import React from 'react'

import { InputContainer } from './styles'
import { IntroLabel } from '../../common/text/introLabel'

interface Props {
  label?: string
  innerComponent: React.ComponentType
  tooltipText?: string
  id: string
}

export const CompanyInputWrapper = (
  {
    label,
    innerComponent,
    tooltipText,
    id,
  }: Props & any) => {
  return (
    <InputContainer>
      {innerComponent}
      {label && <IntroLabel
        id={id}
        text={label}
        tooltipText={tooltipText}
      />}
    </InputContainer>
  )
}

