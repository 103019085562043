import React from 'react'
import { StyledColorRect, StyledLegendLabel, StyledLegendLabelContainer } from './styles'

interface Props {
  /**
   * CSS hex color
   */
  color: string
  label: string
  isPrint?: boolean | undefined
}

export const LegendLabel = ({ label, color, isPrint }: Props) => {
  return <StyledLegendLabelContainer>
    <StyledColorRect isPrint={isPrint} color={color} />
    <StyledLegendLabel isPrint={isPrint}>{label}</StyledLegendLabel>
  </StyledLegendLabelContainer>
}
