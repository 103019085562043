import React, { Component } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'

import Spinner from '../../components/Spinner'
import Home from '../Home'
import { selectHasScenario, selectScenarioEmpty, selectScenarioLoading } from '../../redux/scenario/scenario.selectors'
import { bindActionCreators } from 'redux'
import { getScenarioById } from '../../redux/scenario/scenario.actions'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps<any>

class WithInputComponent extends Component<Props> {

  componentDidMount = () => {
    const { id } = this.props.match.params
    this.props.getScenarioById(id)
  }

  render() {
    // TODO: error handling on trying to load invalid scenario.
    /*if (error) {
      return <Redirect to="/calc" />
    }*/
    return (
      <>
        {this.props.emptyScenario && this.props.scenarioLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <Home />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  hasScenario: selectHasScenario(state),
  emptyScenario: selectScenarioEmpty(state),
  scenarioLoading: selectScenarioLoading(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
  getScenarioById,
}, dispatch)

export default withRouter(connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(WithInputComponent))

const SpinnerContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
