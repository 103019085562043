import startCase from 'lodash/startCase'
import { currencies } from '../../optionsConfig/models'
import { unitOfMeasure } from '../../utils/selectOptions/unitOfMeasure'
import { unitOfTime } from '../../utils/selectOptions/unitOfTime'

const MODAL_TITLE = 'Reset Overrides?'

export enum UnitCurrencySelectTypes {
  Currency = 'currency',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfTime = 'unitOfTime',
}

const UNITS_CURRENCY_FORM = 'unitsCurrency'

export const generateModalBody = (field: string): string => (
  `Changing ${field} will reset market costs to their default values`
)

export const generateDropdownLabel = (type: UnitCurrencySelectTypes) => {
  switch (type) {
    case UnitCurrencySelectTypes.Currency:
      return 'Currency'
    case UnitCurrencySelectTypes.UnitOfMeasure:
      return 'Unit Of Measurement'
    case UnitCurrencySelectTypes.UnitOfTime:
      return 'Unit Of Time'
  }
}

export const generateActionButton = (field: string): string => (
  `Change ${field}`
)

export const snakeCaseToSentence = (value: string): string => (
  startCase(value.replace(/([A-Z])/g, ' $1'))
)

export interface CurrencyFieldOption {
  readonly iso?: string
  readonly currency?: string
  readonly optionName: string

  /**
   * What its conversion rate is.
   */
  readonly usd?: number
  readonly convert: number
  readonly symbol?: string
  readonly uom?: string
  readonly value?: string
}

interface CurrencyField {
  readonly name: UnitCurrencySelectTypes
  readonly placeholder: string
  readonly text: string
  readonly optionsArray: readonly CurrencyFieldOption[]
  readonly modal: {
    readonly title: string
    readonly field: UnitCurrencySelectTypes
    readonly body: string
    readonly actionButtonText: string
    readonly form: string
  }
}

export const FIELDS: readonly CurrencyField[] = [
  {
    name: UnitCurrencySelectTypes.Currency,
    placeholder: startCase(UnitCurrencySelectTypes.Currency),
    text: startCase(UnitCurrencySelectTypes.Currency),
    optionsArray: currencies,
    modal: {
      title: MODAL_TITLE,
      field: UnitCurrencySelectTypes.Currency,
      body: generateModalBody(startCase(UnitCurrencySelectTypes.Currency)),
      actionButtonText: generateActionButton(startCase(UnitCurrencySelectTypes.Currency)),
      form: UNITS_CURRENCY_FORM,
    },
  },
  {
    name: UnitCurrencySelectTypes.UnitOfMeasure,
    placeholder: snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfMeasure),
    text: snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfMeasure),
    optionsArray: unitOfMeasure,
    modal: {
      title: MODAL_TITLE,
      field: UnitCurrencySelectTypes.UnitOfMeasure,
      body: generateModalBody(snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfMeasure)),
      actionButtonText: generateActionButton(snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfMeasure)),
      form: UNITS_CURRENCY_FORM,
    },
  },
  {
    name: UnitCurrencySelectTypes.UnitOfTime,
    placeholder: snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfTime),
    text: snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfTime),
    optionsArray: unitOfTime,
    modal: {
      title: MODAL_TITLE,
      field: UnitCurrencySelectTypes.UnitOfTime,
      body: generateModalBody(snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfTime)),
      actionButtonText: generateActionButton(snakeCaseToSentence(UnitCurrencySelectTypes.UnitOfTime)),
      form: UNITS_CURRENCY_FORM,
    },
  },
]
