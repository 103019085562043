export enum HomeActions {
  ChangeTab = 'home/CHANGE_TAB'
}

export interface ChangeHomeTab {
  type: typeof HomeActions.ChangeTab
  tab: string
}

export const changeHomeTab = (tab: string): HomeActionTypes => ({
  type: HomeActions.ChangeTab,
  tab,
})

export type HomeActionTypes =
  | ChangeHomeTab
