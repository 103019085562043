import React from 'react'
import { selectHasSavedScenario, selectScenarioLoading } from '../../../redux/scenario/scenario.selectors'
import { connect, Dispatch } from 'react-redux'
import { StyledDuplicateButton } from './styles'
import { bindActionCreators } from 'redux'
import { showModal } from '../../../redux/modal/modal.actions'
import { duplicateScenario } from '../../../redux/scenario/scenario.actions'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps;

const hasShownScenarioModal = (): boolean => (JSON.parse(localStorage.getItem('modalOpened') || 'false'))
const markShownScenarioModal = () => localStorage.setItem('modalOpened', JSON.stringify(true))

const DuplicateScenario = ({ hasSavedScenario, isScenarioLoading, showModal, duplicateScenario }: Props) =>
  (<>
    {hasSavedScenario && <StyledDuplicateButton
      onClick={() => {
        if (hasShownScenarioModal()) {
          duplicateScenario()
        } else {
          showModal({
            title: 'Duplicate Scenario',
            body: 'You\'re about to make a copy of your current scenario. ' +
              'This will not delete or change your current version.\n',
            actionButtonText: 'OK',
            secondaryButtonText: 'Cancel',
            action: () => {
              markShownScenarioModal()
              duplicateScenario()
            },
            name: 'duplicateScenario',
          })
        }
      }
      }
      isLoading={isScenarioLoading}
      disabled={isScenarioLoading}
      id="duplicate-scenario--button">
      Duplicate Scenario
    </StyledDuplicateButton>}
  </>)

const mapStateToProps = (state: any) => ({
  hasSavedScenario: selectHasSavedScenario(state),
  isScenarioLoading: selectScenarioLoading(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
  showModal,
  duplicateScenario,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateScenario)
