import styled from 'styled-components'
import { LinkStyles } from '../nav/styles'
import { COLORS } from '../../../constants/styles'


export const StyledDuplicateButton = styled.button<{ isLoading: boolean }>`
  ${LinkStyles}
  background-color: ${COLORS.WHITE};
  transition: opacity 300ms ease-in-out;

  ${({ isLoading }) => isLoading && `
    opacity: 0.5;
    cursor: not-allowed;
  `}

  &:hover {
    text-decoration: ${({ isLoading }) => (isLoading ? 'none' : 'underline')};
  }
`
