import styled, { css } from 'styled-components'
import { StyledShadowBox } from '../../../styles/shadow.styles'
import { GraphBorder } from '../../../styles/border.styles'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'


export interface Props {
  isPrint?: boolean
}
export const StyledChartContainer = styled('div')<Props>`
  border: ${GraphBorder};
  ${({ isPrint }: Props) => css`
    ${!isPrint && `
      margin-right: 4px;
      ${StyledShadowBox}
    `}
    ${!isPrint ? `padding: 15px;` : `padding-bottom: 2px;`}
  `}
`
export const StyledLegend = styled.div<Props>`
  display: flex;
  flex-flow: row wrap;
  ${({ isPrint }: Props) => css`
    ${isPrint ?
      `
        margin-left: 60px;
        margin-bottom: 24px;
        margin-top: 24px;
      `
      :
      `
        margin-left: 10%;
        margin-bottom: 36px;
        margin-top: 45px;
      `
    }
  `}
   >div {
    padding-right: 55px;
  }

  ${breakpoint(BREAKPOINTS.mobileMax)`
   >div {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 0;
      width: 100%;
    }
    margin-bottom: 10px;
    margin-top: 0;
    margin-left: 50px;
  `}
`
