import styled, { css } from 'styled-components'
import breakpoint from '../../utils/breakpoints'
import { COLORS, LAYOUT } from '../../constants/styles'
import { HeadlineH6Primary, LandingContentBody, LandingContentHeading } from '../../styles/text.styles'
import { BREAKPOINTS } from '../../constants/breakpoints'
import { StyledRouterLink } from '../common/text/styles'
import Icon from '../Icon'

export const StyledCenteringContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`

interface DirectionProps {
  direction: string
}

interface PrimaryProps {
  isPrimary: boolean
}

interface ContentProps {
  isPrimary: boolean
  direction: string
}

export const StyledContentContainer = styled.div<ContentProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
  ${breakpoint(BREAKPOINTS.desktopMin)`
    max-width: ${LAYOUT.LANDING_CONTAINER}px;
    margin: 0 auto;
    padding: ${({ isPrimary }: ContentProps) => isPrimary ? '0 24px':'0'}
    margin-top: ${({ isPrimary }: ContentProps) => isPrimary ? '140px':'0'}
  `}
   ${breakpoint(BREAKPOINTS.ultraWideMin)`
    max-width: ${LAYOUT.LANDING_CONTAINER_LARGE}px;
  `}

  ${breakpoint(BREAKPOINTS.desktopMax)`
    align-items: center;
    flex-direction: ${({ direction }: ContentProps) => direction === 'right' ? 'column':'column-reverse'};
    &:nth-child(4) {
      div {
        margin-bottom: 25px;
      }
    }

    &:nth-child(3) {
      div {
        margin-bottom: 25px;
      }
    }
  `}

`

export const StyledTextContent = styled.div<ContentProps>`
  padding-top: 25px;
  margin-bottom: 50px;
  ${({ direction }: ContentProps) => direction === 'right' ? css`
    ${breakpoint(BREAKPOINTS.desktopMin)`
      padding-left: ${({ isPrimary }: ContentProps) => isPrimary ? '24px':'100px'}
    `}
  `: css`
    ${breakpoint(BREAKPOINTS.desktopMin)`
      padding-right: ${({ isPrimary }: ContentProps) => isPrimary ? '24px':'100px'}
    `}
  `}
  ${breakpoint(BREAKPOINTS.tabletMax)`
    margin-bottom: 50px;
  `}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    padding-top: 0;
    width: 50%;
  `}
`


export const StyledLandingContentHeader = styled.h1<PrimaryProps>`
  margin-bottom: 30px;
  ${LandingContentHeading}
  ${({ isPrimary }: PrimaryProps) => isPrimary && css`
      color: ${COLORS.WHITE};
      font-size: 39px;
  `}

  ${breakpoint(BREAKPOINTS.tabletMax)`
    font-size: 30px;
  `}
`

export const StyledLandingBody = styled.p<PrimaryProps>`
  margin-bottom: 30px;
  ${LandingContentBody}
  ${({ isPrimary }) => isPrimary && css`
    color: ${COLORS.WHITE};
    font-size: 21px;
    line-height: 1.43;
      ${breakpoint(BREAKPOINTS.tabletMax)`
        font-size: 16px;
        line-height: 1.56;
      `}
  `}
`

export const StyledImageFlexContainer = styled.div`
  width: 100%;
  ${breakpoint(BREAKPOINTS.desktopMin)`
    width: 50%;
  `}
`

export const StyledImageBg = styled.div<DirectionProps>`
  background-color: #bfd1cc;
  padding: 20px;
  display: inline-block;
  ${breakpoint(BREAKPOINTS.desktopMax)`
    padding: 13px;
    margin-bottom: 25px;
  `}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    float: ${({ direction }: DirectionProps) => direction === 'right' ? 'left':'right'};
  `}
`

export const StyledImageContainer = styled.div<PrimaryProps>`
  max-width: 100%;
  max-width: ${({ isPrimary }: PrimaryProps) => isPrimary ? 'unset' : '100%'};
  width: ${({ isPrimary }: PrimaryProps) => isPrimary ? '118%' : '448px'};
  margin-left: ${({ isPrimary }: PrimaryProps) => isPrimary ? '-7%' : '0'};
  height: auto;
  ${breakpoint(BREAKPOINTS.desktopMax)`
    width: 100%;
    display: ${({ isPrimary }: PrimaryProps) => isPrimary ? 'none' : 'block'};
  `}
  ${breakpoint(BREAKPOINTS.ultraWideMin)`
    width: ${({ isPrimary }: PrimaryProps) => isPrimary ? '105%' : '448px'};
  `}
`

export const StyledImage = styled.img`
  width: 100%;
`

export const StyledFooterContainer = styled.footer`
  width: 100%;
  height: auto;
  background: ${COLORS.SECONDARY_01};
`

export const StyledFooterContainerInner  = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  flex-direction: column;
  padding: 35px 25px;
  ${breakpoint(BREAKPOINTS.desktopMin)`
    padding: 64px 24px;
    max-width: ${LAYOUT.LANDING_CONTAINER}px;
    margin: 0 auto;
  `}
   ${breakpoint(BREAKPOINTS.ultraWideMin)`
    max-width: ${LAYOUT.LANDING_CONTAINER_LARGE}px;
  `}
   ${breakpoint(BREAKPOINTS.tabletMin)`
    flex-direction: row;
  `}
`

export const StyledSocialIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`

export const StyledFooterLogoIconContainer = styled.div`
  ${breakpoint(BREAKPOINTS.tabletMax)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`

export const StyledFooterIconContainer = styled.div`
  ${breakpoint(BREAKPOINTS.tabletMin)`
    margin-top: 32px;
  `}
`
export const StyledFooterContact = styled.div`
  display: flex;

  ${breakpoint(BREAKPOINTS.tabletMax)`
    flex-direction: column;
  `}
`
export const StyledFooterContactContainer = styled.div`
  ${breakpoint('tabletMax')`
    margin-top: 45px;
  `}
`

export const StyledFooterLinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 15px;
  }

  ${breakpoint(BREAKPOINTS.tabletMin)`
    margin-right: 50px;
  `}
`

export const StyledFooterLink = styled.a`
  text-decoration: none;
  ${HeadlineH6Primary}
`

export const StyledLandingRouterLink = styled(StyledRouterLink)`
  text-decoration: none;
`

export const StyledFooterRouterLink = styled(StyledLandingRouterLink)`
  ${HeadlineH6Primary}
`

export const StyledQuestions = styled.p`
  margin-bottom: 15px;
  ${HeadlineH6Primary}
  color: ${COLORS.DARK_00};
`
