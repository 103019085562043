import React, { useState } from 'react'
import styled from 'styled-components'

import { StyledLink } from '../text/styles'

import { hasAgreedToPrivacyPolicy, agreeToPrivacyPolicy } from './policyLocalStorage'
import ModalWrapper, { ModalAction } from './index'

interface Props {
  push: (location: string) => void
}

const PolicyConsentModal = ({ push }: Props) => {
  // getting answer to cbrePrivacy from local storage
  const hasAgreed = hasAgreedToPrivacyPolicy()
  const [cbrePrivacy, setPrivacy] = useState(hasAgreed)

  const handlePolicyCallback = (type: ModalAction) => {
    if (type === ModalAction.Primary) {
      agreeToPrivacyPolicy()
      setPrivacy(true)
    } else {
      push('/')
    }
  }

  return (
    <ModalWrapper
      title="Welcome to CBRE’s Agile Lease Calculator"
      primaryText="Continue"
      swapButtons={true}
      secondaryText="No Thanks"
      name="privacy-modal"
      visible={!cbrePrivacy}
      callback={(type) => handlePolicyCallback(type)}
    >
      <StyledModalBody>By clicking Continue, you are agreeing to our <StyledLink href="/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</StyledLink> and <StyledLink href="https://www.cbre.us/about/privacy-policy" target="_blank" rel="noopener noreferrer">Web Privacy &amp; Cookie Policy</StyledLink>.</StyledModalBody>
    </ModalWrapper>
  )
}

const StyledModalBody = styled.p`
  padding-top: 15px;
`

export default PolicyConsentModal
