import React from 'react'
import { StyledContainer, StyledTitle } from '../styles'
import { StyledEmailButton } from './styles'
import { connect, Dispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showEmailView } from '../../../redux/popover/popover.actions'
import { selectScenarioId, selectScenarioName } from '../../../redux/scenario/scenario.selectors'
import LinkRow from '../linkrow'

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = DispatchProps & StateProps

const AccessScenario = (
  {
    openEmailView,
    scenarioId,
    scenarioName,
  }: Props) => {
  return <StyledContainer submitting={false}>
    <StyledTitle>How to access your scenario</StyledTitle>
    <LinkRow
      scenarioName={scenarioName}
      scenarioId={scenarioId}
      isReadonly={false}
      title="Access and Update"
      content="Return to your saved scenario, with the ability to save changes."
    />
    <LinkRow
      scenarioId={scenarioId}
      scenarioName={scenarioName}
      isReadonly
      title="Share With Clients"
      content="Anyone can view your scenario without the ability to save changes."
    />
    <StyledEmailButton
      id="send-email"
      text="Send via Email"
      onClick={() => openEmailView()}
    />

  </StyledContainer>
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => (bindActionCreators({
  openEmailView: showEmailView,
}, dispatch))

const mapStateToProps = (state: any) => ({
  scenarioId: selectScenarioId(state),
  scenarioName: selectScenarioName(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccessScenario)
