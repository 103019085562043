import styled from 'styled-components'
import breakpoint from '../../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

import { HeadlineH3Default } from '../../../../styles/text.styles'
import { COLORS } from '../../../../constants/styles'


interface StyledInputContainerProps {
  setWidth: string
}

export const StyledInputContainer = styled.div<StyledInputContainerProps>`
  position: relative;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0;
  &:after{
    content: '';
    position: absolute;
    border-bottom: solid ${COLORS.DARK_01} 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }
  ${breakpoint(BREAKPOINTS.tabletMin)`
    &:after{
      border-bottom: solid ${COLORS.DARK_01} 2px;
    }
    transition: width 0.4s ease-in-out;
    width: ${({ setWidth }: StyledInputContainerProps) => setWidth};
  `}
`

interface StyledInputProps {
  error?: boolean
}

export const StyledInput = styled.input<StyledInputProps>`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  display: block;
  outline: none;
  position: relative;
  ${HeadlineH3Default}
  -webkit-appearance: none;
  border: none !important;
  height: unset;
  background: none;
  z-index: 1;
  width: 100%;
  padding: 11px 0;
  white-space: nowrap;
  &::placeholder{
    ${HeadlineH3Default}
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
    ${HeadlineH3Default}
  }

  &:focus {
    &:after{
      border-bottom: solid ${COLORS.DARK_00} 2px;
      ${({ error }: StyledInputProps) => error && `
        border-bottom: solid ${COLORS.DANGER_00} 2px;
      `}
    }
  }

  ${breakpoint(BREAKPOINTS.tabletMin)`
    font-size:28px;
    &::placeholder,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      font-size: 28px;
    }
  `}
  ${breakpoint(BREAKPOINTS.ultraWideMin)`
    font-size: 39px;
    &::placeholder,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      font-size: 39px;
    }
  `}

`

export const StyledSuffix = styled.span`
  white-space: nowrap;
  position: absolute;
  display: inline-block;
  font-size: 39px;
  color: ${COLORS.PRIMARY_00};
  font-weight: bold;
  left: 8px;
  bottom: 35px;
  user-select: none;
  pointer-events: none;
  z-index: 0;
  height: 40%;

  ${breakpoint(BREAKPOINTS.mobileMax)`
    font-size: 16px;
    bottom: 15px;
  `}
`

export const InvisibleSpan = styled.span`
  opacity: 0;
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`
