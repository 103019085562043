import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import {
  addLeaseLocally,
  AddNewLease,
  clearCurrentScenario,
  duplicateScenarioFailed,
  duplicateScenarioSucceeded,
  EmailScenario,
  emailScenarioFailed,
  emailScenarioSucceeded,
  GetScenarioById,
  getScenarioByIdFailed,
  getScenarioByIdSucceeded,
  noCalculation,
  putNewScenario,
  recalculateLease,
  RecalculateLease,
  RemoveLeaseLocally,
  saveLeaseChanges,
  SaveScenario,
  saveScenarioFailed,
  saveScenarioSucceeded,
  ScenarioActions,
  SelectLease,
  selectLease,
  selectLeaseLocally,
  SelectLeaseType,
  storeSavedScenario,
  UpdateLocalLease,
  UpdateNPVDiscountRate,
} from './scenario.actions'
import {
  CalculateIterationsInput,
  MultiLeaseScenario,
  ScenarioLeases,
  ScenarioLeaseType,
  SendEmailBody,
  PCCalcResults,
  LeaseWithCalculation,
  SaveScenarioBody
} from '../../data/models'
import {
  calculateAllLeases,
  calculateLeaseResults,
  clearCompanyInputs,
  removeLeaseFromCalculation,
  storeCompanyInputs,
} from '../calculations/calculation.actions'
import {
  iterationsInputForCompanyInputs,
  mergeScenario,
  scenarioToCompanyInputs,
  scenarioToScenarioData,
} from '../../data/conversions'
import { client } from '../../api/http.client'
import {
  selectActiveLeaseHasChanged,
  selectFlexOverrideLease,
  selectNPVDiscountRate,
  selectSavedScenario,
  selectScenarioSuccess,
  selectSelectedOriginalLease,
  selectTradOverrideLease,
  selectUpdatedOverrideLease,
} from './scenario.selectors'
import { CompanyInputsShape } from '../../data/form_models'
import { selectCompanyInputs, selectCompanyLocation, selectCalculationSuccess, selectSeatChartResults } from '../calculations/calculation.selectors'
import { UnitOfTime } from '../../utils/selectOptions/unitOfTime'
import { selectUnitCurrencyState, selectUnitOfTime } from '../unitCurrency/unitCurrency.selectors'
import { changeHomeTab } from '../home/home.actions'
import { CompanyLocation } from '../../optionsConfig/models'
import {
  createDefaultLeases,
  defaultFlexibleLeaseInputsForLocation,
  defaultTraditionalLeaseForLocation,
} from '../../data/lease_utils'
import { defaultMeetingRoomRequirement } from '../../utils/selectOptions/meetingRoomRequirements'
import { changeSegment } from '../companyprofile/companyprofile.actions'
import { formatScenarioName, successToast, updateRoute } from '../../utils'
import { scenarioUrl } from '../../data/scenario.link_utils'
import { closePopover, showPopoverEdit } from '../popover/popover.actions'
import { errorToast } from '../../utils/successToast'
import { UnitCurrencyState } from '../unitCurrency/unitCurrency.reducer'

function* getScenarioByIdSaga(action: GetScenarioById) {
  try {
    const response: MultiLeaseScenario = yield call(() => client.get<MultiLeaseScenario>(`/scenario/${action.scenarioId}`))
    const unitOfTime: UnitOfTime = yield select(selectUnitOfTime)
    yield all([
      put(getScenarioByIdSucceeded(response)),
      put(calculateLeaseResults({
        timeValue: unitOfTime.convert,
        scenario: scenarioToScenarioData(response),
        leases: response.leases,
      })),
      put(storeCompanyInputs(scenarioToCompanyInputs(response))),
      put(storeSavedScenario(response)),
      // select first least when loading scenario.
      put(selectLease(response.leases[0] as ScenarioLeases)),

      // set segment on company inputs to latest
      put(changeSegment(2)),

      // change home tab to detailed results.
      put(changeHomeTab('3')),
    ])
    if (response.isDuplicated) {
      yield all([
        put(showPopoverEdit())
      ])
    }
  } catch (e) {
    yield put(getScenarioByIdFailed(e))
  }
}

function* putLeaseSaga(action: AddNewLease) {
  const unitOfTime: UnitOfTime = yield select(selectUnitOfTime)
  // locally place it
  yield put(addLeaseLocally(action.lease))

  // then retrieve new scenario merged.
  const scenario: MultiLeaseScenario | undefined = yield select(selectScenarioSuccess)
  if (!scenario) {
    console.error('This method may have been called in error. No existing scenario to modify npv discount rate.')
    yield put(noCalculation('noExistingScenario'))
    return
  }
  yield all([
    put(selectLease(action.lease)),
    put(calculateLeaseResults({
      timeValue: unitOfTime.convert,
      scenario: scenarioToScenarioData(scenario),
      leases: [action.lease], // only submit new lease for iteration calculation.
    })),
  ])
}

function* removeLeaseLocallySaga(action: RemoveLeaseLocally) {
  yield put(removeLeaseFromCalculation([action.leaseId]))
}

function* saveScenarioSaga(action: SaveScenario) {
  try {
    const hasActiveLeaseChanged: boolean = yield select(selectActiveLeaseHasChanged)
    // if changed, we synchronize changes into working copy of scenario.
    if (hasActiveLeaseChanged) {
      // store as now selected lease
      const selectedLease = yield select(selectUpdatedOverrideLease)
      yield put(selectLease(selectedLease))

      // wait for local selection to occur
      yield take(ScenarioActions.SelectLeaseLocally)
    }

    const existingScenario: MultiLeaseScenario | undefined = yield select(selectScenarioSuccess)
    // getting the outputs to store as well
    const calculationResults: Map<string, LeaseWithCalculation> = yield select(selectCalculationSuccess)
    const seatChartResults: PCCalcResults = yield select(selectSeatChartResults)
    const calculationArray: LeaseWithCalculation[] = Array.from(calculationResults.values());
    const outputs = {
      calculations: calculationArray,
      seatChartResults: seatChartResults
    }

    const unitOfTime: UnitOfTime = yield select(selectUnitOfTime)
    let scenarioToSave: MultiLeaseScenario = omit({
      ...existingScenario,
      ...action.scenario,
    } as MultiLeaseScenario, '_id', '_rev', 'isDuplicated')
    // if has an id, keep it/ also remove isDuplicate marker
    if (existingScenario && !!existingScenario._id && !!existingScenario._rev) {
      scenarioToSave = {
        ...scenarioToSave,
        _id: existingScenario._id,
        _rev: existingScenario._rev,
      }
    }
    const payload: SaveScenarioBody = {
      scenario: scenarioToSave,
      outputs
    }
    const response: MultiLeaseScenario = yield call(() => client.post(
      `/scenario`,
      payload,
    ))

    yield all([
      put(saveScenarioSucceeded(response)),
      put(calculateAllLeases({
        timeValue: unitOfTime.convert,
        scenario: scenarioToScenarioData(response),
        leases: response.leases,
      })),
      put(storeSavedScenario(response)),
    ])
    if (!action.isPrint) {
      updateRoute(response._id, formatScenarioName(response.scenarioName))
      successToast(existingScenario && !!existingScenario._id ? 'Scenario updated!' : 'Scenario was saved!')
    }
  } catch (e) {
    yield put(saveScenarioFailed(e))
  }
}

function* duplicateScenario() {
  try {
    const existingScenario: MultiLeaseScenario = yield select(selectScenarioSuccess)
    const response: MultiLeaseScenario = yield call(() => client.post<MultiLeaseScenario>(
      `/scenario/${existingScenario._id}/duplicate`,
    ))

    // no need to recalculate a scenario since we duplicate it.
    yield all([
      put(duplicateScenarioSucceeded(response)),
    ])

    // open new tab with original scenario.
    window.open(scenarioUrl({
      name: response.scenarioName,
      id: response._id!,
    }), '_blank', 'rel=noreferrer')
    window.focus()
  } catch (e) {
    yield put(duplicateScenarioFailed(e))
  }
}

function* emailScenarioSaga(action: EmailScenario) {
  try {
    const savedScenario: MultiLeaseScenario = yield select(selectSavedScenario)
    const response: MultiLeaseScenario = yield call(() => client.put<MultiLeaseScenario, SendEmailBody>(
      `/scenario/email`,
      {
        referenceUrl: window.location.href,
        email: action.email,
        scenario: savedScenario,
      },
    ))
    yield all([
      put(emailScenarioSucceeded(response)),
      put(closePopover()),
    ])
    successToast(`Scenario emailed to ${action.email}`)
  } catch (e) {
    yield put(emailScenarioFailed(e))
    errorToast('Scenario failed to email. Please try again.')
  }
}

function* npvDiscountRateChangeSaga(action: UpdateNPVDiscountRate) {
  const existingScenario: MultiLeaseScenario | undefined = yield select(selectScenarioSuccess)

  if (!existingScenario) {
    console.error('This method may have been called in error. No existing scenario to modify npv discount rate.')
    yield put(noCalculation('noExistingScenario'))
    return
  }
  const existingDiscount: number = yield select(selectNPVDiscountRate)
  // don't recalc if input is the same as current in state
  if (existingDiscount === action.discountRate) {
    yield put(noCalculation('sameDiscountRate'))
    return
  }

  const inputs: CompanyInputsShape = yield select(selectCompanyInputs)
  const { currency, unitOfMeasure, unitOfTime }: UnitCurrencyState = yield select(selectUnitCurrencyState)
  const newScenario: MultiLeaseScenario = {
    ...existingScenario,
    discountRate: action.discountRate,
  }
  // calculate leases again
  const input: CalculateIterationsInput = iterationsInputForCompanyInputs(newScenario,
    unitOfTime,
    unitOfMeasure,
    currency,
    inputs,
    action.discountRate,
    newScenario.leases)
  yield put(calculateAllLeases(input))

  // place new scenario locally, then update remotely if saved
  yield put(putNewScenario(newScenario))
}

function* recalculateLeaseSaga(action: RecalculateLease) {
  const existingScenario: MultiLeaseScenario | undefined = yield select(selectScenarioSuccess)
  if (!existingScenario) {
    console.error('This method may have been called in error. No existing scenario to calculate a local lease')
    yield put(noCalculation('noExistingScenario'))
    return
  }
  const { existingLease, leaseToUpdate } = action
  if (!isEqual(omit(leaseToUpdate, 'name'), omit(existingLease, 'name'))) {
    const { currency, unitOfMeasure, unitOfTime }: UnitCurrencyState = yield select(selectUnitCurrencyState)
    const discountRate: number = yield select(selectNPVDiscountRate)
    const inputs: CompanyInputsShape = yield select(selectCompanyInputs)
    const input: CalculateIterationsInput = iterationsInputForCompanyInputs(existingScenario,
      unitOfTime,
      unitOfMeasure,
      currency,
      inputs, discountRate, [leaseToUpdate])
    yield all([
      // remove lease from calculation when we update locally
      put(removeLeaseFromCalculation([leaseToUpdate.id])),
      put(calculateLeaseResults(input)),
    ])
  }
}

function* updateLocalLeaseSaga(action: UpdateLocalLease) {
  if (action.shouldRecalculate) {
    const leaseToUpdate = {
      ...action.existing,
      ...action.updateValues,
    } as ScenarioLeases
    // avoid just pure name changes to calculate results.
    yield put(recalculateLease(leaseToUpdate, action.existing))
  }
}

function* selectLeaseTypeRecalculateResults(action: SelectLeaseType) {
  const existingScenario: MultiLeaseScenario | undefined = yield select(selectScenarioSuccess)
  if (!existingScenario) {
    console.error('This method may have been called in error. No existing scenario to calculate a local lease')
    yield put(noCalculation('noExistingScenario'))
    return
  }
  const { currency, unitOfMeasure, unitOfTime }: UnitCurrencyState = yield select(selectUnitCurrencyState)
  const discountRate: number = yield select(selectNPVDiscountRate)
  const inputs: CompanyInputsShape = yield select(selectCompanyInputs)
  let leaseSelector: any
  switch (action.leaseType) {
    case ScenarioLeaseType.Traditional:
      leaseSelector = select(selectTradOverrideLease)
      break
    case ScenarioLeaseType.FlexCoworking:
      leaseSelector = select(selectFlexOverrideLease)
      break
  }
  const lease: ScenarioLeases = yield leaseSelector
  const input: CalculateIterationsInput = iterationsInputForCompanyInputs(existingScenario,
    unitOfTime, unitOfMeasure, currency, inputs, discountRate, [lease])
  yield all([
    // remove lease from calculation when we update locally
    put(removeLeaseFromCalculation([lease.id])),
    put(calculateLeaseResults(input)),
  ])
}

function* startOverPreserveCompanyInputsSaga() {

  // go back to company profile
  yield put(changeHomeTab('1'))

  const companyInputs: CompanyInputsShape = yield select(selectCompanyInputs)
  const existingScenario: MultiLeaseScenario = yield select(selectScenarioSuccess)
  const { currency, unitOfMeasure, unitOfTime }: UnitCurrencyState = yield select(selectUnitCurrencyState)
  const companyLocation: CompanyLocation | undefined = yield select(selectCompanyLocation)
  const discountRate: number = yield select(selectNPVDiscountRate)
  if (!companyLocation) {
    console.error('Could not find company location in state')
    return
  }
  // we should have at least 2 leases
  const leases = [
    ...createDefaultLeases(
      companyLocation,
      companyInputs,
      defaultMeetingRoomRequirement.value,
      currency,
      unitOfMeasure,
    ),
  ]

  // cleanup existing leases
  yield put(removeLeaseFromCalculation(existingScenario.leases.map(l => l.id)))

  // recalculate new leases.
  const input: CalculateIterationsInput = iterationsInputForCompanyInputs(existingScenario,
    unitOfTime,
    unitOfMeasure,
    currency,
    companyInputs,
    discountRate,
    leases)
  yield put(calculateAllLeases(input))

  // place new scenario into the fold
  const newScenario: MultiLeaseScenario = mergeScenario(existingScenario, companyInputs,
    companyLocation, leases, currency, unitOfMeasure, unitOfTime)
  yield put(putNewScenario(newScenario))
}

function* startOverSaga() {
  // go back to company profile
  yield put(changeHomeTab('1'))
  const existingScenario: MultiLeaseScenario = yield select(selectScenarioSuccess)

  // cleanup existing leases
  yield put(removeLeaseFromCalculation(existingScenario.leases.map(l => l.id)))

  // clear out current scenario state, company inputs, and reset segment
  yield all([
    put(clearCurrentScenario()),
    put(clearCompanyInputs()),
  ])
}

function* restoreLeaseDefaults() {
  // select lease as combo of defaults.
  // preserve original name
  const companyInputs: CompanyInputsShape = yield select(selectCompanyInputs)
  const { currency, unitOfMeasure, unitOfTime }: UnitCurrencyState = yield select(selectUnitCurrencyState)
  const companyLocation: CompanyLocation | undefined = yield select(selectCompanyLocation)
  const existingScenario: MultiLeaseScenario = yield select(selectScenarioSuccess)
  const discountRate: number = yield select(selectNPVDiscountRate)
  if (!companyLocation) {
    console.error('Could not find company location in state')
    return
  }
  let originalLease: ScenarioLeases = yield select(selectUpdatedOverrideLease)
  switch (originalLease.type) {
    case ScenarioLeaseType.FlexCoworking:
      originalLease = {
        ...originalLease,
        ...defaultFlexibleLeaseInputsForLocation(companyLocation, originalLease.name, defaultMeetingRoomRequirement.value),
      }
      break
    case ScenarioLeaseType.Traditional:
      originalLease = {
        ...originalLease,
        ...defaultTraditionalLeaseForLocation(companyLocation, companyInputs, currency, unitOfMeasure, originalLease.name),
      }
      break
  }
  const input: CalculateIterationsInput = iterationsInputForCompanyInputs(existingScenario,
    unitOfTime, unitOfMeasure, currency, companyInputs, discountRate, [originalLease])
  yield all([
    put(selectLease(originalLease)),
    put(removeLeaseFromCalculation([originalLease.id])),
    put(calculateLeaseResults(input)),
  ])
}

function* selectLeaseSaga(action: SelectLease) {
  const changedLease: ScenarioLeases | undefined = yield select(selectUpdatedOverrideLease)
  const previousOriginalLease: ScenarioLeases | undefined = yield select(selectSelectedOriginalLease)
  if (changedLease && previousOriginalLease) {
    // if different, store new one in state + calculate data if anything but name changes.
    if (!isEqual(previousOriginalLease, changedLease)) {
      yield all([
        put(saveLeaseChanges(changedLease)),
        put(recalculateLease(changedLease, previousOriginalLease)),
      ])
    }
  }
  // place lease locally in state
  yield put(selectLeaseLocally(action.lease))
}

export function* scenarioSagas() {
  yield takeEvery(ScenarioActions.AddNewLease, putLeaseSaga)
  yield takeEvery(ScenarioActions.SaveScenario, saveScenarioSaga)
  yield takeEvery(ScenarioActions.DuplicateScenario, duplicateScenario)
  yield takeEvery(ScenarioActions.GetScenarioById, getScenarioByIdSaga)
  yield takeEvery(ScenarioActions.EmailScenario, emailScenarioSaga)
  yield takeEvery(ScenarioActions.RemoveLeaseLocally, removeLeaseLocallySaga)
  yield takeEvery(ScenarioActions.UpdateNPVDiscountRate, npvDiscountRateChangeSaga)
  yield takeEvery(ScenarioActions.UpdateLocalLease, updateLocalLeaseSaga)
  yield takeEvery(ScenarioActions.RecalculateLease, recalculateLeaseSaga)
  yield takeEvery(ScenarioActions.SelectLeaseType, selectLeaseTypeRecalculateResults)
  yield takeEvery(ScenarioActions.StartOverPreserveInputs, startOverPreserveCompanyInputsSaga)
  yield takeEvery(ScenarioActions.StartOver, startOverSaga)
  yield takeEvery(ScenarioActions.RestoreLeaseDefaults, restoreLeaseDefaults)
  yield takeEvery(ScenarioActions.SelectLease, selectLeaseSaga)
}
