import { PopoverActions, PopoverActionTypes } from './popover.actions'
import { ScenarioActions, ScenarioActionTypes } from '../scenario/scenario.actions'

export enum PopoverScreen {
  Naming,
  Edit,
  Email,
  AccessLinks
}

export interface PopoverState {
  visible: boolean
  screen: PopoverScreen
}

const initialState: PopoverState = {
  visible: false,
  screen: PopoverScreen.Naming,
}

export const popoverReducer = (state = initialState, action: PopoverActionTypes | ScenarioActionTypes): PopoverState => {
  switch (action.type) {
    case PopoverActions.ShowPopover:
      return {
        ...state,
        visible: true,
      }
    case PopoverActions.ClosePopover:
      return {
        ...state,
        visible: false,
        // if email or edit name and we close popover, revert back to access links
        screen: (state.screen === PopoverScreen.Email || state.screen === PopoverScreen.Edit)
          ? PopoverScreen.AccessLinks : state.screen,
      }
    case PopoverActions.ShowPopoverEdit:
      return {
        ...state,
        visible: true,
        screen: PopoverScreen.Edit,
      }
    case ScenarioActions.StoreSavedScenario:
      return {
        ...state,
        screen: PopoverScreen.AccessLinks,
      }
    case ScenarioActions.StartOver:
      return {
        ...state,
        screen: PopoverScreen.Naming,
      }

    case PopoverActions.ShowEmailView:
      return {
        ...state,
        screen: PopoverScreen.Email,
        visible: true,
      }
    default:
      return state
  }
}
