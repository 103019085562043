import React from 'react'
import styled from 'styled-components'
import { COLORS, FONTS } from '../../../../constants/styles'
import breakpoint from '../../../../utils/breakpoints'
import { TableMobileSmallAccent, UIDefault, UISmallError } from '../../../../styles/text.styles'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

const {
  Input: { TextField },
} = require('@cbrebuild/blocks-react')

interface TextFieldProps {
  expandable: boolean
}

export const StyledTextField = styled(({expandable, ...rest})=> <TextField {...rest}/>)<TextFieldProps>`
  width: 100%;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    width:${({ expandable }: TextFieldProps) => expandable ? '100%' :  '66%'}
    min-width:${({ expandable }: TextFieldProps) => expandable ? '100%' :  '66%'}
    margin-right: 15px;
  `}

  .blx-text-field-container {
    font-family: ${FONTS.PRIMARY};
    input{
      font-size: 17px;
      margin-top: -1px;
      -webkit-appearance: none;
      -moz-appearance: none;
      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus{
        border: solid 1px ${COLORS.PRIMARY_00}
      }
      &.blx-invalid{
        border: solid 1px ${COLORS.DANGER_00} !important;
      }
    }
  }
  .blx-text-field-icon{
    line-height: 13px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .blx-text-field-suffix, .blx-text-field-prefix{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .blx-text-field-helper-text{
    font-size: 12px;
    font-weight: 400;
    font-family: ${FONTS.PRIMARY};
    color: ${COLORS.DARK_01};
    margin: 5px 2px 0px 2px;
  }

`

export const StyledErrorText = styled.div`
  color: ${COLORS.ERROR};
  font-size: 12px;
  font-family: ${FONTS.PRIMARY};
`
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SuffixExtrasContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${breakpoint(BREAKPOINTS.mobileMax)`
    flex-direction: row;
  `}
`

interface StyledExtrasProps {
  disabled: boolean
}

export const StyledExtras = styled.div<StyledExtrasProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${({ disabled }: StyledExtrasProps) => (disabled ? 0.35 : 1)};
  margin-top: 8px;
  ${UISmallError}
`

export const StyledStory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: max-content;
`

export const StyledBottomText = styled.h4`
  margin-top: 4px;
  ${TableMobileSmallAccent}
`

export const StyledSuffix = styled.span`
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  left: 23px;
  top: 11px;
  user-select: none;
  pointer-events: none;
  z-index: 0;
  ${UIDefault}
`

export const InvisibleSpan = styled.span`
  opacity: 0;
`
