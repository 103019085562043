import styled from 'styled-components'
import { COLORS } from '../../../constants/styles'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'

const {
  Input: { Toggle },
} = require('@cbrebuild/blocks-react')

export const StyledToggle = styled(Toggle)`
  ${breakpoint(BREAKPOINTS.mobileMax)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
  .blx-toggle-container {
    .blx-toggle-text{
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.13;
      user-select: none;
      ${breakpoint(BREAKPOINTS.mobileMax)`
        padding: 6px 10px;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 44px;
      `}

    }

    ${breakpoint(BREAKPOINTS.mobileMax)`
      flex: 1;
    `}
    input{
      &:checked~.blx-toggle-text{
        background-color: ${COLORS.PRIMARY_00};
      }
      ${breakpoint(BREAKPOINTS.mobileMax)`
        &:hover~.blx-toggle-text{
          padding: 6px 10px;
        }
      `}
    }
  }
`

export const StyledStory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: max-content;
`
