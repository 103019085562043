import { scenarioUrl } from '../../../data/scenario.link_utils'
import { message } from 'antd'
import React from 'react'
import {
  StyledOptionButton,
  StyledRowContainer,
  StyledRowContent,
  StyledRowContentContainer,
  StyledRowTitle,
} from './styles'

interface Props {
  title: string
  content: string
  scenarioId: string
  scenarioName: string
  isReadonly: boolean
}

const LinkRow = ({ title, content, scenarioId, scenarioName, isReadonly }: Props) => {
  return <StyledRowContainer>
    <StyledRowTitle>{title}</StyledRowTitle>
    <StyledRowContentContainer>
      <StyledRowContent>{content}</StyledRowContent>
      <StyledOptionButton
        data-clipboard-text={scenarioUrl({
          id: scenarioId,
          name: scenarioName,
          isReadonly,
        })}
        className={`copy-link-${isReadonly ? 'readonly': 'edit'}`}
        onSuccess={() => message.success('Link copied to clipboard!', 1.5)}>
        Copy Link
      </StyledOptionButton>
    </StyledRowContentContainer>
  </StyledRowContainer>
}

export default LinkRow
