import round from 'lodash/round'

// formula for CA-G82
export const lossFactorCalc = (rentableArea) => (round((1 - rentableArea), 5))

/**
 * calc for CA-G79
 * netAreaSeatAssumption = from locations selection
 * seatVolatility = from seatVolatility selection
 */

export const spacePlanningAssumptionCalc = (seats) => {
  if (seats <= 0) {
    return new Error('seats cannot be <= 0 in spacePlanningAssumption func')
  }
  if (seats < 20) {
    return 0.25
  }
  if (seats < 50) {
    return 0.15
  }
  if (seats < 100) {
    return 0.10
  }
  if (seats < 150) {
    return 0
  }
  if (seats < 200) {
    return -0.05
  }
  if (seats <= 250) {
    return -0.10
  }
  if (seats > 250) {
    return new Error('seats cannot be > 250 in spacePlanningAssumption func')
  }

  return new Error('something went wrong in spacePlanningAssumption')
}

export const usableSeatAreaCalc = (netAreaSeatAssumption, initialSeats) => {
  return round(netAreaSeatAssumption * (1 + spacePlanningAssumptionCalc(initialSeats)), 2)
}
