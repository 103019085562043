import styled from 'styled-components'
import { GraphBorder } from '../../../styles/border.styles'

export const StyledComparisonContainer = styled.div`
  /* display: flex;
  flex-direction: column; */
  border-bottom: ${GraphBorder};
  border-left: ${GraphBorder};
  @media print {
    border-width: 1px;
  }
  width: 100%;
`

export const StyledComparisonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 16.6666666667%);
`

export const StyledComparisonHeader = styled.div`
 display: grid;
 grid-template-columns: repeat(6, 16.6666666667%);
 width: 100%;
 background: white;
 z-index: 1;
 max-height: 40px;
`
