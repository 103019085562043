import styled, { css } from 'styled-components'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { COLORS, FONTS, LAYOUT, ORDER } from '../../../constants/styles'


export const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  background: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-bottom: 1px solid ${COLORS.GRAY};
  background: ${COLORS.WHITE};
   z-index: ${ORDER.HEADER};

  @media print {
    display: none;
  }
  ${breakpoint(BREAKPOINTS.mobileMax)`
    position: relative !important;
  `}
`

export const StyledNavContainer = styled.nav`
  align-items: center;
  display: flex;
  height: ${LAYOUT.HEADERHEIGHT}px;
  justify-content: space-between;
  padding: 0 ${LAYOUT.GUTTER}px;
  padding-bottom: 0px;

  ${breakpoint(BREAKPOINTS.tabletMax)`
    height: ${LAYOUT.MOBILE_HEADERHEIGHT}px;
    padding: 0 ${LAYOUT.MOBILE_GUTTER}px;
  `}
`

export const StyledLogoLink = styled.a`
  user-select: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:focus{
    background-color: transparent;
    outline: none;
    img, span {
      opacity: 0.8;
    }
  }
`
export const StyledLogoText = styled.span`
  user-select: none;
  color: ${COLORS.PRIMARY_00};
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: -0.06px;
  ${breakpoint(BREAKPOINTS.tabletMax)`
    font-size: 21px;
    line-height: 1.14;
    letter-spacing: -0.05px;
  `}
`

export const StyledLogo = styled.div`
  img {
    width: 79px;
    user-select: none;
    margin-right: 4px;
    display: none;
    ${breakpoint(BREAKPOINTS.tabletMin)`
      display: block;
    `}
  }
`

export const StyledRightSide = styled.div<{ hasScenario: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ hasScenario }) => (hasScenario ? 1 : 0.2)};
  ${({ hasScenario }) => !hasScenario && `
    span, a, button {
      user-select: none;
      &:hover {
        cursor: not-allowed;
        text-decoration: none;
      }
    }
  `}
`

export const LinkStyles = css`
  font-family: ${FONTS.PRIMARY};
  color: #006a4d;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
  margin-right: 8px;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledNavItem = styled.button`
  ${LinkStyles}
  transition: opacity 300ms ease-in-out;
  a {
    color: #006a4d;
  }

  ${breakpoint(BREAKPOINTS.tabletMax)`
    margin-left: 0px;
    margin-right: 0px;
  `}

  &:disabled{
    opacity: 0.35;
  }
`
export const CreatePDFButton = styled(StyledNavItem)`
  &:hover {
    text-decoration: underline;
  }
`
export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const UnsavedText = styled.p`
  font-style: italix;
  font-family: ${FONTS.PRIMARY};
  margin: 5px 0 0 31px;
  font-size: 16px;
`

export const StyledScenarioNameContainer = styled.div`
  display: flex;
`
