export interface Validator {
  isValid: (value?: string) => boolean
  errorMessage: (value?: string) => string
}

export const isRequired: Validator = {
  isValid: value => !!value,
  errorMessage: () => 'Required',
}

/**
 * Combine validation and return an error message on the first failure.
 */
export const combineValidators = (...validators: Validator[]) => (value?: string): string | undefined => {
  for (const v of validators) {
    if (!v.isValid(value)) {
      return v.errorMessage(value)
    }
  }
  return undefined
}
