import { ScenarioState } from './scenario.reducer'
import { createDeepEqualSelector } from '../selector_utils'
import { createSelector } from 'reselect'
import {
  CostComparisonLease,
  INITIAL_NPV_DISCOUNT_RATE,
  LeaseWithCalculations,
  MultiLeaseScenario,
  ScenarioLeases,
  ScenarioLeaseType,
  TermOptionsLease,
} from '../../data/models'
import { leaseToCostComparisonLease, leaseToTermOptionsLease } from '../../data/conversions'

import { createDefaultFlexibleLease, createDefaultTraditionalLease } from '../../data/lease_utils'
import {
  selectCalculationById,
  selectCompanyInputs,
  selectCompanyLocation,
} from '../calculations/calculation.selectors'
import { selectCurrencyOption, selectUnitOfMeasure } from '../unitCurrency/unitCurrency.selectors'
import { defaultMeetingRoomRequirement } from '../../utils/selectOptions/meetingRoomRequirements'
import isEqual from 'lodash/isEqual'
import { defaultLocations } from '../../optionsConfig/models'

export const selectScenarioState = (state: { scenario: ScenarioState }) => state.scenario

export const selectScenarioModel = createDeepEqualSelector(selectScenarioState,
  (state: ScenarioState) => state.scenario)

export const selectScenarioLoading = createSelector(selectScenarioModel, (state) => state.isLoading)

export const selectScenarioSuccess = createDeepEqualSelector(selectScenarioModel,
  (state) => state.optionalSuccess)

export const selectSavedScenario = createSelector(selectScenarioState, (state) => state.savedScenario)

export const selectSavedScenarioName = createSelector(selectSavedScenario, (state) => (state && state.scenarioName) || '')

export const selectHasScenarioChanged = createDeepEqualSelector(selectScenarioSuccess, selectSavedScenario, (success, saved) =>
  !isEqual(success, saved))

export const selectIsScenarioDuplicate = createDeepEqualSelector(selectScenarioSuccess, (state) => (state && state.isDuplicated) || false)

export const selectHasSavedScenario = createSelector(selectSavedScenario, (saved) => !!saved)

export const selectScenarioId = createSelector(selectScenarioSuccess, (scenario: MultiLeaseScenario | undefined) => (scenario && scenario._id) || '')

export const selectScenarioName = createSelector(selectScenarioSuccess, (scenario: MultiLeaseScenario | undefined) => (scenario && scenario.scenarioName) || '')

export const selectScenarioRevId = createSelector(selectScenarioSuccess, (scenario: MultiLeaseScenario | undefined) => (scenario && scenario._rev) || '')

export const selectScenarioLeases = createDeepEqualSelector(selectScenarioSuccess, (state: MultiLeaseScenario | undefined) =>
  (state && state.leases) || [])

export const selectEmailScenarioRequest = createDeepEqualSelector(selectScenarioState,
  (state: ScenarioState) => state.emailScenarioRequest)

export const selectEmailScenarioRequestLoading = createDeepEqualSelector(selectEmailScenarioRequest, (request) => request.isLoading)

/**
 * Checks if we have a scenario object. If it exists, we have calculated data.
 */
export const selectHasScenario = createSelector(selectScenarioModel, (scenario) => !!scenario.optionalSuccess)

export const selectScenarioPrintValue = createSelector(selectScenarioSuccess, (state) => (state && state.print) || 0)

export const selectScenarioEmpty = createSelector(selectScenarioModel, (scenario) => scenario.isEmpty())

export const selectNPVDiscountRate = createSelector(selectScenarioSuccess, (state: MultiLeaseScenario | undefined) =>
  (state && state.discountRate) || INITIAL_NPV_DISCOUNT_RATE)

export const selectSelectedLeaseState = createSelector(selectScenarioState, (state: ScenarioState) => state.selectedLease)
export const selectSelectedOriginalLease = createSelector(selectSelectedLeaseState, (state) => state.original)

export const selectFlexOverrideLease = createSelector(
  selectSelectedLeaseState,
  selectCompanyLocation,
  selectSelectedOriginalLease,
  (state, location, original) => state.flex
    || createDefaultFlexibleLease(location || defaultLocations[0], defaultMeetingRoomRequirement.value, '', original))

export const selectTradOverrideLease = createSelector(
  selectSelectedLeaseState,
  selectCompanyLocation,
  selectCompanyInputs,
  selectCurrencyOption,
  selectUnitOfMeasure,
  selectSelectedOriginalLease, (state, location, inputs, currencyOption, unitOfMeasure, original) =>
    state.trad || createDefaultTraditionalLease(location || defaultLocations[0], inputs, currencyOption, unitOfMeasure, '', original))

export const selectActiveLeaseTab = createSelector(selectSelectedLeaseState, (state) =>
  state.activeTab || ScenarioLeaseType.FlexCoworking)

export const selectUpdatedOverrideLease = createSelector(
  selectActiveLeaseTab,
  selectFlexOverrideLease,
  selectTradOverrideLease,
  (activeTab, flexOverride, tradOverride) => {
    switch (activeTab) {
      case ScenarioLeaseType.Traditional:
        return tradOverride
      case ScenarioLeaseType.FlexCoworking:
        return flexOverride
    }
  },
)

export const selectCanDeleteLeases = createSelector(selectScenarioLeases, (leases) =>
  // based on requirements, if leases are greater than 2, we can delete with confirmation. otherwise we
  // show the modal that resets them to home
  leases.length > 2)

export const selectCanAddLeases = createSelector(selectScenarioLeases, (leases) => leases.length < 10)

export const selectActiveLeaseHasChanged = createSelector(selectSelectedOriginalLease,
  selectActiveLeaseTab, selectTradOverrideLease, selectFlexOverrideLease, (original, activeTab, tradOverride, flexOverride) => {
    if (!original) {
      return false
    }
    switch (activeTab) {
      case ScenarioLeaseType.Traditional:
        return !isEqual(original, tradOverride)
      case ScenarioLeaseType.FlexCoworking:
        return !isEqual(original, flexOverride)
    }
  })

export const selectTermOptionsLeases = createSelector((state: any) => state, selectScenarioLeases,
  (state, leases) => {
    return leases.map<TermOptionsLease | undefined>(l => {
      const calculation = selectCalculationById(state)(l.id)
      return calculation && leaseToTermOptionsLease(l as ScenarioLeases, calculation as LeaseWithCalculations)
    }).filter(l => !!l) as TermOptionsLease[]
  })

export const selectCostComparisonLeases = createSelector((state: any) => state, selectScenarioLeases, selectCompanyInputs,
  (state, leases, inputs) => {
    if (!inputs) {
      return []
    }
    return leases.map<CostComparisonLease | undefined>(l => {
      const calculation = selectCalculationById(state)(l.id)
      return calculation && leaseToCostComparisonLease(inputs, l as ScenarioLeases, calculation as LeaseWithCalculations)
    }).filter(l => !!l) as CostComparisonLease[]
  })
