import styled from 'styled-components'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { HeadlineH5Default, TableMobileSmallAccent, UIDefault } from '../../../styles/text.styles'

export const StyledNonEditValue = styled.div`
  ${TableMobileSmallAccent}
`
export const NonEditField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoint(BREAKPOINTS.mobileMax)`
    margin-bottom: 24px;
  `}
`

export const StyledTitle = styled.div<{ disabledEdit: boolean }>`
  display: flex;
  align-items: center;
  padding-bottom: 4px;

  span {
    white-space: nowrap;
    margin-right: 4px;
    ${({ disabledEdit }) => disabledEdit ? HeadlineH5Default : UIDefault}
  }

  img {
    margin-left: 5px;
  }
`

export const StyledInputRelativeContainer = styled.div`
  position: relative;
`
