import React, { useState , useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
// Helpers
import { foundValue } from '../../utils/arrayHelpers'
import { isSafari } from '../../utils/isSafari'
import optionsConfig from '../../optionsConfig/locations.json'
// Styles
import * as Print from './styles'
// Assets
import logo from '../../static/images/cbreLogo.png'
import bg from '../../static/images/city.svg'

// Components
import Layout from '../../components/layout'

import { UNTITLED_SCENARIO_NAME } from '../../data/models'


import HeadcountProjection from '../../components/detailedresults/headcountProjection'
import PrintCostSummary from '../../components/printComparison/printCostSummary'
import PrintTermOption from '../../components/printTermOptions/printTermOptions'

import { selectMeasureName } from '../../redux/unitCurrency/unitCurrency.selectors'
import { selectHasScenario } from '../../redux/scenario/scenario.selectors'
import { selectCompanyInputs, selectCalculationResultsLoading } from '../../redux/calculations/calculation.selectors'

import { saveScenario } from '../../redux/scenario/scenario.actions'
import { useTimeout } from '../../utils/hooks/hooks'
import { RouteComponentProps } from '@reach/router'
import { CompanyLocation } from '../../optionsConfig/models'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps<any>

const renderError = () => {
  return (
    <Layout>
      <Print.ErrorContainer>
        <Print.ErrorText>Whoops, no scenario found. Redirecting you back to CALC...</Print.ErrorText>
        <Print.ErrorImage src={bg}/>
      </Print.ErrorContainer>
    </Layout>
  )
}

export const InternalPrintableVersion = (
  {
    companyInputs: { initialSeats, initialDesiredLeaseTerm, annualSeatChange, location: companyLocation },
    measureName,
    hasScenario,
    history,
    saveScenario,
    loading,
  }: Props) => {
  const seatPercentage = annualSeatChange * 100
  const company: CompanyLocation = foundValue(optionsConfig.locations, companyLocation)
  const increaseDecreaseWord = seatPercentage < 0 ? 'contraction' : 'growth'
  const setWidth = 570
  const [hasError, setError] = useState(false)
  const [hasPrinted, setPrinted] = useState(false)

  useEffect(() => {
    if(!loading){
      if(!hasScenario || !companyLocation) {
        setError(true)
      }else{
        setError(false)
        saveScenario(hasScenario ? {} : { scenarioName: UNTITLED_SCENARIO_NAME }, true)
          // prevent triggering every render after the first thime
          !hasPrinted && window.print()
          setPrinted(true)
      }
    }
  },[hasScenario, companyLocation, loading, hasPrinted, setPrinted, saveScenario]);

  useTimeout(4000, () => {
    if (hasError) {
      history.push('/calc')
    }
  }, [hasError])

  if (hasError) {
    return renderError()
  }

  return <Layout>
    <Print.PrintStyles />
    <Print.Container>
      <div>
        <Print.LogoContainer isSafari={isSafari}>
          <Print.Image src={logo} />
          <Print.Subtext>Agile Lease Calculator</Print.Subtext>
        </Print.LogoContainer>
        <Print.TextContainer>
          <h2>Quickly and accurately compare the cost of standard leases and coworking spaces.</h2>
        </Print.TextContainer>
        <Print.Line />
        <Print.FactContainer>
          <p>
            Your company of <Print.Emphasized>{initialSeats} employees</Print.Emphasized> is seeking
            a<br /> <Print.Emphasized>{initialDesiredLeaseTerm}-year
            lease</Print.Emphasized> in <Print.Emphasized>{company && company.city}</Print.Emphasized> with an
            <Print.Emphasized> anticipated {Math.abs(Number(seatPercentage))}% annual
              headcount {increaseDecreaseWord}.</Print.Emphasized>
          </p>
        </Print.FactContainer>
      </div>
      <div>
        <PrintCostSummary measureName={measureName} />
      </div>
    </Print.Container>
    <Print.Container>
      <Print.SeatContainer>
        <Print.SectionTitle>Analyze lease term options ranging from one to ten years.</Print.SectionTitle>
      </Print.SeatContainer>
      <Print.ChartContainer>
        <PrintTermOption
          // safari doesnt allow disabling print header and footer so graphs are shortened
          chartHeight={isSafari ? 260 : 300}
          chartWidth={setWidth}
        />
      </Print.ChartContainer>
      <Print.HeadcountContainer>
        <Print.SectionTitle>Project your headcount over time.</Print.SectionTitle>
      </Print.HeadcountContainer>
      <Print.ChartContainer>
        <HeadcountProjection
          isPrint
          chartHeight={210}
          chartWidth={setWidth}
        />
      </Print.ChartContainer>
      <Print.StyledFooterText isSafari={isSafari}>CBRE’s Agile Leasing Calculator (“Calc”) is provided “as is” and “as
        available” with no
        warranties. All results generated from your use of Calc are for illustrative purposes only, and do not reflect
        actual costs or lease terms. Actual costs and lease terms, whether a standard lease or flexible space, may
        vary greatly and will depend on many factors which Calc does not take into account. By using Calc or any
        report generated by the use of Calc, you accept our Terms of Service and Privacy Policy located respectively
        at the urls <a href="/termsofservice"
                       target="_blank"
                       rel="noopener noreferrer">https://calc.cbre.com/termsofservice</a> and <a href="https://www.cbre.us/about/privacy-policy"
                                                                                                 target="_blank"
                                                                                                 rel="noopener noreferrer">https://www.cbre.us/about/privacy-policy</a>.
        © 2018 CBRE, Inc. All rights reserved.
      </Print.StyledFooterText>
    </Print.Container>
  </Layout>
}

const mapStateToProps = (state: any) => ({
  hasScenario: selectHasScenario(state),
  measureName: selectMeasureName(state),
  companyInputs: selectCompanyInputs(state),
  loading: selectCalculationResultsLoading(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => (bindActionCreators({ saveScenario }, dispatch))

export const PrintableVersion = withRouter(connect<StateProps, DispatchProps, any>(mapStateToProps, mapDispatchToProps)(InternalPrintableVersion))
