import React from 'react'
import { Tooltip } from '../tooltip'
import { StyledTitle } from './styles'

interface Props {
  tooltipText?: string | null
  text: string

  /**
   * If true, this is displaying in disabled mode for fields that do not have editing capabilities.
   */
  disabledEdit?: boolean

  /**
   * Give a unique ID
   */
  id: string
}

export const InputTitle = ({ text, tooltipText = null, disabledEdit = false, id }: Props) => (
  <StyledTitle disabledEdit={disabledEdit}>
    <span id={id}>{text}</span>
    {tooltipText &&
    <Tooltip
      title={tooltipText}
      id={`${id}--tooltip`} />}
  </StyledTitle>
)
