export enum MediaBreakpointType {
  Min = 'min',
  Max = 'max'
}

export enum MediaBreakpointDirection {
  Height = 'height',
  Width = 'width'
}

export interface MediaBreakpoint {
  value: number
  type: MediaBreakpointType
  direction: MediaBreakpointDirection
}

export const BREAKPOINTS = {
  /*
   * Min BPS
   */
  mobileMin: {
    value: 576,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Width
  },
  tabletMin: {
    value: 768,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Width
  },
  // targeting devices that are LARGER than the iPad (which is 1024px in landscape mode)
  desktopMin: {
    value: 1025,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Width
  },
  // Targeting screen sizes that are very wide (Large Mac monitors widers than 1440px)
  ultraWideMin: {
    value: 1441,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Width
  },

  /*
   * Max BPS
   */
  // targeting small width phones below width of an iPhone6
  smallMobileMax: {
    value: 395,
    type: MediaBreakpointType.Max,
    direction: MediaBreakpointDirection.Width
  },

  mobileMax: {
    value: 577,
    type: MediaBreakpointType.Max,
    direction: MediaBreakpointDirection.Width
  },
  tabletMax: {
    value: 767,
    type: MediaBreakpointType.Max,
    direction: MediaBreakpointDirection.Width
  },
  desktopMax: {
    value: 1024,
    type: MediaBreakpointType.Max,
    direction: MediaBreakpointDirection.Width
  },
  ultraWideMax: {
    value: 1441,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Width
  },
  cbreMax: {
    value: 915,
    type: MediaBreakpointType.Max,
    direction: MediaBreakpointDirection.Width
  },
  cbreMin: {
    value: 916,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Width
  },
  introExtraLargeMin: {
    value: 1030,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Height
  },
  introLargeMin: {
    value: 960,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Height
  },
  introMediumMin: {
    value: 850,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Height
  },
  introSmallMin: {
    value: 800,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Height
  },
  introExtraSmallMin: {
    value: 768,
    type: MediaBreakpointType.Min,
    direction: MediaBreakpointDirection.Height
  }
}
