import Highcharts, { AxisLabelFormatterOptions, BarChartSeriesOptions } from 'highcharts'
import { COLORS, FONTS } from '../../../../constants/styles'
import { PCCalcResults, TermOptionsLease } from '../../../../data/models'
import { leaseTypeToShortName } from '../../../../data/conversions'

import { abbNumber } from '../../../../utils'


export const termOptionsGraphOptions = (termLabels: string[],
                                        initialDesiredLeaseTerm: number,
                                        lease1: TermOptionsLease,
                                        firstValue: PCCalcResults,
                                        chartHeight: number,
                                        chartWidth: number | undefined,
                                        lease2?: TermOptionsLease,
                                        secondValue?: PCCalcResults,
                                        isPrint?: boolean
                                        ): Highcharts.Options => {
  const series = [
    {
      name: leaseTypeToShortName(lease1),
      data: firstValue.avg,
      color: COLORS.DARK_01,
    },
  ] as BarChartSeriesOptions[]
  if (lease2 && secondValue) {
    series.push({
      name: leaseTypeToShortName(lease2),
      data: secondValue.avg,
      color: COLORS.DARK_02,
    })
  }
  return {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    chart: {
      height: chartHeight,
      width: chartWidth,
      pinchType: '',
      zoomType: '',
      type: 'column',
      style: {
        fontFamily: FONTS.PRIMARY,
        fontSize: '16px',
      },
    },
    title: {
      text: '',
    },
    plotOptions: {
      bar: {
        pointWidth: 35,
      },
      series: {
        animation: !isPrint,
      },
    },
    xAxis: [{
      categories: termLabels,
      crosshair: true,
      labels: {
        style: {
          color: COLORS.DARK_01,
          fontFamily: FONTS.PRIMARY,
          fontSize: isPrint ? '9px' :'12px',
        },
        rotation: -90,
      },
      plotBands: [{
        color: COLORS.SECONDARY_01,
        from: initialDesiredLeaseTerm - 1.5,
        to: initialDesiredLeaseTerm - 0.5,
      }],
    }],
    yAxis: [{ // Primary yAxis
      // min: 0,
      labels: {
        format: '{value}',
        formatter: function (this: AxisLabelFormatterOptions) {
          return abbNumber(this.value)
        },
        style: {
          color: COLORS.DARK_01,
          fontFamily: FONTS.PRIMARY,
          fontSize: '14px',
        },
      },
      title: {
        text: null,
      },
    }],
    tooltip: {
      enabled: !isPrint,
      followTouchMove: false,
      shared: true,
      followPointer: true,
    },
    series,
  }
}
