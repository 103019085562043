import styled, { css } from 'styled-components'
import { HeadlineH4Default } from '../../../styles/text.styles'
import { COLORS } from '../../../constants/styles'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'


export const StyledLegendLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface StyledColorRectProps {
  color: string,
  isPrint?: boolean | undefined
}

export const StyledColorRect = styled.div<StyledColorRectProps>`
  background-color: ${({ color }) => color};
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  ${({ isPrint }: any) => css`
    ${isPrint ? `
    width: 14px;
    height: 14px;
    ` : `
    width: 19px;
    height: 19px;
    `}
  `}
  border-radius: 2px;
`

interface StyledLegendLabelProps {
  isPrint?: boolean | undefined
}

export const StyledLegendLabel = styled.span<StyledLegendLabelProps>`
  margin-left: 10px;
  ${HeadlineH4Default}
  font-weight: bold;
  color: ${COLORS.DARK_01};

  ${breakpoint(BREAKPOINTS.mobileMax)`
    font-size: 13px;
  `}
  ${({ isPrint }) => isPrint &&`font-size: 11px;`};
`
