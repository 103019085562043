const clearInput = (e) => {
  e.target.value = ''
}

export const inputSelectAll = (e) => {
  e.target.select()
  if (e.target.value === '0') {
    clearInput(e)
  }
}
