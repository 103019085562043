/* eslint-disable react-hooks/exhaustive-deps */
// Hooks
import { DependencyList, useEffect, useState, useRef } from 'react'
import { innerWidthCalculation } from '../documentUtils'
import { timeoutHandler } from '../windowUtils'

import { BREAKPOINTS, MediaBreakpoint } from '../../constants/breakpoints'
import { useMediaQuery } from 'react-responsive'

export const useKeyPress = (targetKeys: string[], callback: (e: KeyboardEvent) => void, deps?: DependencyList) => {
  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    const downHandler = (e: KeyboardEvent) => {
      if (targetKeys.find(t => t === e.key)) {
        callback(e)
      }
    }

    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [
    targetKeys,
    callback,
    ...(deps || []),
  ]) // Empty array ensures that effect is only run on mount and unmount
}

/**
 * Schedules a timeout that can be cleared.
*/

export const useTimeout = <T>(timeout: number, callback: () => void, deps?: DependencyList) => {
  const savedCallback = useRef<() => void>()
  const savedTimer = useRef<undefined | number>();
  useEffect(
    () => {
      savedCallback.current = callback
    },
    [callback]
  )
  useEffect(() => {
    const timerID = timeoutHandler.setTimeout(savedCallback.current!, timeout);
    savedTimer.current = timerID;

    // cleans the timer identified by timerID when the effect is unmounted.
    return () => timeoutHandler.clearTimeout(timerID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  // clear timeout
  return timeoutHandler.clearTimeout(savedTimer.current);
}

/**
 * Sets up a resize event with a callback reporting client width
 */
export const useWindowResize = (callback: (width: number) => void, deps?: DependencyList) => useEffect(() => {
  const innerCallback = () => {
    callback(innerWidthCalculation())
  }
  window.addEventListener('resize', innerCallback)
  // run an initial calculation
  innerCallback()
  return () => {
    window.removeEventListener('resize', innerCallback)
  }
}, [
  callback,
  ...(deps || []),
])

/**
 * Sets up a resize event for desktop
 */
export const useWindowResizeDesktop = (callback: (newWidth: number) => void, deps?: DependencyList) => {
  const [width, setWidth] = useState(innerWidthCalculation())
  const [desktopWidth, setDesktopWidth] = useState(width > BREAKPOINTS.cbreMax.value)

  const innerCallback = (newWidth: number) => {
    const newDesktopWidth = newWidth > BREAKPOINTS.cbreMax.value
    if (desktopWidth !== newDesktopWidth) {
      setDesktopWidth(newDesktopWidth)
      setWidth(newWidth)
      callback(newWidth)
    }
  }
  useWindowResize(innerCallback, deps)
}

/**
 * Convenience wrapper around the {@link useMediaQuery} method that takes in a {@link MediaBreakpoint}
 */
export const useBreakpoint = (breakpoint: MediaBreakpoint) =>
  useMediaQuery({ query: `(${breakpoint.type}-${breakpoint.direction}: ${breakpoint.value}px)` })

  /**
 * Convenience hook that returns the last value of something
 */
export const usePrevious = <T>(value: T) => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

/**
 * Convenience wrapper around the {@link useBreakpoint} method that takes in an optional height and returns a height
 */

export const useChartHeight = (setHeight?: number): number => {
  const isMedium = useBreakpoint(BREAKPOINTS.introMediumMin)
  const isLarge = useBreakpoint(BREAKPOINTS.introLargeMin)
  if (setHeight) {
    return setHeight
  }else if (isMedium) {
    return 476
  } else if (isLarge) {
    return 576
  }
  return 420
}
