import styled from 'styled-components'
import { Dropdown } from '../common/dropdown/dropdown'
import { TableAccent } from '../../styles/text.styles'

export const StyledForm = styled.form`
  text-align: left;
  padding-top: 20px;
`

export const StyledDropdownWrapper = styled(Dropdown)<{ isLast?: boolean }>`
  margin-bottom: ${({ isLast }) => isLast ? '22px' : '36px'};
  width: 280px;
`

export const StyledUnitsCurrencyWarning = styled.span`
  ${TableAccent}
  display: block;
  max-width: 85%;
  padding-bottom: 5px;
`
