import { LoadingModel } from '../../data/loading_model'
import {
  MultiLeaseScenario,
  ScenarioLease,
  ScenarioLeaseFlexCoworking,
  ScenarioLeases,
  ScenarioLeaseTraditional,
  ScenarioLeaseType,
} from '../../data/models'
import { ScenarioActions, ScenarioActionTypes } from './scenario.actions'

export interface ScenarioState {
  scenario: LoadingModel<MultiLeaseScenario>

  /**
   * If a scenario is saved, it goes here. It is used as reference between {@link scenario} to determine changes.
   */
  savedScenario?: MultiLeaseScenario

  // this represents the WIP state when editing a lease.
  selectedLease: {
    original?: ScenarioLeases
    trad?: ScenarioLeaseTraditional
    flex?: ScenarioLeaseFlexCoworking
    activeTab?: ScenarioLeaseType
  }
  emailScenarioRequest: LoadingModel
}

export const defaultScenarioState: ScenarioState = {
  scenario: LoadingModel.empty(),
  emailScenarioRequest: LoadingModel.empty(),
  selectedLease: {},
}

export const scenarioReducer = (state: ScenarioState = defaultScenarioState, action: ScenarioActionTypes): ScenarioState => {
  switch (action.type) {
    case ScenarioActions.SaveScenario:
    case ScenarioActions.DuplicateScenario:
    case ScenarioActions.GetScenarioById:
      return {
        ...state,
        scenario: state.scenario.loading(),
      }
    case ScenarioActions.SaveScenarioFailed:
    case ScenarioActions.DuplicateScenarioFailed:
    case ScenarioActions.GetScenarioByIdFailed:
      return {
        ...state,
        scenario: LoadingModel.error(action.error, state.scenario.optionalSuccess),
      }
    case ScenarioActions.SaveScenarioSucceeded:
    case ScenarioActions.DuplicateScenarioSucceeded:
    case ScenarioActions.GetScenarioByIdSucceeded:
      return {
        ...state,
        scenario: LoadingModel.success(action.scenario),
      }
    case ScenarioActions.PutNewScenario:
      const firstLease = action.scenario.leases[0] as ScenarioLeases
      // new scenario, we also pick the first lease as selected one.
      return {
        ...state,
        scenario: LoadingModel.success(action.scenario),
        selectedLease: {
          original: firstLease,
          trad: (firstLease.type === ScenarioLeaseType.Traditional && firstLease) || undefined,
          flex: (firstLease.type === ScenarioLeaseType.FlexCoworking && firstLease) || undefined,
        },
      }
    case ScenarioActions.AddLeaseLocally:
      return {
        ...state,
        scenario: LoadingModel.success<MultiLeaseScenario>({
          ...state.scenario.success,
          leases: [
            ...state.scenario.success.leases,
            action.lease,
          ],
        }),
      }
    case ScenarioActions.RemoveLeaseLocally:
      return {
        ...state,
        scenario: LoadingModel.success<MultiLeaseScenario>({
          ...state.scenario.success,
          leases: state.scenario.success.leases.filter(l => l.id !== action.leaseId),
        }),
      }
    case ScenarioActions.EmailScenario:
      return {
        ...state,
        emailScenarioRequest: state.emailScenarioRequest.loading(),
      }
    case ScenarioActions.EmailScenarioSucceeded:
      return {
        ...state,
        scenario: LoadingModel.success({
          ...state.scenario.success,
          ...action.scenario,
        }),
        emailScenarioRequest: LoadingModel.success(),
        savedScenario: {
          ...state.savedScenario,
          ...action.scenario,
        },
      }
    case ScenarioActions.EmailScenarioFailed:
      return {
        ...state,
        emailScenarioRequest: LoadingModel.error(action.error),
      }
    case ScenarioActions.UpdateLocalScenario: {
      let scenarioToUpdate = state.scenario.optionalSuccess
      // only if it exists will we update it
      if (scenarioToUpdate) {
        scenarioToUpdate = {
          ...scenarioToUpdate,
          ...action.scenario,
        }
      }
      return {
        ...state,
        // preserve existing error / success state since this is local.
        scenario: state.scenario.isSuccess()
          ? LoadingModel.success(scenarioToUpdate)
          : LoadingModel.error(state.scenario.error, scenarioToUpdate),
      }
    }
    case ScenarioActions.UpdateLocalLease: {
      if (action.existing.type === ScenarioLeaseType.FlexCoworking) {
        return {
          ...state,
          selectedLease: {
            ...state.selectedLease,
            flex: {
              ...action.existing,
              ...action.updateValues as Partial<ScenarioLeaseFlexCoworking>,
            },
          },
        }
      } else if (action.existing.type === ScenarioLeaseType.Traditional) {
        return {
          ...state,
          selectedLease: {
            ...state.selectedLease,
            trad: {
              ...action.existing,
              ...action.updateValues as Partial<ScenarioLeaseTraditional>,
            },
          },
        }
      }
      return { ...state }
    }
    case ScenarioActions.SelectLeaseLocally:
      return {
        ...state,
        selectedLease: {
          original: action.lease,
          trad: (action.lease.type === ScenarioLeaseType.Traditional && action.lease) || undefined,
          flex: (action.lease.type === ScenarioLeaseType.FlexCoworking && action.lease) || undefined,
          activeTab: action.lease.type,
        },
      }
    case ScenarioActions.ClearSelectedLease:
      return {
        ...state,
        selectedLease: {},
      }
    case ScenarioActions.SelectLeaseType:
      return {
        ...state,
        selectedLease: {
          ...state.selectedLease,
          activeTab: action.leaseType,
        },
      }
    case ScenarioActions.SaveLeaseChanges: {
      return {
        ...state,
        scenario: LoadingModel.success({
          ...state.scenario.success,
          leases: state.scenario.success.leases.map<ScenarioLease>(l => {
            if (l.id === action.lease.id) {
              return action.lease
            }
            return l
          }) as readonly ScenarioLease[],
        }),
      }
    }
    case ScenarioActions.ClearCurrentScenario:
      return {
        ...state,
        scenario: LoadingModel.empty(),
        selectedLease: defaultScenarioState.selectedLease,
        savedScenario: undefined,
      }
    case ScenarioActions.StoreSavedScenario:
      return {
        ...state,
        savedScenario: action.scenario,
      }
  }
  return state
}
