import styled from 'styled-components'
import { FONTS } from '../../../constants/styles'
import PencilIcon from '../nav/assets/pencil.svg'
import PencilIconActive from '../nav/assets/pencil-active.svg'
import breakpoint from '../../../utils/breakpoints'

export const StyledScenarioName = styled.p<{ isEditing: boolean, isReadOnly: boolean, isPrintPage: boolean }>`
  font-size: 14px;
  font-family: ${FONTS.PRIMARY};
  margin: 1px 0 0 35px;
  cursor: pointer;
  
  &:after {
    content: "";
    background-image: url(${({ isEditing }) => (isEditing ? PencilIcon : PencilIconActive)});
    display: ${({ isReadOnly, isPrintPage }) => (isReadOnly || isPrintPage ? 'none' : 'inline-block')};
    margin-left: 4px;
    width: 16px;
    height: 16px;
    background-size: contain;
    position: relative;
    top: 3px;
  }

  &:hover {
    &:after {
      content: "";
      background-image: url(${PencilIconActive});
    }
  }

  ${breakpoint('cbreMax')`
    display: none;
  `}
`
