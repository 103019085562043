import React from 'react'
import MediaQuery from 'react-responsive'
import {
  StyledCloseContainer,
  StyledContentColumn,
  StyledResultBold,
  StyledResultNormal,
  StyledSummaryCardContainer,
  StyledSummaryContainer,
} from './styles'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { abbNumber } from '../../../utils'
import { Close } from '../../common/icons'
import { ScenarioLeaseType } from '../../../data/models'
import { scenarioLeaseTypeToUIName } from '../../../data/conversions'

interface Props {
  leaseName: string
  npv?: number
  leaseType: ScenarioLeaseType
  isSelected: boolean
  currencySymbol: string
  onClose: () => void
  className?: string
  onClick: () => void
}

const ResultsSummaryCard = ({ leaseName, npv, leaseType, isSelected, currencySymbol, onClose, className, onClick }: Props) => {
  const displayPrice = (npv && `${currencySymbol}${abbNumber(npv, 1)}`) || '--'
  return (
    <StyledSummaryCardContainer
      // loading if NPV not defined, we're assuming its calculating.
      isLoading={!npv}
      className={className}
      onClick={onClick}>
      <StyledCloseContainer>
        <button
          onClick={(event: any) => {
            event.stopPropagation()
            onClose()
          }}
          aria-label="close"
        >
          <Close />
        </button>
      </StyledCloseContainer>
      <StyledSummaryContainer isSelected={isSelected}>
        <MediaQuery minWidth={BREAKPOINTS.desktopMin.value}>
          <StyledContentColumn>
            <StyledResultNormal>{scenarioLeaseTypeToUIName(leaseType)}</StyledResultNormal>
            <StyledResultBold>{leaseName}</StyledResultBold>
          </StyledContentColumn>
          <StyledContentColumn>
            <StyledResultNormal>Total Average Cost (NPV)</StyledResultNormal>
            <StyledResultBold>{displayPrice}</StyledResultBold>
          </StyledContentColumn>
        </MediaQuery>
        <MediaQuery maxWidth={BREAKPOINTS.desktopMax.value}>
          <StyledContentColumn>
            <StyledResultNormal>{scenarioLeaseTypeToUIName(leaseType)}</StyledResultNormal>
            <StyledResultBold>{displayPrice}</StyledResultBold>
            <StyledResultNormal>{leaseName}</StyledResultNormal>
          </StyledContentColumn>
        </MediaQuery>

      </StyledSummaryContainer>
    </StyledSummaryCardContainer>)
}

export { ResultsSummaryCard }
