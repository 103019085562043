import styled, { css } from 'styled-components'
import { Tabs } from 'antd'

import {
  COLORS,
  FONTS,
  ORDER,
  LAYOUT
} from '../../constants/styles'
// Utils
import breakpoint from '../../utils/breakpoints'
import { BREAKPOINTS } from '../../constants/breakpoints'
import { TabTitle } from '../../styles/text.styles'

const { TabPane } = Tabs

const borderWidth = 1;
const tabContentOffsetDesktop = LAYOUT.TABSHEIGHT + LAYOUT.HEADERHEIGHT + borderWidth

export const StyledTabs = styled(Tabs)`
  width: 100%;
  min-height: fill-available;
  padding-top: 14px;
  .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
    background-color: transparent !important;
  }

  .ant-tabs-bar {
    display: none !important;
  }

  ${breakpoint(BREAKPOINTS.mobileMin)`
    margin-top: ${LAYOUT.TABSHEIGHT + LAYOUT.MOBILE_HEADERHEIGHT + borderWidth}px;
  `}

  ${breakpoint(BREAKPOINTS.tabletMin)`
    padding-top: 0px;
  `}

  ${breakpoint(BREAKPOINTS.tabletMin)`
    margin-top: ${tabContentOffsetDesktop}px;
    min-height: unset;
  `}
`

export const TabStyles = css`
  padding: 0 ${LAYOUT.MOBILE_GUTTER}px;
  ${breakpoint(BREAKPOINTS.tabletMin)`
    padding: 0 ${LAYOUT.GUTTER}px;
    padding-top: ${LAYOUT.TAB_CONTENT_TOP_PADDING}px;
  `}

`

export const StyledResultsTabPane = styled(TabPane)`
  ${TabStyles}
`
export const StyledLeaseVariablesTabPane = styled(TabPane)`
  ${TabStyles}
  ${breakpoint(BREAKPOINTS.tabletMin)`
    padding-right: ${LAYOUT.GUTTER - LAYOUT.SCROLLBAR_WIDTH}px;
  `}
`

export const StyledIntroTabPane = styled(TabPane)`
  ${TabStyles}
  ${breakpoint(BREAKPOINTS.mobileMax)`
    overflow: visible;
    min-height: -webkit-fill-available;
  `}
`

export const StyledTabButton = styled.button<{isActive: boolean}>`
  border: none;
  background: none;
  padding: 4px 10px 9px 10px;
  width: 104px;
  cursor: pointer;
  h3, span{
    color: ${COLORS.PRIMARY_00};
  }
  &:focus-within, &:hover {
    h3, span{
      color: ${COLORS.PRIMARY_01};
    }
  }
  &:focus{
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    h3, span{
      opacity: 0.3 !important;
      color: ${COLORS.DARK_01} !important;
    }
  }
  ${({ isActive }) => css`
    border-bottom: 2px solid ${isActive ? COLORS.PRIMARY_00 : 'transparent'};
  `}

  transition: border-bottom 300ms ease-in-out, color 300ms ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    flex-direction: column;
    align-items: center;
    padding: 12px 2px 4px 2px;
  `}


  span {
    font-family: ${FONTS.PRIMARY};
    font-size: 39px;
    font-weight: bold;
    line-height: 1;
    ${breakpoint(BREAKPOINTS.mobileMax)`
      font-size: 16px;
  `}
  }

  h3 {
    max-width: 66px;
    text-align: left;
    margin: 0 0 0 8px ;
    ${TabTitle}

    ${breakpoint(BREAKPOINTS.mobileMax)`
      font-size: 11px;
      font-weight: 500;
      max-width: unset;
      margin-left: 0;
  `}
  }
`
const lineSpacing = 31;
export const StyledTabNavTabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid ${borderWidth}px ${COLORS.GRAYLINES};
  ${breakpoint(BREAKPOINTS.mobileMin)`
    min-width: 440px;
  `}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    margin-right: ${lineSpacing}px;
    max-width: calc(${LAYOUT.LEASE_VARIABLES_LEFT_MAX_WIDTH}px - ${lineSpacing}px);
  `}

`

export const StyledTabNavSpacer = styled.div`
  min-width: ${LAYOUT.LEASE_VARIABLES_RIGHT_MAX_WIDTH}px;
  width: 100%;
  ${breakpoint(BREAKPOINTS.tabletMax)`
    display: none;
  `}
`

export const StyledTabNav = styled.div`
  display: flex;
  flex-direction: row;
  top: 0;
  position: relative;
  width: 100%;
  z-index: ${ORDER.TABS};
  background-color: ${COLORS.WHITE};
  padding: 12px ${LAYOUT.GUTTER}px 0;

  ${breakpoint(BREAKPOINTS.mobileMax)`
    padding: 0 ${LAYOUT.MOBILE_GUTTER}px;
  `}
  ${breakpoint(BREAKPOINTS.mobileMin)`
    position: fixed;
    top: ${LAYOUT.MOBILE_HEADERHEIGHT}px;
  `}
  ${breakpoint(BREAKPOINTS.tabletMin)`
    top: ${LAYOUT.HEADERHEIGHT}px;
  `}
`

export const StyledTab = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`
