import React from 'react'
import { TermOptionsLease } from '../../../../data/models'
import { StyledDropdownWrapper, StyledLeaseComparisonDropdownContainer } from './styles'
import { OptionValue } from '../../../../utils/selectOptions/optionValues'
import { leaseTypeToShortName } from '../../../../data/conversions'
import { SetState } from '../../../../utils/state_types'

interface Props {
  leases: readonly TermOptionsLease[]
  selectedLease1: SetState<TermOptionsLease>
  selectedLease2: SetState<TermOptionsLease>
  className?: string
}

const dropdownForLeaseOption = (
  num: number,
  [lease, setLease]: SetState<TermOptionsLease>,
  filteredLeases: readonly TermOptionsLease[]) => <StyledDropdownWrapper
    id={`leaseComparison-${num}`}
    label={`Lease ${num}`}
    optionsArray={filteredLeases.map<OptionValue>(l => ({
      optionName: leaseTypeToShortName(l),
      value: l.id,
    }))}
    value={lease.id}
    onChange={(value: any) => setLease(filteredLeases.find(l => l.id === value)!)}
  />

export const LeaseComparisonDropdown = (
  {
    leases,
    selectedLease1,
    selectedLease2,
    className,
  }: Props) => {
  return <StyledLeaseComparisonDropdownContainer className={className}>
    {dropdownForLeaseOption(1, selectedLease1, leases.filter(l => !selectedLease2[0] || l.id !== selectedLease2[0].id))}
    {dropdownForLeaseOption(2, selectedLease2, leases.filter(l => l.id !== selectedLease1[0].id))}
  </StyledLeaseComparisonDropdownContainer>
}
