import React from 'react'

interface Props {
  width?: number
  height?: number
}

export const StandardLease1 = ({ width = 94, height = 83 }: Props) =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width={width}
       height={height}
       viewBox="0 0 94 83">
    <g fill="none"
       fillRule="evenodd">
      <path fill="#AAB7B2"
            fillRule="nonzero"
            d="M14.402 0h58v82h-58z" />
      <path fill="#FFF"
            d="M21.402 8h10v17h-10zM39.402 8h10v17h-10zM56.402 8h10v17h-10zM21.402 33h10v17h-10zM39.402 33h10v17h-10zM56.402 33h10v17h-10zM21.402 57h10v17h-10zM39.402 57h10v17h-10zM56.402 57h10v17h-10z" />
      <path fill="#D5F0E5"
            fillRule="nonzero"
            d="M27.402 23v30h-18V23z" />
      <path fill="#009F6F"
            fillRule="nonzero"
            d="M16.758 20.427c-2.412-1.99-5.749-1.89-8.059.244-2.31 2.134-2.985 5.74-1.631 8.708l-.763 1.899-1.328-.163-1.254 3.988H2.395l-.935 3.01-1.058 1.655L.968 44l3.837-.624 7.034-10.118c2.964.546 5.88-1.222 7.05-4.275 1.17-3.052.289-6.591-2.13-8.556zm-1.599 5.724c-.27.425-.688.708-1.154.78a1.586 1.586 0 0 1-1.305-.4c-.36-.288-.6-.723-.665-1.209-.065-.485.05-.979.32-1.368.262-.437.68-.73 1.148-.808.47-.078.946.068 1.312.4.742.618.896 1.78.344 2.605z" />
      <path stroke="#505958"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12.402 41.5h10M12.402 46.5h10" />
      <circle cx="81.402"
              cy="60"
              r="12"
              fill="#009F6F"
              fillRule="nonzero" />
      <path stroke="#AAB7B2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M80.902 51v31M77.402 61l3 5M81.402 59l3-3" />
    </g>
  </svg>

export const StandardLease2 = ({ width = 94, height = 82 }: Props) =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width={width}
       height={height}
       viewBox="0 0 94 82">
    <g fill="none"
       fillRule="evenodd">
      <path fill="#D5F0E5"
            fillRule="nonzero"
            d="M49 0h45v82H49z" />
      <path fill="#009F6F"
            fillRule="nonzero"
            d="M17 82h32V0L17 21.082z" />
      <path stroke="#505958"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M86 13.5H57M86 27.5H57M86 43.5H57M86 58.5H57M86 72.5H57" />
      <path fill="#D5F0E5"
            fillRule="nonzero"
            d="M28.5 22.5v30h-19v-30z" />
      <path fill="#505958"
            fillRule="nonzero"
            d="M17.216 21.376a6.78 6.78 0 0 0-8.482.235c-2.432 2.058-3.143 5.534-1.718 8.396l-.803 1.805-1.398-.131-1.32 3.819H2.097l-.984 2.929L0 39.999.595 44l4.04-.628 7.404-9.73c3.12.527 6.189-1.178 7.42-4.12 1.233-2.944.305-6.356-2.243-8.25v.104zm-1.682 5.519a1.799 1.799 0 0 1-2.589.366 1.786 1.786 0 0 1 .32-3.064 1.746 1.746 0 0 1 1.75.187c.401.262.68.675.778 1.146a1.82 1.82 0 0 1-.26 1.365z" />
      <path stroke="#505958"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14 42.5h10M14 46.5h10" />
    </g>
  </svg>

export const StandardLease3 = ({ width = 94, height = 82 }: Props) => {
  return <svg xmlns="http://www.w3.org/2000/svg"
              width={width}
              height={height}
              viewBox="0 0 94 82">
    <g fill="none"
       fillRule="evenodd">
      <path fill="#D5F0E5"
            fillRule="nonzero"
            d="M17 0h60v82H17z" />
      <path fill="#FFF"
            fillRule="nonzero"
            d="M26 9h11v12H26zM41 9h11v12H41zM57 9h11v12H57zM26 26h11v12H26zM41 26h11v12H41zM57 26h11v12H57zM26 44h11v12H26zM41 44h11v12H41zM57 44h11v12H57zM26 61h11v12H26zM41 61h11v12H41zM57 61h11v12H57z" />
      <path fill="#009F6F"
            fillRule="nonzero"
            d="M29.5 22.5v31h-20v-31z" />
      <path fill="#AAB7B2"
            fillRule="nonzero"
            d="M18.077 22.427a7.188 7.188 0 0 0-8.907.244 7.151 7.151 0 0 0-1.803 8.708l-.843 1.899-1.468-.163-1.386 3.988H2.202l-1.033 3.01L0 41.769.625 46l4.24-.651 7.776-10.091a7.18 7.18 0 0 0 7.792-4.275 7.152 7.152 0 0 0-2.356-8.556zm-1.767 5.724a1.876 1.876 0 0 1-2.718.38 1.844 1.844 0 0 1-.38-2.713 1.879 1.879 0 0 1 2.495-.227 1.87 1.87 0 0 1 .495 2.451l.108.109z" />
      <path stroke="#505958"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 42.5h10M15 46.5h10" />
      <rect width="22"
            height="37"
            x="72"
            y="30"
            fill="#7C8C88"
            fillRule="nonzero"
            rx="4.07" />
      <path stroke="#AAB7B2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M82.5 42v39.5M78 54l4 7M83 51l5-5" />
    </g>
  </svg>
}

export const StandardLease4 = ({ width = 94, height = 82 }: Props) =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width={width}
       height={height}
       viewBox="0 0 94 82">
    <g fill="none"
       fillRule="evenodd">
      <circle cx="83.5"
              cy="61.5"
              r="10.5"
              fill="#D5F0E5"
              fillRule="nonzero" />
      <circle cx="83"
              cy="50"
              r="8"
              fill="#D5F0E5"
              fillRule="nonzero" />
      <path stroke="#AAB7B2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M83.5 55v26.5M80 63l3 4M84 61l4-4" />
      <path fill="#009F6F"
            fillRule="nonzero"
            d="M21 0h23v82H21z" />
      <path fill="#7C8C88"
            fillRule="nonzero"
            d="M44 14h29v68H44z" />
      <path stroke="#505958"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M26.5 8v67M32.5 8v67M38.5 8v67" />
      <path fill="#D5F0E5"
            fillRule="nonzero"
            d="M28 23v29H9V23z" />
      <path fill="#505958"
            fillRule="nonzero"
            d="M17.243 22.313c-2.543-1.831-6.061-1.738-8.496.225-2.435 1.962-3.147 5.28-1.72 8.01l-.804 1.747-1.4-.15-1.323 3.67H2.1l-.985 2.794L0 40.107.596 44l4.045-.574 7.416-9.284c3.12.474 6.174-1.16 7.402-3.961 1.228-2.801.314-6.048-2.216-7.868zm-1.686 5.266a1.809 1.809 0 0 1-2.593.275 1.664 1.664 0 0 1-.701-1.112c-.068-.447.054-.901.338-1.26a1.771 1.771 0 0 1 1.21-.743c.495-.071.997.063 1.383.369.4.274.664.693.732 1.16.069.467-.064.941-.369 1.31z" />
      <path stroke="#505958"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14 41.5h9M14 45.5h9" />
      <path fill="#FFF"
            fillRule="nonzero"
            d="M50 20h7v8h-7zM60 20h7v8h-7zM50 32h7v8h-7zM60 32h7v8h-7zM50 44h7v8h-7zM60 44h7v8h-7zM50 56h7v8h-7zM60 56h7v8h-7zM50 68h7v8h-7zM60 68h7v8h-7z" />
    </g>
  </svg>

export const StandardLease5 = ({ width = 94, height = 82 }: Props) =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width={width}
       height={height}
       viewBox="0 0 94 82">
    <g fill="none"
       fillRule="evenodd">
      <path fill="#D5F0E5"
            fillRule="nonzero"
            d="M17 0h40v82H17z" />
      <path fill="#7C8C88"
            fillRule="nonzero"
            d="M94 82H57V0l37 18.729z" />
      <path fill="#FFF"
            fillRule="nonzero"
            d="M33 18H22V7h11zM52 18H41V7h11zM33 32H22V21h11zM52 32H41V21h11zM33 46H22V35h11zM52 46H41V35h11zM33 60H22V49h11zM52 60H41V49h11zM33 75H22V64h11zM52 75H41V64h11z" />
      <path fill="#009F6F"
            fillRule="nonzero"
            d="M29 23v30H9V23z" />
      <path fill="#AAB7B2"
            fillRule="nonzero"
            d="M17.216 22.37a6.804 6.804 0 0 0-8.482.234 6.902 6.902 0 0 0-1.718 8.36l-.803 1.823-1.398-.157-1.32 3.828H2.097l-.984 2.891L0 40.938.595 45l4.04-.599 7.404-9.713a6.827 6.827 0 0 0 7.42-4.104 6.9 6.9 0 0 0-2.243-8.214zm-1.682 5.495a1.779 1.779 0 0 1-2.589.365 1.778 1.778 0 0 1-.362-2.604 1.779 1.779 0 0 1 2.376-.219c.732.549.935 1.563.471 2.354l.104.104z" />
      <path stroke="#505958"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14 42.5h10M14 46.5h10M64 25.5h22M64 33.5h22M64 41.5h22M64 49.5h22M64 57.5h22M64 65.5h22M64 73.5h22" />
    </g>
  </svg>
