import React from 'react'
import styled, { keyframes } from 'styled-components'
import { COLORS } from '../../constants/styles'

const Spinner = () => (
  <Container />
)

export default Spinner

const load = keyframes`
  to { transform: rotate(360deg); }
`

const Container = styled.div`
  width: 43px;
  height: 43px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 8px solid ${COLORS.SECONDARY};
    border-radius: 50%;
    display: block;
  }

  &:after {
    content: '';
    border-radius: 50%;
    border-color: ${COLORS.PRIMARY};
    border-top-color: transparent;
    border-right-color: transparent;
    animation: ${load} 1s linear infinite;
  }
`
