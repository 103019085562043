/**
 * For use in mobile anticipated lease term
 */

export const anticipatedLeaseTerm = [
  { optionName: '1 Year', value: 1 },
  { optionName: '2 Years', value: 2 },
  { optionName: '3 Years', value: 3 },
  { optionName: '4 Years', value: 4 },
  { optionName: '5 Years', value: 5 },
  { optionName: '6 Years', value: 6 },
  { optionName: '7 Years', value: 7 },
  { optionName: '8 Years', value: 8 },
  { optionName: '9 Years', value: 9 },
  { optionName: '10 Years', value: 10 },
]
