import round from 'lodash/round'

export const seatVolatility = [
  {
    optionName: '0% Uncertainty',
    optionShortName: 'Completely certain',
    printVersion: '0%',
    value: 0
  },
  {
    optionName: '4% Uncertainty',
    optionShortName: 'Highly certain',
    printVersion: '4%',
    value: round(0.04 + 0.001, 3)
  },
  {
    optionName: '7% Uncertainty',
    optionShortName: 'Moderately certain',
    printVersion: '7%',
    value: round(0.07 + 0.001, 3)
  },
  {
    optionName: '15% Uncertainty',
    optionShortName: 'Uncertain',
    printVersion: '15%',
    value: round(0.15 + 0.001, 3)
  },
  {
    optionName: '25% Uncertainty',
    optionShortName: 'Completely uncertain',
    printVersion: '25%',
    value: round(0.25 + 0.001, 3)
  },
]
