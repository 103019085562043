import React, { ReactNode } from 'react'
import FocusLock from 'react-focus-lock';


import { ModalStyles, StyledModal, StyledModalTitle } from './styles'
import Button, { ButtonType } from '../button'

export enum ModalAction {
  Primary = 'primary',
  Secondary = 'secondary',
  Close = 'close'
}

interface Props {
  title?: string
  primaryText?: string
  primaryDisabled?: boolean
  secondaryText?: string
  swapButtons?: boolean
  visible: boolean
  callback?: (type: ModalAction) => void
  children: ReactNode

  /**
   * Used for id on buttons and elements
   */
  name: string
}

const ModalWrapper = (
  {
    title,
    primaryText = 'OK',
    secondaryText,
    swapButtons = false,
    children,
    visible,
    primaryDisabled = false,
    callback = () => {
    },
    name,
  }: Props) => (
  <FocusLock
    disabled={!visible}
  >
    <ModalStyles />
    <StyledModal
      id={name}
      isHidden={!visible}
      tabIndex={-1}
      swapButtons={swapButtons}
      classes={{
        close: `${name}--modal-button-close`,
        overlay: `${name}--modal-button-overlay`,
      }}
      onClose={() => callback(ModalAction.Close)}
    >
      <div data-cy="modal">
        {title && <StyledModalTitle id={`${name}--title`}>{title}</StyledModalTitle>}
        {children}
        <div className="blx-h-button-group blx-modal-buttons">
          {secondaryText && <Button
            id={`${name}--modal-button-secondary`}
            type={ButtonType.Secondary}
            text={secondaryText}
            onClick={() => callback(ModalAction.Secondary)}
            data-cy="secondary"
          />}
          <Button
            id={`${name}--modal-button-primary`}
            text={primaryText}
            disabled={primaryDisabled}
            onClick={() => callback(ModalAction.Primary)}
            data-cy="primary"
          />
        </div>
      </div>
    </StyledModal>
  </FocusLock>
)

export default ModalWrapper
