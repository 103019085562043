import { css } from 'styled-components'
import { COLORS, FONTS } from '../constants/styles'
import breakpoint from '../utils/breakpoints'
import { BREAKPOINTS } from '../constants/breakpoints'

export const HeadlineH5Default = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${COLORS.DARK_00};
`

export const HeadlineH4Default = css`
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: 1.13;
  letter-spacing: 0.17px;
  color: ${COLORS.DARK_00};
`

export const HeadlineH4Small = css`
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${COLORS.DARK_00};
`

export const HeadlineH3Dark = css`
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  font-weight: bold;
  font-size: 23px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.04;
  letter-spacing: -0.05px;
  color: ${COLORS.DARK_01};

  ${breakpoint(BREAKPOINTS.mobileMax)`
    font-size: 16px;
  `}
`

export const HeadlineH3Default = css`
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  font-weight: bold;
  font-size: 23px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.04;
  letter-spacing: -0.05px;
  color: ${COLORS.PRIMARY_00};
`

export const UICaps = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  line-height: 1.71;
  font-weight: normal;
  letter-spacing: 0.8px;
  color: ${COLORS.DARK_00};
`

export const UISmallDefault = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  line-height: 1.5;
  font-weight: normal;
  letter-spacing: 0.15px;
  color: ${COLORS.DARK_00};
`

export const UIDefault = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  line-height: 1.29;
  color: ${COLORS.DARK_00};
  font-weight: normal;
`
export const UIDefaultImportant = css`
  font-family: ${FONTS.PRIMARY} !important;
  font-size: 14px !important;
  line-height: 1.29 !important;
  font-weight: normal !important;
`

export const SubCopy = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${COLORS.DARK_01};
`

export const TableMobileSmallAccent = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: ${COLORS.DARK_01};
`

export const TabTitle = css`
  font-family: ${FONTS.PRIMARY};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: normal;
  color: ${COLORS.PRIMARY_00};
`

export const TableAccent = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: ${COLORS.DARK_00};
`

export const UILinkSmallDefault = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.PRIMARY_00};
`

export const UILight = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${COLORS.SECONDARY_00};
`

export const GreenLabel = css`
  ${HeadlineH4Default}
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.PRIMARY_00};
`

export const UISmallError = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLORS.DANGER_00};
`

export const LandingContentHeading = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.06;
  letter-spacing: -0.07px;
  color: ${COLORS.DARK_00};
   ${breakpoint(BREAKPOINTS.mobileMax)`
      font-size: 32px;
  `}
`

export const LandingContentBody = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: normal;
  color: ${COLORS.DARK_00};
 ${breakpoint(BREAKPOINTS.mobileMax)`
    font-size: 16px;
  `}
`
export const HeadlineH6Primary = css`
  font-family: ${FONTS.PRIMARY};
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: ${COLORS.PRIMARY_00};
`
