import styled from 'styled-components'
import { COLORS, FONTS } from '../../../../constants/styles'
import breakpoint from '../../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../../constants/breakpoints'
import { TableBorder } from '../../../../styles/border.styles'

export const StyledContainer = styled.div`
  height: 100%;
  border-bottom: ${TableBorder};
  border-right: ${TableBorder};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 25px 15px;
  text-align: center;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    padding: 10px;
    text-align: start;
  `}
  width: 100%;
  height: 100%;
`

export const StyledButton = styled.button`
  &:focus {
    outline: none;
    svg {
      opacity: 0.7;
    }
  }
`

type LeaseDescriptionProps = { isLeaseType: boolean }

export const StyledLeaseDescription = styled('span')<LeaseDescriptionProps>`
  color: ${({ isLeaseType }: LeaseDescriptionProps) => isLeaseType ? COLORS.DARK_00 : COLORS.DARK_01};
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.15px;
`

export const StyledLeaseName = styled.h3`
  display: -webkit-box;
  color: ${COLORS.DARK_01};
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  font-size: 16px;
  line-height: 1.13;
  letter-spacing: 0.17px;
  margin-bottom: 4px;
  margin-top: 4px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-height: 40px;
  -webkit-line-clamp: 2;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    -webkit-line-clamp: 3;
    min-height: 54px;
  `}
  flex: 1;
`

export const StyledCloseContainer = styled.div`
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
  button {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    ${breakpoint(BREAKPOINTS.mobileMax)`
      padding: 1px;
    `}
  }

  img{
    width: 20px;
    height: 20px;
  }
`

export const StyledIconContainer = styled.div`
  display: flex;
  height:148px;

`
