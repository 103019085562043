import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'
import { BREAKPOINTS } from '../../../constants/breakpoints'

import breakpoint from '../../../utils/breakpoints'


const { AddIcon } = require('@cbrebuild/blocks-react').Icons

export const CustomButton = ({ disabled, text, onClick }) => (
  <StyledAddButton
    name="button-c"
    text={text}
    onClick={(e) => onClick(e)}
    disabled={disabled}
    role="button"
    aria-label="add"
  >
    <StyledAdd fill="#fff" />
  </StyledAddButton>
)

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

CustomButton.defaultProps = {
  disabled: false
}

const StyledAddButton = styled.button`
  position: relative;
  background-color: ${COLORS.PRIMARY_00};
  border: 2px solid ${COLORS.PRIMARY_00};
  width: 44px;
  height: 44px;
  box-shadow: 0 1px 2px 0 ${COLORS.SHADOW_00};
  ${breakpoint(BREAKPOINTS.desktopMax)`
    height: 78px;
  `}
  ${breakpoint(BREAKPOINTS.desktopMin)`
    border-radius: 7px;
  `}
  &:focus, &:hover{
    background-color: ${COLORS.PRIMARY_01};
    border-color: ${COLORS.PRIMARY_01};
    outline: none;
  }
`

const StyledAdd = styled(AddIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
`
export default CustomButton
