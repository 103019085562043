import React from 'react'

import { StyledToggle } from './styles'


export interface ToggleOption {
  text: string
  value: string
}

interface Props {
  options: readonly ToggleOption[],
  callback: (value: string) => void
  value: string
  name: string
  disabled?: boolean
  label?: string
}

const ToggleWrapper = ({ value, options, callback, disabled = false, label, name }: Props) =>
  <StyledToggle
    name={name}
    //using name here as an class for testing stability
    className={name}
    value={value}
    options={options}
    onChange={(e: any) => callback(e.target.value)}
    disabled={disabled}
    label={label}
  />

export default ToggleWrapper
