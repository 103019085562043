import styled, { createGlobalStyle } from 'styled-components'

import { COLORS } from '../../../constants/styles'
import { UILight } from '../../../styles/text.styles'

const { HelpIcon } = require('@cbrebuild/blocks-react').Icons

export const TooltipStyles = createGlobalStyle`
  /* Used for antd styling outside react styled components. */
  .ant-tooltip-inner {
    background: #2b3836;
    border-radius: 5px;
  }
  /* Hide arrow */
  .ant-tooltip-arrow {
    display: none;
  }
`

export const StyledHelpIcon = styled(HelpIcon)`
  circle[fill="#D6E2E0"]{
   fill:${COLORS.PRIMARY_00};
  }
  height: 14px;
  min-height: 14px;
  width: 14px;
  min-width: 14px;
  display: block;
  border-radius:1px;
  &:focus {
    outline: none;
    circle[fill="#D6E2E0"]{
      fill:${COLORS.PRIMARY_01};
    }
  }
  circle, path, g{
    pointer-events: none;
  }
`

export const StyledTooltipTitle = styled.span`
  ${UILight}
`
export const StyledOverlay = styled.div`
  &:focus{
    outline: none;
  }
`

export const StyledStory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
`
