import styled from 'styled-components'
import { Dropdown } from '../../../common/dropdown/dropdown'
import breakpoint from '../../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../../constants/breakpoints'

export const StyledLeaseComparisonDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledDropdownWrapper = styled(Dropdown)`
  margin-bottom: 20px;
  
  ${breakpoint(BREAKPOINTS.mobileMax)`
    margin-bottom: 16px;
  `}
`
