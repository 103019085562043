import React, { useState, useEffect } from 'react'
import { Tooltip as AntdTooltip } from 'antd'

import { TooltipStyles, StyledHelpIcon, StyledOverlay, StyledTooltipTitle } from './styles'

import { BREAKPOINTS } from '../../../constants/breakpoints'
import { useBreakpoint } from '../../../utils/hooks/hooks'

interface Props {
  title: string
  id: string
}
export const FOCUS_DEBOUNCE_TIMEOUT = 300
export const TOOLTIP_CLASSNAME = 'tooltip'

export const Tooltip = ({ title, id }: Props) =>
{
  const [isOpen, setOpen] = useState(false)
  const [isFocused, setFocused] = useState(false)
  const [isClicked, setClicked] = useState(false)

  const isMobile = useBreakpoint(BREAKPOINTS.mobileMax)

  const scrollHandler = (e: any) => setOpen(false)

  const bodyClickHandler = (e: any) => {
    if(!e.target.closest(`.${TOOLTIP_CLASSNAME}`)){
      setOpen(false)
      setClicked(false)
    }
  }

  const clickHandler = (e: any) => {
    !isFocused && setOpen(!isOpen)
    setClicked(!isOpen)
  }

  const focusHandler = (e: any) => {
    if(!isClicked){
      setFocused(true)
      setOpen(true)
      const timer = setTimeout(() =>{
        setFocused(false)
        clearTimeout(timer)
      },FOCUS_DEBOUNCE_TIMEOUT)
    }
  }

  const blurHandler = (e: any) => {
    // if the blur is because of clicking within the tooltip, re-add the document listener
    if(
      (e.nativeEvent.relatedTarget &&
        (e.nativeEvent.relatedTarget.tagName.toLowerCase() === "a" || e.nativeEvent.relatedTarget.classList.contains(TOOLTIP_CLASSNAME))
      )
    ){
      document.addEventListener('mousedown', bodyClickHandler, false)
    }else{
      // if not, close the tooltip
      setOpen(false)
      setClicked(false)
    }
  }

  useEffect(() => {
    // useEffect to enable the closing of tooltips after clickout / scroll
    if(isOpen){
      document.addEventListener('mousedown', bodyClickHandler, false)
      !isMobile && document.addEventListener('scroll', scrollHandler, true)
    }else {
      document.removeEventListener('mousedown', bodyClickHandler, false)
      !isMobile && document.removeEventListener('scroll', scrollHandler, true)
    }
    return () => {
      document.removeEventListener('mousedown', bodyClickHandler, false)
      !isMobile && document.removeEventListener('scroll', scrollHandler, true)
    }
  },[
    isOpen,
    isMobile
  ])

  return (
    <AntdTooltip
      visible={isOpen}
      overlay={<StyledOverlay tabIndex={isOpen ? 0 : -1} className={TOOLTIP_CLASSNAME}>
        <StyledTooltipTitle  id={id}>{title}</StyledTooltipTitle>
      </StyledOverlay>}
    >
      <TooltipStyles />
      <StyledHelpIcon
        aria-labelledby={id}
        role="button"
        tabIndex="0"
        id={`${id}-${TOOLTIP_CLASSNAME}`}
        onTouchStart={clickHandler}
        onMouseDown={clickHandler}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
    </AntdTooltip>
  )
}
