import styled, { css } from 'styled-components'
import { COLORS, FONTS } from '../../../constants/styles'
import { HeadlineH4Small, UICaps, UISmallDefault } from '../../../styles/text.styles'
import { GraphBorder } from '../../../styles/border.styles'

export const StyledHeaderContainer = styled.div`
  height: 100%;
  border-top: ${GraphBorder};
  border-right: ${GraphBorder};
  display: flex;
  flex: 0;
  flex-direction: column;
  align-items: center;
`

export const StyledHeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
`


type LeaseDescriptionProps = { isLeaseType: boolean }

export const StyledLeaseDescription = styled('span')<LeaseDescriptionProps>`
  color: ${({ isLeaseType }: LeaseDescriptionProps) => isLeaseType ? COLORS.DARK_00 : COLORS.DARK_01};
  font-family: ${FONTS.PRIMARY};
  font-size: 9px;
  line-height: 1.5;
  letter-spacing: 0.15px;
`

export const StyledLeaseName = styled.h3`
  color: ${COLORS.DARK_01};
  font-family: ${FONTS.PRIMARY_SEMIBOLD};
  font-size: 9px;
  line-height: 1.13;
  letter-spacing: 0.17px;
  margin-bottom: 4px;
  margin-top: 4px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-height: 22px;
  -webkit-line-clamp: 2;
`

export interface StyledLabelContainerProps {
  isHighlighted?: boolean
}

const CellStyles = css<StyledLabelContainerProps>`
  display: flex;
  flex-direction: column;
  border-top: ${GraphBorder};
  border-right: ${GraphBorder};
  color-adjust: exact;
  justify-content: center;
  -webkit-print-color-adjust: exact; /* print preview doesn't render background-color properly, so this is needed. */
`
export const StyledLabelContainer = styled('div')<StyledLabelContainerProps>`
  ${CellStyles}
  max-height: 40px;
  padding: 10px 10px 16px 10px;
  ${({ isHighlighted }: StyledLabelContainerProps) => css`
    ${isHighlighted && `background: ${COLORS.SECONDARY_01}`}
  `}

`
export const StyledValueContainer = styled('div')<StyledLabelContainerProps>`
  ${CellStyles}
  padding: 10px 15px;
  ${({ isHighlighted }: StyledLabelContainerProps) => css`
    ${isHighlighted && `background: ${COLORS.SECONDARY_01}`}
  `}
`

export const StyledValue = styled.h4`
  ${UICaps}
  font-size: 9px;
  font-weight: 400;
  letter-spacing: normal;
`
export const StyledLabel = styled.h4`
  ${HeadlineH4Small}
  font-size: 9px;
  font-weight: 400;
`
export const StyledValueTitle = styled.span`
  display: -webkit-box;
  margin-bottom: 4px;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  ${UISmallDefault}
  font-size: 9px;
`
