import React from 'react'

import { ScenarioLeaseType } from '../../../data/models'
import { scenarioLeaseTypeToUIName } from '../../../data/conversions'

import {
  StyledLabel,
  StyledLabelContainer,
  StyledLabelContainerProps,
  StyledValue,
  StyledValueContainer,
  StyledValueTitle,
  StyledHeaderContainer,
  StyledHeaderContentContainer,
  StyledLeaseDescription,
  StyledLeaseName,
} from './styles'

interface ValueProps extends StyledLabelContainerProps {
  value: string
  title?: string
}

export const PrintCostComparisonDataValue = ({ value, isHighlighted, title }: ValueProps) =>
  <StyledValueContainer
    isHighlighted={isHighlighted}
  >
    {title && <StyledValueTitle>{title}</StyledValueTitle>}
    <StyledValue>{value}</StyledValue>
  </StyledValueContainer>

interface PrintLabelProps extends StyledLabelContainerProps {
  label: string
}

export const PrintCostComparisonDataLabel = ({ label, isHighlighted }: PrintLabelProps) => {
  return <StyledLabelContainer
    isHighlighted={isHighlighted}>
    <StyledLabel>{label}</StyledLabel>
  </StyledLabelContainer>
}

interface LeaseLabelProps {
  leaseType: ScenarioLeaseType
  leaseName: string
}

export const PrintCostComparisonLeaseLabel = ({ leaseName, leaseType }: LeaseLabelProps) => {
  return <StyledHeaderContainer>
    <StyledHeaderContentContainer>
      <StyledLeaseDescription isLeaseType>{scenarioLeaseTypeToUIName(leaseType)}</StyledLeaseDescription>
      <StyledLeaseName>{leaseName}</StyledLeaseName>
    </StyledHeaderContentContainer>
  </StyledHeaderContainer>
}
