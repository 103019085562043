import ButtonWrapper, { ButtonType } from '../common/button'
import React from 'react'
import {
  StyledCenteringContainer,
  StyledContentContainer,
  StyledImage,
  StyledImageContainer,
  StyledImageBg,
  StyledImageFlexContainer,
  StyledLandingBody,
  StyledLandingContentHeader,
  StyledTextContent,
  StyledLandingRouterLink
} from './styles'
import { BREAKPOINTS } from '../../constants/breakpoints'
import { useBreakpoint } from '../../utils/hooks/hooks'

interface Props {
  imageSrc: string
  imageAlt: string
  header: string
  body: string
  direction: 'left' | 'right'
  isPrimary?: boolean
  showImageInMobile?: boolean
  className?: string
  positionIndex?: number
}

export const LandingContent = (
  {
    imageAlt,
    imageSrc,
    header,
    body,
    direction,
    isPrimary = false,
    showImageInMobile = true,
    className,
    positionIndex
  }: Props) => {
  const isTablet = useBreakpoint(BREAKPOINTS.tabletMax)
  const image = () => <StyledImageContainer
    // if primary, image size is larger
    isPrimary={isPrimary}
  >
    <StyledImage
      alt={imageAlt}
      src={imageSrc}
    />
  </StyledImageContainer>

  const imageContent = () => ((!isTablet || showImageInMobile) && <StyledImageFlexContainer>
    {isPrimary ? image() :
    <StyledImageBg
      direction={direction}
    >
      {image()}
    </StyledImageBg>}
  </StyledImageFlexContainer>)
  return <StyledCenteringContainer className={className}>
    <StyledContentContainer
      direction={direction}
      isPrimary={isPrimary}
    >
      {(direction === 'right' || isTablet) && imageContent()}
      <StyledTextContent
        direction={direction}
        isPrimary={isPrimary}
      >
        <StyledLandingContentHeader isPrimary={isPrimary}>
          {header}
        </StyledLandingContentHeader>
        <StyledLandingBody isPrimary={isPrimary}>{body}</StyledLandingBody>
        <StyledLandingRouterLink
          to="/calc"
          href="/calc"
          id={`calc-link-${positionIndex}`}>
          <ButtonWrapper
            id={`begin-calculating-${positionIndex}`}
            text="Begin Calculating"
            wrapContent
            // weird, but primary is inverse as being the header in this case.
            type={isPrimary ? ButtonType.Secondary : ButtonType.Primary} />
        </StyledLandingRouterLink>
      </StyledTextContent>
      {(direction === 'left' && !isTablet) && imageContent()}
    </StyledContentContainer>
  </StyledCenteringContainer>
}
