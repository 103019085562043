import styled, { css } from 'styled-components'
import { COLORS, FONTS } from '../../../constants/styles'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'

export const StyledSummaryCardContainer = styled.div<{ isLoading: boolean }>`
  text-align: left;
  width: 100%;
  ${breakpoint(BREAKPOINTS.desktopMax)`
    max-width: 111px;
  `}

  ${({ isLoading }) => isLoading && css`
    opacity: 0.5;
  `}
`

interface StyledSummaryContainerProps {
  isSelected: boolean
}

export const StyledSummaryContainer = styled('div')<StyledSummaryContainerProps>`
  ${props => props.isSelected && css`
    border: solid 2px ${COLORS.PRIMARY_00};
  `}
  ${(props) => !props.isSelected && css`
    border: solid 1px ${COLORS.LIGHT_01};
  `}
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:  ${(props: StyledSummaryContainerProps) => props.isSelected ? '5px 15px' : '6px 16px'};
  ${breakpoint(BREAKPOINTS.desktopMin)`
    flex-direction: row;
    padding: ${(props: StyledSummaryContainerProps) => props.isSelected ? '20px' : '21px'};
    align-content: stretch;
    border-radius: 7px;
    min-height: 139px;
  `}

`

export const StyledContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`
export const StyledResultBold = styled.span`
  font-family: ${FONTS.PRIMARY};
  font-weight: bold;
  font-size: 16px;
  ${breakpoint(BREAKPOINTS.desktopMin)`
     font-size: 23px;
  `}
  line-height: 1.3;
  color: ${COLORS.DARK_01};
`

export const StyledResultNormal = styled.span`
  font-family: ${FONTS.PRIMARY};
  font-size: 12px;
  margin-bottom: 4px;
  margin-right: 10px;
   ${breakpoint(BREAKPOINTS.desktopMin)`
     font-size: 16px;
     margin-bottom: 16px;
     margin-right: 0;
  `}
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: ${COLORS.DARK_01};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const StyledCloseContainer = styled.div`
  position: relative;
  right: 0;
  top: 0;
  button {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }

  img{
    width: 20px;
    height: 20px;
  }
`
