import { OptionValue } from './optionValues'

export interface UnitOfMeasure extends OptionValue {
  readonly uom: string
  readonly convert: number
}

export const unitOfMeasure: readonly UnitOfMeasure[] = [
  {
    value: 1,
    uom: 'sq ft',
    optionName: 'Square Feet',
    convert: 1,
  },
  {
    value: 2,
    uom: 'sq m',
    optionName: 'Square Meters',
    convert: 10.7639,
  },
  {
    value: 3,
    uom: 'tsubo',
    optionName: 'Tsubo (Japan Only)',
    convert: 35.5832,
  },
]
