import React from 'react'
import MediaQuery from 'react-responsive'

import { BREAKPOINTS } from '../../../constants/breakpoints'
import { IntroDropdown } from '../../common/dropdown/introDropdown'
import { CompanyInputWrapper } from '../companyInputWrapper'
import { tooltipText } from '../../../utils/selectOptions/tooltipText'
import { annualSeatChange, seatVolatility, anticipatedLeaseTerm } from '../../../utils/selectOptions'
import optionsConfig from '../../../optionsConfig/locations.json'
import {
  StyledButtonContainer,
  StyledContainer,
  StyledGrowthRiskSection,
  StyledHeadcountSection,
  StyledHeaderContainer,
  StyledMarketSection,
  StyledTipCopy,
  StyledUnitButton
} from './styles'
import { IntroInput } from '../../common/input/introInput'
import { selectCompanyInputs } from '../../../redux/calculations/calculation.selectors'
import { selectCurrentCompanyProfileSegment } from '../../../redux/companyprofile/companyprofile.selectors'
import { bindActionCreators, Dispatch } from 'redux'
import { showModal } from '../../../redux/modal/modal.actions'
import { updateCompanyInputs } from '../../../redux/calculations/calculation.actions'
import { changeSegment } from '../../../redux/companyprofile/companyprofile.actions'
import { connect } from 'react-redux'
import { NextButton } from './NextButton'
import {
  selectCurrencySymbol,
  selectMeasureName,
  selectPeriodName,
} from '../../../redux/unitCurrency/unitCurrency.selectors'
import { unitDisplayToSystemName } from '../../../redux/unitCurrency/unitCurrency.utils'

import { useBreakpoint } from '../../../utils/hooks/hooks'

interface OwnProps {
  displayUnitCurrency: () => void,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const renderUnitCurrencySelect = (onClick: (e: any) => void,
                                  currencySymbol: string,
                                  measureName: string,
                                  periodName: string) => <StyledUnitButton
  id="units-currency--button"
  onClick={onClick}
>
  {currencySymbol} / {unitDisplayToSystemName(measureName)} / {periodName}
</StyledUnitButton>


export const InternalCompanyInputs = (
  {
    segment,
    changeSegment,
    displayUnitCurrency,
    inputs,
    updateCompanyInputs,
    showModal,
    currencySymbol,
    measureName,
    periodName,
  }: Props) => {
  const onClick = (e: any) => {
    e.preventDefault()
    displayUnitCurrency()
  }
  const isDesktop = useBreakpoint(BREAKPOINTS.desktopMin)
  const formWidth = isDesktop ? '332px' : '241px'

  return (
    <div>
      <form onSubmit={() => updateCompanyInputs(inputs)}>
        <div>
          {/* Location */}
          <StyledMarketSection segment={segment}>
            <StyledHeaderContainer segment={segment}>
              <h2>Tell us more about your company.</h2>
              <MediaQuery minWidth={BREAKPOINTS.tabletMin.value}>
                {renderUnitCurrencySelect(onClick, currencySymbol, measureName, periodName)}
              </MediaQuery>
            </StyledHeaderContainer>
            <IntroDropdown
              id="select-market--dropdown"
              placeholder="Find Your Market"
              optionsArray={optionsConfig.locations}
              value={inputs.location}
              showSearch={true}
              large={segment === 0}
              onChange={(value: any) => { // sets up a pop up modal to confirm if you want to change the field
                if (!inputs.location) {
                  updateCompanyInputs({ location: value })
                } else {
                  // if traditional or flexible override values are dirty, the show a pop up modal warning user that overrides will be lost
                  showModal({
                    title: 'Reset Overrides?',
                    body: 'Changing Location will reset market costs to their default values.',
                    actionButtonText: 'Change Location',
                    action: () => updateCompanyInputs({ location: value }),
                    name: 'resetOverrides',
                  })
                }
                segment === 0 && changeSegment(1)
              }}
            />
            <MediaQuery maxWidth={BREAKPOINTS.tabletMax.value}>
              {renderUnitCurrencySelect(onClick, currencySymbol, measureName, periodName)}
            </MediaQuery>
          </StyledMarketSection>
          {/*  Initial Seats Required  */}
          <StyledHeadcountSection segment={segment}>
            <CompanyInputWrapper
              id="headcount"
              label="Current Headcount"
              tooltipText={tooltipText.initialSeats}
              innerComponent={
                <IntroInput
                  id="headcount--input"
                  initialValue={inputs.initialSeats}
                  valueIfEmpty={1}
                  submitForm={(value: any) => {
                    updateCompanyInputs({ initialSeats: value })
                    segment === 1 && changeSegment(2)
                  }} // passes method to parent to check if we should submit
                  min={1}
                  max={100}
                  setWidth={formWidth}
                  label="Current Headcount"
                />}
            />
            <StyledTipCopy>100 Max</StyledTipCopy>
          </StyledHeadcountSection>
          {/*  STEP  **************************** */}
          <StyledGrowthRiskSection segment={segment}>
            {/* Annual Seat Change */}
            <StyledContainer>
              <CompanyInputWrapper
                id="headcount-change"
                label="Anticipated Headcount Change"
                tooltipText={tooltipText.annualSeatChange}
                innerComponent={
                  <IntroDropdown
                    id="headcount-change--dropdown"
                    label="Anticipated Headcount Change"
                    large={false}
                    optionsArray={annualSeatChange}
                    value={inputs.annualSeatChange}
                    setWidth={formWidth}
                    onChange={(value) => updateCompanyInputs({ annualSeatChange: value })}
                  />
                }
              />

            </StyledContainer>
            {/* Seat Volatility */}
            <StyledContainer>
              <CompanyInputWrapper
                id="degree-certainty"
                label="Degree of Uncertainty"
                tooltipText={tooltipText.seatVolatility}
                innerComponent={
                  <IntroDropdown
                    id="degree-certainty--dropdown"
                    label="Degree of Uncertainty"
                    optionsArray={seatVolatility}
                    setWidth={formWidth}
                    large={false}
                    value={inputs.seatVolatility}
                    onChange={(value) => updateCompanyInputs({ seatVolatility: value })}
                  />
                }
              />

            </StyledContainer>
            {/*  Initial Desired Lease Term */}
            <StyledContainer>
              <CompanyInputWrapper
                id="anticipated-lease-term"
                label="Anticipated Lease Term"
                tooltipText={tooltipText.initialDesiredLeaseTerm}
                innerComponent={
                  <IntroDropdown
                    label="Anticipated Lease Term"
                    id="lease-term--dropdown"
                    value={inputs.initialDesiredLeaseTerm}
                    large={false}
                    optionsArray={anticipatedLeaseTerm}
                    // disableSubmit={true} // only use for basic values to disable submitting
                    onChange={(value: any) => updateCompanyInputs({ initialDesiredLeaseTerm: value })}
                    setWidth={formWidth}
                  />
                }
              />

            </StyledContainer>
            <StyledButtonContainer>
              <NextButton />
            </StyledButtonContainer>
          </StyledGrowthRiskSection>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  inputs: selectCompanyInputs(state),
  segment: selectCurrentCompanyProfileSegment(state),
  measureName: selectMeasureName(state),
  periodName: selectPeriodName(state),
  currencySymbol: selectCurrencySymbol(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators({
    showModal,
    updateCompanyInputs,
    changeSegment,
  }, dispatch)

export const CompanyInputs = connect(mapStateToProps, mapDispatchToProps)(InternalCompanyInputs)

