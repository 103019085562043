import { FormUnitCurrency } from './unitCurrency.utils'

export enum UnitCurrencyActions {
  UpdateUnitCurrencySelection = 'unitCurrency/UPDATE_UNIT_CURRENCY_SELECTION',
  NoCalculation = 'unitCurrency/NO_CALCULATION'
}

export interface UpdateUnitCurrencySelection {
  type: typeof UnitCurrencyActions.UpdateUnitCurrencySelection
  values: FormUnitCurrency
}

export const updateUnitCurrencySelection = (values: FormUnitCurrency): UnitCurrencyActionTypes => ({
  type: UnitCurrencyActions.UpdateUnitCurrencySelection,
  values,
})

export interface NoCalculation {
  type: typeof UnitCurrencyActions.NoCalculation
  reason: string
}

/**
 * Called when we modify unit currency without scenario calculation running quite yet. Hold
 * off and wait until company inputs.
 */
export const noCalculation = (reason: string): UnitCurrencyActionTypes => ({
  type: UnitCurrencyActions.NoCalculation,
  reason,
})

export type UnitCurrencyActionTypes =
  | UpdateUnitCurrencySelection
  | NoCalculation
