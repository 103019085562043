/**
 * This component displays the results of iterations received from the
 * backend. Based on chart ID, it will render the respective values.
 */

import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { StyledBottomContainer, StyledScroller } from './styles'
import CostComparisonTable from '../costComparisonTable'
import { bindActionCreators } from 'redux'
import { removeLeaseLocally, startOverPreserveInputs } from '../../../../redux/scenario/scenario.actions'
import { CostComparisonHeaderContainer, StyledTabHeader, TabHeader } from '../../detailedResultsSummary/styles'
import ResultsOptions from '../../resultsOptions'
import {
  selectInitialDesiredLeaseTerm,
  selectInitialSeats,
  selectSelectedChartOption,
  selectCalculationResultsLoading,
} from '../../../../redux/calculations/calculation.selectors'

import { selectCurrencySymbol, selectMeasureName } from '../../../../redux/unitCurrency/unitCurrency.selectors'
import { selectCanDeleteLeases, selectCostComparisonLeases } from '../../../../redux/scenario/scenario.selectors'
import { showDeleteLeaseModal, showStartOverLeaseModal } from '../../../lease/lease.actions'
import { showModal } from '../../../../redux/modal/modal.actions'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>


type Props = StateProps & DispatchProps

export const CostSummary = (
  {
    measureName,
    currencySymbol,
    loading = false,
    initialSeats = 0,
    leases,
    initialDesiredLeaseTerm,
    removeLeaseLocally,
    chartOption,
    canDeleteLeases,
    showModal,
    startOverPreserveInputs,
  }: Props) => {
  return (
    <>
      <CostComparisonHeaderContainer>
        <StyledTabHeader>
          <TabHeader>Cost Comparison for a {initialDesiredLeaseTerm} Year Term</TabHeader>
        </StyledTabHeader>
        <ResultsOptions />
      </CostComparisonHeaderContainer>
      <StyledScroller loading={loading}>
        <StyledBottomContainer>
          <CostComparisonTable
            currentOption={chartOption}
            currencySymbol={currencySymbol}
            measureName={measureName}
            initialSeats={initialSeats}
            onClose={(lease) => {
              if (canDeleteLeases) {
                showDeleteLeaseModal(showModal, lease.id, removeLeaseLocally)
              } else {
                showStartOverLeaseModal(showModal, startOverPreserveInputs)
              }
            }}
            leases={leases}
            id="cost-comparison--table"
          />
        </StyledBottomContainer>
      </StyledScroller>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  initialSeats: selectInitialSeats(state),
  leases: selectCostComparisonLeases(state),
  initialDesiredLeaseTerm: selectInitialDesiredLeaseTerm(state),
  currencySymbol: selectCurrencySymbol(state),
  chartOption: selectSelectedChartOption(state),
  canDeleteLeases: selectCanDeleteLeases(state),
  loading: selectCalculationResultsLoading(state),
  measureName: selectMeasureName(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators(({
  removeLeaseLocally,
  showModal,
  startOverPreserveInputs,
}), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CostSummary)
