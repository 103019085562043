import { CostComparisonLease } from '../../../data/models'
import { ChartTypeId } from '../../../utils/selectOptions/chartOptions'


export const valueForCurrentOption = (option: ChartTypeId,
                                      lease: CostComparisonLease): number => {
  switch (option) {
    case ChartTypeId.cash:
      return lease.totalCosts
    case ChartTypeId.npv:
      return lease.npv
    case ChartTypeId.costSeatYear:
      return lease.costSeatYear
  }
}
