import { CompanyProfileActions, CompanyProfileActionTypes } from './companyprofile.actions'

export interface CompanyProfileState {
  segment: number
}


export const defaultCompanyProfileState: CompanyProfileState = {
  segment: 0,
}

export const companyProfileReducer = (state: CompanyProfileState = defaultCompanyProfileState, action: CompanyProfileActionTypes): CompanyProfileState => {
  switch (action.type) {
    case CompanyProfileActions.ChangeSegment:
      return {
        ...state,
        segment: action.segment,
      }
    default:
      return state
  }
}
