import React from 'react'
import Select from 'antd/lib/select'

const { Option } = Select

const makeId = ({ optionName, city, region }: { optionName: string, city?: string, region?: string }) => (
  city && region ? `${city} - ${region}` : optionName
)

export interface OptionValue {
  value: any
  optionName: string
  city?: string
  region?: string
}

export const optionValues = (options: readonly OptionValue[]) => (
  options.map(({ value, optionName }: OptionValue) => (
    <Option
      key={value}
      value={value}>
      {optionName}
    </Option>
  ))
)

export const mobileValues = (options: readonly OptionValue[]) => (
  options.map(({ value, optionName, city, region }: OptionValue) => (
    <option
      key={value}
      value={value}
      id={makeId({
        optionName,
        city,
        region,
      })}
    >
      {optionName}
    </option>
  ))
)
