import React from 'react'
import styled from 'styled-components'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'


import { COLORS, FONTS } from '../../../constants/styles'
import { Tooltip } from '../tooltip'

interface Props {
  tooltipText?: string
  text: string
  id: string
}

export const IntroLabel = ({ text, tooltipText, id }: Props) => (
  <StyledLabel id={id}>
    <span id={`${id}--text`}>{text}</span>
    {!!tooltipText &&
    <Tooltip
      id={`${id}--tooltip`}
      title={tooltipText}
    />
    }
  </StyledLabel>
)

const StyledLabel = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 4px;

  ${breakpoint(BREAKPOINTS.tabletMin)`
    padding-left: 16px;
  `}

  & > span {
    white-space: nowrap;

    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.17px;

    font-weight: normal;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-family: ${FONTS.PRIMARY};
    color: ${COLORS.DARK_01}
    ${breakpoint(BREAKPOINTS.tabletMin)`
      line-height: 1;
      margin-top: -9px;
      letter-spacing: -1.2px;
      font-size: 28px;
    `}
    ${breakpoint(BREAKPOINTS.ultraWideMin)`
      font-size: 39px;
    `}
  }
  svg{
    position: relative;
    left: 4px;
    ${breakpoint(BREAKPOINTS.tabletMin)`
      top: -18px;
      transform: scale(1.2)
    `}
    ${breakpoint(BREAKPOINTS.ultraWideMin)`
      top: -28px;
      transform: scale(1.2)
    `}
  }
`
