import styled from 'styled-components'

import { COLORS, FONTS } from '../../../constants/styles'
// Utils
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import LottieAnimation from '../../introAnimation'


export const StyledContainer = styled.div`
  display: flex;
`

interface StyledHeaderProps {
  readonly display: string
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  padding: 30px 25px 20px 28px;

  ${breakpoint(BREAKPOINTS.cbreMax)`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
  `}
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ display }) => display === 'basicInputs' && `
    display: none;
  `}

  ${breakpoint(BREAKPOINTS.cbreMax)`
    ${({ display }: StyledHeaderProps) => display !== 'basicInputs' && `
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      background: ${COLORS.WHITE};
      z-index: 6;
      box-shadow: 0 2px 4px 0 rgba(39, 43, 47, 0.12);
      padding: 18px 23px;
      height: 48px;
    `}
  `}

  h2 {
    font-size: 18px;
    font-weight: 500;
    color: ${COLORS.DARKGREYTEXT};

    ${breakpoint(BREAKPOINTS.cbreMin)`
      font-size: 24px;
    `}
  }
`

export const StyledInputContainer = styled.div<{ display: string }>`
  width: 100%;
  text-align: left;
  height: 100%;
  position: relative;

  h2 {
    font-weight: 500;
  }
  ${breakpoint(BREAKPOINTS.desktopMin)`
    width: 80%;
    margin: 0 auto;
  `}
`
export const AnimationContainer = styled(LottieAnimation)`
  height: 100%;
  position: absolute;
  bottom: 0;
`

export const StyledButton = styled.button`
  align-items: center;
  background: none;
  font-family: ${FONTS.PRIMARY};
  border-radius: 29.5px;
  border: solid 1px #004a36;
  cursor: pointer;
  display: flex;
  width: 152px;
  font-size: 16px;
  font-weight: 500;
  height: 29px;
  justify-content: center;
  font-size: 12px;
  color: #004a36;
  :focus {
    outline: none;
  }

  ${breakpoint(BREAKPOINTS.cbreMax)`
    width: 88px;
  `}
`
