import { ScenarioLeaseType } from '../../data/models'

export const leaseTypeToTabName = (leaseType: ScenarioLeaseType): string => {
  switch (leaseType) {
    case ScenarioLeaseType.Traditional:
      return 'Standard Lease'
    case ScenarioLeaseType.FlexCoworking:
      return 'Coworking Space'
  }
}
