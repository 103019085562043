import React from 'react'
import { connect, Dispatch } from 'react-redux'
import * as selectOpt from '../../../../utils/selectOptions/index'

import { tooltipText } from '../../../../utils/selectOptions/tooltipText'
import {
  StyledContainer,
  StyledDropdownWrapper,
  StyledField,
  StyledForm,
  StyledLeaseInputName,
  StyledNonCollapseTitle,
} from '../styles'
import {
  selectCurrencyName,
  selectCurrencyValue,
  selectMeasureName,
  selectPeriodName,
} from '../../../../redux/unitCurrency/unitCurrency.selectors'
import { selectFlexOverrideLease, selectTradOverrideLease } from '../../../../redux/scenario/scenario.selectors'
import { monthlyMeetingCharge } from '../../../../services/flexLeaseCalc'
import { bindActionCreators } from 'redux'
import { updateLocalLease } from '../../../../redux/scenario/scenario.actions'
import { NumberInput } from '../../../common/input/NumberInput'
import { TextInput } from '../../../common/input/TextInput'
import { MAX_LEASE_NAME_LENGTH } from '../../../../data/models'
import { maxFlexInputsForCurrencyValue, roundLeaseVariableData, roundToDisplay } from '../../../../data/lease_utils'

interface OwnProps {
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>
type Props = OwnProps & StateProps & DispatchProps

export const InternalCustomizeFlex = (
  {
    currencyName,
    currencyValue,
    selectedLease,
    updateLocalLease,
    otherLease,
  }: Props) => {
  const measureName = `${currencyName}/Seat`
  const {
    montlyMeetingRoomCostMax,
    meetingRoomChargesHourlyMax,
    otherOpexChargesMax,
    quotedSeatChargesMax,
  } = maxFlexInputsForCurrencyValue(currencyValue)

  // rounded and currency adjusted maxes
  const quotedSeatChargesMaxDisplay = roundToDisplay(quotedSeatChargesMax * currencyValue)
  const otherOpexChargesMaxDisplay = roundToDisplay(otherOpexChargesMax * currencyValue)
  const oneTimeSetupCostMaxDisplay = roundToDisplay(100 * currencyValue)
  const meetingRoomChargesHourlyMaxDisplay = roundToDisplay(meetingRoomChargesHourlyMax * currencyValue)
  const montlyMeetingRoomCostMaxDisplay = roundToDisplay(montlyMeetingRoomCostMax * currencyValue)

  return <div>
    <StyledForm
      id="customize-coworking--form"
      onSubmit={() => updateLocalLease({}, selectedLease)}
      noValidate>
      <StyledLeaseInputName id="lease-name">
        <TextInput
          id="name"
          maxLength={MAX_LEASE_NAME_LENGTH}
          initialValue={selectedLease.name}
          expandable
          label="Name (Optional)"
          onChange={(value: any) => {
            // synchronize changes between both set of inputs.
            updateLocalLease({ name: value }, otherLease)
            updateLocalLease({ name: value }, selectedLease)
          }}
        />
      </StyledLeaseInputName>
      <StyledNonCollapseTitle>Costs</StyledNonCollapseTitle>
      <StyledContainer>
        {/*  Quoted Seat Charge / Seat */}
        <StyledField id="seat-charges--slider">
          <NumberInput
            id="quotedSeatCharges"
            initialValue={selectedLease.quotedSeatCharges}
            tooltipText={tooltipText.quotedSeatCharges}
            valueIfEmpty={100}
            valueMultiplier={currencyValue}
            min={0}
            max={quotedSeatChargesMaxDisplay}
            bottomText={`${measureName} ${quotedSeatChargesMaxDisplay} Max`}
            label="Cost Per Seat"
            submitForm={(value) => updateLocalLease({ quotedSeatCharges: value }, selectedLease)}
          />
        </StyledField>
        {/* Other Opex Flex Charges */}
        <StyledField id="opex-flex--slider">
          <NumberInput
            id="otherOpexCharges"
            valueIfEmpty={0}
            valueMultiplier={currencyValue}
            initialValue={selectedLease.otherOpexCharges}
            tooltipText={tooltipText.otherOpexFlexCharges}
            min={0}
            max={otherOpexChargesMaxDisplay}
            bottomText={`${measureName} ${otherOpexChargesMaxDisplay} Max`}
            label="Other Costs"
            submitForm={(value) => updateLocalLease({ otherOpexCharges: value }, selectedLease)}
          />
        </StyledField>
        {/* One Time Setup Costs */}
        <StyledField id="one-time--slider">
          <NumberInput
            id="oneTimeSetupCost"
            tooltipText={tooltipText.oneTimeSetupCost}
            valueIfEmpty={0}
            initialValue={selectedLease.oneTimeSetupCost}
            min={0}
            valueMultiplier={currencyValue}
            max={oneTimeSetupCostMaxDisplay}
            bottomText={`${measureName} ${oneTimeSetupCostMaxDisplay} Max`}
            label="Set-up Costs"
            submitForm={(value) => updateLocalLease({ oneTimeSetupCost: value }, selectedLease)}
          />
        </StyledField>
        {/* Flex Cost Escalation */}
        <StyledField id="flex-cost--slider">
          <StyledDropdownWrapper
            id="costEscalationFlex"
            value={selectedLease.costEscalation}
            tooltipText={tooltipText.flexCostEscalation}
            label="Cost Escalation"
            optionsArray={selectOpt.costEscalation}
            onChange={(value: number) => updateLocalLease({ costEscalation: value }, selectedLease)}
          />
        </StyledField>
      </StyledContainer>
      <StyledNonCollapseTitle>Meeting Room Expenses</StyledNonCollapseTitle>
      <StyledContainer>
        {/*  meetingRoomChargesHourly  */}
        <StyledField id="meeting-hourly--slider">
          <NumberInput
            id="meetingRoomChargesHourly"
            key="meetingRoomChargesHourly"
            valueIfEmpty={1}
            min={1}
            valueMultiplier={currencyValue}
            initialValue={selectedLease.meetingRoomChargesHourly}
            max={meetingRoomChargesHourlyMaxDisplay}
            tooltipText={tooltipText.meetingRoomChargesHourly}
            bottomText={`${currencyName}/Hourly ${meetingRoomChargesHourlyMaxDisplay} Max`}
            label="Meeting Room Charge"
            onChange={(value: any) => {
              const monthlyMeetingRoomCost = monthlyMeetingCharge({
                // readjust for currencyValue since value when submitted is adjusted.
                meetingRoomChargesHourly: value,
                meetingRoomRequirement: selectedLease.meetingRoomRequirement,
              })
              return updateLocalLease({
                monthlyMeetingRoomCost: roundLeaseVariableData(monthlyMeetingRoomCost),
              }, selectedLease, false)
            }}
            submitForm={(value) => updateLocalLease({ meetingRoomChargesHourly: value }, selectedLease)}
          />
        </StyledField>
        {/* Meeting Room Charges Monthly */}
        <StyledField id="meeting-monthly--slider">
          <NumberInput
            id="monthlyMeetingRoomCost"
            key="monthlyMeetingRoomCost"
            valueIfEmpty={0}
            initialValue={selectedLease.monthlyMeetingRoomCost}
            tooltipText={tooltipText.monthlyMeetingRoomCost}
            min={0}
            valueMultiplier={currencyValue}
            max={montlyMeetingRoomCostMaxDisplay}
            bottomText={`${measureName} ${montlyMeetingRoomCostMaxDisplay} Max`}
            label="Meeting Room Cost"
            submitForm={(value) => updateLocalLease({ monthlyMeetingRoomCost: value }, selectedLease)}
          />
        </StyledField>
        {/* Meeting Room Requirement */}
        <StyledField id="meeting-usage--slider">
          <StyledDropdownWrapper
            id="meetingRoomRequirement"
            value={selectedLease.meetingRoomRequirement}
            tooltipText={tooltipText.meetingRoomRequirement}
            optionsArray={selectOpt.meetingRoomRequirements}
            label="Meeting Room Usage"
            onChange={(value: any) => {
              const meetingCharge = monthlyMeetingCharge({
                meetingRoomChargesHourly: selectedLease.meetingRoomChargesHourly,
                meetingRoomRequirement: value,
              })
              updateLocalLease({
                meetingRoomRequirement: value,
                monthlyMeetingRoomCost: meetingCharge,
              }, selectedLease)
            }}
          />
        </StyledField>
      </StyledContainer>
    </StyledForm>
  </div>
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({
  updateLocalLease,
}, dispatch)


const mapStateToProps = (state: any) => ({
  periodName: selectPeriodName(state),
  measureName: selectMeasureName(state),
  currencyValue: selectCurrencyValue(state),
  currencyName: selectCurrencyName(state),
  selectedLease: selectFlexOverrideLease(state),
  otherLease: selectTradOverrideLease(state),
})

export const CustomizeFlex = connect(mapStateToProps, mapDispatchToProps)(InternalCustomizeFlex)
