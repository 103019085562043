import styled, { createGlobalStyle } from 'styled-components'
import { COLORS } from '../../../constants/styles'

import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'


const {
  Modals: { BaseModal },
} = require('@cbrebuild/blocks-react')

export const StyledStory = styled.div`
  margin: 0 auto;
  padding-top: 220px;
  max-width: 420px;
`

export const StyledModalTitle = styled.h4`
  color: ${COLORS.DARK_01};
  font-weight: 700;
  padding-bottom: 10px;
`

export const ModalStyles = createGlobalStyle`
  .blx-modal-overlay{
    background-color: ${COLORS.DARK_02};
    opacity: 0.45;
  }
`

export const StyledModal = styled(BaseModal)<{ swapButtons: boolean }>`
  padding: 30px 25px;
  width: 402px;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    padding: 30px 13px;
    width: calc(100% - 10px * 2);
  `}
  .blx-modal-close{
    top: 6px;
    right: 6px;
    height: 20px;
    width: 20px;
    path{
      fill: ${COLORS.PRIMARY_00};
    }
  }
  span, p{
    a{
      color: ${COLORS.PRIMARY_00};
      font-size: 14px;
    }
  }
  .blx-modal-buttons{
    justify-content: flex-end;
    flex-direction: row-reverse;
    border: none;
    padding-bottom: 0;
    padding-top: 30px;
    .blx-button{
      margin-left: 1px;
      min-width: 94px;
      width: unset;
    }
    & > div{
      &:first-child{
        ${({ swapButtons }) => swapButtons && `
          order: 1
          ${breakpoint(BREAKPOINTS.mobileMax)`
            margin-right: 14px;
          `}
        `}
      }
      &:last-child{
        ${({ swapButtons }) => !swapButtons && `
          order: 1
          ${breakpoint(BREAKPOINTS.mobileMax)`
            margin-right: 14px;
          `}
        `}
      }
    }
  }
`
