import {
  ScenarioLease,
  ScenarioLeaseFlexCoworking,
  ScenarioLeaseFlexCoworkingInputs,
  ScenarioLeases,
  ScenarioLeaseTraditional,
  ScenarioLeaseTraditionalInputs,
  ScenarioLeaseType,
} from './models'
import { defaultCostEscalation } from '../utils/selectOptions/costEsclation'
import { CompanyLocation, CurrencyOption } from '../optionsConfig/models'
import { CompanyInputsShape } from './form_models'
import { generateUuid } from '../utils/uuid'
import { UnitOfMeasure } from '../utils/selectOptions/unitOfMeasure'
import {
  rentFreePeriodCalc,
  tiDefaultCalc,
  totalGrossRentableAreaYear2Calc,
  usableSeatAreaReqYear2Calc,
} from '../services/tradLeaseCalc'
import { lossFactorCalc, spacePlanningAssumptionCalc, usableSeatAreaCalc } from '../services/seatCalc'
import round from 'lodash/round'

/**
 * Returns an object of defaults for a {@link ScenarioLeaseFlexCoworking}
 * @param location - the company location
 * @param defaultName - the default name of the lease (as original)
 * @param meetingRoomRequirementValue - the meeting room requirement value.
 */
export const defaultFlexibleLeaseInputsForLocation =
  (location: CompanyLocation,
   defaultName: string,
   meetingRoomRequirementValue: number): ScenarioLeaseFlexCoworkingInputs => {
    return {
      name: defaultName,
      costEscalation: defaultCostEscalation,
      otherOpexCharges: location.otherOpexFlexCharges,
      quotedSeatCharges: location.quotedSeatCharges,
      oneTimeSetupCost: 0,
      monthlyMeetingRoomCost: location.meetingRoomChargesHourly * meetingRoomRequirementValue,
      meetingRoomChargesHourly: location.meetingRoomChargesHourly,
      meetingRoomRequirement: meetingRoomRequirementValue,
    }
  }

export const createDefaultFlexibleLease =
  (location: CompanyLocation,
   meetingRoomRequirementValue: number,
   /**
    * If existing is not specified, this is used as default
    */
   defaultName: string,
   /**
    * If type is not of {@link ScenarioLeaseFlexCoworking}. This
    * is used to pass on name and id.
    */
   existing?: ScenarioLease): ScenarioLeaseFlexCoworking => ({
    id: (existing && existing.id) || generateUuid(),
    type: ScenarioLeaseType.FlexCoworking,
    ...defaultFlexibleLeaseInputsForLocation(location, (existing && existing.name) || defaultName, meetingRoomRequirementValue),
  })

export const defaultTraditionalLeaseForLocation =
  (location: CompanyLocation,
   inputs: CompanyInputsShape,
   currency: CurrencyOption,
   unitOfMeasure: UnitOfMeasure,
   defaultName: string): ScenarioLeaseTraditionalInputs => {
    const { initialDesiredLeaseTerm, initialSeats, seatVolatility, annualSeatChange } = inputs
    const usableSeatArea = usableSeatAreaCalc(location.netAreaSeatAssumption, initialSeats)
    const spacePlanningAssumption = spacePlanningAssumptionCalc(initialSeats) as number
    const lossFactor = lossFactorCalc(location.rentableArea)
    return {
      name: defaultName,
      costEscalation: defaultCostEscalation,
      rentRate: location.rentRate,
      rentFreePeriodValue: rentFreePeriodCalc({
        rentFreePeriod: location.rentFreePeriod,
        initialDesiredLeaseTerm,
      }),
      rentableSeatArea: 0, // TODO: not used
      tiAllowanceValue: tiDefaultCalc({
          tenantIncentives: location.tenantIncentives,
          initialDesiredLeaseTerm,
          // we just keep defaults
          currencyValue: 1,
          measureValue: 1,
        },
      ),
      rentEscalationRate: location.rentEscalationRate,
      operatingCosts: location.operatingCosts,
      facilityManagement: location.facilityManagement,
      otherMiscOpex: location.otherMiscOpex,
      capexHardCost: location.capexHardCost,
      capexFFE: location.capexFFE,
      capexSoftCost: location.capexSoftCost,
      capexITData: location.capexITData,
      reinstatement: location.reinstatement,
      earlyExitPenalty: location.earlyExitPenalty,
      usableSeatArea,
      spacePlanningAssumption, // TODO: throws error
      lossFactor,
      usableSeatAreaReqYear2: usableSeatAreaReqYear2Calc({
        usableSeatArea,
        initialSeats,
        spacePlanningAssumption,
        annualSeatChange,
        seatVolatility,
      }),
      totalGrossRentableAreaYear2: totalGrossRentableAreaYear2Calc({
        usableSeatArea,
        spacePlanningAssumption,
        initialSeats,
        annualSeatChange,
        lossFactor,
        seatVolatility,
      }),
    }
  }

export const createDefaultTraditionalLease =
  (location: CompanyLocation,
   inputs: CompanyInputsShape,
   currency: CurrencyOption,
   unitOfMeasure: UnitOfMeasure,
   /**
    * If existing is not specified, this is used as default
    */
   defaultName: string,
   /**
    * If type is not of {@link ScenarioLeaseTraditional}. This
    * is used to pass on name and id.
    */
   existing?: ScenarioLease): ScenarioLeaseTraditional => ({
    id: (existing && existing.id) || generateUuid(),
    type: ScenarioLeaseType.Traditional,
    ...defaultTraditionalLeaseForLocation(location, inputs, currency, unitOfMeasure, (existing && existing.name) || defaultName),
  })

/**
 * Creates a set of one of each kind of {@link ScenarioLeaseType}.
 */
export const createDefaultLeases = (location: CompanyLocation,
                                    inputs: CompanyInputsShape,
                                    meetingRoomRequirementValue: number,
                                    currency: CurrencyOption,
                                    unitOfMeasure: UnitOfMeasure): ReadonlyArray<ScenarioLease> => {
  const flex = createDefaultFlexibleLease(location, meetingRoomRequirementValue, 'Option A')
  const trad = createDefaultTraditionalLease(location, inputs, currency, unitOfMeasure, 'Option B')
  return [
    flex,
    trad,
  ]
}

/**
 * Duplicates an existingLease with a new UUID and name that is picked from existing lease names and added 1 to the letter.
 */
export const addNewLeaseDuplicate = (existingLease: ScenarioLeases,
                                     leaseNames: string[]): ScenarioLeases => {
  const leaseRegex = /Option [a-zA-Z]$/gm
  let leaseMatchingName: string | undefined = undefined
  leaseNames.forEach(l => {
    if (l.match(leaseRegex)) {
      const lastLetter = l[l.length - 1]
      let newCharCode = lastLetter.charCodeAt(0) + 1
      if ((newCharCode > 90 && newCharCode < 97) ||  // between Z and a
        newCharCode > 122 || newCharCode < 65) { // higher than z, less than A
        newCharCode = 65 // resetting to 'A'. This is an edge case.
      }
      leaseMatchingName = l.substring(0, l.length - 1) + String.fromCharCode(newCharCode)
    }
  })
  if (!leaseMatchingName) {
    leaseMatchingName = 'Option A'
  }
  return {
    ...existingLease!,
    id: generateUuid(),
    name: leaseMatchingName,
  }
}

export const maxFlexInputsForCurrencyValue = (currencyValue: number) => {
  return {
    meetingRoomChargesHourlyMax: roundToDisplay(200 * currencyValue),
    montlyMeetingRoomCostMax: roundToDisplay(5000 * currencyValue),
    otherOpexChargesMax: roundToDisplay(500 * currencyValue),
    quotedSeatChargesMax: roundToDisplay(3000 * currencyValue),
  }
}

/**
 * Rounds the {@link data} to 2 digits.
 */
export const roundLeaseVariableData = (data: number) => round(data, 2)

/**
 * Removes decimal places to a whole number.
 */
export const roundToDisplay = (data: number) => round(data)
