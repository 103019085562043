import styled, { css } from 'styled-components'
import { COLORS } from '../../../constants/styles'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { UILinkSmallDefault } from '../../../styles/text.styles'
import { Link } from 'react-router-dom'

export const StyledStory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
`

const LinkProps = css`
  color: ${COLORS.PRIMARY_00};
  text-decoration: underline;
  &:hover, &:focus {
    color: ${COLORS.PRIMARY_01};
    text-decoration: underline;
  }

  ${breakpoint(BREAKPOINTS.mobileMax)`
    ${UILinkSmallDefault}
  `}
`

export const StyledLink = styled.a`
  ${LinkProps}
`

export const StyledRouterLink = styled(Link)`
  ${LinkProps}
`

export const StyledLinkButton = styled.button`
  ${LinkProps}
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  text-align: inherit;
  padding: 0;
  width: auto;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
`
