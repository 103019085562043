import styled from 'styled-components'
import { Input } from '../common/input/input'
import { HeadlineH4Default } from '../../styles/text.styles'
import { COLORS } from '../../constants/styles'
import ButtonWrapper from '../common/button'

export const StyledContainer = styled.div<{ submitting: boolean }>`
  transition: opacity 300ms ease-in-out;

  ${({ submitting }) => submitting && `
    opacity: 0.5;
  `}
`

export const StyledInput = styled(Input)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 280px;
`

export const StyledTitle = styled.span`
  ${HeadlineH4Default}
  color: ${COLORS.DARK_01}
`

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledButton = styled(ButtonWrapper)<{ isSecondary?: boolean }>`
  ${props => props.isSecondary && 'margin-left: 16px'}
`
