import styled from 'styled-components'

import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  .blx-tooltip-message {
    max-height: 130px;
    max-width: 325px;
  }
  > div {
    width: 50%;
  }
  ${breakpoint(BREAKPOINTS.tabletMin)`
    flex-direction: row;
    & > div {
      width: unset;
    }
  `}
`
