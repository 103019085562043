export const CONFIG = [
  {
    // Section 0 (building 1)
    frames: [0, 30],
    loopFrames: [31, 60]
  },
  {
    // Section 1 (building 2)
    frames: [61, 127],
    loopFrames: [128, 159]
  },
  {
    // Section 2 (desks)
    frames: [160, 241],
    loopFrames: [242, 243],
  },
  {
    // Section 3
    frames: [243, 290]
  }
]
