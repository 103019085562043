import { CalculationsState } from './calculation.reducer'
import { createSelector, defaultMemoize } from 'reselect'
import { createDeepEqualSelector } from '../selector_utils'
import { CompanyInputsShape } from '../../data/form_models'
import { defaultLocations } from '../../optionsConfig/models'

export const selectCalculationsState = (state: { calculations: CalculationsState }) => state.calculations

export const selectCalculationResults = createDeepEqualSelector(selectCalculationsState, (state: CalculationsState) => state.results)

export const selectCalculationSuccess = createSelector(selectCalculationResults, (state) => state.optionalSuccess)

export const selectCalculationResultsLoading = createSelector(selectCalculationResults, (state) => state.isLoading)

export const selectCalculationById = createSelector(selectCalculationResults,
  results => defaultMemoize((id: string) => results.optionalSuccess && results.optionalSuccess.get(id)))

export const selectCompanyInputs = createDeepEqualSelector(selectCalculationsState, (state: CalculationsState) => state.companyInputs)

export const selectInitialDesiredLeaseTerm = createSelector(selectCompanyInputs, (inputs: CompanyInputsShape | undefined) =>
  (inputs && inputs.initialDesiredLeaseTerm) || 1)

export const selectInitialSeats = createSelector(selectCompanyInputs, (inputs: CompanyInputsShape | undefined) =>
  (inputs && inputs.initialSeats) || 0)

export const selectSelectedChartOption = createSelector(selectCalculationsState, (state: CalculationsState) =>
  state.selectedChartOption)

export const selectSeatChartResults = createDeepEqualSelector(selectCalculationsState, (state: CalculationsState) =>
  state.seatChartResults)

export const selectCompanyLocation = createSelector(selectCompanyInputs,
  (inputs) => defaultLocations.find(l => l.value === inputs.location))
