import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '../../components/layout'

import { StyledErrorContainer } from './styles'

class NotFound extends Component {
  constructor() {
    super()
    this.state = {
      redirect: false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ redirect: true }), 3000) // sets redirect state to true
  }

  render() {
    const { redirect } = this.state

    if (redirect) return <Redirect push to="/calc" />
    // if the redirect state is true, use react-router's Redirect to render the calc page.
    // Push is used here to add on to the history instead of replacing it.

    return (
      <Layout>
        <StyledErrorContainer>
          <h1>This page does not exist. Redirecting you to the Calculcator ...</h1>
        </StyledErrorContainer>
      </Layout>
    )
  }
}

export default NotFound