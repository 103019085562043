export enum CompanyProfileActions {
  ChangeSegment = 'companyProfile/CHANGE_SEGMENT'
}

export interface ChangeSegment {
  type: typeof CompanyProfileActions.ChangeSegment
  segment: number
}

export const changeSegment = (segment: number): CompanyProfileActionTypes => ({
  type: CompanyProfileActions.ChangeSegment,
  segment,
})

export type CompanyProfileActionTypes =
  | ChangeSegment
