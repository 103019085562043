import styled, { css }from 'styled-components'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { HeadlineH3Dark } from '../../../styles/text.styles'
import { Dropdown } from '../../common/dropdown/dropdown'

export const StyledForm = styled.form`
  text-align: left;
  ${breakpoint('cbreMax')`
    padding-top: 20px;
  `}
`
const FieldStyles = css`
  flex: 1;
  margin-right: 32px;
  ${breakpoint(BREAKPOINTS.mobileMin)`
    width: calc(50% - 32px);
    max-width: calc(50% - 32px);
    min-width: calc(50% - 32px);
    margin-bottom: 24px;
  `}

  ${breakpoint(BREAKPOINTS.ultraWideMin)`
    width: calc(33% - 32px);
    max-width: calc(33% - 32px);
    min-width: calc(33% - 32px);
    margin-bottom: 24px;
  `}

  ${breakpoint(BREAKPOINTS.desktopMax)`
    margin-bottom: 14px;
  `}
`

export const StyledField = styled.div`
  ${FieldStyles}
`
export const StyledFieldExtended = styled.div`
  ${FieldStyles}
  /* specific style here cause of the length of the label for Tenant Improvement Allowance*/
  @media (min-width: ${BREAKPOINTS.desktopMin.value}px) and (max-width: 1120px) {
    min-width: calc(100% - 32px) !important;
  }
`
export const StyledFieldSpacer = styled.div`
  ${FieldStyles}
  display: none;
  /* specific spacing here for the non-editable values*/
  @media (min-width: ${BREAKPOINTS.tabletMin.value}px) and (max-width: ${BREAKPOINTS.ultraWideMax.value}px) {
    display: block;
  }
`

export const StyledLeaseInputName = styled.div`
  width: calc(50% - 64px);
  margin-top: 24px;
  margin-bottom: 24px;

  ${breakpoint(BREAKPOINTS.tabletMax)`
    width: 100%;
    margin-top: 0;
    margin-bottom: 18px;
  `}
`

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  ${breakpoint(BREAKPOINTS.desktopMin)`
    ${StyledField}:first-child, ${StyledField}:nth-child(2) {
      margin-bottom: 27px;
    }
  `}
  ${breakpoint(BREAKPOINTS.mobileMax)`
    flex-flow: column wrap;
  `}
`

export const StyledNonCollapseTitle = styled.h4`
  margin-bottom: 24px;
  ${HeadlineH3Dark}
  ${breakpoint(BREAKPOINTS.tabletMax)`
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 4px;
  `}
`

export const StyledDropdownWrapper = styled(Dropdown)`
  ${breakpoint(BREAKPOINTS.mobileMax)`
    width: 66%;
  `}
`
