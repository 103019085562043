import React from 'react'
import { StyledComparisonContainer, StyledComparisonGrid, StyledComparisonHeader } from './styles'
import { leaseTypeToLeaseUnit, leaseTypeToLeaseValue } from '../conversions'
import { leaseTypeToAnnualReOccurringCostsHeader, leaseTypeToOneTimeCostsHeader } from '../../../data/conversions'


import { CostComparisonLease } from '../../../data/models'
import {
  PrintCostComparisonDataLabel,
  PrintCostComparisonDataValue,
  PrintCostComparisonLeaseLabel,
} from '../printComparisonElements'
import { tableColumns } from '../../../utils/selectOptions/printTableOptions'
import { formatPrice } from '../../../utils/price_utils'


interface Props {
  leases: readonly CostComparisonLease[]
  currencySymbol: string

  /**
   * This value is picked by user on the Company Profile
   */
  initialSeats: number
  measureName: string
}

const PrintComparisonGrid = (
  {
    leases,
    initialSeats,
    measureName,
    currencySymbol,
  }: Props) =>
  (<StyledComparisonGrid>
    {leases.map((l, i) => [
      <PrintCostComparisonLeaseLabel
        key={l.id}
        leaseType={l.type}
        leaseName={l.name}
      />,
      <PrintCostComparisonDataValue
        isHighlighted
        key={l.id + 1}
        value={formatPrice(currencySymbol, l.npv)}
      />,
      <PrintCostComparisonDataValue
        key={l.id + 2}
        title={leaseTypeToLeaseUnit(l.type, initialSeats, measureName)}
        value={leaseTypeToLeaseValue(l.type, l.netArea, initialSeats)}
      />,
      <PrintCostComparisonDataValue
        key={l.id + 3}
        value={formatPrice(currencySymbol, l.costSeatYear)}
      />,
      <PrintCostComparisonDataValue
        key={l.id + 4}
        title={leaseTypeToAnnualReOccurringCostsHeader(l.type)}
        value={formatPrice(currencySymbol, l.annualReOccurringCosts)}
      />,
      <PrintCostComparisonDataValue
        key={l.id + 5}
        title={leaseTypeToOneTimeCostsHeader(l.type)}
        value={formatPrice(currencySymbol, l.oneTimeCosts)} />,
    ])}
  </StyledComparisonGrid>)

const PrintCostComparisonTable = ({ leases, currencySymbol, initialSeats, measureName }: Props) => {
  return (
    <StyledComparisonContainer>
      <StyledComparisonHeader>
        {(['Leases',
          ...tableColumns,
        ].filter(f => !!f) as string[]).map((l: string, index: number) => [
          <PrintCostComparisonDataLabel
            key={l}
            label={l}
            isHighlighted={index === 1} />,
        ])}
      </StyledComparisonHeader>
      {PrintComparisonGrid({
        leases,
        currencySymbol,
        initialSeats,
        measureName,
      })}
    </StyledComparisonContainer>
  )
}
export default PrintCostComparisonTable
