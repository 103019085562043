import React from 'react'
import { connect, Dispatch } from 'react-redux'
import ResultSummaryCardContainer from '../resultSummaryCardContainer'
import { selectLeaseNames, selectLeaseResults } from '../selectors'
import {
  selectCanAddLeases,
  selectCanDeleteLeases,
  selectSelectedOriginalLease,
  selectUpdatedOverrideLease,
} from '../../../redux/scenario/scenario.selectors'
import { bindActionCreators } from 'redux'
import {
  addNewLease,
  removeLeaseLocally,
  selectLease,
  startOverPreserveInputs,
} from '../../../redux/scenario/scenario.actions'
import { selectCurrencySymbol } from '../../../redux/unitCurrency/unitCurrency.selectors'
import { addNewLeaseDuplicate } from '../../../data/lease_utils'
import { changeHomeTab } from '../../../redux/home/home.actions'
import { showModal } from '../../../redux/modal/modal.actions'
import { showDeleteLeaseModal, showStartOverLeaseModal } from '../lease.actions'

interface OwnProps {
  className?: string
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = OwnProps & StateProps & DispatchProps

const LeaseResultsSummary = (
  {
    currencySymbol, results, selectedLease,
    overrideLease,
    selectLease, addNewLease,
    removeLeaseLocally,
    leaseNames,
    changeHomeTab,
    canDeleteLeases,
    showModal,
    startOverPreserveInputs,
    canAddLeases,
    className,
  }: Props) =>
  <ResultSummaryCardContainer
    canAddLease={canAddLeases}
    className={className}
    results={results}
    addLease={() => addNewLease(addNewLeaseDuplicate(overrideLease!, leaseNames))}
    removeLease={(model) => {
      if (canDeleteLeases) {
        showDeleteLeaseModal(showModal, model.lease.id, removeLeaseLocally)
      } else {
        showStartOverLeaseModal(showModal, startOverPreserveInputs)
      }
    }}
    onTapLease={(model => selectLease(model.lease))}
    selectedLease={(selectedLease && selectedLease.id) || ''}
    viewDetailedResults={() => changeHomeTab('3')}
    currencySymbol={currencySymbol}
  />

const mapStateToProps = (state: any) => ({
  results: selectLeaseResults(state),
  selectedLease: selectSelectedOriginalLease(state),
  overrideLease: selectUpdatedOverrideLease(state),
  currencySymbol: selectCurrencySymbol(state),
  leaseNames: selectLeaseNames(state),
  canDeleteLeases: selectCanDeleteLeases(state),
  canAddLeases: selectCanAddLeases(state),
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => (bindActionCreators({
  selectLease,
  addNewLease,
  removeLeaseLocally,
  changeHomeTab,
  showModal,
  startOverPreserveInputs,
}, dispatch))

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(LeaseResultsSummary)
