import React from 'react'
import PropTypes from 'prop-types'

export const Down = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={height} height={width} viewBox="0 0 20 20">
    <path fill="#00704F" fillRule="nonzero" d="M9.986 12.578L16.384 6.2 17.8 7.611 9.986 15.4 2.2 7.64l1.416-1.412z" />
  </svg>
)

Down.defaultProps = {
  height: 20,
  width: 20
}

Down.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
}
