import React, { ReactNode } from 'react'

import { Collapse } from 'antd'

import { Down } from '../icons'

import {
  StyledCollapse,
  StyledCollapseHeader,
  StyledCollapseSubtitle,
  StyledCollapseTitle,
  StyledPanelBody,
} from './styles'

const { Panel } = Collapse

interface Props {
  children: ReactNode
  title: string
  subtitle: string
  id: string
}

export const AccordionPanel = ({ children, title, subtitle, id, ...props }: Props) => (
  <Panel
    key={id}
    forceRender={true}
    {...props}
    header={
      <StyledCollapseHeader>
        <StyledCollapseTitle className="title">{title}</StyledCollapseTitle>
        <StyledCollapseSubtitle className="subtitle">{subtitle}</StyledCollapseSubtitle>
      </StyledCollapseHeader>
    }
  >
    <StyledPanelBody>{children}</StyledPanelBody>
  </Panel>
)

interface AccordionWrapperProps {
  children: ReactNode
}

export const AccordionWrapper = ({ children }: AccordionWrapperProps) => (
  <StyledCollapse
    expandIcon={() => (
      <Down />
    )}
    expandIconPosition="right"
    accordion={true}
    defaultActiveKey={['0']}
  >
    {children}
  </StyledCollapse>
)
