import React, { Component } from 'react'
// components
import CostSummary from '../comparison/costSummary'
import { StyledContainer, StyledTabHeader, StyledTabNav, StyledTabPane, StyledTabs, TabHeader } from './styles'

import ToggleWrapper, { ToggleOption } from '../../common/toggle'
import TermOptions from '../termOptions/termOptions'
import HeadcountProjection from '../headcountProjection'
// charts

const toggleOptions: readonly ToggleOption[] = [{
  text: 'Comparison',
  value: '1',
},
  {
    text: 'Term Options',
    value: '2',
  },
  {
    text: 'Headcount Projection',
    value: '3',
  }]

interface State {
  tab: string
}

export class DetailedResultsSummary extends Component<{}, State> {
  state = {
    tab: '1',
  }

  changeTab = (key: string) => this.setState({ tab: key })

  render() {
    const { tab } = this.state
    return (
      <StyledContainer>
        <StyledTabNav>
          <ToggleWrapper
            options={toggleOptions}
            callback={this.changeTab}
            value={tab}
            name="detailed-results-toggle"
          />
        </StyledTabNav>
        <StyledTabs
          activeKey={tab}
          onChange={this.changeTab}
        >
          <StyledTabPane
            tab=""
            key="1"
          >
            <CostSummary
              key={tab}
            />
          </StyledTabPane>
          <StyledTabPane
            tab=""
            key="2"
          >
            <TermOptions
             id="term-options--table"
             />
          </StyledTabPane>
          <StyledTabPane
            tab=""
            key="3"
          >
            <StyledTabHeader margin>
              <TabHeader>Project your headcount over time.</TabHeader>
            </StyledTabHeader>
            <HeadcountProjection
              id="headcount-projection--table"
              key={tab}
            />
          </StyledTabPane>
        </StyledTabs>
      </StyledContainer>
    )
  }
}

export default DetailedResultsSummary
