import round from 'lodash/round'

export const rentFreePeriodCalc = ({ initialDesiredLeaseTerm, rentFreePeriod }) => (round(rentFreePeriod * 12 * initialDesiredLeaseTerm))

export const tiDefaultCalc = ({
  tenantIncentives,
  initialDesiredLeaseTerm,
  currencyValue,
  measureValue
}) => round(tenantIncentives * initialDesiredLeaseTerm * currencyValue * measureValue, 2)

// formula for CA-G81
export const usableSeatAreaReqYear2Calc = ({
  usableSeatArea,
  initialSeats,
  spacePlanningAssumption,
  annualSeatChange,
  seatVolatility,
}) => Math.round((usableSeatArea * initialSeats * (1 + spacePlanningAssumption) * ((1 + annualSeatChange + (0.4 * seatVolatility)) ** 3)))


export const rentableSeatAreaCalc = ({
  totalGrossRentableAreaYear2,
  initialSeats,
}) => Math.round(totalGrossRentableAreaYear2 / initialSeats)

// formula for CA-G84
export const totalGrossRentableAreaYear2Calc = ({
  usableSeatArea,
  spacePlanningAssumption,
  initialSeats,
  annualSeatChange,
  lossFactor,
  seatVolatility,
}) => (
  Math.round((1 + lossFactor) * usableSeatArea * (1 + spacePlanningAssumption) * initialSeats * ((1 + annualSeatChange + (0.4 * seatVolatility)) ** 3))
)
