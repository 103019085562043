/**
 * This component displays the results of iterations received from the
 * backend. Based on chart ID, it will render the respective values.
 */

import React from 'react'
import { connect } from 'react-redux'
import CostComparisonTable from '../printComparisonTable'
import { selectCostComparisonLeases } from '../selectors'
import {
  selectInitialSeats
} from '../../../redux/calculations/calculation.selectors'
import { selectCurrencySymbol } from '../../../redux/unitCurrency/unitCurrency.selectors'

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
  measureName: string,
}

type Props = OwnProps & StateProps

export const PrintCostSummary = (
  {
    measureName,
    currencySymbol,
    initialSeats = 0,
    leases,
  }: Props) => {
  return (
    <CostComparisonTable
      currencySymbol={currencySymbol}
      measureName={measureName}
      initialSeats={initialSeats}
      leases={leases}
    />
  )
}

const mapStateToProps = (state: any) => ({
  initialSeats: selectInitialSeats(state),
  leases: selectCostComparisonLeases(state),
  currencySymbol: selectCurrencySymbol(state),
})

export default connect(mapStateToProps)(PrintCostSummary)
