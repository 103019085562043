import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import { useTimeout } from '../../../utils/hooks/hooks'
import { NonEditField, StyledInputRelativeContainer, StyledNonEditValue } from './styles'
import { InputTitle } from './InputTitle'
import { Input } from './input'
import { inputSelectAll } from '../../../utils/inputSelectAll'

export const FORM_IDLE_TIMEOUT = 1000

export const onBlur = (e: any,
                       adjustedValue: any,
                       onChangeValue: any,
                       inputValueChange: React.Dispatch<SetStateAction<number | undefined>>,
                       innerValueChange: React.Dispatch<SetStateAction<number | undefined>>,
                       inputTouchedChange: React.Dispatch<SetStateAction<boolean>>,
                       onChange: (value: any) => void,
                       submitForm: (value: any) => void) => {
  e.target.blur()
  inputValueChange(adjustedValue)
  innerValueChange(adjustedValue)
  onChange(onChangeValue)
  inputTouchedChange(false)
  submitForm(onChangeValue)
}

export type ValueAdjuster<T = any> = (value: T, isSubmitting: boolean, forInput: boolean) => T

export type Validator<T = any> = (value: T) => boolean

export interface SubmittingInputProps {
  label: string
  initialValue?: any
  onChange?: (value: any) => void
  submitForm?: (value: any) => void
  // called when form submitting disabled, but a submit would have been called
  formNoSubmit?: (value: any) => void
  disableEditing?: boolean
  tooltipText?: string
  bottomText?: string
  suffix?: string
  disableSubmit?: boolean
  onBlur: (e: any,
           inputValueChange: React.Dispatch<SetStateAction<number | undefined>>,
           innerValueChange: React.Dispatch<SetStateAction<number | undefined>>,
           inputTouchedChange: React.Dispatch<SetStateAction<boolean>>,
           onSubmit: (value: any) => void) => void

  /**
   * @param value the value to adjust
   * @param isSubmitting if submitting, this is reverse
   * @param for input, if true, then maybe round?
   */
  valueAdjuster?: ValueAdjuster
  defaultOnChange?: any
  inputFilter?: (value: any) => any
  placeholder?: string
  id: string
  // input type
  type?: string
  expandable?: boolean
  maxLength?: number
  validator?: Validator
}

export const SubmittingInput = (
  {
    label,
    initialValue,
    onChange = () => ({}),
    submitForm = () => ({}),
    formNoSubmit = () => ({}),
    valueAdjuster = (value) => value,
    disableEditing = false,
    tooltipText,
    bottomText,
    disableSubmit,
    suffix,
    onBlur,
    defaultOnChange,
    placeholder,
    id,
    type,
    inputFilter = (value) => value,
    expandable,
    maxLength,
    validator = (value) => true
  }: SubmittingInputProps) => {
  const initialAdjustedValue = valueAdjuster(initialValue, false, false)
  const [inputTouched, inputTouchedChanged] = useState(false)
  const [inputValue, inputValueChanged] = useState<any | undefined>(initialAdjustedValue)
  const [innerValue, innerValueChanged] = useState<any | undefined>(initialAdjustedValue)

  const onSubmit = disableSubmit ? formNoSubmit : submitForm

  const error = validator(innerValue)
  // trigger change on
  useTimeout(FORM_IDLE_TIMEOUT, () => {
    if (inputTouched && error) {
      onSubmit(valueAdjuster(inputValue, true, false))
    }
  }, [inputValue, inputTouched, error])
  // have to add a ref to prevent recursive loop
  const latestOnChange = useRef(onChange)
  useEffect(() => {
    latestOnChange.current = onChange
  }, [onChange])

  useEffect(() => {
    // dispatch onChange whenever initial value changes.
    latestOnChange.current(initialAdjustedValue || defaultOnChange)
  }, [
    initialAdjustedValue,
    defaultOnChange,
    latestOnChange,
  ])
  if (disableEditing) {
    return (<NonEditField>
      <InputTitle
        id={`${id}--title`}
        text={label}
        disabledEdit
        tooltipText={tooltipText} />
      <StyledNonEditValue>
        {initialAdjustedValue} {bottomText}
      </StyledNonEditValue>
    </NonEditField>)
  }
  const formattedInputValue = inputTouched ? inputFilter(inputValue) : valueAdjuster(initialValue, false, true)
  return (
    <StyledInputRelativeContainer>
      <Input
        type={type}
        maxLength={maxLength}
        placeholder={placeholder}
        id={id}
        callback={(value: any | undefined) => {
          if (value !== innerValue) {
            const adjustedValue = value && valueAdjuster(value, true, false)
            innerValueChanged(adjustedValue)
            inputValueChanged(value)
            inputTouchedChanged(true)
            onChange(adjustedValue || defaultOnChange)
          }
        }}
        onBlur={(e) => onBlur(e, inputValueChanged, innerValueChanged, inputTouchedChanged, onSubmit)}
        handleInputTouch={() => inputTouchedChanged(true)}
        inputSelectAll={inputSelectAll}
        value={formattedInputValue}
        label={label}
        tooltipText={tooltipText}
        bottomText={bottomText}
        suffix={suffix}
        expandable={expandable}
        error={!error}
      />
    </StyledInputRelativeContainer>
  )
}
