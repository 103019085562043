/**
 * @param {number} - value: Number passed in to be formatted
 * @param {number} - abrDigits: Space to abbreviate
 */

import round from 'lodash/round'

export const abbNumber = (value: string | number, abrDigits = 2): string => {
  const toNumber = (numVal: number | string) => {
    const val = typeof numVal === 'string' ? parseFloat(numVal.replace(/,/g, '')) : numVal
    return Math.floor(val)
  }

  const num = toNumber(value)
  if (num >= 1000000000) return `${round(num / 1000000000, abrDigits)}B`
  if (num >= 1000000) return `${round(num / 1000000, abrDigits)}M`
  if (num >= 1000) return `${round(num / 1000, 0)}k`
  return `${num}`
}
