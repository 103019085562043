/**
 *
 * @param {string} key: add key/value to local storage
 */

export const setItem = (key: string, value: any) => localStorage.setItem(key, value)

/**
 *
 * @param {string} key: get value for key in local storage
 */

export const getItem = (key: string): string | null => localStorage.getItem(key)


