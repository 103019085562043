import styled from 'styled-components'
import { HeadlineH5Default, UIDefault } from '../../../styles/text.styles'
import Clipboard from 'react-clipboard.js'
import { COLORS, FONTS } from '../../../constants/styles'

export const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledRowTitle = styled.span`
  padding-top: 25px;
  ${HeadlineH5Default}
`

export const StyledRowContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledRowContent = styled.span`
  padding-top: 16px;
  ${UIDefault}
  flex: 1;
`

export const StyledOptionButton = styled(Clipboard)`
  font-family: ${FONTS.PRIMARY};
  font-size: 14px;
  color: ${COLORS.PRIMARY_00};
  letter-spacing: normal;
  border-color: transparent;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
`
