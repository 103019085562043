export interface ScenarioUnitCurrency {
  readonly unitOfMeasure: number
  readonly unitOfTime: number
  readonly currency: number
}

export const UNTITLED_SCENARIO_NAME = 'Untitled Scenario'

export const INITIAL_NPV_DISCOUNT_RATE = 8
export const MAX_LEASE_NAME_LENGTH = 30

export interface MultiLeaseScenario {
  readonly _id?: string
  readonly _rev?: string
  readonly leases: readonly ScenarioLease[]
  readonly annualSeatChangeVariance: number
  readonly email: readonly string[]
  readonly discountRate: number
  readonly market: string
  readonly unitCurrency: ScenarioUnitCurrency
  readonly location: number
  // boolean number
  readonly save: number
  readonly print: number
  readonly scenarioName: string
  readonly measureName: string
  readonly initialDesiredLeaseTerm: number
  readonly periodName: string
  readonly currencyName: string
  readonly seatVolatility: number
  readonly initialSeats: number
  readonly annualSeatChange: number
  readonly timestamp: string

  /**
   * If duplicated from another scenario.
   */
  readonly isDuplicated?: boolean
}

export enum ScenarioLeaseType {
  FlexCoworking = 'flexCoworking',
  Traditional = 'trad'
}

export interface UpdateLeaseBody {
  readonly revId: string
  readonly lease: ScenarioLease
}

export interface ScenarioLease {
  readonly type: ScenarioLeaseType
  readonly costEscalation: number
  readonly id: string
  readonly name: string
}

export type ScenarioLeaseFlexCoworkingInputs = Omit<ScenarioLeaseFlexCoworking, 'id' | 'type'>

export interface ScenarioLeaseFlexCoworking extends ScenarioLease {
  readonly type: typeof ScenarioLeaseType.FlexCoworking
  readonly otherOpexCharges: number
  readonly quotedSeatCharges: number
  readonly oneTimeSetupCost: number
  readonly monthlyMeetingRoomCost: number
  readonly meetingRoomChargesHourly: number
  readonly meetingRoomRequirement: number
}

export type ScenarioLeaseTraditionalInputs = Omit<ScenarioLeaseTraditional, 'id' | 'type'>

export interface ScenarioLeaseTraditional extends ScenarioLease {
  readonly type: typeof ScenarioLeaseType.Traditional
  readonly rentRate: number
  readonly rentFreePeriodValue: number
  readonly rentableSeatArea: number
  readonly tiAllowanceValue: number
  readonly rentEscalationRate: number
  readonly operatingCosts: number
  readonly facilityManagement: number
  readonly otherMiscOpex: number
  readonly capexHardCost: number
  readonly capexFFE: number
  readonly capexSoftCost: number
  readonly capexITData: number
  readonly reinstatement: number
  readonly earlyExitPenalty: number
  readonly usableSeatArea: number
  readonly spacePlanningAssumption: number
  readonly lossFactor: number
  readonly usableSeatAreaReqYear2: number
  readonly totalGrossRentableAreaYear2: number
}

export type ScenarioLeases =
  | ScenarioLeaseTraditional
  | ScenarioLeaseFlexCoworking

export interface RemoveLeaseBody {
  readonly revId: string
  readonly leaseId: string
}

/**
 * Used for default on all {@link CalculateIterationsInput.timeValue}
 */
export const TIME_VALUE_DEFAULT = 1

export interface CalculateIterationsInput {

  /**
   * Unit of time number.
   */
  readonly timeValue: number

  /**
   * The partial scenario data to use in calculation.
   */
  readonly scenario: ScenarioData

  /**
   * The set of leases to calculate iterations for.
   */
  readonly leases: readonly ScenarioLease[]

  /**
   * Set of testing values to spit out consistent results.
   */
  randomNumOverride?: number | null
}

export interface ScenarioData {
  readonly _id: string
  readonly discountRate: number
  readonly initialDesiredLeaseTerm: number
  readonly seatVolatility: number
  readonly initialSeats: number
  readonly annualSeatChange: number
}

/**
 * The set ot calculations that correspond for each lease calculated
 */
export interface CalculateIterationsResult {
  readonly seatChartResults: Readonly<PCCalcResults>
  readonly calculations: LeaseWithCalculation<any>[]
}

export interface PCCalcResults {
  low: number[],
  avg: number[],
  high: number[],
}

export interface LeaseWithCalculation<T = any> {
  readonly id: string
  readonly type: ScenarioLeaseType
  readonly totalCost: PCCalcResults
  readonly costSeatYear: PCCalcResults
  readonly npvSeatYear: PCCalcResults
  readonly totalNPV: PCCalcResults
  readonly breakdown: T
}

export interface TraditionalLeaseCalculation extends LeaseWithCalculation<{
  readonly rentCost: number
  readonly facilMgmtCost: number
  readonly oneTimeTotalCost: number
}> {
  readonly type: typeof ScenarioLeaseType.Traditional
  readonly netArea: PCCalcResults
}

export interface FlexLeaseCalculation extends LeaseWithCalculation<{
  readonly meetAncCost: number
  readonly seatCharges: number
  readonly oneTimeCosts: number
}> {
  readonly type: typeof ScenarioLeaseType.FlexCoworking
}

export type LeaseWithCalculations =
  | FlexLeaseCalculation
  | TraditionalLeaseCalculation

/**
 * The main logic behind running the iterations.
 */
export type ObjectNumArray = { [index: number]: (number | null) [] }

export interface BaseCostData {
  readonly id: string
  readonly type: ScenarioLeaseType
  readonly totalCostIt: ObjectNumArray
  readonly totalNPVIt: ObjectNumArray
  readonly costSeatYearIt: ObjectNumArray
  readonly npvSeatYearIt: ObjectNumArray
}

export interface TraditionalLeaseCostData extends BaseCostData {
  netAreaIt: ObjectNumArray
  rentCostArray: number[]
  facilMgmtCostArray: number[]
  oneTimeTotalCostArray: number[]
  baseSeatIt: ObjectNumArray
}

export interface FlexLeaseCostData extends BaseCostData {
  meetAncCostArray: number[]
  seatChargesArray: number[]
  oneTimeCostsArray: number[]
}

export interface SendEmailBody {
  readonly email: string

  /**
   * The url to populate the body for links with.
   */
  readonly referenceUrl: string

  /**
   * The scenario to update / insert into the DB.
   */
  readonly scenario: MultiLeaseScenario
}

/**
 * Represents the body of saveScenario
 */
export interface SaveScenarioBody {
  /**
   * The scenario to update / insert into the DB.
   */
  readonly scenario: MultiLeaseScenario
    /**
   * The calculations to insert into the outputs DB.
   */
  readonly outputs: CalculateIterationsResult
}

/**
 * Represents a cost comparison column object.
 */
export interface CostComparisonLease {
  readonly id: string
  readonly name: string
  readonly type: ScenarioLeaseType

  /**
   * AVG NPV based on year term
   */
  readonly npv: number

  /**
   * This is the Total Cumulative Cost (Cash)
   */
  readonly totalCosts: number

  /**
   * Cost / Head / Year
   */
  readonly costSeatYear: number

  /**
   * One-time cost (either {@link TraditionalLeaseCalculation.breakdown.oneTimeTotalCost} ,
   * or {@link FlexLeaseCalculation.breakdown.oneTimeCosts}
   */
  readonly oneTimeCosts: number

  /**
   * This is operating costs and meeting rooms costs.
   * i.e. {@link TraditionalLeaseCalculation.breakdown.facilMgmtCost}
   * or {@link FlexLeaseCalculation.breakdown.meetAncCost}
   */
  readonly annualReOccurringCosts: number

  /**
   * Average annual cost.
   */
  readonly annualCost: number

  /**
   * If specified, its for a {@link ScenarioLeaseType.Traditional}
   */
  readonly netArea?: number
}

/**
 * Data structure that represents a lease in term options.
 */
export interface TermOptionsLease {
  readonly id: string
  readonly name: string
  readonly type: ScenarioLeaseType

  readonly npv: PCCalcResults

  /**
   * This is the Total Cumulative Cost (Cash)
   */
  readonly totalCosts: PCCalcResults

  /**
   * Cost / Head / Year
   */
  readonly costSeatYear: PCCalcResults
}

