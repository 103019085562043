import styled from 'styled-components'
import { TableBorder } from '../../../../styles/border.styles'

import { StyledShadowBox } from '../../../../styles/shadow.styles'


export const StyledBottomContainer = styled.div`
  min-width: 476px;
  display: flex;
  justify-content: space-between;

`

interface StyledScrollerProps {
  readonly loading: boolean
}
export const StyledScroller = styled('div')<StyledScrollerProps>`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  transition: opacity 300ms ease-in-out;
  opacity: ${({ loading }) => (loading ? 0.35 : 1)};
  border-top: ${TableBorder};
  border-left: ${TableBorder};
  margin: 4px;
  ${StyledShadowBox}
`
