import { LoadingModel } from '../../data/loading_model'
import { LeaseWithCalculation, PCCalcResults } from '../../data/models'
import { CalculationActions, CalculationActionTypes } from './calculation.actions'
import { CompanyInputsShape, defaultCompanyInputs } from '../../data/form_models'
import { ChartOption, chartOptions, ChartTypeId } from '../../utils/selectOptions/chartOptions'


export interface CalculationsState {
  readonly results: LoadingModel<Map<string, LeaseWithCalculation>>
  readonly seatChartResults: Readonly<PCCalcResults>
  readonly companyInputs: CompanyInputsShape
  readonly selectedChartOption: ChartOption
}

export const initialCalculationState: CalculationsState = {
  companyInputs: defaultCompanyInputs,
  results: LoadingModel.empty(),
  // default option
  selectedChartOption: chartOptions.find(f => f.value === ChartTypeId.costSeatYear)!,
  seatChartResults: {
    low: [],
    avg: [],
    high: [],
  },
}

export const calculationReducer = (state: CalculationsState = initialCalculationState,
                                   action: CalculationActionTypes): CalculationsState => {
  switch (action.type) {
    case CalculationActions.CalculateLeaseResults:
    case CalculationActions.CalculateAllLeases:
      return {
        ...state,
        results: state.results.loading(),
      }
    case CalculationActions.CalculateAllLeasesFailed:
    case CalculationActions.CalculateLeaseResultsFailed:
      return {
        ...state,
        results: LoadingModel.error(action.error, state.results.optionalSuccess),
      }
    case CalculationActions.CalculateAllLeasesSucceeded:
      // always overwrite since this is a global change
      return {
        ...state,
        // associate by lease id
        results: LoadingModel.success(action.body.calculations.reduce((acc, obj) => {
          acc.set(obj.id, obj)
          return acc
        }, new Map())),
        seatChartResults: action.body.seatChartResults,
      }
    case CalculationActions.CalculateLeaseResultsSucceeded:
      // merge results as it can be 1...n leases
      const results = state.results.optionalSuccess || new Map<string, LeaseWithCalculation>()
      return {
        ...state,
        results: LoadingModel.success(action.body.calculations.reduce((acc, obj) => {
          acc.set(obj.id, obj)
          return acc
        }, results)),
        seatChartResults: action.body.seatChartResults,
      }
    case CalculationActions.RemoveLeaseFromCalculation:
      // when lease is removed, remove from state and no need to recalculate results.
      const existingResults = state.results.optionalSuccess
        ? new Map<string, LeaseWithCalculation>(state.results.optionalSuccess)
        : new Map<string, LeaseWithCalculation>()

      // remove any no longer found from existing set of results.
      const notFound: Map<string, LeaseWithCalculation> = new Map<string, LeaseWithCalculation>(existingResults)
      action.leaseIds.forEach(l => notFound.delete(l))
      return {
        ...state,
        results: LoadingModel.success(notFound),
      }
    case CalculationActions.ClearCompanyInputs:
      return {
        ...state,
        companyInputs: defaultCompanyInputs,
      }
    case CalculationActions.StoreCompanyInputs:
      return {
        ...state,
        companyInputs: action.body,
      }
    case CalculationActions.SelectChartOption:
      return {
        ...state,
        selectedChartOption: action.option,
      }
  }
  return state
}
