import styled from 'styled-components'
import { HeadlineH3Dark } from '../../../styles/text.styles'
import { ResultsSummaryCard } from '../resultsSummaryCard'
import { StandardCard } from '../../../styles/card.styles'
import ButtonWrapper from '../../common/button'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { COLORS, LAYOUT } from '../../../constants/styles'


export const ResultsContainer = styled.div`
 display: flex;
 flex-direction: column;
`

export const StyledLeaseResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: ${LAYOUT.SCROLLBAR_WIDTH}px;

   ${breakpoint(BREAKPOINTS.desktopMax)`
     flex-direction: row;
     overflow-x: auto;
     overflow-y: none;
     flex: 1;
     padding: 0;
 `}
`

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 24px;
  position: sticky;
  background-color: ${COLORS.SECONDARY_00};
  bottom: 0;
  /* TODO add JS tracking of location to enable shadow */
  /* ${StandardCard} */
`

export const StyledButtonWrapperWithMargin = styled(ButtonWrapper)`
  margin-left: 24px;
`
export const StyledMobileButtonWrapper = styled(ButtonWrapper)`
  button{
    width: 100%;
    max-width: 100%;
  }
`

export const StyledResultsSummaryCard = styled(ResultsSummaryCard)`
  margin-top: 24px;
   ${breakpoint(BREAKPOINTS.desktopMax)`
     margin-top: 0;
 `}
`

export const ResultsTitle = styled.div`
  margin-bottom: 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-right: 8px;
  }
  ${HeadlineH3Dark}
`

export const MobileResultsContainer = styled.div`
  background-color: ${COLORS.HIGHLIGHT};
  display: flex;
`

export const StyledAddButton = styled(ButtonWrapper)`
`
