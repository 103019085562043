export const annualSeatChange = [
  {
    optionName: '12.5% Contraction',
    optionShortName: 'Contraction',
    value: -0.125
  },
  {
    optionName: '7.5% Contraction',
    optionShortName: 'Mod. Contraction',
    value: -0.075
  },
  {
    optionName: '2.5% Contraction',
    optionShortName: 'Low Contraction',
    value: -0.025
  },
  {
    optionName: '0% Growth',
    optionShortName: 'Flat',
    value: 0
  },
  {
    optionName: '2.5% Growth',
    optionShortName: 'Low Growth',
    value: 0.025
  },
  {
    optionName: '5% Growth',
    optionShortName: 'Growth',
    value: 0.05,
  },
  {
    optionName: '10% Growth',
    optionShortName: 'Growth',
    value: 0.10,
  },
  {
    optionName: '15% Growth',
    optionShortName: 'Growth',
    value: 0.15,
  },
  {
    optionName: '20% Growth',
    optionShortName: 'Growth',
    value: 0.20,
  },
  {
    optionName: '25% Growth',
    optionShortName: 'Growth',
    value: 0.25,
  },
]
