import ButtonWrapper from '../../common/button'
import { changeHomeTab } from '../../../redux/home/home.actions'
import { connect, Dispatch } from 'react-redux'
import { selectHasScenario } from '../../../redux/scenario/scenario.selectors'
import { bindActionCreators } from 'redux'
import React from 'react'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

const InternalNextButton = ({ hasScenario, changeHomeTab }: Props) =>
  <ButtonWrapper
    id="next-lease-variables"
    text="Next: Lease Variables"
    disabled={!hasScenario}
    onClick={(e: any) => {
      e.preventDefault()
      return changeHomeTab('2')
    }}
  />

const mapStateToProps = (state: any) => ({
  hasScenario: selectHasScenario(state),
})
const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators({ changeHomeTab }, dispatch)

export const NextButton = connect(mapStateToProps, mapDispatchToProps)(InternalNextButton)
