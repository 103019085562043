
// TODO implement calculations for these options
// Enum starts at 1 as 0 creates an error here
export enum ChartTypeId {
  // averageAnnualCost,
  // averageMonthlyCost,
  costSeatYear = 1,
  cash,
  npv,
}

export interface ChartOption {
  optionName: string
  value: ChartTypeId,
}

export const chartColumns = [
  'Cost / Seat / Year',
  'Annual Recurring Costs',
  'One Time Costs'
]

export const chartOptions: readonly ChartOption[] = [
  // {
  //   optionName: 'Average Annual Cost',
  //   value: ChartTypeId.averageAnnualCost,
  // },
  // {
  //   optionName: 'Average Monthly Cost',
  //   value: ChartTypeId.averageMonthlyCost,
  // },
  {
    optionName: 'Cost / Seat / Year',
    value: ChartTypeId.costSeatYear,
  },
  {
    optionName: 'Total Cumulative Cost (Cash)',
    value: ChartTypeId.cash,
  },
  {
    optionName: 'Total Cumulative Cost (NPV)',
    value: ChartTypeId.npv,
  },
]
