import React from 'react'
import { StyledLabel, StyledLabelContainer, StyledLabelContainerProps, StyledValue, StyledValueTitle } from './styles'

interface ValueProps extends StyledLabelContainerProps {
  value: string
  title?: string
}

export const CostComparisonDataValue = ({ value, isHighlighted, title }: ValueProps) =>
  <StyledLabelContainer
    isHighlighted={isHighlighted}
    isValue
  >
    <StyledValueTitle>{title}</StyledValueTitle>
    <StyledValue>{value}</StyledValue>
  </StyledLabelContainer>

interface LabelProps extends StyledLabelContainerProps {
  label: string
}

export const CostComparisonDataLabel = ({ label, isHighlighted, isHeader }: LabelProps) => {
  return <StyledLabelContainer
    isValue={false}
    isHeader={isHeader}
    isHighlighted={isHighlighted}>
    <StyledLabel>{label}</StyledLabel>
  </StyledLabelContainer>
}
