import styled, { css } from 'styled-components'
import { COLORS } from '../../../../constants/styles'
import { HeadlineH4Default, HeadlineH4Small, UICaps, UISmallDefault } from '../../../../styles/text.styles'
import breakpoint from '../../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../../constants/breakpoints'
import { TableBorder } from '../../../../styles/border.styles'

export interface StyledLabelContainerProps {
  isHighlighted?: boolean
  isHeader?: boolean
  isValue?: boolean
}

export const StyledLabelContainer = styled('div')<StyledLabelContainerProps>`
  display: flex;
  flex-direction: column;
  border-bottom: ${TableBorder};
  border-right: ${TableBorder};
  justify-content: center;
  flex: 1;
  align-items: center;
  text-align: center;
  padding: 25px;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    align-items: start;
    text-align: left;
    padding: 10px;
    ${({ isValue }: StyledLabelContainerProps) => isValue && css`
      justify-content: flex-start;
    `}
  `}
  ${({ isHighlighted, isHeader }: StyledLabelContainerProps) => css`
    ${isHighlighted && `background: ${COLORS.SHADOW_02}`}
    ${isHeader ? 'height: 225px' : 'height: 100%'}
    ${isHeader && 'min-height: 225px'}
    ${breakpoint(BREAKPOINTS.mobileMax)`
      ${!isHeader && css`
        height: 100%;
      `}
      ${isHeader && css`
        height: 120px;
        min-height: 120px;
        max-height: 120px;
        justify-content: flex-start;
        padding-top: 31px;
      `}
    `}
  `}
`

export const StyledValue = styled.h4`
  ${UICaps}
`
export const StyledLabel = styled.h4`
  ${breakpoint(BREAKPOINTS.mobileMax)`
    ${HeadlineH4Small}
  `}
  ${HeadlineH4Default}
`
export const StyledValueTitle = styled.span`
${breakpoint(BREAKPOINTS.mobileMax)`
    display: -webkit-box;
    margin-bottom: 4px;
    min-height: 36px;
    height: 36px;
    max-height: 36px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
  ${UISmallDefault}
`
