import styled from 'styled-components'
import breakpoint from '../../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../../constants/breakpoints'
import { StyledShadowBox } from '../../../../styles/shadow.styles'

export const StyledComparisonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 4px;
  ${StyledShadowBox}
`

interface StyledComparisonGridProps {
  isCurrentObjectReflected: boolean
}

const desktopRowHeight = '94px'
const mobileRowHeight = '120px'
export const StyledComparisonGrid = styled.div<StyledComparisonGridProps>`
  display: grid;
  grid-template-rows: ${props => props.isCurrentObjectReflected
  ? `225px ${desktopRowHeight} ${desktopRowHeight} ${desktopRowHeight}`
  : `225px ${desktopRowHeight} ${desktopRowHeight} ${desktopRowHeight} ${desktopRowHeight}`};
  grid-auto-columns: 238px;
  grid-auto-flow: column;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    grid-template-rows: ${(props: StyledComparisonGridProps) => props.isCurrentObjectReflected
  ? `${mobileRowHeight} ${mobileRowHeight} ${mobileRowHeight} ${mobileRowHeight}`
  : `${mobileRowHeight} ${mobileRowHeight} ${mobileRowHeight} ${mobileRowHeight} ${mobileRowHeight}`};
    grid-auto-columns: minmax(114px, auto);
  `}
`

export const StyledComparisonLeftColumn = styled.div`
 display: flex;
 flex-direction: column;
 width: 238px;
 max-width: 238px;
 min-width: 238px;
 ${breakpoint(BREAKPOINTS.mobileMax)`
    width: 114px;
    max-width: 114px;
    min-width: 114px;
 `}

 flex: 1;
 position: sticky;
 left: 0;
 background: white;
 z-index: 1;
`
