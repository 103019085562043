import React from 'react'
import styled from 'styled-components'

import { UIDefault } from '../../../styles/text.styles'
import { Tooltip } from '../tooltip'

interface Props {
  text: string
  tooltipText?: string
  id?: string
}

export const Label = ({ text, tooltipText, id }: Props) => (
  <StyledLabel>
    <span id={id}>{text}</span>
    {!!tooltipText &&
    <Tooltip
      id={`${id}--tooltip`}
      title={tooltipText}
    />
    }
  </StyledLabel>
)

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  padding-left: 3px;
  height: 22px;

  span {
    white-space: nowrap;
    margin-right: 8px;
    ${UIDefault}
  }
`
