/**
 * Returns window setTimeout and clearTimeout with types
 */
export interface TimeoutHandler<T> {
    setTimeout: (fn: () => void, timeout: number) => T;
    clearTimeout: (timeout: T | undefined) => void;
}

export const timeoutHandler: TimeoutHandler<number> = {
    setTimeout: (fn: () => void, timeout: number) => window.setTimeout(fn, timeout),
    clearTimeout: (timeout: number | undefined) => window.clearTimeout(timeout),
}
