import { ModalActionTypes, showModal } from '../../redux/modal/modal.actions'
import { removeLeaseLocally, startOverPreserveInputs } from '../../redux/scenario/scenario.actions'


export const showDeleteLeaseModal = (
  showModalAction: typeof showModal,
  leaseId: string,
  removeLeaseLocallyAction: typeof removeLeaseLocally): ModalActionTypes =>
  showModalAction({
    title: 'Delete Lease',
    body: 'Deleting a lease is permanent and can\'t be undone.\nAre you sure you want to delete this lease?',
    actionButtonText: 'Cancel',
    secondaryButtonText: 'Yes',
    secondaryAction: () => removeLeaseLocallyAction(leaseId),
    name: 'deleteLease',
  })

export const showStartOverLeaseModal = (
  showModalAction: typeof showModal,
  startOverPreserveInputsAction: typeof startOverPreserveInputs,
): ModalActionTypes =>
  showModalAction({
    title: 'Delete Lease',
    body: 'You must start over to delete either of the two remaining leases.',
    actionButtonText: 'Cancel',
    secondaryButtonText: 'Start Over',
    secondaryAction: () => startOverPreserveInputsAction(),
    name: 'startOverLease',
  })
