import styled, { css } from 'styled-components'
import { Dropdown } from '../../common/dropdown/dropdown'
import breakpoint from '../../../utils/breakpoints'
import { BREAKPOINTS } from '../../../constants/breakpoints'
import { ResultDisplayMode } from './index'

interface ResultDisplayModeProps {
  displayMode: ResultDisplayMode
}

export const StyledResultsContainer = styled.div<ResultDisplayModeProps>`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;

  ${breakpoint(BREAKPOINTS.mobileMax)`
    flex-direction: column;
    align-items: flex-start;
  `}

  ${({ displayMode }) => displayMode === ResultDisplayMode.Condensed && css`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const StyledSelectOption = styled.div<ResultDisplayModeProps>`
  width: 100%;
  ${breakpoint(BREAKPOINTS.mobileMax)`
    padding-top: 12px;
    padding-bottom: 15px;
  `}
   ${({ displayMode }) => {
  switch (displayMode) {
    case ResultDisplayMode.Condensed:
      return css`
        padding-bottom: 24px;
      `
    case ResultDisplayMode.Full:
      return css`
        ${breakpoint(BREAKPOINTS.mobileMin)`
         margin-right: 16px
        `}
        ${breakpoint(BREAKPOINTS.desktopMin)`
          margin-right: 34px;
        `}
      `
  }
}}

`
export const StyledDropdownWrapper = styled(Dropdown)`
  padding-top: 21px;
  ${breakpoint(BREAKPOINTS.desktopMax)`
    width: 240px;
  `}
`

export const StyledDiscountPlaceholder = styled.div`
  min-width: 176px;
`

export const StyledInputContainer = styled.div<ResultDisplayModeProps>`
  width: 100%;
  max-width: 167px;
`
