import { HomeActions, HomeActionTypes } from './home.actions'

export interface HomeState {
  currentTab: string
}

export const defaultHomeState: HomeState = {
  currentTab: '1',
}

export const homeReducer = (state: HomeState = defaultHomeState, action: HomeActionTypes): HomeState => {
  switch (action.type) {
    case HomeActions.ChangeTab:
      return {
        ...state,
        currentTab: action.tab,
      }
    default:
      return state
  }
}
