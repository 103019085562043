import round from 'lodash/round'
import { foundValue } from '../utils/arrayHelpers'
import * as selectOpt from '../utils/selectOptions'

export const monthlyMeetingCharge =
  ({ meetingRoomChargesHourly, meetingRoomRequirement, meetingRoomChargesMonthly = undefined }) => {
    const meetingRoomRequirementValue = foundValue(selectOpt.meetingRoomRequirements, meetingRoomRequirement).value
    return (
      round(meetingRoomChargesMonthly || meetingRoomChargesHourly * meetingRoomRequirementValue, 2)
    )
  }
